export const renderDoseValue = (value: number): string => {
	const int = Math.floor(value)
	switch (value.toFixed(2).split('.')[1]) {
		case '00':
			return value.toFixed(0)
		case '25':
			return `${int || ''} ¼`
		case '33':
			return `${int || ''} ⅓`
		case '50':
			return `${int || ''} ½`
		case '67':
			return `${int || ''} ⅔`
		case '75':
			return `${int || ''} ¾`
		default:
			return value.toFixed(2)
	}
}
