import {
	Box,
	IconButton,
	ListItem,
	OrderedList,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	UnorderedList,
	useDisclosure,
} from '@chakra-ui/react'
import {
	Datepicker,
	formatDate,
	TableButton,
	Tile,
	TileGrid,
	TilePrintButton,
} from '@kevea/react-components'
import { MenuDay } from 'models/menu'
import React, {
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { FaAngleLeft, FaAngleRight, FaCopy, FaUtensils } from 'react-icons/fa'
import { useQuery } from 'react-query'
import { MenuService } from 'services/menu'
import { MenuModal } from './MenuModal'
import { Menu as MenuClass } from 'models/menu'
import { SelectedServiceContext } from 'App'
import { CopyModal } from './CopyModal'

export const NA_WZGORZU_ID = '65a7d3ed9daa60001d636104'

export function renderDishNameWithCalories(
	menu: MenuClass,
	isDiabetic?: boolean,
): ReactNode {
	if (menu.dish?.calories || menu.dish?.weight) {
		return (
			<span>
				{menu.dish?.name}
				{isDiabetic ? ' (C)' : ''}
				<br />{' '}
				<span style={{ fontSize: 10 }}>
					({menu.dish?.calories ?? 0} kcal, {menu.dish?.weight ?? 0} g)
				</span>
			</span>
		)
	}
	return menu.dish?.name
}

function getMonday(date: string) {
	const d = new Date(date)
	const day = d.getDay(),
		diff = d.getDate() - day + (day == 0 ? -6 : 1) // adjust when day is sunday
	return new Date(d.setDate(diff))
}

const getWeekDateRange = (date: string): [string, string] => {
	const startDate = getMonday(date)
	const endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000)
	return [
		formatDate(startDate.toISOString(), 'date'),
		formatDate(endDate.toISOString(), 'date'),
	]
}

type RenderMenuDaysProps = {
	menuDays: MenuDay[]
	setSelectedMenuDay: (menuDay: MenuDay) => void
}

const RenderMenuDays = ({
	menuDays,
	setSelectedMenuDay,
}: RenderMenuDaysProps) => {
	const [hoverColumnId, setHoverColumnId] = useState<null | number>(null)

	if (!menuDays) return null
	return (
		<>
			{[0, 3, 1, 4, 2].map(type => (
				<Tr key={type}>
					<Th fontSize={11}>
						{type === 0
							? 'Śniadanie'
							: type === 1
							? 'Obiad'
							: type === 2
							? 'Kolacja'
							: type === 3
							? 'Drugie śniadanie'
							: type === 4
							? 'Podwieczorek'
							: null}
					</Th>
					{menuDays?.map((day, idx) => (
						<Td
							key={idx}
							verticalAlign="top"
							h={36}
							w="100%"
							p={2}
							borderLeft="1px solid var(--chakra-colors-gray-100)"
							cursor="pointer"
							bg={
								hoverColumnId === idx ? 'var(--chakra-colors-gray-50)' : 'white'
							}
							onClick={() => setSelectedMenuDay(day)}
							onMouseOver={() => setHoverColumnId(idx)}
							onMouseOut={() => setHoverColumnId(null)}
						>
							<Box overflowY="auto" w="100%" h="100%">
								<UnorderedList fontSize={14}>
									{day.dishes
										.filter(
											dish => dish.type === type || dish.type === type + 10,
										)
										.sort((a, b) => a.type - b.type)
										.map((menu, idx) =>
											menu.dish && menu.type === type + 10 ? (
												<ListItem key={idx} style={{ color: 'orange' }}>
													{renderDishNameWithCalories(menu, true)}
												</ListItem>
											) : (
												<ListItem key={idx}>
													{renderDishNameWithCalories(menu)}
												</ListItem>
											),
										)}
								</UnorderedList>
							</Box>
						</Td>
					))}
				</Tr>
			))}
		</>
	)
}

type MenuProps = {}

export const Menu: React.FC<MenuProps> = ({}) => {
	const { selectedService } = useContext(SelectedServiceContext)
	const [isLoading, setIsLoading] = useState(false)
	const [selectedMenuDay, setSelectedMenuDay] = useState<MenuDay | undefined>(
		undefined,
	)
	const [selectedDateRange, setSelectedDateRange] = useState<[string, string]>(
		getWeekDateRange(new Date().toISOString()),
	)
	const { isOpen, onOpen, onClose } = useDisclosure()
	const copyDisclosure = useDisclosure()

	const updateMenuDay = async (menuDay: MenuDay) => {
		setIsLoading(true)
		return MenuService.updateDay(menuDay).then(() => {
			setIsLoading(false)
			refetch()
		})
	}

	const setWeekDateRange = (date: string) => {
		setSelectedDateRange(getWeekDateRange(date))
	}

	const {
		data: menuDays,
		isFetching,
		refetch,
	} = useQuery('fetchMenuDays', () =>
		MenuService.getRange(...selectedDateRange).then(res => res.data),
	)

	const data = useMemo(
		() =>
			menuDays?.map(menuDay => new MenuDay(menuDay.date, menuDay.dishes)) ?? [],
		[menuDays],
	)

	const handleCopyModalClose = async (
		currentMenuDays: MenuDay[],
		newMenuDays?: MenuDay[],
	) => {
		if (newMenuDays) {
			const toUpdate: MenuDay[] = []
			for (let i = 0; i < currentMenuDays.length; i++) {
				const menuDay = currentMenuDays[i]
				const newMenuDay = newMenuDays[i]
				if (menuDay && newMenuDay) {
					toUpdate.push({ date: menuDay.date, dishes: newMenuDay.dishes })
				}
			}
			await MenuService.updateRange(toUpdate)
			refetch()
		}
		copyDisclosure.onClose()
	}

	useEffect(() => {
		refetch()
	}, [selectedDateRange])

	return (
		<TileGrid>
			{data && (
				<CopyModal
					isOpen={copyDisclosure.isOpen}
					onClose={(curr, nm) => handleCopyModalClose(curr, nm)}
					currentMenuDays={menuDays}
				/>
			)}
			{selectedMenuDay && isOpen && (
				<MenuModal
					isOpen={isOpen}
					onClose={menuDay => {
						onClose()
						if (menuDay) updateMenuDay(menuDay)
					}}
					selectedMenuDay={selectedMenuDay}
				/>
			)}
			<Tile
				minW="1200px"
				headerIcon={<FaUtensils />}
				header={`Jadłospis ${formatDate(
					selectedDateRange[0],
					'localeDateString',
				)} - ${formatDate(selectedDateRange[1], 'localeDateString')}`}
				headerButtons={
					<>
						<IconButton
							size="sm"
							icon={<FaAngleLeft />}
							aria-label="Poprzedni tydzień"
							onClick={() =>
								setWeekDateRange(
									formatDate(
										new Date(
											Date.parse(selectedDateRange[0]) -
												7 * 24 * 60 * 60 * 1000,
										).toISOString(),
										'date',
									),
								)
							}
						/>
						<Datepicker
							date={selectedDateRange[0]}
							onDateChange={setWeekDateRange}
						/>
						<IconButton
							size="sm"
							icon={<FaAngleRight />}
							aria-label="Następny tydzień"
							onClick={() =>
								setWeekDateRange(
									formatDate(
										new Date(
											Date.parse(selectedDateRange[0]) +
												7 * 24 * 60 * 60 * 1000,
										).toISOString(),
										'date',
									),
								)
							}
						/>
						<TableButton
							action={copyDisclosure.onOpen}
							icon={<FaCopy />}
							label="Kopiuj/Wklej"
						/>
						<TilePrintButton
							options={[
								{
									fileName: `Jadłospis ${formatDate(
										selectedDateRange[0],
										'localeDateString',
									)} - ${formatDate(selectedDateRange[1], 'localeDateString')}`,
									label: 'Wydruk jadłospisu',
									promiseFunction: () =>
										MenuService.print(...selectedDateRange),
								},
								{
									fileName: 'Wykaz alergenów',
									label: 'Wykaz alergenów',
									promiseFunction: () => MenuService.printAllergens(),
								},
							]}
						/>
					</>
				}
				isLoading={isLoading || isFetching}
			>
				<Table style={{ tableLayout: 'fixed' }}>
					<Thead>
						<Tr>
							<Th w="160px" />
							<Th fontSize={11} textAlign="center">
								Poniedziałek
							</Th>
							<Th fontSize={11} textAlign="center">
								Wtorek
							</Th>
							<Th fontSize={11} textAlign="center">
								Środa
							</Th>
							<Th fontSize={11} textAlign="center">
								Czwartek
							</Th>
							<Th fontSize={11} textAlign="center">
								Piątek
							</Th>
							<Th fontSize={11} textAlign="center">
								Sobota
							</Th>
							<Th fontSize={11} textAlign="center">
								Niedziela
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						<RenderMenuDays
							menuDays={data}
							setSelectedMenuDay={day => {
								setSelectedMenuDay(day)
								onOpen()
							}}
						/>
						{data.length === 7 && (
							<>
								<Tr>
									<Th fontSize={11}>Suma</Th>
									{[0, 1, 2, 3, 4, 5, 6].map(dayIdx => (
										<Td
											key={dayIdx}
											style={{ fontSize: 12 }}
											borderLeft="1px solid var(--chakra-colors-gray-100)"
										>
											{data
												.at(dayIdx)
												.dishes.filter(d => d.type < 10)
												.reduce(
													(acc, curr) => acc + (curr?.dish.calories ?? 0),
													0,
												)}{' '}
											kcal
											<br />
											{data
												.at(dayIdx)
												.dishes.filter(d => d.type < 10)
												.reduce(
													(acc, curr) => acc + (curr?.dish.weight ?? 0),
													0,
												)}{' '}
											g
										</Td>
									))}
								</Tr>
								{selectedService._id === NA_WZGORZU_ID && (
									<Tr>
										<Th fontSize={11}>Suma (dieta cukrzycowa)</Th>
										{[0, 1, 2, 3, 4, 5, 6].map(dayIdx => (
											<Td
												key={dayIdx + 'c'}
												style={{ fontSize: 12 }}
												borderLeft="1px solid var(--chakra-colors-gray-100)"
											>
												{data
													.at(dayIdx)
													.dishes.filter(d => d.type >= 10)
													.reduce(
														(acc, curr) => acc + (curr?.dish.calories ?? 0),
														0,
													)}{' '}
												kcal
												<br />
												{data
													.at(dayIdx)
													.dishes.filter(d => d.type >= 10)
													.reduce(
														(acc, curr) => acc + (curr?.dish.weight ?? 0),
														0,
													)}{' '}
												g
											</Td>
										))}
									</Tr>
								)}
							</>
						)}
					</Tbody>
				</Table>
			</Tile>
		</TileGrid>
	)
}
