import { Patient } from './patient'
import * as yup from 'yup'
import { formatDate } from '@kevea/react-components'

export class Measurement {
	constructor(measurement: Measurement) {
		this._id = measurement._id
		this.type = measurement.type ?? 1
		this.note = measurement.note ?? ''
		this.patient = measurement.patient
		this.measurementDate =
			measurement.measurementDate !== undefined
				? formatDate(measurement.measurementDate, 'datetime-local')
				: formatDate(new Date().toISOString(), 'datetime-local')
		this.value = measurement.value
		this.value2 = measurement.value2
		this.value3 = measurement.value3
		this.rawValue = measurement.rawValue ?? ''
	}

	_id?: string | undefined
	type?: number //type: 1 - blood pressure; 2 - blood sugar level; 3 - body temperature; 4 - heart rate; 5 - blood saturation; 6 - weight; 7 - height; 8 - urine; 9 - blood pressure + heart rate; 10 - blood sugar level + insulin; 11 - stool;
	patient: Patient
	note?: string
	measurementDate?: string
	value?: number | undefined
	value2?: number | undefined
	value3?: number | undefined
	rawValue?: string = ''

	static convertType = (type: number): string => {
		switch (type) {
			case 1:
				return 'Ciśnienie krwi'
			case 2:
				return 'Poziom glukozy'
			case 3:
				return 'Temperatura'
			case 4:
				return 'Tętno'
			case 5:
				return 'Saturacja'
			case 6:
				return 'Waga'
			case 7:
				return 'Wzrost'
			case 8:
				return 'Pomiar moczu'
			case 9:
				return 'Ciśnienie i tętno'
			case 10:
				return 'Poziom glukozy i insulina'
			case 11:
				return 'Stolec'
			default:
				return `${type}`
		}
	}
	static typeOptionsTable = [
		{
			label: Measurement.convertType(1),
			value: Measurement.convertType(1),
		},
		{
			label: Measurement.convertType(2),
			value: Measurement.convertType(2),
		},
		{
			label: Measurement.convertType(3),
			value: Measurement.convertType(3),
		},
		{
			label: Measurement.convertType(4),
			value: Measurement.convertType(4),
		},
		{
			label: Measurement.convertType(5),
			value: Measurement.convertType(5),
		},
		{
			label: Measurement.convertType(6),
			value: Measurement.convertType(6),
		},
		{
			label: Measurement.convertType(7),
			value: Measurement.convertType(7),
		},
		{
			label: Measurement.convertType(8),
			value: Measurement.convertType(8),
		},
		{
			label: Measurement.convertType(9),
			value: Measurement.convertType(9),
		},
		{
			label: Measurement.convertType(10),
			value: Measurement.convertType(10),
		},
		{
			label: Measurement.convertType(11),
			value: Measurement.convertType(11),
		},
	]
	static typeOptions = [
		{
			label: Measurement.convertType(1),
			value: 1,
		},
		{
			label: Measurement.convertType(2),
			value: 2,
		},
		{
			label: Measurement.convertType(3),
			value: 3,
		},
		{
			label: Measurement.convertType(4),
			value: 4,
		},
		{
			label: Measurement.convertType(5),
			value: 5,
		},
		{
			label: Measurement.convertType(6),
			value: 6,
		},
		{
			label: Measurement.convertType(7),
			value: 7,
		},
		{
			label: Measurement.convertType(8),
			value: 8,
		},
		{
			label: Measurement.convertType(9),
			value: 9,
		},
		{
			label: Measurement.convertType(10),
			value: 10,
		},
		{
			label: Measurement.convertType(11),
			value: 11,
		},
	]
	static getValue = (measurement: Measurement): string => {
		const { type, value, value2, value3 } = measurement
		switch (type) {
			case 1:
				return `${value}/${value2}`
			case 2:
				return `${value} mg/dl`
			case 3:
				return `${value}°C`
			case 4:
				return `${value}/min`
			case 5:
				return `${value} %`
			case 6:
				return `${value} kg`
			case 7:
				return `${value} cm`
			case 8:
				return `${value} ml`
			case 9:
				return `${value}/${value2} ${value3}/min`
			case 10:
				return `${value} mg/dl - ${value2} j.`
			case 11:
				return Measurement.convertStoolOption(value)
			default:
				return `${value}${value2} - not implemented`
		}
	}
	static getRawValue = (measurement: Measurement): string => {
		const { type, value, value2, value3 } = measurement
		switch (type) {
			case 1:
				return `${value}/${value2}`
			case 2:
				return `${value}`
			case 3:
				return `${value}`
			case 4:
				return `${value}`
			case 5:
				return `${value}`
			case 6:
				return `${value}`
			case 7:
				return `${value}`
			case 8:
				return `${value}`
			case 9:
				return `${value}/${value2}`
			case 10:
				return `${value}`
			case 11:
				return `${value}`
			default:
				return `${value}`
		}
	}

	static convertStoolOption = (value: number): string => {
		switch (value) {
			case 1:
				return 'Brak'
			case 2:
				return 'Luźny'
			case 3:
				return 'Normalny'
			case 4:
				return 'Twardy'
			default:
				return 'Brak'
		}
	}

	static stoolOptions = [
		{
			label: Measurement.convertStoolOption(1),
			value: 1,
		},
		{
			label: Measurement.convertStoolOption(2),
			value: 2,
		},
		{
			label: Measurement.convertStoolOption(3),
			value: 3,
		},
		{
			label: Measurement.convertStoolOption(4),
			value: 4,
		},
	]

	static validationSchema = yup.object({
		measurementDate: yup.string().required('Data i godzina jest wymagana'),
		rawValue: yup.string().required('Wartość jest wymagana'),
		value3: yup.string().when('type', {
			is: 9,
			then: yup.string().required('Wartość jest wymagana'),
		}),
	})
	static parseValue = (measurement: Measurement): Measurement => {
		let { type, rawValue } = measurement
		if (!rawValue) rawValue = ''

		switch (type) {
			case 1:
				const values = rawValue.split('/').map(x => parseInt(x))
				measurement.value = values[0]
				measurement.value2 = values[1]
				return measurement
			case 2:
				measurement.value = parseInt(rawValue)
				return measurement
			case 3:
				measurement.value = parseFloat(rawValue)
				return measurement
			case 4:
				measurement.value = parseInt(rawValue)
				return measurement
			case 5:
				measurement.value = parseInt(rawValue)
				return measurement
			case 6:
				measurement.value = parseFloat(rawValue)
				return measurement
			case 7:
				measurement.value = parseFloat(rawValue)
				return measurement
			case 8:
				measurement.value = parseFloat(rawValue)
				return measurement
			case 9:
				const values2 = rawValue.split('/')
				measurement.value = parseInt(values2[0])
				measurement.value2 = parseInt(values2[1])
				return measurement
			case 10:
				measurement.value = parseInt(rawValue)
				return measurement
			case 11:
				measurement.value = parseInt(rawValue)
				return measurement
			default:
				measurement.value = parseFloat(rawValue)
				return measurement
		}
	}
}
export class TableMeasurement {
	constructor(measurement: Measurement) {
		this._id = measurement._id ?? ''
		this.type = Measurement.convertType(measurement.type ?? 1)
		this.note = measurement.note ?? ''
		this.patient = measurement.patient
		this.measurementDate =
			measurement.measurementDate !== undefined
				? formatDate(measurement.measurementDate, 'localeString')
				: ''
		this.filterDate =
			measurement.measurementDate !== undefined
				? formatDate(measurement.measurementDate, 'date')
				: ''
		this.parsedValue = Measurement.getValue(measurement)
		this.original = measurement
	}

	_id: string
	type: string
	patient: Patient
	note: string
	measurementDate: string
	filterDate: string
	parsedValue: string
	original: Measurement
}
