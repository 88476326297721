import { Attachments } from 'pages/Attachments'
import { useOutletContext } from 'react-router-dom'
import { DiapersOrderContext } from '../DiapersOrder'

export const AttachmentsWrapper = () => {
	const { breadcrumbs, diapersOrder } = useOutletContext<DiapersOrderContext>()

	return (
		<Attachments
			attachmentType="diapersOrders"
			breadcrumbs={breadcrumbs}
			header={`${diapersOrder?.number} - Lista załączników`}
		/>
	)
}
