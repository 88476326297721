import { Link, Table, Tile } from '@kevea/react-components'
import { SettingsContext } from 'pages/settings/Settings'
import React, { useMemo } from 'react'
import { FaSlidersH } from 'react-icons/fa'
import { useOutletContext } from 'react-router-dom'
import { Cell, Column, useTable } from 'react-table'
import { isModuleEnabled } from 'routes'

type Dictionary = {
	name: string
	path: string
}

export const DictionaryList = () => {
	const { instance, refetch, ...rest } = useOutletContext<SettingsContext>()

	const columns = useMemo<Column<Dictionary>[]>(
		() => [
			{
				Header: 'Nazwa',
				accessor: 'name',
				Cell: cell => <Link to={cell.row.original.path}>{cell.value}</Link>,
			},
		],
		[],
	)

	const data = useMemo<Dictionary[]>(
		() =>
			[
				isModuleEnabled('KITCHEN') && { name: 'Produkty', path: 'products' },
				isModuleEnabled('KITCHEN') && { name: 'Dania', path: 'dishes' },
				{ name: 'Rodzaje zadań', path: 'tasks' },
				{ name: 'Rodzaje notatek', path: 'notes' },
			].filter(Boolean),
		[],
	)

	const tableInstance = useTable({ columns, data })

	return (
		<Tile {...rest} header="Słowniki" headerIcon={<FaSlidersH />}>
			<Table tableInstance={tableInstance} hideNumberOfRows />
		</Tile>
	)
}
