import { Menu, MenuDay } from './menu'

export class SavedMenus {
	constructor(name?: string, menuDays?: MenuDay[], _id?: string) {
		this._id = _id
		this.name = name ?? ''
		this.menuDays = menuDays ?? []
	}
	_id?: string
	name: string
	menuDays: MenuDay[]
}
