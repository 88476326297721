import { Text, useDisclosure } from '@chakra-ui/react'
import {
	AlertDialog,
	Link,
	SelectFilter,
	Table,
	Tile,
	TileGrid,
} from '@kevea/react-components'
import { Badge } from 'components/StatusBadge'
import { Contract, TableContract } from 'models/contract'
import { Patient } from 'models/patient'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FaFileContract, FaIdCard, FaMedkit } from 'react-icons/fa'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router'
import {
	Cell,
	Column,
	useFilters,
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table'
import { ContractService } from 'services/contract'
import { getColorCSSVariable } from 'utils/chakra'

export const ContractList = () => {
	const {
		data: contracts,
		isLoading,
		refetch,
	} = useQuery('fetchContracts', Contract.fetchContracts)
	const [isStatusChanging, setIsStatusChanging] = useState(false)
	const removeDisclosure = useDisclosure()
	const dissolveDisclosure = useDisclosure()

	const navigate = useNavigate()

	const confirm = () => {
		if (selectedContract) {
			setIsStatusChanging(true)
			ContractService.changeStatus(selectedContract.original, 2).then(() => {
				refetch?.()
				setIsStatusChanging(false)
			})
		}
	}
	const dissolve = () => {
		if (selectedContract) {
			setIsStatusChanging(true)
			ContractService.changeStatus(selectedContract.original, 3).then(() => {
				refetch?.()
				dissolveDisclosure.onClose()
				setIsStatusChanging(false)
			})
		}
	}
	const remove = () => {
		if (selectedContract?._id) {
			setIsStatusChanging(true)
			ContractService.delete(selectedContract._id).then(() => {
				setIsStatusChanging(false)
				removeDisclosure.onClose()
				refetch?.()
			})
		}
	}

	const data = useMemo(
		() => contracts?.map(contract => new TableContract(contract)) ?? [],
		[contracts],
	)

	const columns = useMemo<Column<TableContract>[]>(
		() => [
			{
				Header: 'Numer umowy',
				accessor: 'number',
				Cell: ({ row, value }) => (
					<Link to={row.original.original._id}>{value}</Link>
				),
			},
			{ Header: 'Własny numer', accessor: 'ownNumber' },
			{ Header: 'Pacjent', accessor: 'patientName' },
			{ Header: 'Data podpisania', accessor: 'dateOfConclusion' },
			{ Header: 'Ważna od', accessor: 'validFrom' },
			{ Header: 'Ważna do', accessor: 'validUntil' },
			{
				Header: 'Status',
				accessor: 'status',
				Filter: ({ column }) => (
					<SelectFilter column={column} options={TableContract.statusOptions} />
				),
				Cell: ({ row }) => <Badge contract={row.original.original} />,
			},
		],
		[],
	)
	const tableInstance = useTable(
		{
			columns: columns,
			data: data,
			initialState: {
				hiddenColumns: ['ownNumber'],
			},
		},
		useGlobalFilter,
		useFilters,
		useSortBy,
		usePagination,
		useRowSelect,
	)

	useEffect(() => {
		if (contracts?.filter(x => Boolean(x.ownNumber)).length === 0) {
			tableInstance.state.hiddenColumns = ['ownNumber']
		} else {
			tableInstance.state.hiddenColumns = []
		}
	}, [contracts, tableInstance])

	const selectedContract = useMemo(
		() => tableInstance.selectedFlatRows[0]?.original,
		[tableInstance.selectedFlatRows],
	)
	return (
		<TileGrid>
			<Tile headerIcon={<FaFileContract />} header="Umowy">
				<AlertDialog
					isRemoving={isStatusChanging}
					isOpen={removeDisclosure.isOpen}
					onClose={removeDisclosure.onClose}
					header="Usuń umowę"
					onRemove={remove}
				>
					Czy na pewno chcesz usunąć umowę{' '}
					<strong>{selectedContract?.number}</strong> dla pacjenta{' '}
					<strong>{selectedContract?.patientName}</strong>?
				</AlertDialog>
				<AlertDialog
					isRemoving={isStatusChanging}
					isOpen={dissolveDisclosure.isOpen}
					onClose={dissolveDisclosure.onClose}
					header="Rozwiąż umowę"
					onRemove={dissolve}
					confirmButtonLabel="Rozwiąż"
				>
					Czy na pewno chcesz rozwiązać umowę{' '}
					<strong>{selectedContract?.number}</strong> dla pacjenta{' '}
					<strong>{selectedContract?.patientName}</strong>?
				</AlertDialog>
				<Table
					tableInstance={tableInstance}
					loading={isLoading}
					buttons={[
						{
							label: 'Dodaj',
							type: 'add',
							to: '/contracts/new',
						},
					]}
					menuOptions={{
						noSelection: [],
						singleSelection: [
							{
								label: 'Potwierdź',
								onClick: confirm,
								disabled: selectedContract?.original.status !== 1,
							},
							{
								label: 'Rozwiąż',
								onClick: dissolveDisclosure.onOpen,
								disabled: selectedContract?.original.status !== 2,
							},
							{
								label: 'Usuń',
								onClick: removeDisclosure.onOpen,
								disabled: selectedContract?.original.status !== 1,
							},
						],
						multipleSelection: [],
					}}
				/>
			</Tile>
		</TileGrid>
	)
}
