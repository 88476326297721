import {
	AddressFields,
	InputField,
	ModalDialog,
	SelectField,
	TextAreaField,
} from '@kevea/react-components'
import { Form, Formik, FormikProps } from 'formik'
import { Deposit } from 'models/deposit'
import React, { useRef } from 'react'
import { useOutletContext } from 'react-router'
import { PatientContext } from '../Patient'

type Props = {
	isOpen: boolean
	onClose: (deposit: Deposit) => void
	deposit?: Deposit
}

export const DepositModal = ({ isOpen, onClose, deposit }: Props) => {
	const { patient } = useOutletContext<PatientContext>()
	const formik = useRef<FormikProps<Deposit>>(null)

	const handleSubmit = (values: Deposit) => {
		onClose(values)
	}

	return (
		<ModalDialog
			header={deposit !== undefined ? 'Edytuj depozyt' : 'Dodaj depozyt'}
			onClose={() => onClose(undefined)}
			onSave={() => formik.current?.submitForm()}
			isOpen={isOpen}
		>
			<Formik
				validateOnBlur={false}
				validateOnChange={false}
				validationSchema={Deposit.validationSchema}
				innerRef={formik}
				onSubmit={handleSubmit}
				initialValues={
					new Deposit({
						...deposit,
						patient,
					})
				}
			>
				<Form>
					<InputField
						isRequired
						name="name"
						placeholder="Nazwa"
						label="Nazwa"
					/>
					<TextAreaField name="description" label="Opis" placeholder="Opis" />
					<InputField
						isRequired
						name="amount"
						placeholder="Ilość"
						label="Ilość"
					/>
				</Form>
			</Formik>
		</ModalDialog>
	)
}
