import { Attachment } from 'models/attachment'
import { getBaseInstancePath, printConfig, Service } from 'services'
import http from './axios'

export type AttachmentType =
	| 'patients'
	| 'diapersOrders'
	| 'medicines'
	| 'products'
	| 'contracts'
	| 'orders'
	| 'bills'

export class AttachmentService {
	static getAll(oid: string, type: AttachmentType) {
		return http.get<Attachment[]>(
			`${getBaseInstancePath(`/${type}`)}/${oid}/attachments`,
		)
	}
	static get(oid: string, type: AttachmentType, _id: string) {
		return http.get<Attachment>(
			`${getBaseInstancePath(`/${type}`)}/${oid}/attachments/${_id}`,
		)
	}
	static put(oid: string, type: AttachmentType, attachment: Attachment) {
		return http.put<Attachment>(
			`${getBaseInstancePath(`/${type}`)}/${oid}/attachments/${attachment._id}`,
			attachment,
		)
	}
	static post(oid: string, type: AttachmentType, attachment: Attachment) {
		return http.post<Attachment>(
			`${getBaseInstancePath(`/${type}`)}/${oid}/attachments`,
			attachment,
		)
	}
	static delete(oid: string, type: AttachmentType, attachment: Attachment) {
		return http.delete(
			`${getBaseInstancePath(`/${type}`)}/${oid}/attachments/${attachment._id}`,
		)
	}
	static downloadFile(oid: string, type: AttachmentType, _id: string) {
		return http.get<Blob>(
			`${getBaseInstancePath(`/${type}`)}/${oid}/attachments/${_id}/file`,
			printConfig,
		)
	}
	static uploadFile(
		oid: string,
		type: AttachmentType,
		attachment: Attachment,
		file: File,
	) {
		let formData = new FormData()
		formData.append('file', file)
		return http.post(
			`${getBaseInstancePath(`/${type}`)}/${oid}/attachments/${
				attachment._id
			}/file`,
			formData,
			printConfig,
		)
	}
}
