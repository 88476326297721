import { Button, IconButton, Text, useDisclosure } from '@chakra-ui/react'
import {
	AlertDialog,
	Link,
	SelectFilter,
	Table,
	useErrorToast,
} from '@kevea/react-components'
import { Contract } from 'models/contract'
import { ContractAnnex, TableContractAnnex } from 'models/contractAnnex'
import React, { useMemo, useState } from 'react'
import { FaPrint } from 'react-icons/fa'
import { useQuery } from 'react-query'
import { Column, usePagination, useRowSelect, useTable } from 'react-table'
import { ContractAnnexService } from 'services/contractAnnex'
import { getColorCSSVariable } from 'utils/chakra'
import { AddAnnexModal } from './AddAnnexModal'

type Props = {
	contract: Contract & { status: number; _id: string }
	setIsLoading: (loading: boolean) => void
}

export const Annexes = ({ contract, setIsLoading }: Props) => {
	const {
		data: annexes,
		isLoading,
		refetch,
	} = useQuery('fetchAnnexes', () => ContractAnnex.fetchAnnexes(contract._id))

	const data = useMemo(
		() => annexes?.map(annex => new TableContractAnnex(annex)) ?? [],
		[annexes],
	)
	const errorT = useErrorToast()

	const addDisclosure = useDisclosure()
	const removeDisclosure = useDisclosure()
	const dissolveDisclosure = useDisclosure()

	const confirm = () => {
		if (selectedAnnex) {
			setIsLoading(true)
			ContractAnnexService.changeStatus(selectedAnnex.original, 2).then(() => {
				refetch?.()
				setIsLoading(false)
			})
		}
	}
	const dissolve = () => {
		if (selectedAnnex) {
			setIsLoading(true)
			ContractAnnexService.changeStatus(selectedAnnex.original, 3).then(() => {
				refetch?.()
				dissolveDisclosure.onClose()
				setIsLoading(false)
			})
		}
	}
	const remove = () => {
		if (selectedAnnex?._id) {
			setIsLoading(true)
			ContractAnnexService.delete(selectedAnnex._id, contract._id).then(() => {
				setIsLoading(false)
				removeDisclosure.onClose()
				refetch?.()
			})
		}
	}
	const print = (id: string) => {
		setIsLoading(true)
		ContractAnnexService.print(id, contract._id)
			.then(res => {
				const pdf = new Blob([res], { type: 'application/pdf' })
				const pdfURL = URL.createObjectURL(pdf)
				const pdfWindow = window.open(pdfURL, '_blank').focus()
				setIsLoading(false)
			})
			.catch(e => {
				errorT({ description: 'Wystąpił błąd podczas generowania wydruku' })
				setIsLoading(false)
			})
	}

	const columns = useMemo<Column<TableContractAnnex>[]>(
		() => [
			{
				Header: 'Numer',
				accessor: 'number',
			},
			{
				Header: 'Nowa stawka',
				accessor: 'monthlyCharge',
			},
			{
				Header: 'Data podpisania',
				accessor: 'dateOfConclusion',
			},
			{
				Header: 'Ważny od',
				accessor: 'validFrom',
			},
			{
				Header: 'Ważna do',
				accessor: 'validUntil',
			},
			{
				Header: 'Status',
				accessor: 'status',
				Cell: ({ value, row }) => (
					<Text
						color={getColorCSSVariable(
							ContractAnnex.getStatusColor(row.original.original.status),
						)}
					>
						{value}
					</Text>
				),
			},
			{
				id: 'print',
				accessor: '_id',
				Cell: ({ value }) => (
					<Button
						rightIcon={<FaPrint />}
						onClick={() => print(value)}
						colorScheme="blue"
						size="sm"
					>
						Wydruk
					</Button>
				),
			},
		],
		[],
	)

	const tableInstance = useTable(
		{ columns: columns, data: data },
		usePagination,
		useRowSelect,
	)

	const selectedAnnex = useMemo(
		() => tableInstance.selectedFlatRows[0]?.original,
		[tableInstance.selectedFlatRows],
	)

	return (
		<div>
			<AddAnnexModal
				contract={contract}
				isOpen={addDisclosure.isOpen}
				onClose={() => {
					refetch?.()
					addDisclosure.onClose()
				}}
			/>
			<AlertDialog
				isRemoving={isLoading}
				isOpen={removeDisclosure.isOpen}
				onClose={removeDisclosure.onClose}
				header="Usuń aneks"
				onRemove={remove}
			>
				Czy na pewno chcesz usunąć aneks{' '}
				<strong>{selectedAnnex?.number}</strong>?
			</AlertDialog>
			<AlertDialog
				isRemoving={isLoading}
				isOpen={dissolveDisclosure.isOpen}
				onClose={dissolveDisclosure.onClose}
				header="Rozwiąż aneks"
				onRemove={dissolve}
				confirmButtonLabel="Rozwiąż"
			>
				Czy na pewno chcesz rozwiązać aneks{' '}
				<strong>{selectedAnnex?.number}</strong>?
			</AlertDialog>
			<Table
				tableInstance={tableInstance}
				loading={isLoading}
				hideNumberOfRows
				buttons={
					contract.status === 2
						? [
								{
									label: 'Dodaj',
									type: 'add',
									action: addDisclosure.onOpen,
								},
						  ]
						: []
				}
				menuOptions={{
					singleSelection:
						contract.status === 2
							? [
									{
										label: 'Potwierdź',
										onClick: confirm,
										disabled: selectedAnnex?.original.status !== 1,
									},
									{
										label: 'Rozwiąż',
										onClick: dissolveDisclosure.onOpen,
										disabled: selectedAnnex?.original.status !== 2,
									},
									{
										label: 'Usuń',
										onClick: removeDisclosure.onOpen,
										disabled: selectedAnnex?.original.status !== 1,
									},
							  ]
							: [],
				}}
			/>
		</div>
	)
}
