import {
	formatDate,
	OutletContext,
	TileGrid,
	useBreadcrumbs,
} from '@kevea/react-components'
import { RhmShiftSettings } from 'models/service'
import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { ShiftService } from 'services/shift'
import { KvTime } from 'utils/kvTime'
import reportRoutes from './routes'

export type ReportContext = OutletContext & {
	selectedShift: RhmShiftSettings
	setSelectedShift: (x: RhmShiftSettings) => void
	shiftSettings: RhmShiftSettings[]
	selectedDate: string
	setSelectedDate: (date: string) => void
}

export const Report = () => {
	const [shiftSettings, setShiftSettings] = useState<
		RhmShiftSettings[] | undefined
	>()
	const [isLoading, setIsLoading] = useState(true)
	const [selectedShift, setSelectedShift] = useState<
		RhmShiftSettings | undefined
	>()
	const [selectedDate, setSelectedDate] = useState(
		formatDate(new Date().toISOString(), 'date'),
	)

	const fetchShiftSettings = async () => {
		setIsLoading(true)
		ShiftService.getSettings()
			.then(res => res.data)
			.then(settings => {
				setIsLoading(false)
				setShiftSettings(settings)
				const currentTime = KvTime.getMinutes(
					formatDate(new Date().toISOString().split('.')[0] ?? '', 'time'),
				)
				setSelectedShift(
					settings.find(shift =>
						shift.startTime > shift.endTime
							? currentTime >= shift.startTime || currentTime < shift.endTime
							: shift.startTime <= currentTime && shift.endTime > currentTime,
					),
				)
			})
	}

	useEffect(() => {
		fetchShiftSettings()
	}, [])

	const breadcrumbs = useBreadcrumbs(reportRoutes)
	if (selectedShift && shiftSettings) {
		const context: ReportContext = {
			breadcrumbs: breadcrumbs,
			selectedShift,
			setSelectedShift,
			shiftSettings,
			selectedDate,
			setSelectedDate,
		}
		return (
			<TileGrid isLoading={isLoading}>
				<Outlet context={context} />
			</TileGrid>
		)
	} else {
		return <TileGrid isLoading={true}></TileGrid>
	}
}
