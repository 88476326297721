import { FormControl, FormErrorMessage, useDisclosure } from '@chakra-ui/react'
import { Dropdown, ModalDialog, NumberField } from '@kevea/react-components'
import { Form, Formik, FormikProps } from 'formik'
import { Product } from 'models/dictionaries/product'
import { OrderProduct } from 'models/order'
import { ProductModal } from 'pages/settings/views/dictionaries/products/ProductModal'
import { ProductModalList } from 'pages/settings/views/dictionaries/products/ProductModalList'
import React, { useMemo, useRef } from 'react'
import { useQuery } from 'react-query'

type OrderProductModalProps = {
	orderProduct: OrderProduct | undefined
	alreadySelectedProductIds: string[]
	isOpen: boolean
	onClose: (orderProduct?: OrderProduct) => void
}

export const OrderProductModal: React.FC<OrderProductModalProps> = ({
	isOpen,
	onClose,
	orderProduct,
	alreadySelectedProductIds,
}) => {
	const formik = useRef<FormikProps<OrderProduct>>(null)
	const handleSubmit = (values: OrderProduct) => {
		onClose(values)
	}

	const { data: productsRaw } = useQuery(
		'fetchProducts',
		Product.fetchProducts,
		{ enabled: isOpen },
	)

	const products = useMemo(
		() =>
			productsRaw
				?.map(
					product =>
						product._id &&
						(!alreadySelectedProductIds.includes(product._id) ||
							orderProduct?.product?._id === product._id) &&
						new Product(product),
				)
				.filter(product => product) ?? [],
		[productsRaw, alreadySelectedProductIds],
	)

	const showAllProductsDisclosure = useDisclosure()
	const addProductDisclosure = useDisclosure()

	const changeProduct = (product?: Product) => {
		formik.current?.setFieldValue(
			'product',
			product ? new Product(product) : undefined,
		)
	}

	return (
		<ModalDialog
			header={orderProduct ? 'Edytuj pozycję' : 'Dodaj pozycję'}
			isOpen={isOpen}
			onClose={() => onClose()}
			onSave={() => formik.current?.submitForm()}
		>
			<ProductModalList
				hideProductIds={alreadySelectedProductIds}
				isOpen={showAllProductsDisclosure.isOpen}
				onClose={product => {
					if (product) changeProduct(product)
					showAllProductsDisclosure.onClose()
				}}
			/>
			<ProductModal
				selectedProduct={undefined}
				isOpen={addProductDisclosure.isOpen}
				onClose={product => {
					if (product) changeProduct(product)
					addProductDisclosure.onClose()
				}}
			/>
			<Formik
				validateOnBlur={false}
				validateOnChange={false}
				innerRef={formik}
				onSubmit={handleSubmit}
				initialValues={new OrderProduct(orderProduct) ?? new OrderProduct()}
				validationSchema={OrderProduct.validationSchema}
			>
				{({ errors, values }) => (
					<Form>
						<FormControl isInvalid={Boolean(errors.product)}>
							<Dropdown
								displayAccessor="nameWithUnit"
								items={products}
								selectedItem={values.product}
								setSelectedItem={changeProduct}
								placeholder="Wybierz produkt"
								label="Produkt"
								render={(product: Product) => product.nameWithUnit}
								numberOfResults={6}
								onShowAllClick={showAllProductsDisclosure.onOpen}
								onAddNewClick={addProductDisclosure.onOpen}
								name="product"
								disabled={Boolean(orderProduct)}
							/>
							{errors.product && (
								<FormErrorMessage>{errors.product}</FormErrorMessage>
							)}
						</FormControl>
						<NumberField
							autoFocus
							name="quantity"
							placeholder="Ilość"
							label="Ilość"
							min={1}
						/>
					</Form>
				)}
			</Formik>
		</ModalDialog>
	)
}
