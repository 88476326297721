import { Box, Center, Progress, Text } from "@chakra-ui/react";
import { IconElement, Link } from "@kevea/react-components";
import React from "react";
import Dropzone from "react-dropzone";
import {
  FaFile,
  FaFileAlt,
  FaFileArchive,
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFileWord,
} from "react-icons/fa";
import { humanFileSize } from "utils/file";

export type FileInputProps = {
  onDrop: (files: File[]) => void;
  showProgress?: boolean;
};

export const FileInput = ({ onDrop, showProgress }: FileInputProps) => {
  const getFileTypeIcon = (type: string): IconElement => {
    const splittedType = type.split("/");
    if (splittedType[0] === "image") return <FaFileImage />;
    if (splittedType[1] === "pdf") return <FaFilePdf />;
    if (
      splittedType[1] === "msword" ||
      splittedType[1] ===
        "vnd.openxmlformats-officedocument.wordprocessingml.document"
    )
      return <FaFileWord />;
    if (
      splittedType[1] === "vnd.ms-excel" ||
      splittedType[1] ===
        "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    )
      return <FaFileExcel />;
    if (
      splittedType[1] === "zip" ||
      splittedType[1] === "vnd.rar" ||
      splittedType[1] === "x-7z-compressed"
    )
      return <FaFileArchive />;
    if (splittedType[0] === "text") return <FaFileAlt />;
    return <FaFile />;
  };

  return (
    <Dropzone maxFiles={1} maxSize={15000000} onDrop={onDrop}>
      {({
        getInputProps,
        getRootProps,
        acceptedFiles,
        isDragActive,
        isDragReject,
        fileRejections,
      }) => (
        <Center
          border="1px solid"
          borderColor={fileRejections.length > 0 ? "red.500" : "gray.300"}
          borderRadius="md"
          minW="220px"
        >
          <Box w="100%" {...getRootProps()} h="160px" minW="210px" p={2}>
            <input type="file" {...getInputProps()} />
            <Center
              w="100%"
              h="100%"
              flexDirection="column"
              opacity={isDragActive ? 0.4 : 1}
              borderRadius="md"
              border={
                isDragActive
                  ? `2px dashed var(--chakra-colors-${
                      isDragReject ? "red" : "accent"
                    }-500)}`
                  : ""
              }
            >
              {isDragReject ? (
                <Text fontSize="xl" fontWeight="semibold" color="red.500">
                  Błędny plik
                </Text>
              ) : acceptedFiles.length > 0 ? (
                <Center flexDirection="column" w="100%">
                  <Center
                    w="48px"
                    h="64px"
                    border="1px solid"
                    borderColor="gray.300"
                    borderRadius="md"
                    fontSize="4xl"
                  >
                    {getFileTypeIcon(acceptedFiles[0]?.type ?? "")}
                  </Center>
                  <Text fontSize="xs">
                    {humanFileSize(acceptedFiles[0]?.size ?? 0)}
                  </Text>
                  <Text
                    display="inline-block"
                    maxW="calc(100% - 20px)"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    fontSize="sm"
                  >
                    {acceptedFiles[0]?.name}
                  </Text>
                  <Center h="20px" w="100%">
                    {showProgress && (
                      <Progress
                        mt={2}
                        isIndeterminate
                        colorScheme="accent"
                        w="90%"
                        rounded="md"
                      />
                    )}
                  </Center>
                </Center>
              ) : fileRejections.length > 0 &&
                fileRejections.at(-1)?.errors[0]?.code === "file-too-large" ? (
                <Text fontSize="xl" color="red.500" fontWeight="semibold">
                  Plik jest zbyt duży
                </Text>
              ) : (
                <>
                  <Text fontSize="xl" fontWeight="semibold">
                    Upuść plik tutaj
                  </Text>
                  <Text fontSize="sm">lub</Text>
                  <Link fontSize="sm">wybierz go z dysku</Link>
                  <Text fontSize="xs">(maksymalny rozmiar pliku 15MB)</Text>
                </>
              )}
            </Center>
          </Box>
        </Center>
      )}
    </Dropzone>
  );
};
