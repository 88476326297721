import { Measurement } from 'models/measurement'
import { getBaseInstancePath, Service } from 'services'
import http from './axios'

const path = '/measurements'

export class MeasurementService extends Service<Measurement>({
	objectPath: '/patients',
	path,
	objectService: true,
}) {}
