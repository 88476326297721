import {
	OutletContext,
	TileGrid,
	useBreadcrumbs,
	useNotFoundToast,
} from '@kevea/react-components'
import React from 'react'
import { useQuery } from 'react-query'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { StatusBadge } from 'components/StatusBadge'
import { DiapersOrder as DiapersOrderClass } from 'models/diapersOrder'
import { DiapersOrderService } from 'services/diapersOrder'
import diapersOrdersRoutes from './routes'

export type DiapersOrderContext = OutletContext & {
	diapersOrder: DiapersOrderClass | undefined
}

export const DiapersOrder = () => {
	const { id } = useParams<{ id: string }>()
	const toast = useNotFoundToast()
	const navigate = useNavigate()

	const fetchDiapersOrder = () => {
		if (id) {
			return DiapersOrderService.get(id)
				.then(res => res.data)
				.catch(e => {
					toast()
					navigate('/nfz-orders')
					return undefined
				})
		}
	}
	const {
		data: diapersOrder,
		isLoading,
		isRefetching,
		refetch,
	} = useQuery('fetchDiapersOrder', fetchDiapersOrder)

	const breadcrumbs = useBreadcrumbs(diapersOrdersRoutes)
	const context: DiapersOrderContext = {
		breadcrumbs: breadcrumbs,
		diapersOrder,
		refetch,
		titleElement: diapersOrder ? (
			<StatusBadge diaperOrder={diapersOrder} />
		) : null,
	}

	return (
		<TileGrid isLoading={isLoading || isRefetching}>
			<Outlet context={context} />
		</TileGrid>
	)
}
