import {
	OutletContext,
	TileGrid,
	useBreadcrumbs,
	useNotFoundToast,
} from '@kevea/react-components'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { StatusBadge } from 'components/StatusBadge'
import { Order as OrderClass } from 'models/order'
import contractsRoutes from './routes'
import { OrderService } from 'services/order'

export type OrderContext = OutletContext & {
	order: OrderClass | undefined
}

export const Order = () => {
	const { id } = useParams<{ id: string }>()
	const toast = useNotFoundToast()
	const navigate = useNavigate()

	const fetchOrder = () => {
		if (id) {
			return OrderService.get(id)
				.then(res => res.data)
				.catch(e => {
					toast()
					navigate('/orders')
					return undefined
				})
		}
	}
	const {
		data: order,
		isLoading,
		isRefetching,
		refetch,
	} = useQuery('fetchOrder', fetchOrder)

	const breadcrumbs = useBreadcrumbs(contractsRoutes)
	const context: OrderContext = {
		breadcrumbs: breadcrumbs,
		order,
		refetch,
		titleElement: order ? <StatusBadge order={order} /> : null,
	}

	useEffect(() => {
		refetch()
	}, [id])

	return (
		<TileGrid isLoading={isLoading || isRefetching}>
			<Outlet context={context} />
		</TileGrid>
	)
}
