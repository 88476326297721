import { RouteObject } from 'react-router-dom'
import { EventLogList } from './EventLogList'

const eventLogRoutes: RouteObject[] = [
	{
		path: 'event-log',
		element: <EventLogList />,
		breadcrumb: 'Lista zgłoszeń',
	},
]

export default eventLogRoutes
