import { useDisclosure } from '@chakra-ui/react'
import {
	AlertDialog,
	Link,
	SelectFilter,
	Table,
	Tile,
} from '@kevea/react-components'
import { NoteDictionary } from 'models/dictionaries/note'
import { SettingsContext } from 'pages/settings/Settings'
import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useOutletContext } from 'react-router-dom'
import {
	Cell,
	Column,
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table'
import { NoteDictionaryService } from 'services/dictionaries/note'
import { NoteTypeModal } from './NoteTypeModal'

export const NoteList = () => {
	const { instance, refetch, ...rest } = useOutletContext<SettingsContext>()
	const removeDisclosure = useDisclosure()
	const [isRemoving, setIsRemoving] = useState(false)
	const addDisclosure = useDisclosure()
	const [selectedNote, setSelectedNote] = useState<NoteDictionary | undefined>(
		undefined,
	)

	const fetchNotes = async () => {
		return NoteDictionaryService.getAll().then(res => res.data)
	}

	const {
		data: note,
		isLoading,
		refetch: refetchNotes,
	} = useQuery('fetchNotes', fetchNotes)

	const data = useMemo(
		() => note?.map(note => new NoteDictionary(note)) || [],
		[note],
	)

	const columns = useMemo<Column<NoteDictionary>[]>(
		() => [
			{
				Header: 'Nazwa',
				accessor: 'name',
				Cell: cell => (
					<Link
						onClick={() => {
							setSelectedNote(cell.row.original)
							addDisclosure.onOpen()
						}}
					>
						{cell.value}
					</Link>
				),
			},
			{ Header: 'Opis', accessor: 'description' },
		],
		[],
	)

	const tableInstance = useTable(
		{ columns, data },
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
	)

	return (
		<Tile {...rest}>
			<AlertDialog
				header="Usuń typ notatki"
				onClose={removeDisclosure.onClose}
				isRemoving={isRemoving}
				isOpen={removeDisclosure.isOpen}
				onRemove={() => {
					if (selectedNote?._id) {
						setIsRemoving(true)
						NoteDictionaryService.delete(selectedNote._id).then(() => {
							setIsRemoving(false)
							setSelectedNote(undefined)
							removeDisclosure.onClose()
							refetchNotes()
						})
					}
				}}
			>
				Czy chcesz usunąć typ notatki <strong>{selectedNote?.name}</strong>?
			</AlertDialog>
			<NoteTypeModal
				selectedNote={selectedNote}
				isOpen={addDisclosure.isOpen}
				onClose={note => {
					addDisclosure.onClose()
					refetchNotes()
				}}
			/>
			<Table
				tableInstance={tableInstance}
				loading={isLoading}
				buttons={[
					{
						label: 'Dodaj',
						type: 'add',
						action: () => {
							setSelectedNote(undefined)
							addDisclosure.onOpen()
						},
					},
				]}
				menuOptions={{
					singleSelection: [
						{
							label: 'Usuń',
							onClick: notes => {
								setSelectedNote(notes[0])
								removeDisclosure.onOpen()
							},
						},
					],
				}}
			/>
		</Tile>
	)
}
