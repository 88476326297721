import { formatDate } from '@kevea/react-components'
import { AnnouncementService } from 'services/announcements'
import { getLSServiceId } from 'utils/service'
import * as yup from 'yup'
import { User } from './user'

export class Announcement {
	_id?: string
	dateFrom: string
	dateTo?: string
	name: string
	description?: string
	status: 'active' | 'inactive'
	addedBy?: string
	user?: User

	constructor(announcement?: Announcement) {
		this._id = announcement?._id
		this.dateFrom = announcement?.dateFrom
			? formatDate(new Date(announcement.dateFrom).toISOString(), 'date')
			: formatDate(new Date().toISOString(), 'date')
		this.dateTo = announcement?.dateTo
			? formatDate(new Date(announcement.dateTo).toISOString(), 'date')
			: ''
		this.name = announcement?.name ?? ''
		this.description = announcement?.description ?? ''
		this.status = announcement?.status ?? 'active'
		this.user = announcement?.user
		this.addedBy = announcement?.addedBy
	}

	static async fetchAll() {
		if (!getLSServiceId()) return []
		return AnnouncementService.getAll().then(res => res.data)
	}

	static convertStatus(status: string) {
		switch (status) {
			case 'active':
				return 'Aktywne'
			case 'inactive':
				return 'Nieaktywne'
			default:
				return status
		}
	}

	static getStatusColor(status: string) {
		switch (status) {
			case 'active':
				return 'active'
			case 'inactive':
				return 'gray'
			default:
				return ''
		}
	}

	static schema = yup.object({
		dateFrom: yup.date().required('Data jest wymagana'),
		dateTo: yup.date(),
		name: yup.string().required('Nazwa jest wymagana'),
		description: yup.string(),
	})
}

export class TableAnnouncement {
	_id: string
	dateFrom: string
	dateTo: string
	name: string
	addedBy: string
	description: string
	status: string
	original: Announcement

	constructor(announcement: Announcement) {
		this._id = announcement._id
		this.dateFrom = formatDate(
			new Date(announcement.dateFrom).toISOString(),
			'localeDateString',
		)
		this.dateTo = announcement.dateTo
			? formatDate(
					new Date(announcement.dateTo).toISOString(),
					'localeDateString',
			  )
			: ''
		this.name = announcement.name
		this.description = announcement.description
		this.status = Announcement.convertStatus(announcement.status)
		this.addedBy = announcement.addedBy ?? ''
		this.original = announcement
	}

	static statusOptions = [
		{
			value: Announcement.convertStatus('active'),
			label: Announcement.convertStatus('active'),
		},
		{
			value: Announcement.convertStatus('inactive'),
			label: Announcement.convertStatus('inactive'),
		},
	]
}
