import { FormControl, FormErrorMessage, GridItem } from '@chakra-ui/react'
import {
	Tile,
	TileSaveButton,
	TileCancelButton,
	InputField,
	SelectField,
} from '@kevea/react-components'
import { AxiosResponse } from 'axios'
import { StatusBadge } from 'components/StatusBadge'
import { Form, Formik, FormikProps } from 'formik'
import { Order } from 'models/order'
import { useRef, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { isModuleEnabled } from 'routes'
import { OrderService } from 'services/order'
import { OrderContext } from '../Order'
import { MedicineTable } from './MedicineTable'
import { ProductTable } from './ProductTable'

export const GeneralEdit = () => {
	const { order, refetch, ...rest } = useOutletContext<OrderContext>()
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()

	const formik = useRef<FormikProps<Order>>(null)

	const handleSubmit = (values: Order) => {
		const afterSubmit = (response?: AxiosResponse<Order>) => {
			setLoading(false)
			if (order?.number) refetch?.()
			navigate('/orders/' + response?.data?._id ?? '')
		}
		setLoading(true)
		const newOrder: Order = {
			...values,
		}
		if (order?._id) {
			OrderService.put(newOrder).then(afterSubmit)
		} else {
			OrderService.post(newOrder).then(afterSubmit)
		}
	}

	return (
		<Formik
			validateOnBlur={false}
			validateOnChange={false}
			onSubmit={handleSubmit}
			initialValues={new Order(order)}
			innerRef={formik}
			validationSchema={Order.validationSchema}
		>
			{({ errors, setFieldValue, values }) => (
				<Form>
					<Tile
						grid
						{...rest}
						titleElement={<StatusBadge order={order} />}
						headerButtons={
							<>
								<TileSaveButton
									onSave={() => formik.current?.submitForm()}
									isLoading={loading}
								/>
								<TileCancelButton
									isLoading={loading}
									onCancel={() =>
										order?._id
											? navigate('/orders/' + order._id)
											: navigate('/orders')
									}
								/>
							</>
						}
					>
						<GridItem colSpan={{ base: 2, lg: 1 }}>
							{!order?.number && (
								<SelectField
									options={Order.typeOptions.filter(
										t =>
											(isModuleEnabled('MEDICINE_WAREHOUSE') &&
												t.value === 'medicine') ||
											(isModuleEnabled('KITCHEN') && t.value === 'product'),
									)}
									name="type"
									label="Rodzaj zamówienia"
								/>
							)}
							<InputField
								name="foreignNumber"
								placeholder="Numer obcy"
								label="Numer obcy"
							/>
						</GridItem>
						<GridItem colSpan={2}>
							{values.type === 'medicine' ? (
								<FormControl isInvalid={Boolean(errors.patients)}>
									<MedicineTable
										order={values}
										setPatientsField={patients =>
											setFieldValue('patients', patients)
										}
									/>
									{errors.patients && (
										<FormErrorMessage>{errors.patients}</FormErrorMessage>
									)}
								</FormControl>
							) : (
								<FormControl isInvalid={Boolean(errors.products)}>
									<ProductTable
										order={values}
										setProductsField={products =>
											setFieldValue('products', products)
										}
									/>
									{errors.products && (
										<FormErrorMessage>{errors.products}</FormErrorMessage>
									)}
								</FormControl>
							)}
						</GridItem>
					</Tile>
				</Form>
			)}
		</Formik>
	)
}
