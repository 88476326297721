import { DishService } from 'services/dictionaries/dish'
import * as yup from 'yup'
export class Dish {
	constructor(dish?: Dish) {
		this._id = dish?._id
		this.name = dish?.name ?? ''
		this.allergens = dish?.allergens ?? []
		this.description = dish?.description ?? ''
		this.calories = dish?.calories
		this.weight = dish?.weight
	}
	_id: string | undefined
	name: string
	allergens: number[]
	description: string
	calories: number
	weight: number

	static validationSchema = yup.object({
		name: yup.string().required('Nazwa jest wymagana'),
	})
	static fetchDishes = async () => {
		return DishService.getAll().then(res => res.data)
	}
}
