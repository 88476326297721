import { Attachments } from 'pages/Attachments'
import { useOutletContext } from 'react-router-dom'
import { OrderContext } from '../Order'

export const AttachmentsWrapper = () => {
	const { breadcrumbs, order: contract } = useOutletContext<OrderContext>()

	return (
		<Attachments
			attachmentType="orders"
			breadcrumbs={breadcrumbs}
			header={`${contract?.number} - Lista załączników`}
		/>
	)
}
