export class Shift {
	constructor(shift?: Shift) {
		this.startDate = shift?.startDate ?? ''
		this.endDate = shift?.endDate ?? ''
		this.noteForEmployees = shift?.noteForEmployees ?? ''
		this.description = shift?.description ?? ''
	}
	startDate?: string
	endDate?: string
	noteForEmployees?: string
	description?: string
}
