import { useDisclosure } from '@chakra-ui/react'
import { AlertDialog, Link, Table, Tile } from '@kevea/react-components'
import { Product } from 'models/dictionaries/product'
import { SettingsContext } from 'pages/settings/Settings'
import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useOutletContext } from 'react-router-dom'
import {
	Cell,
	Column,
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table'
import { ProductService } from 'services/dictionaries/product'
import { ProductModal } from './ProductModal'

export const ProductList = () => {
	const { instance, refetch, ...rest } = useOutletContext<SettingsContext>()
	const removeDisclosure = useDisclosure()
	const [isRemoving, setIsRemoving] = useState(false)
	const addDisclosure = useDisclosure()
	const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(
		undefined,
	)

	const {
		data: product,
		isLoading,
		refetch: refetchProducts,
	} = useQuery('fetchProducts', Product.fetchProducts)

	const data = useMemo(
		() => product?.map(product => new Product(product)) || [],
		[product],
	)

	const columns = useMemo<Column<Product>[]>(
		() => [
			{
				Header: 'Nazwa',
				accessor: 'name',
				Cell: cell => (
					<Link
						onClick={() => {
							setSelectedProduct(cell.row.original)
							addDisclosure.onOpen()
						}}
					>
						{cell.value}
					</Link>
				),
			},
			{
				Header: 'Jednostka',
				accessor: 'unit',
			},
		],
		[],
	)

	const tableInstance = useTable(
		{ columns, data },
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
	)

	return (
		<Tile {...rest}>
			<AlertDialog
				header="Usuń produkt"
				onClose={removeDisclosure.onClose}
				isRemoving={isRemoving}
				isOpen={removeDisclosure.isOpen}
				onRemove={() => {
					if (selectedProduct?._id) {
						setIsRemoving(true)
						ProductService.delete(selectedProduct._id).then(() => {
							setIsRemoving(false)
							setSelectedProduct(undefined)
							removeDisclosure.onClose()
							refetchProducts()
						})
					}
				}}
			>
				Czy chcesz usunąć produkt <strong>{selectedProduct?.name}</strong>?
			</AlertDialog>
			<ProductModal
				selectedProduct={selectedProduct}
				isOpen={addDisclosure.isOpen}
				onClose={note => {
					addDisclosure.onClose()
					refetchProducts()
				}}
			/>
			<Table
				tableInstance={tableInstance}
				loading={isLoading}
				buttons={[
					{
						label: 'Dodaj',
						type: 'add',
						action: () => {
							setSelectedProduct(undefined)
							addDisclosure.onOpen()
						},
					},
				]}
				menuOptions={{
					singleSelection: [
						{
							label: 'Usuń',
							onClick: notes => {
								setSelectedProduct(notes[0])
								removeDisclosure.onOpen()
							},
						},
					],
				}}
			/>
		</Tile>
	)
}
