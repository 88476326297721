import { AxiosResponse } from 'axios'
import { User } from 'models/user'
import { resolvePath } from 'services'
import http from './axios'

const path = '/users'

export class UserService {
	static async getAll(): Promise<AxiosResponse<User[]>> {
		return http.get(
			resolvePath({
				path,
			}),
		)
	}
	static async updateMobileAccess(user: User): Promise<AxiosResponse<User>> {
		return http.post(
			resolvePath({
				path,
				id: user._id,
			}),
			user,
		)
	}
}
