import { useDisclosure } from '@chakra-ui/react'
import {
	AlertDialog,
	BreadcrumbData,
	Link,
	Table,
	Tile,
	useBreadcrumbs,
	useErrorToast,
} from '@kevea/react-components'
import { Attachment, TableAttachment } from 'models/attachment'
import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { RouteObject, useNavigate, useParams } from 'react-router-dom'
import {
	Cell,
	Column,
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table'
import { AttachmentService, AttachmentType } from 'services/attachment'
import { AttachmentModal } from './AttachmentModal'
import { saveAs } from 'file-saver'

type Props = {
	attachmentType: AttachmentType
	idParamName?: string
	breadcrumbs?: BreadcrumbData<string>[]
	header?: string
	onAction?: VoidFunction
}

export const Attachments = ({
	attachmentType,
	breadcrumbs,
	onAction,
	header,
	idParamName = 'id',
}: Props) => {
	const id = useParams()[idParamName]
	const navigate = useNavigate()
	const addDisclosure = useDisclosure()
	const removeDisclosure = useDisclosure()
	const [selectedAttachment, setSelectedAttachment] = useState<
		Attachment | undefined
	>(undefined)
	const [isRemoving, setIsRemoving] = useState(false)
	const error = useErrorToast()

	const downloadFile = async (_id: string): Promise<Blob | undefined> => {
		if (id) {
			return AttachmentService.downloadFile(id, attachmentType, _id)
				.then(res => res.data)
				.catch(() => {
					error({ description: 'Wystąpił błąd podczas pobierania pliku' })
					return undefined
				})
		}

		return undefined
	}
	const fetchAttachments = async () => {
		if (id) {
			return AttachmentService.getAll(id, attachmentType).then(res => res.data)
		} else {
			navigate('/')
		}
	}

	const {
		data: attachments,
		isLoading,
		refetch,
	} = useQuery('fetchAttachments', fetchAttachments)

	const data = useMemo<TableAttachment[]>(
		() => attachments?.map(attachment => new TableAttachment(attachment)) ?? [],
		[attachments],
	)

	const columns = useMemo<Column<TableAttachment>[]>(
		() => [
			{
				Header: 'Nazwa',
				accessor: 'name',
				Cell: cell => (
					<Link
						onClick={() => {
							const attachment = cell.row.original.original
							if (attachment?._id)
								downloadFile(attachment._id).then(blob => {
									if (blob) window.open(URL.createObjectURL(blob), '_blank')
								})
						}}
					>
						{cell.value}
					</Link>
				),
			},
			{ Header: 'Opis', accessor: 'description' },
			{ Header: 'Rozmiar', accessor: 'fileSize' },
		],
		[],
	)

	const tableInstance = useTable(
		{ data, columns },
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
	)

	return (
		<Tile titleElement={null} breadcrumbs={breadcrumbs} header={header}>
			{id && (
				<>
					<AttachmentModal
						isOpen={addDisclosure.isOpen}
						attachmentType={attachmentType}
						oid={id}
						selectedAttachment={selectedAttachment}
						onClose={() => {
							refetch()
							onAction?.()
							addDisclosure.onClose()
						}}
					/>

					<AlertDialog
						header="Usuń załącznik"
						onClose={removeDisclosure.onClose}
						isRemoving={isRemoving}
						isOpen={removeDisclosure.isOpen}
						onRemove={() => {
							if (selectedAttachment?._id) {
								setIsRemoving(true)
								AttachmentService.delete(
									id,
									attachmentType,
									selectedAttachment,
								).then(res => {
									setIsRemoving(false)
									setSelectedAttachment(undefined)
									removeDisclosure.onClose()
									onAction?.()
									refetch()
								})
							}
						}}
					>
						Czy chcesz usunąć załącznik o nazwie{' '}
						<strong>{selectedAttachment?.name}</strong>?
					</AlertDialog>
					<Table
						tableInstance={tableInstance}
						loading={isLoading}
						buttons={[
							{
								type: 'add',
								action: () => {
									setSelectedAttachment(undefined)
									addDisclosure.onOpen()
								},
							},
						]}
						menuOptions={{
							singleSelection: [
								{
									label: 'Edytuj',
									onClick: (attachments: Attachment[]) => {
										if (attachments[0]?._id) {
											setSelectedAttachment(attachments[0])
											addDisclosure.onOpen()
										}
									},
								},
								{
									label: 'Pobierz',
									onClick: (attachments: Attachment[]) => {
										if (attachments[0]?._id)
											downloadFile(attachments[0]?._id).then(blob => {
												if (blob) saveAs(blob, attachments[0]?.fileName)
											})
									},
								},
								{
									label: 'Usuń',
									onClick: (attachments: Attachment[]) => {
										setSelectedAttachment(attachments[0])
										removeDisclosure.onOpen()
									},
								},
							],
						}}
					/>
				</>
			)}
		</Tile>
	)
}
