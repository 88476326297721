import { ModuleRoute } from 'components/ModuleRoute'
import { RestrictRoute } from 'components/RestrictRoute'
import {
	FaFileSignature,
	FaSlidersH,
	FaTabletAlt,
	FaUsers,
} from 'react-icons/fa'
import { RouteObject } from 'react-router-dom'
import { Settings } from './Settings'
import { ContractTemplateList } from './views/contractTemplates/ContractTemplateList'
import contractTemplateRoutes from './views/contractTemplates/routes'
import { DeviceList } from './views/devices/DeviceList'
import { DictionaryList } from './views/dictionaries/DictionaryList'
import { DishList } from './views/dictionaries/dishes/DishList'
import { NoteList } from './views/dictionaries/notes/NoteList'
import { ProductList } from './views/dictionaries/products/ProductList'
import { TaskTypeList } from './views/dictionaries/tasks/TaskTypeList'
import { General } from './views/General'
import { UserList } from './views/users/UserList'

const settingsRoutes: RouteObject[] = [
	{
		path: 'settings',
		element: (
			<RestrictRoute module="settings" power="view">
				<Settings />
			</RestrictRoute>
		),
		children: [
			{
				index: true,
				element: <General />,
				breadcrumb: 'Ustawienia placówki',
				breadcrumbIcon: <FaSlidersH />,
			},
			{
				path: 'dictionaries',
				element: (
					<RestrictRoute module="settings" power="dictionaries">
						<DictionaryList />
					</RestrictRoute>
				),
				breadcrumb: 'Słowniki',
				breadcrumbIcon: <FaSlidersH />,
			},
			{
				path: 'dictionaries/notes',
				element: <NoteList />,
				breadcrumb: 'Rodzaje notatek',
				breadcrumbIcon: <FaSlidersH />,
			},
			{
				path: 'dictionaries/products',
				element: (
					<ModuleRoute type="KITCHEN">
						<ProductList />
					</ModuleRoute>
				),
				breadcrumb: 'Produkty',
				breadcrumbIcon: <FaSlidersH />,
			},
			{
				path: 'dictionaries/dishes',
				element: (
					<ModuleRoute type="KITCHEN">
						<DishList />
					</ModuleRoute>
				),
				breadcrumb: 'Dania',
				breadcrumbIcon: <FaSlidersH />,
			},
			{
				path: 'dictionaries/tasks',
				element: <TaskTypeList />,
				breadcrumb: 'Rodzaje zadań',
				breadcrumbIcon: <FaSlidersH />,
			},
			{
				path: 'devices',
				element: (
					<RestrictRoute module="settings" power="devices">
						<DeviceList />
					</RestrictRoute>
				),
				breadcrumb: 'Urządzenia',
				breadcrumbIcon: <FaTabletAlt />,
			},
			{
				path: 'users',
				element: (
					<RestrictRoute module="settings" power="users">
						<UserList />
					</RestrictRoute>
				),
				breadcrumbIcon: <FaUsers />,
				breadcrumb: 'Użytkownicy',
			},
			...contractTemplateRoutes,
		],
	},
]

export default settingsRoutes
