import {
	Box,
	Button,
	Center,
	Flex,
	IconButton,
	Text,
	useDisclosure,
} from '@chakra-ui/react'
import {
	formatDate,
	Link,
	Tile,
	TileButton,
	TileGrid,
} from '@kevea/react-components'
import { Announcement } from 'models/announcement'
import { RhmShiftSettings } from 'models/service'
import { TableTask, Task } from 'models/task'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { FaBell, FaInfo, FaPlus, FaTasks, FaTrashAlt } from 'react-icons/fa'
import { useQuery } from 'react-query'
import { AnnouncementService } from 'services/announcements'
import { ShiftService } from 'services/shift'

const Dashboard = () => {
	const [currentShift, setCurrentShift] = useState<
		RhmShiftSettings | undefined
	>(undefined)
	const [loading, setLoading] = useState(true)

	const fetchShiftSettings = async () => {
		setLoading(true)
		ShiftService.getCurrentShiftSettings()
			.then(settings => {
				setCurrentShift(settings)
				setLoading(false)
			})
			.catch(e => {
				setLoading(e)
			})
	}

	const {
		data: tasks,
		isFetching: isFetchingTasks,
		refetch,
	} = useQuery(
		'fetchTasks',
		() =>
			Task.fetchTasks(
				formatDate(new Date().toISOString(), 'date'),
				undefined,
				currentShift,
			).then(tasks => tasks?.filter(task => task.status === 1) ?? []),
		{ enabled: Boolean(currentShift) },
	)

	const { data: announcements, isLoading: isLoadingAnnouncement } = useQuery(
		'fetchAnnouncements',
		() =>
			Announcement.fetchAll().then(
				announcements =>
					announcements?.filter(
						announcement => announcement.status === 'active',
					) ?? [],
			),
	)

	const dataTasks = useMemo(() => {
		return tasks?.slice(0, 10).map(task => new TableTask(task)) ?? []
	}, [tasks])
	const dataAnnouncements = useMemo(() => {
		return (
			announcements
				?.slice(0, 10)
				.map(announcement => new Announcement(announcement)) ?? []
		)
	}, [announcements])

	useEffect(() => {
		fetchShiftSettings()
	}, [])

	useEffect(() => {
		if (currentShift) {
			refetch()
		}
	}, [currentShift])

	return (
		<TileGrid>
			<Tile
				headerIcon={<FaTasks />}
				header="Zadania na dziś"
				isLoading={isFetchingTasks || loading}
			>
				<Row
					left={<Text fontWeight="semibold">Zadanie</Text>}
					right={<Text fontWeight="semibold">Osoba odpowiedzialna</Text>}
				/>
				{dataTasks?.map((task, idx) => (
					<Row
						key={idx}
						left={
							<Text>
								{task.title} - <strong>{task.patient}</strong>
							</Text>
						}
						right={<Text>{task.assignTo || '-'}</Text>}
					/>
				))}
				<Center py={1}>
					<Link to="/reports/tasks">Pokaż wszystkie</Link>
				</Center>
			</Tile>
			<Tile
				isLoading={isLoadingAnnouncement}
				headerIcon={<FaInfo />}
				header="Ogłoszenia"
			>
				<Box textAlign="center">
					{dataAnnouncements?.length > 0 ? (
						dataAnnouncements.map((announcement, idx) => (
							<Row key={idx} left={<strong>{announcement.name}</strong>} />
						))
					) : (
						<strong>Brak ogłoszeń</strong>
					)}
				</Box>
				<Center py={1}>
					<Link to="/announcements">Pokaż wszystkie</Link>
				</Center>
			</Tile>
		</TileGrid>
	)
}

type RowProps = {
	left: ReactNode
	right?: ReactNode
}

const Row = ({ left, right }: RowProps) => {
	return (
		<Flex
			justifyContent="space-between"
			py={2}
			borderBottom="1px solid"
			borderColor="gray.300"
			alignItems="center"
		>
			<Box>{left}</Box>
			<Box>{right}</Box>
		</Flex>
	)
}

export default Dashboard
