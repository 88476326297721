import { Dish } from './dictionaries/dish'

export class MenuDay {
	constructor(date: string, dishes?: Menu[]) {
		this.date = date
		this.dishes = dishes ?? []
	}

	date: string
	dishes: Menu[]
}

export class Menu {
	constructor(menu: Menu) {
		this.date = menu.date
		this.type = menu.type
		this.dish = menu.dish
	}

	date: string
	type: number // 0 - śniadanie, 1 - obiad, 2 - kolacja
	dish: Dish | undefined
}
