import { formatDate } from '@kevea/react-components'
import { AxiosResponse } from 'axios'
import { RhmShiftSettings } from 'models/service'
import { Shift } from 'models/shift'
import { Task } from 'models/task'
import { getBaseInstancePath, printConfig } from 'services'
import { KvTime } from 'utils/kvTime'
import http from './axios'

const path = '/shifts'

export class ShiftService {
	static async getCurrentShiftSettings(): Promise<
		RhmShiftSettings | undefined
	> {
		return ShiftService.getSettings()
			.then(res => res.data)
			.then(settings => {
				const currentTime = KvTime.getMinutes(
					formatDate(new Date().toISOString().split('.')[0] ?? '', 'time'),
				)
				return settings.find(shift =>
					shift.startTime > shift.endTime
						? currentTime >= shift.startTime || currentTime < shift.endTime
						: shift.startTime <= currentTime && shift.endTime > currentTime,
				)
			})
	}

	static async getSettings(): Promise<AxiosResponse<RhmShiftSettings[]>> {
		return http
			.get<RhmShiftSettings[]>(`${getBaseInstancePath(path)}/settings`)
			.then(res => {
				res.data.map(setting => {
					//@ts-ignore
					setting.startTime = setting.startTime.value
					//@ts-ignore
					setting.endTime = setting.endTime.value
					setting.startTimeString = KvTime.getTimeFromMinutes(setting.startTime)
					setting.endTimeString = KvTime.getTimeFromMinutes(setting.endTime)
					return setting
				})
				return res
			})
	}
	static async get(dateString: string): Promise<AxiosResponse<Shift>> {
		return http.get(`${getBaseInstancePath(path)}/${dateString}`)
	}
	static async put(shift: Shift): Promise<AxiosResponse<Shift>> {
		return http.put(`${getBaseInstancePath(path)}/${shift.startDate}`, shift)
	}
	static async print(dateString: string): Promise<Blob> {
		return http
			.get<Blob>(
				`${getBaseInstancePath(path)}/${dateString}/print`,
				printConfig,
			)
			.then(res => res.data)
	}
	static async printMultiple(from: Date, to: Date): Promise<Blob> {
		return http
			.get<Blob>(
				`${getBaseInstancePath(
					path,
				)}/print?dateFrom=${from.toISOString()}&dateTo=${to.toISOString()}`,
				printConfig,
			)
			.then(res => res.data)
	}

	// TASKS
	static async getTasks(dateString: string): Promise<AxiosResponse<Task[]>> {
		return http.get(`${getBaseInstancePath(path)}/${dateString}/tasks`)
	}
	static async getNotes(dateString: string): Promise<AxiosResponse<Task[]>> {
		return http.get(`${getBaseInstancePath(path)}/${dateString}/notes`)
	}
}
