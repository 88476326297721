import { formatDate } from '@kevea/react-components'
import { NoteDictionary } from './dictionaries/note'
import { Patient } from './patient'
import * as yup from 'yup'
import { RhmShiftSettings } from './service'
import { NoteService } from 'services/note'
import { ShiftService } from 'services/shift'

export class Note {
	constructor(note: Note) {
		this._id = note._id
		this.name = note.noteType?.name ?? note.name ?? ''
		this.description = note.description ?? ''
		this.date = note.date ?? new Date().toISOString()
		this.noteType = note.noteType ?? null
		this.noteTypeId = note.noteType?._id
		this.patient = note.patient
		this.addedBy = note.addedBy
	}
	_id?: string | undefined
	date?: string
	noteType?: NoteDictionary | null // null = other
	noteTypeId?: string | undefined
	patient?: Patient
	description?: string
	addedBy?: string
	name?: string

	static validationSchema = yup.object({
		name: yup.string().when('noteTypeId', {
			is: (noteTypeId: string | undefined) => noteTypeId === undefined,
			then: yup.string().required('Nazwa jest wymagana'),
		}),
	})

	static fetchNotes = async (
		patient?: Patient,
		date?: string,
		shift?: RhmShiftSettings,
	) => {
		if (patient?._id) {
			return NoteService.getAll(patient._id).then(res => res.data)
		} else if (shift && date) {
			return ShiftService.getNotes(
				`${date}T${shift.startTimeString}:00.000Z`,
			).then(res => res.data)
		}
	}
}

export class TableNote {
	constructor(note: Note) {
		this._id = note._id
		this.name = note.noteType?.name ?? note.name ?? ''
		this.description = note.description ?? ''
		this.date = formatDate(note.date ?? '', 'localeString')
		this.filterDate = formatDate(note.date ?? '', 'date')
		this.patient = note.patient ? Patient.getFullName(note.patient) : ''
		this.addedBy = note.addedBy ?? ''
		this.original = note
	}
	_id: string | undefined
	date: string
	filterDate: string
	description: string
	patient: string
	name: string
	addedBy: string
	original: Note
}
