import { Address, formatDate } from '@kevea/react-components'
import { Patient } from './patient'
import * as yup from 'yup'
import { ContractService } from 'services/contract'
import { ContractTemplate } from './contractTemplate'
import { Validators } from 'utils/validators'

export type TContract = {
	_id: string
	number: string
	ownNumber: string
	validFrom: string
	hasThirdParty: boolean
	validUntil: string
	dateOfConclusion: string
	paymentDate: number
	patient: Patient
	thirdParty: ContractThirdParty
	status: number
	monthlyCharge: number
	contractTemplate: ContractTemplate | null
}

export class Contract {
	constructor(contract?: Contract) {
		this._id = contract?._id
		this.number = contract?.number
		this.ownNumber = contract?.ownNumber ?? ''
		this.hasThirdParty = contract?.hasThirdParty ?? true
		this.validFrom = contract?.validFrom
			? formatDate(contract.validFrom, 'date')
			: formatDate(new Date().toISOString(), 'date')
		this.validUntil = contract?.validUntil
			? formatDate(contract.validUntil, 'date')
			: ''
		this.dateOfConclusion = contract?.dateOfConclusion
			? formatDate(contract.dateOfConclusion, 'date')
			: formatDate(new Date().toISOString(), 'date')
		this.paymentDate = contract?.paymentDate ?? 5
		this.patient = contract?.patient
		this.status = contract?.status ?? 1
		this.monthlyCharge = contract?.monthlyCharge ?? 0
		this.thirdParty = new ContractThirdParty(contract?.thirdParty)
		this.contractTemplate = contract?.contractTemplate ?? null
	}
	_id?: string
	number?: string
	ownNumber?: string
	validFrom: string
	hasThirdParty: boolean
	validUntil: string
	dateOfConclusion: string
	paymentDate: number
	patient?: Patient
	thirdParty: ContractThirdParty
	status: number
	monthlyCharge: number
	contractTemplate: ContractTemplate | null

	static validationSchema = yup.object({
		validFrom: yup.date().required('Data rozpoczęcia umowy jest wymagana'),
		validUntil: yup
			.date()
			.min(
				yup.ref('validFrom'),
				'Data zakończenia nie może być wcześniejsza niż data rozpoczęcia',
			),
		dateOfConclusion: yup
			.date()
			.required('Data podpisania umowy jest wymagana'),
		paymentDate: yup
			.number()
			.required('Dzień płatności jest wyamgany')
			.min(1, 'Nieprawidłowy dzień')
			.max(31, 'Nieprawidłowy dzień'),
		monthlyCharge: yup
			.number()
			.required('Miesięczna opłata jest wymagana')
			.min(1, 'Nieprawidłowa kwota'),
		patient: yup.string().required('Pacjent jest wymagany'),
		contractTemplate: yup.mixed().required('Szablon umowy jest wymagany'),
		thirdParty: yup.object().when('hasThirdParty', {
			is: true,
			then: yup.object({
				firstName: yup.string().required('Imię jest wymagane'),
				lastName: yup.string().required('Nazwisko jest wymagane'),
				pesel: yup
					.string()
					.test('isPeselValid', 'Numer PESEL jest nieprawidłowy', pesel =>
						pesel && pesel.length > 0 ? Validators.pesel(pesel) : true,
					),
				identification: yup.object({
					idNumber: yup.string().when('type', {
						is: (type: ContractThirdPartyIdentification['type']) =>
							type === 'idCard',
						then: s => s.required('Numer dowodu osobistego jest wymagany'),
					}),
					passportNumber: yup.string().when('type', {
						is: (type: ContractThirdPartyIdentification['type']) =>
							type === 'passport',
						then: s => s.required('Numer paszportu jest wymagany'),
					}),
				}),
				address: yup.object({
					address: yup.string().required('Ulica i numer lokalu jest wymagany'),
					city: yup.string().required('Miasto jest wymagane'),
					zipCode: yup.string().required('Kod pocztowy jest wymagany'),
				}),
			}),
		}),
	})
	static fetchContracts = async () => {
		return ContractService.getAll().then(res => res.data)
	}

	static convertStatus = (status: number): string => {
		switch (status) {
			case 1:
				return 'Niepotwierdzona'
			case 2:
				return 'Aktywna'
			case 3:
				return 'Rozwiązana'
			default:
				return `${status}`
		}
	}
	static getStatusColor = (status: number): string => {
		switch (status) {
			case 1:
				return 'future'
			case 2:
				return 'active'
			case 3:
				return 'dead'
			default:
				return `${status}`
		}
	}
	static statusOptions = [
		{
			label: Contract.convertStatus(1),
			value: 1,
		},
		{
			label: Contract.convertStatus(2),
			value: 2,
		},
		{
			label: Contract.convertStatus(3),
			value: 3,
		},
	]
}

export type ContractThirdPartyIdentification =
	| {
			type: 'idCard'
			idNumber: string
			idIssuer: string
			idExpiryDate: string
	  }
	| {
			type: 'passport'
			passportNumber: string // AA9999999
			passportExpiryDate: string
	  }
	| undefined

export class ContractThirdParty {
	firstName: string
	lastName: string
	address: Address
	identification: ContractThirdPartyIdentification
	idNumber: string
	idIssuer: string
	idExpiryDate: string
	pesel: string
	dateOfBirth: string
	phoneNumber: string
	emailAddress: string
	constructor(thirdParty?: ContractThirdParty) {
		this.firstName = thirdParty?.firstName ?? ''
		this.lastName = thirdParty?.lastName ?? ''
		this.idNumber = thirdParty?.idNumber // backwords compatibility
		this.idIssuer = thirdParty?.idIssuer // backwords compatibility
		this.idExpiryDate = thirdParty?.idExpiryDate // backwords compatibility
		this.pesel = thirdParty?.pesel ?? ''
		this.phoneNumber = thirdParty?.phoneNumber ?? ''
		this.emailAddress = thirdParty?.emailAddress ?? ''
		this.dateOfBirth = thirdParty?.dateOfBirth
			? formatDate(thirdParty?.dateOfBirth, 'date')
			: ''
		this.address = new Address(thirdParty?.address)
		this.identification = thirdParty?.identification ?? {
			type: 'idCard',
			idNumber: this.idNumber ?? '',
			idExpiryDate: this.idExpiryDate
				? formatDate(this.idExpiryDate, 'date')
				: '',
			idIssuer: this.idIssuer ?? '',
		}
	}

	static convertIdentificationType = (
		type: ContractThirdPartyIdentification['type'],
	): string => {
		switch (type) {
			case 'idCard':
				return 'Dowód osobisty'
			case 'passport':
				return 'Paszport'
			default:
				return 'Brak'
		}
	}

	static getFullName = (thirdParty: ContractThirdParty) =>
		`${thirdParty.firstName} ${thirdParty.lastName}`
}

export class TableContract {
	constructor(contract: Contract) {
		this._id = contract._id
		this.original = contract
		this.number = contract.number ?? ''
		this.ownNumber = contract.ownNumber ?? ''
		this.patientName = contract.patient
			? Patient.getFullName(contract.patient)
			: ''
		this.validFrom = contract.validFrom
			? formatDate(contract.validFrom, 'localeDateString')
			: ''
		this.validUntil = contract.validUntil
			? formatDate(contract.validUntil, 'localeDateString')
			: ''
		this.dateOfConclusion = contract.dateOfConclusion
			? formatDate(contract.dateOfConclusion, 'localeDateString')
			: ''
		this.status = Contract.convertStatus(contract.status)
	}
	_id: string | undefined
	number: string
	ownNumber: string
	patientName: string
	validFrom: string
	validUntil: string
	dateOfConclusion: string
	status: string
	original: Contract

	static statusOptions = [
		{
			label: Contract.convertStatus(1),
			value: Contract.convertStatus(1),
		},
		{
			label: Contract.convertStatus(2),
			value: Contract.convertStatus(2),
		},
		{
			label: Contract.convertStatus(3),
			value: Contract.convertStatus(3),
		},
	]
}
