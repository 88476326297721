import { Layout } from 'layout/Layout'
import Dashboard from 'pages/Dashboard'
import patientRoutes from 'pages/patients/routes'
import diapersOrdersRoutes from 'pages/diapersOrders/routes'
import Login from 'pages/Login'
import reportRoutes from 'pages/reports/routes'
import settingsRoutes from 'pages/settings/routes'
import { ReactNode } from 'react'
import { Navigate, RouteObject } from 'react-router-dom'
import { isAuthenticated } from 'services'
import contractsRoutes from 'pages/contracts/routes'
import ordersRoutes from 'pages/orders/routes'
import { Menu } from 'pages/menu/Menu'
import warehouseDocumentRoutes from 'pages/warehouse/routes'
import announcementsRoutes from 'pages/announcements/routes'
import billRoutes from 'pages/bills/routes'
import { IService, RHMModule } from 'models/service'
import { getLSService } from 'utils/service'
import { ModuleRoute } from 'components/ModuleRoute'
import { RestrictRoute } from 'components/RestrictRoute'
import eventLogRoutes from 'pages/eventLog/routes'

const protect = (element: ReactNode) =>
	isAuthenticated() ? element : <Navigate to="/login" />
export const isModuleEnabled = (module: RHMModule): boolean => {
	const service = getLSService()
	if (!service) return false
	if (!service.rhmConfig.planConfig) return true
	return service.rhmConfig.planConfig.modules.includes(module)
}

const routes: RouteObject[] = [
	{
		path: '/',
		element: protect(<Layout />),
		children: [
			{ path: '', element: <Dashboard /> },
			...diapersOrdersRoutes,
			...billRoutes,
			...settingsRoutes,
			...patientRoutes,
			...reportRoutes,
			...contractsRoutes,
			...ordersRoutes,
			...warehouseDocumentRoutes,
			...announcementsRoutes,
			...eventLogRoutes,
			{
				path: 'menu',
				element: (
					<ModuleRoute type="KITCHEN">
						<RestrictRoute module="menu" power="view">
							<Menu />
						</RestrictRoute>
					</ModuleRoute>
				),
			},
			{
				path: '*',
				element: <Navigate to="/" />,
			},
		],
	},
	{
		path: '/login',
		element: <Login />,
	},
]

export default routes
