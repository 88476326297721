import { Box, useDisclosure } from '@chakra-ui/react'
import {
	AlertDialog,
	Link,
	SelectFilter,
	Table,
	Tile,
	TileGrid,
	useErrorToast,
} from '@kevea/react-components'
import { SelectedServiceContext } from 'App'
import { Badge } from 'components/StatusBadge'
import { Patient, PatientTable } from 'models/patient'
import React, { useContext, useMemo, useState } from 'react'
import { FaIdCard } from 'react-icons/fa'
import { useQuery } from 'react-query'
import {
	Cell,
	Column,
	useFilters,
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table'
import { PatientService } from 'services/patient'
import { ExportListModal } from './ExportListModal'
import { PrintListModal, PrintOptions } from './PrintListModal'

type Props = {}

const PatientList = (props: Props) => {
	const {
		data: patients,
		isLoading,
		refetch,
	} = useQuery('fetchPatients', () => Patient.fetchPatients(false))

	const { selectedService } = useContext(SelectedServiceContext)
	const [loading, setLoading] = useState(false)
	const printDisclosure = useDisclosure()
	const exportDisclosure = useDisclosure()

	const maxPatients = useMemo(
		() =>
			selectedService?.rhmConfig?.planConfig?.maxPatients ??
			Number.MAX_SAFE_INTEGER,
		[selectedService],
	)
	const patientCount = useMemo(
		() => patients?.filter(p => p.status === 0).length ?? 0,
		[patients],
	)

	const removePatientDisclosure = useDisclosure()
	const [selectedPatient, setSelectedPatient] = useState<undefined | Patient>(
		undefined,
	)
	const error = useErrorToast()

	const [isRemoving, setIsRemoving] = useState(false)
	const removePatient = () => {
		if (selectedPatient) {
			setIsRemoving(true)
			PatientService.delete(selectedPatient._id)
				.then(() => {
					setIsRemoving(false)
					removePatientDisclosure.onClose()
					refetch()
					setSelectedPatient(undefined)
				})
				.catch(() => {
					setIsRemoving(false)
					removePatientDisclosure.onClose()
				})
		}
	}

	const data = useMemo(
		() => patients?.map(patient => new PatientTable(patient)) || [],
		[patients],
	)

	const columns = useMemo<Column<PatientTable>[]>(
		() => [
			{
				Header: 'Imię i nazwisko',
				accessor: 'name',
				Cell: ({ row, value }) => <Link to={row.original._id}>{value}</Link>,
			},
			{ Header: 'PESEL', accessor: 'pesel' },
			{ Header: 'Data urodzenia', accessor: 'dateOfBirth' },
			{ Header: 'Data przyjęcia', accessor: 'admissionDate' },
			{
				Header: 'Status',
				accessor: 'status',
				Cell: ({ row }) => <Badge patient={row.original.original} />,
				Filter: ({ column }) => (
					<SelectFilter column={column} options={Patient.statusOptionsTable} />
				),
			},
			{
				Header: 'Płeć',
				accessor: 'sex',
				id: 'sex',
				Filter: ({ column }) => (
					<SelectFilter column={column} options={PatientTable.sexOptions} />
				),
			},
			{
				Header: 'Ubezwłasnowolniony',
				accessor: 'incapacitated',
				id: 'incapacitated',
				Filter: ({ column }) => (
					<SelectFilter
						column={column}
						options={PatientTable.incapacitatedOptions}
					/>
				),
			},
			{
				Header: 'Stopień niepełnosprawności',
				accessor: 'disability',
				id: 'disibility',
				Filter: ({ column }) => (
					<SelectFilter
						column={column}
						options={PatientTable.disablityLevelOptions}
					/>
				),
			},
		],
		[],
	)

	const tableInstance = useTable(
		{
			columns: columns,
			data,
			initialState: {
				filters: [{ id: 'status', value: 'Aktywny' }],
				hiddenColumns: ['disibility', 'sex', 'incapacitated'],
			},
		},
		useGlobalFilter,
		useFilters,
		useSortBy,
		usePagination,
		useRowSelect,
	)

	const handlePrintModalClose = (options: PrintOptions) => {
		printDisclosure.onClose()
		if (options) {
			setLoading(true)
			PatientService.printList(options)
				.then(blob => {
					if (blob) window.open(URL.createObjectURL(blob), '_blank')
					setLoading(false)
				})
				.catch(e => {
					error({
						description: 'Wystąpił błąd podczas generowania wydruku',
					})
					setLoading(false)
				})
		}
	}

	return (
		<TileGrid>
			<ExportListModal
				isOpen={exportDisclosure.isOpen}
				onClose={exportDisclosure.onClose}
				selectedPatients={tableInstance.selectedFlatRows.map(
					x => x.original.original,
				)}
				allPatients={patients}
			/>
			<PrintListModal
				isOpen={printDisclosure.isOpen}
				onClose={handlePrintModalClose}
			/>
			<Tile headerIcon={<FaIdCard />} header="Pacjenci">
				<AlertDialog
					isRemoving={isRemoving}
					isOpen={removePatientDisclosure.isOpen}
					onClose={removePatientDisclosure.onClose}
					header="Usuń Pacjenta"
					onRemove={removePatient}
				>
					Czy na pewno chcesz usunąć pacjenta ?
				</AlertDialog>
				<Table
					advancedFiltersColumnIds={['disibility', 'sex', 'incapacitated']}
					tableInstance={tableInstance}
					loading={isLoading || loading}
					buttons={[
						{ label: 'Dodaj', type: 'add', to: 'new' },
						{
							label: 'Wydruk',
							type: 'print',
							action: printDisclosure.onOpen,
						},
					]}
					menuOptions={{
						noSelection: [
							{
								label: 'Eksport',
								onClick: _ => {
									exportDisclosure.onOpen()
								},
							},
						],
						singleSelection: [
							{
								label: 'Eksport',
								onClick: patients => {
									exportDisclosure.onOpen()
								},
							},
							{
								label: 'Usuń',
								onClick: patients => {
									setSelectedPatient(patients[0])
									removePatientDisclosure.onOpen()
								},
							},
						],
						multipleSelection: [
							{
								label: 'Eksport',
								onClick: patients => {
									exportDisclosure.onOpen()
								},
							},
						],
					}}
				/>
			</Tile>
			{!isLoading &&
			maxPatients !== Number.MAX_SAFE_INTEGER &&
			patientCount >= maxPatients ? (
				<Box
					bg="red.500"
					position="absolute"
					left={0}
					right={0}
					bottom={0}
					zIndex={999999}
					color="white"
					fontSize={18}
					padding={1}
					textAlign="center"
					opacity={0.8}
				>
					Limit pacjentów ({`${patientCount}/${maxPatients}`}) został
					osiągnięty, skontaktuj się z nami w celu jego zwiększenia.
				</Box>
			) : (
				!isLoading &&
				maxPatients !== Number.MAX_SAFE_INTEGER &&
				patientCount >= maxPatients - 3 && (
					<Box
						bg="orange.500"
						position="absolute"
						left={0}
						right={0}
						bottom={0}
						zIndex={999999}
						color="white"
						fontSize={18}
						padding={1}
						textAlign="center"
						opacity={0.8}
					>
						Zbliżasz się do limitu pacjentów ({`${patientCount}/${maxPatients}`}
						), skontaktuj się z nami w celu jego zwiększenia.
					</Box>
				)
			)}
		</TileGrid>
	)
}

export default PatientList
