import { Product } from 'models/dictionaries/product'
import { Service } from 'services'

const path = '/products'
const objectPath = '/dictionaries'

export class ProductService extends Service<Product>({
	objectPath,
	path,
	objectService: true,
}) {}
