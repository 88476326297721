import { AxiosResponse } from 'axios'
import { Device } from 'models/device'
import { resolvePath } from 'services'
import http from './axios'

const path = '/devices'

export class DeviceService {
	static async getAll(): Promise<AxiosResponse<Device[]>> {
		return http.get(
			resolvePath({
				path,
			}),
		)
	}
	static async register(): Promise<AxiosResponse<Device>> {
		return http.get(
			resolvePath({
				path,
				id: 'token',
			}),
		)
	}
	static async unregister(id: string) {
		return http.delete(
			resolvePath({
				path,
				id,
			}),
		)
	}
}
