import { GridItem } from '@chakra-ui/react'
import {
	DataDisplay,
	MenuButton,
	Table,
	Tile,
	TileEditButton,
} from '@kevea/react-components'
import {
	TableWarehouseDocument,
	TableWarehouseEntry,
} from 'models/warehouseDocument'
import React, { useMemo, useState } from 'react'
import { useOutletContext } from 'react-router'
import { Column, useTable } from 'react-table'
import { WarehouseDocumentService } from 'services/warehouseDocument'
import { WarehouseDocument as WarehouseDocumentClass } from 'models/warehouseDocument'
import {
	WarehouseDocument,
	WarehouseDocumentContext,
} from '../WarehouseDocument'
import { renderDoseValue } from 'utils/fractions'
import { MedicineTable } from './MedicineTable'

type Props = {}

export const General = (props: Props) => {
	const { warehouseDocument, refetch, ...rest } =
		useOutletContext<WarehouseDocumentContext>()

	const [loading, setLoading] = useState(false)

	return warehouseDocument ? (
		<Tile
			isLoading={loading}
			grid
			{...rest}
			headerButtons={
				<>
					{
						<MenuButton
							options={[
								{
									label: 'Potwierdź',
									onClick: () => {
										if (warehouseDocument) {
											setLoading(true)
											WarehouseDocumentService.put({
												...warehouseDocument,
												status: 'completed',
											}).then(() => {
												setLoading(false)
												refetch()
											})
										}
									},
									disabled: warehouseDocument.status !== 'new',
								},
								{
									label: 'Cofnij status',
									onClick: () => {
										if (warehouseDocument) {
											setLoading(true)
											WarehouseDocumentService.put({
												...warehouseDocument,
												status: 'new',
											}).then(() => {
												setLoading(false)
												refetch()
											})
										}
									},
									disabled: warehouseDocument.status === 'new',
								},
							]}
						/>
					}
					{WarehouseDocumentClass.checkStatus(['new'], warehouseDocument) && (
						<TileEditButton />
					)}
				</>
			}
		>
			<GridItem colSpan={{ base: 2, lg: 1 }}>
				<DataDisplay
					data={warehouseDocument}
					rows={[
						{
							label: 'Typ dokumentu',
							accessor: 'documentType',
							render: WarehouseDocumentClass.convertDocumentType,
						},
					]}
				/>
			</GridItem>
			<GridItem colSpan={2}>
				<MedicineTable docEntries={warehouseDocument.entries} />
			</GridItem>
		</Tile>
	) : (
		<></>
	)
}
