import { ProductService } from 'services/dictionaries/product'
import * as yup from 'yup'
export class Product {
	constructor(product?: Product) {
		this._id = product?._id
		this.name = product?.name ?? ''
		this.unit = product?.unit ?? ''
		this.nameWithUnit = product ? `${product.name} [${product.unit}]` : ''
	}
	_id: string | undefined
	name: string
	unit: string
	nameWithUnit?: string

	static validationSchema = yup.object({
		name: yup.string().required('Nazwa jest wymagana'),
		unit: yup.string().required('Jednostka jest wymagana'),
	})
	static fetchProducts = async () => {
		return ProductService.getAll().then(res => res.data)
	}
}
