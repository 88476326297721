import { GridItem, Heading, useDisclosure } from '@chakra-ui/react'
import {
	DataDisplay,
	DataDisplayRow,
	Tile,
	TileEditButton,
	TilePrintButton,
	MenuButton,
	AlertDialog,
	formatDate,
} from '@kevea/react-components'
import { render } from '@testing-library/react'
import {
	NavigateButton,
	NavigationButtonContainer,
} from 'components/NavigateButton'
import { Contract, ContractThirdParty, TContract } from 'models/contract'
import { Patient } from 'models/patient'
import { useMemo, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { ContractService } from 'services/contract'
import { ContractContext } from '../Contract'
import { Annexes } from './Annexes'

export const General = () => {
	const { contract, refetch, ...rest } = useOutletContext<ContractContext>()
	const removeDisclosure = useDisclosure()
	const dissolveDisclosure = useDisclosure()
	const [isLoading, setIsLoading] = useState(false)

	const navigate = useNavigate()

	const confirm = () => {
		if (contract) {
			setIsLoading(true)
			ContractService.changeStatus(contract, 2).then(() => {
				refetch?.()
				setIsLoading(false)
			})
		}
	}
	const dissolve = () => {
		if (contract) {
			setIsLoading(true)
			ContractService.changeStatus(contract, 3).then(() => {
				refetch?.()
				setIsLoading(false)
			})
		}
	}
	const remove = () => {
		if (contract?._id) {
			setIsLoading(true)
			ContractService.delete(contract._id).then(() => {
				setIsLoading(false)
				navigate('/contracts')
			})
		}
	}

	const rows = useMemo<DataDisplayRow<TContract>[]>(
		() => [
			{
				accessor: 'patient',
				label: 'Pacjent',
				render: (patient: Patient) => Patient.getFullName(patient),
			},
			{
				accessor: 'ownNumber',
				label: 'Własny numer',
				hidden: !Boolean(contract.ownNumber),
			},

			{
				accessor: 'contractTemplate',
				label: 'Szablon umowy',
				render: x => x?.name ?? '-',
			},
			{ accessor: 'dateOfConclusion', label: 'Data podpisania', type: 'date' },
			{ accessor: 'validFrom', label: 'Ważna od', type: 'date' },
			{ accessor: 'validUntil', label: 'Ważna do', type: 'date' },
			{
				accessor: 'thirdParty',
				label: 'Osoba trzecia - Imię i Nazwisko',
				render: thirdParty => ContractThirdParty.getFullName(thirdParty),
				hidden: !contract?.hasThirdParty,
			},
			{
				accessor: 'thirdParty',
				label: 'Osoba trzecia - Data urodzenia',
				render: (thirdParty: ContractThirdParty | undefined) =>
					thirdParty?.dateOfBirth
						? formatDate(thirdParty.dateOfBirth, 'localeDateString')
						: '-',
				hidden: !contract?.hasThirdParty,
			},
			{
				accessor: 'thirdParty',
				label: 'Osoba trzecia - PESEL',
				render: thirdParty => thirdParty?.pesel,
				hidden: !contract?.hasThirdParty,
			},
			{
				accessor: 'thirdParty',
				label: 'Osoba trzecia - Dokument tożsamości',
				hidden: !contract?.hasThirdParty,
				render: (tp: ContractThirdParty) =>
					ContractThirdParty.convertIdentificationType(tp?.identification.type),
			},
			{
				accessor: (contract: Contract) =>
					contract?.thirdParty?.identification.type === 'idCard'
						? contract.thirdParty.identification.idNumber
						: undefined,
				label: 'Osoba trzecia - Nr dowodu osobistego',
				textProps: { casing: 'uppercase' },
				hidden:
					contract?.thirdParty?.identification.type !== 'idCard' ||
					!contract?.hasThirdParty,
			},
			{
				accessor: (contract: Contract) =>
					contract?.thirdParty?.identification.type === 'passport'
						? contract.thirdParty.identification.passportNumber
						: undefined,
				label: 'Osoba trzecia - Nr paszportu',
				textProps: { casing: 'uppercase' },
				hidden:
					contract?.thirdParty?.identification.type !== 'passport' ||
					!contract?.hasThirdParty,
			},
			{
				accessor: (contract: Contract) =>
					contract?.thirdParty?.identification.type === 'idCard'
						? contract.thirdParty.identification.idIssuer
						: undefined,
				label: 'Osoba trzecia - Organ wydający dowód osobisty',
				textProps: { casing: 'uppercase' },
				hidden:
					contract?.thirdParty?.identification.type !== 'idCard' ||
					!contract?.hasThirdParty,
			},
			{
				accessor: (contract: Contract) =>
					contract?.thirdParty?.identification.type === 'idCard'
						? contract.thirdParty.identification.idExpiryDate
						: undefined,
				label: 'Osoba trzecia - Data ważności dowodu osobistego',
				hidden:
					contract?.thirdParty?.identification.type !== 'idCard' ||
					!contract?.hasThirdParty,
				type: 'date',
			},
			{
				accessor: (contract: Contract) =>
					contract?.thirdParty?.identification.type === 'passport'
						? contract.thirdParty.identification.passportExpiryDate
						: undefined,
				label: 'Osoba trzecia - Data ważności paszportu',
				hidden:
					contract?.thirdParty?.identification.type !== 'passport' ||
					!contract?.hasThirdParty,
				type: 'date',
			},
			{
				accessor: contract => contract?.thirdParty?.address,
				label: 'Osoba trzecia - Adres zamieszkania',
				type: 'address',
				hidden: !contract?.hasThirdParty,
			},
			{
				accessor: contract => contract?.thirdParty?.phoneNumber,
				label: 'Osoba trzecia - Telefon kontaktowy',
				hidden: !contract?.hasThirdParty,
			},
			{
				accessor: contract => contract?.thirdParty?.emailAddress,
				label: 'Osoba trzecia - Adres Email',
				hidden: !contract?.hasThirdParty,
			},
			{
				accessor: 'monthlyCharge',
				label: 'Stawka miesięczna',
				render: (monthlyCharge: number) => `${monthlyCharge} zł`,
			},
			{
				accessor: 'paymentDate',
				label: 'Dzień płatności',
				hidden: !contract.paymentDate,
				render: (paymentDate: number) =>
					`${paymentDate}. dnia każdego miesiąca`,
			},
		],
		[],
	)

	return contract && refetch ? (
		<Tile
			isLoading={isLoading}
			grid
			{...rest}
			headerButtons={
				<>
					<MenuButton
						options={[
							{
								label: 'Potwierdź',
								onClick: confirm,
								disabled: contract.status !== 1,
							},
							{
								label: 'Rozwiąż',
								onClick: dissolveDisclosure.onOpen,
								disabled: contract.status !== 2,
							},
							{
								label: 'Usuń',
								onClick: removeDisclosure.onOpen,
								disabled: contract.status !== 1,
							},
						]}
					/>
					<TilePrintButton
						options={[
							{
								fileName: 'Umowa ' + contract.number,
								label: 'Wydruk umowy',
								promiseFunction: () =>
									ContractService.print(contract._id ?? ''),
							},
						]}
					/>
					{contract.status === 1 && <TileEditButton />}
				</>
			}
		>
			<AlertDialog
				isRemoving={isLoading}
				isOpen={removeDisclosure.isOpen}
				onClose={removeDisclosure.onClose}
				header="Usuń umowę"
				onRemove={remove}
			>
				Czy na pewno chcesz usunąć umowę <strong>{contract?.number}</strong> dla
				pacjenta{' '}
				<strong>
					{contract?.patient ? Patient.getFullName(contract.patient) : ''}
				</strong>
				?
			</AlertDialog>
			<AlertDialog
				isRemoving={isLoading}
				isOpen={dissolveDisclosure.isOpen}
				onClose={dissolveDisclosure.onClose}
				header="Rozwiąż umowę"
				onRemove={dissolve}
				confirmButtonLabel="Rozwiąż"
			>
				Czy na pewno chcesz rozwiązać umowę <strong>{contract?.number}</strong>{' '}
				dla pacjenta{' '}
				<strong>
					{contract?.patient ? Patient.getFullName(contract.patient) : ''}
				</strong>
				?
			</AlertDialog>
			<GridItem colSpan={{ base: 2, lg: 1 }}>
				<DataDisplay data={contract} rows={rows} />
			</GridItem>
			<GridItem colSpan={{ base: 2, lg: 1 }}>
				<NavigationButtonContainer>
					<NavigateButton label="Załączniki" to="attachments" />
				</NavigationButtonContainer>
			</GridItem>
			{contract?.status !== 1 && contract?._id && (
				<GridItem colSpan={2}>
					<Heading size="md">Aneksy</Heading>
					{
						<Annexes
							contract={{ ...contract, _id: contract._id }}
							setIsLoading={setIsLoading}
						/>
					}
				</GridItem>
			)}
		</Tile>
	) : null
}
