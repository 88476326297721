import { IUser } from 'models/user'
import { setUser } from 'services'
import http from './axios'

export class AuthenticationService {
	static async login(token: string): Promise<IUser> {
		const user = (await http.post<IUser>('/login', { token })).data
		http.defaults.headers.common['Authorization'] = `Bearer ${user.token}`
		setUser(user)
		return user
	}
}
