import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useDisclosure } from '@chakra-ui/react'
import {
	Column,
	useFilters,
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table'
import {
	formatDate,
	Link,
	SelectFilter,
	Table,
	Tile,
	TileGrid,
	useErrorToast,
} from '@kevea/react-components'
import { FaBoxes, FaClipboardList, FaFileExport, FaInfo } from 'react-icons/fa'
import { Badge } from 'components/StatusBadge'
import { EventLog, TableEventLog } from 'models/eventLog'
import { EventLogService } from 'services/eventLog'
import { EventLogModal } from './EventLogModal'
import { EventLogPrintModal } from './EventLogPrintModal'
import { saveAs } from 'file-saver'

export const EventLogList = () => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const exportDisclosure = useDisclosure()
	const [loading, setLoading] = useState(false)
	const [selectedEventLog, setSelectedEventLog] = useState<
		EventLog | undefined
	>()
	const {
		data: eventLogs,
		isLoading,
		refetch,
	} = useQuery('fetchEventLogs', EventLog.fetchAll)
	const error = useErrorToast()

	const data = useMemo(
		() =>
			eventLogs
				?.sort(
					(a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
				)
				?.map(eventLog => new TableEventLog(eventLog)) ?? [],
		[eventLogs],
	)

	const archiveEventLog = async (eventLog: EventLog) => {
		setLoading(true)
		EventLogService.delete(eventLog._id).then(() => {
			setLoading(false)
			refetch()
		})
	}
	const handlePrintDisclosureOnClose = (dateRange?: [Date, Date]) => {
		if (dateRange) {
			setLoading(true)
			exportDisclosure.onClose()
			const startDate = new Date(dateRange[0])
			const endDate = new Date(dateRange[1])
			EventLogService.export(dateRange[0], dateRange[1])
				.then(blob => {
					if (blob)
						saveAs(
							blob,
							`${formatDate(startDate.toISOString(), 'date')} - ${formatDate(
								endDate.toISOString(),
								'date',
							)}.xlsx`,
						)
					setLoading(false)
				})
				.catch(e => {
					error({ description: 'Wystąpił błąd podczas generowania wydruku' })
					setLoading(false)
				})
		} else {
			exportDisclosure.onClose()
		}
	}

	const columns = useMemo<Column<TableEventLog>[]>(
		() => [
			{
				Header: 'Typ zdarzenia',
				accessor: 'type',
				Cell: ({ row, value }) => (
					<Link
						onClick={() => {
							setSelectedEventLog(row.original.original)
							onOpen()
						}}
					>
						{value}
					</Link>
				),
			},
			{ Header: 'Osoba zgłaszająca', accessor: 'reporterName' },
			{ Header: 'Data', accessor: 'date' },
		],
		[],
	)
	const tableInstance = useTable(
		{
			columns: columns,
			data: data,
		},
		useGlobalFilter,
		useFilters,
		useSortBy,
		usePagination,
		useRowSelect,
	)
	return (
		<TileGrid>
			{exportDisclosure.isOpen && (
				<EventLogPrintModal
					isOpen={exportDisclosure.isOpen}
					onClose={handlePrintDisclosureOnClose}
				/>
			)}
			<EventLogModal
				isOpen={isOpen}
				onClose={() => {
					setSelectedEventLog(undefined)
					onClose()
					refetch()
				}}
				eventLog={selectedEventLog ?? new EventLog()}
			/>
			<Tile
				headerIcon={<FaClipboardList />}
				header="Rejestr zdarzeń nadzwyczajnych"
			>
				<Table
					tableInstance={tableInstance}
					loading={isLoading}
					buttons={[
						{
							label: 'Dodaj',
							type: 'add',
							action: () => {
								setSelectedEventLog(undefined)
								onOpen()
							},
						},
						{
							label: 'Eksport',
							icon: <FaFileExport />,
							action: () => {
								setSelectedEventLog(undefined)
								exportDisclosure.onOpen()
							},
						},
					]}
					menuOptions={{
						noSelection: [],
						singleSelection: [
							{
								label: 'Usuń',
								onClick: rows => {
									const eventLog = rows[0].original
									archiveEventLog(eventLog)
								},
							},
						],
						multipleSelection: [],
					}}
				/>
			</Tile>
		</TileGrid>
	)
}
