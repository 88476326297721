import { Link, ModalDialog, Table } from '@kevea/react-components'
import { Patient, PatientTable } from 'models/patient'
import { useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
	Cell,
	Column,
	useGlobalFilter,
	usePagination,
	useSortBy,
	useTable,
} from 'react-table'

type Props = {
	isOpen: boolean
	onClose: (patient?: Patient) => void
	hidePatientIds?: string[]
}

export const PatientModalList = ({
	isOpen,
	onClose,
	hidePatientIds,
}: Props) => {
	const {
		data: patients,
		isLoading,
		refetch,
	} = useQuery('fetchPatients', () => Patient.fetchPatients(), {
		enabled: isOpen,
	})

	useEffect(() => {
		if (isOpen) refetch()
	}, [isOpen])

	const data = useMemo(
		() =>
			(patients
				?.map(
					patient =>
						patient._id &&
						!hidePatientIds?.includes(patient._id) &&
						new PatientTable(patient),
				)
				.filter(patient => patient) as PatientTable[]) ?? [],
		[patients],
	)

	const columns = useMemo<Column<PatientTable>[]>(
		() => [
			{
				Header: 'Imię i nazwisko',
				accessor: 'name',
				Cell: cell => (
					<Link
						onClick={() => {
							onClose(cell.row.original.original)
						}}
					>
						{cell.value}
					</Link>
				),
			},
			{ Header: 'PESEL', accessor: 'pesel' },
		],
		[],
	)

	const tableInstance = useTable(
		{ columns, data },
		useGlobalFilter,
		useSortBy,
		usePagination,
	)

	return (
		<ModalDialog
			tableModal
			isOpen={isOpen}
			onClose={() => onClose()}
			header="Wybierz pacjenta"
		>
			<Table tableInstance={tableInstance} loading={isLoading} />
		</ModalDialog>
	)
}
