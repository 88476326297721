import {
	OutletContext,
	TileGrid,
	useBreadcrumbs,
	useNotFoundToast,
} from '@kevea/react-components'
import { Patient as PatientClass } from 'models/patient'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { PatientService } from 'services/patient'
import patientRoutes from './routes'
import { StatusBadge } from 'components/StatusBadge'

export type PatientContext = OutletContext & {
	patient: PatientClass | undefined
}

export const Patient = () => {
	const { id } = useParams<{ id: string }>()
	const toast = useNotFoundToast()
	const navigate = useNavigate()

	const fetchPatient = async () => {
		if (id && id !== 'new') {
			try {
				const res = await PatientService.get(id)
				return res.data
			} catch (e) {
				toast()
				navigate('/patients')
				return undefined
			}
		}
	}
	const {
		data: patient,
		isLoading,
		isRefetching,
		refetch,
	} = useQuery('fetchPatient', fetchPatient)

	const breadcrumbs = useBreadcrumbs(patientRoutes)
	const context: PatientContext = {
		breadcrumbs: breadcrumbs,
		patient: new PatientClass(patient),
		refetch,
		titleElement: patient ? <StatusBadge patient={patient} /> : null,
	}

	useEffect(() => {
		refetch()
	}, [id])

	return (
		<TileGrid isLoading={isLoading || isRefetching}>
			<Outlet context={context} />
		</TileGrid>
	)
}
