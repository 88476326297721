import { Box, Select, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react'
import { InputField, Label } from '@kevea/react-components'
import { useField, useFormikContext } from 'formik'
import React, { useMemo } from 'react'

type Props = {
	name: string
	hidden?: boolean
}

const symbolOptions = [
	{ label: '01-U (upośledzenie umysłowe)', value: '01-U' },
	{ label: '02-P (choroby psychiczne)', value: '02-P' },
	{ label: '03-L (zaburzenia głosu, mowy i choroby słuchu)', value: '03-L' },
	{ label: '04-O (choroby narządu wzroku)', value: '04-O' },
	{ label: '05-R (upośledzenie narządu ruchu)', value: '05-R' },
	{ label: '06-E (epilepsja)', value: '06-E' },
	{ label: '07-S (choroby układu oddechowego i krążenia)', value: '07-S' },
	{ label: '08-T (choroby układu pokarmowego)', value: '08-T' },
	{ label: '09-M (choroby układu moczowo-płciowego)', value: '09-M' },
	{ label: '10-N (choroby neurologiczne)', value: '10-N' },
	{
		label: '11-I (inne)',
		value: '11-I',
	},
	{ label: '12-C (całościowe zaburzenia rozwojowe)', value: '12-C' },
]

export const DisabilitySymbolsPicker = ({ name, hidden }: Props) => {
	const [{ value }, {}, { setValue }] = useField(name)

	const options = useMemo(
		() =>
			value && Array.isArray(value)
				? symbolOptions.filter(option => !value?.includes(option.value))
				: symbolOptions,
		[value],
	)

	return (
		<Box hidden={hidden}>
			<Label>Symbole:</Label>
			{value &&
				Array.isArray(value) &&
				value.map(
					symbol =>
						typeof symbol === 'string' && (
							<Symbol name={name} symbol={symbol} key={symbol} />
						),
				)}
			<Select
				marginTop={2}
				placeholder="Wybierz symbol"
				value=""
				onChange={e => {
					setValue([...value, e.target.value])
				}}
			>
				{options.map(({ label, value }) => (
					<option key={value} value={value}>
						{label}
					</option>
				))}
			</Select>
			<InputField
				name={name.split('.')[0] + '.expiryDate'}
				placeholder="Data Ważności Orzeczenia"
				label="Data Ważności Orzeczenia"
				type="date"
			/>
		</Box>
	)
}

export const Symbol = ({ symbol, name }: { symbol: string; name: string }) => {
	const [{ value }, {}, { setValue }] = useField(name)

	const removeSymbol = () => {
		setValue(value.filter((s: string) => s !== symbol))
	}

	return (
		<Tag marginRight={2}>
			<TagLabel>{symbol}</TagLabel>
			<TagCloseButton onClick={removeSymbol} />
		</Tag>
	)
}
