import {
	OutletContext,
	TileGrid,
	useBreadcrumbs,
} from '@kevea/react-components'
import { useQuery } from 'react-query'
import { Outlet, useNavigate } from 'react-router-dom'
import { ServiceService } from 'services/service'
import { getLSServiceId } from 'utils/service'
import { IService } from 'models/service'
import settingsRoutes from './routes'

export type SettingsContext = OutletContext & {
	instance: IService | undefined
}

export const Settings = () => {
	const navigate = useNavigate()

	const fetchInstance = async () => {
		const id = getLSServiceId()
		if (id) {
			return ServiceService.get(id)
				.then(res => res.data)
				.catch(e => {
					navigate('/')
					return undefined
				})
		}
	}
	const {
		data: instance,
		isLoading,
		isRefetching,
		refetch,
	} = useQuery('fetchPatient', fetchInstance)

	const breadcrumbs = useBreadcrumbs(settingsRoutes)
	const context: SettingsContext = {
		breadcrumbs: breadcrumbs,
		instance,
		refetch,
	}

	return (
		<TileGrid isLoading={isLoading || isRefetching}>
			<Outlet context={context} />
		</TileGrid>
	)
}
