import { ModuleRoute } from 'components/ModuleRoute'
import { FaShoppingCart } from 'react-icons/fa'
import { RouteObject } from 'react-router-dom'
import { Order } from './Order'
import { OrderList } from './OrderList'
import { AttachmentsWrapper } from './views/Attachments'
import { General } from './views/General'
import { GeneralEdit } from './views/GeneralEdit'

const ordersRoutes: RouteObject[] = [
	{
		path: 'orders',
		element: (
			<ModuleRoute type={['KITCHEN', 'MEDICINE_WAREHOUSE']}>
				<OrderList />
			</ModuleRoute>
		),
		breadcrumb: 'Lista Zamówień',
	},
	{
		path: 'orders/:id',
		element: (
			<ModuleRoute type={['KITCHEN', 'MEDICINE_WAREHOUSE']}>
				<Order />
			</ModuleRoute>
		),
		children: [
			{
				index: true,
				element: <General />,
				breadcrumb: 'Zamówienie',
				breadcrumbIcon: <FaShoppingCart />,
			},
			{
				path: 'edit',
				element: <GeneralEdit />,
				breadcrumb: 'Edycja zamówienia',
				breadcrumbIcon: <FaShoppingCart />,
			},
			{
				path: 'attachments',
				element: <AttachmentsWrapper />,
				breadcrumb: 'Lista załączników',
				breadcrumbIcon: <FaShoppingCart />,
			},
		],
	},
	{
		path: 'orders/new',
		element: (
			<ModuleRoute type={['KITCHEN', 'MEDICINE_WAREHOUSE']}>
				<Order />
			</ModuleRoute>
		),
		children: [
			{
				index: true,
				element: <GeneralEdit />,
				breadcrumb: 'Nowe zamówienie',
				breadcrumbIcon: <FaShoppingCart />,
			},
		],
	},
]

export default ordersRoutes
