import {
	FormControl,
	FormErrorMessage,
	useDisclosure,
	Flex,
	Box,
	Text,
	Switch,
} from '@chakra-ui/react'
import {
	Dropdown,
	Label,
	ModalDialog,
	NumberField,
} from '@kevea/react-components'
import { Form, Formik, FormikProps } from 'formik'
import { Medicine } from 'models/dictionaries/medicine'
import { Product } from 'models/dictionaries/product'
import { OrderMedicine, OrderPatient } from 'models/order'
import { Patient } from 'models/patient'
import { PatientModalList } from 'pages/patients/PatientModalList'
import { MedicineModalList } from 'pages/settings/views/dictionaries/medicines/MedicineModalList'
import { ProductModal } from 'pages/settings/views/dictionaries/products/ProductModal'
import { ProductModalList } from 'pages/settings/views/dictionaries/products/ProductModalList'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import debounce from 'lodash.debounce'
import { PatientMedicineService } from 'services/patientMedicine'

type OrderPatientModalProps = {
	initialPatient: Patient | undefined
	initialMedicine: Medicine | undefined
	orderMedicine: OrderMedicine | undefined
	alreadySelectedMedicineIds: string[]
	alreadySelectedPatientIds: string[]
	isOpen: boolean
	onClose: ({
		patient,
		medicine,
	}: {
		patient: Patient | undefined
		medicine: OrderMedicine | undefined
	}) => void
}

export const OrderPatientModal: React.FC<OrderPatientModalProps> = ({
	isOpen,
	onClose,
	initialPatient,
	alreadySelectedPatientIds,
	orderMedicine,
	initialMedicine,
}) => {
	const formik = useRef<FormikProps<OrderMedicine>>(null)
	const handleSubmit = (values: OrderMedicine) => {
		if (selectedPatient) {
			onClose({
				patient: selectedPatient,
				// @ts-ignore
				medicine: { ...values, amount: parseInt(values.amount ?? '1') },
			})
		} else {
		}
	}
	const [selectedPatient, setSelectedPatient] = useState(
		new Patient(initialPatient),
	)
	const [selectedMedicine, setSelectedMedicine] = useState(
		new Medicine(initialMedicine),
	)
	const [medicines, setMedicines] = useState<Medicine[]>([])
	const [showOnlyPatientMedicines, setShowOnlyPatientMedicines] = useState(true)
	const [patientMedicines, setPatientMedicines] = useState<Medicine[]>([])

	useEffect(() => {
		changePatient(initialPatient)
	}, [isOpen, initialPatient])

	useEffect(() => {
		if (selectedPatient?._id) {
			PatientMedicineService.getAll(selectedPatient._id)
				.then(res => res.data)
				.then(pm => pm.map(pm => pm.medicine))
				.then(meds => meds.map(m => new Medicine(m)))
				.then(setPatientMedicines)
		} else {
			setPatientMedicines([])
		}
	}, [selectedPatient, initialPatient])

	useEffect(() => {
		if (patientMedicines && showOnlyPatientMedicines) {
			setMedicines(patientMedicines)
		} else {
			setMedicines([])
		}
	}, [patientMedicines, showOnlyPatientMedicines])

	useEffect(() => {
		changeMedicine(new Medicine(initialMedicine))
	}, [isOpen, initialMedicine])

	const handleFilterChange = useCallback(
		debounce((filter: string) => {
			if (filter) {
				if (patientMedicines && showOnlyPatientMedicines) {
					setMedicines(
						patientMedicines.filter(pm =>
							pm.name.toLowerCase().includes(filter.toLowerCase()),
						),
					)
				} else {
					Medicine.fetchMedicines({ filter }).then(medicines => {
						setMedicines(medicines.map(med => new Medicine(med)))
					})
				}
			} else {
				if (patientMedicines && showOnlyPatientMedicines) {
					setMedicines(patientMedicines)
				} else {
					setMedicines([])
				}
			}
		}, 100),
		[showOnlyPatientMedicines, patientMedicines],
	)

	const { data: patientsRaw } = useQuery(
		'fetchPatients',
		() => Patient.fetchPatients(),
		{ enabled: isOpen },
	)

	const patients = useMemo(
		() =>
			patientsRaw
				?.map(
					patient =>
						patient._id &&
						(!alreadySelectedPatientIds.includes(patient._id) ||
							initialPatient?._id === patient._id) &&
						new Patient(patient),
				)
				.filter(patient => patient) ?? [],
		[patientsRaw, alreadySelectedPatientIds],
	)

	const showAllMedicinesDisclosure = useDisclosure()
	const showAllPatientsDisclosure = useDisclosure()

	const changePatient = (patient?: Patient) => {
		formik.current?.setFieldValue(
			'patient',
			patient ? new Patient(patient) : undefined,
		)
		setSelectedPatient(new Patient(patient))
	}
	const changeMedicine = (medicine?: Medicine) => {
		formik.current?.setFieldValue(
			'medicine',
			medicine ? new Medicine(medicine) : undefined,
		)
		setSelectedMedicine(new Medicine(medicine))
	}

	return (
		<ModalDialog
			header={orderMedicine ? 'Edytuj pozycję' : 'Dodaj pozycję'}
			isOpen={isOpen}
			onClose={() => onClose({ medicine: undefined, patient: undefined })}
			onSave={() => formik.current?.submitForm()}
		>
			<PatientModalList
				hidePatientIds={alreadySelectedPatientIds}
				isOpen={showAllPatientsDisclosure.isOpen}
				onClose={patient => {
					if (patient) changePatient(patient)
					showAllPatientsDisclosure.onClose()
				}}
			/>
			<MedicineModalList
				isOpen={showAllMedicinesDisclosure.isOpen}
				onClose={medicine => {
					if (medicine) changeMedicine(medicine)
					showAllMedicinesDisclosure.onClose()
				}}
			/>
			<Formik
				validateOnBlur={false}
				validateOnChange={false}
				innerRef={formik}
				onSubmit={handleSubmit}
				initialValues={
					new OrderMedicine({ ...orderMedicine, patient: initialPatient }) ??
					new OrderMedicine({ patient: initialPatient })
				}
				validationSchema={OrderMedicine.validationSchema}
			>
				{({ errors, values }) => (
					<Form>
						<FormControl isInvalid={Boolean(errors.patient)}>
							<Dropdown
								displayAccessor="fullName"
								items={patients}
								selectedItem={selectedPatient}
								setSelectedItem={changePatient}
								placeholder="Wybierz pacjenta"
								label="Pacjent"
								render={(patient: Patient) => patient.fullName}
								numberOfResults={6}
								onShowAllClick={showAllPatientsDisclosure.onOpen}
								name="patient"
								disabled={Boolean(initialPatient)}
							/>
							{errors.patient && (
								<FormErrorMessage>{errors.patient}</FormErrorMessage>
							)}
						</FormControl>
						<Label>Pokaż tylko leki pacjenta</Label>
						<Switch
							colorScheme="accent"
							isChecked={showOnlyPatientMedicines}
							onChange={e => setShowOnlyPatientMedicines(e.target.checked)}
						/>
						<FormControl isInvalid={Boolean(errors.medicine)}>
							<Dropdown
								disabled={Boolean(initialMedicine)}
								displayAccessor="nameWithDose"
								items={medicines}
								selectedItem={selectedMedicine}
								setSelectedItem={changeMedicine}
								serverSideFilter
								onFilterChange={handleFilterChange}
								placeholder="Wybierz lek"
								label="Lek"
								render={(medicine: Medicine) => (
									<Box>
										<Text fontWeight="semibold" isTruncated>
											{medicine.nameWithDose}
										</Text>
										<Flex justifyContent="space-between">
											<Box>{medicine.commonName}</Box>
											<Box>{medicine.entityResponsible}</Box>
										</Flex>
									</Box>
								)}
								numberOfResults={6}
								onShowAllClick={showAllMedicinesDisclosure.onOpen}
								name="medicine"
							/>
							{errors.medicine && (
								<FormErrorMessage>{errors.medicine}</FormErrorMessage>
							)}
						</FormControl>
						<NumberField
							autoFocus
							name="amount"
							placeholder="Ilość"
							label="Ilość"
							min={1}
						/>
					</Form>
				)}
			</Formik>
		</ModalDialog>
	)
}
