import { Box, Progress } from '@chakra-ui/react'
import {
	Datepicker,
	formatDate,
	InputField,
	InputSpacingWrapper,
	ModalDialog,
	SelectField,
} from '@kevea/react-components'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as yup from 'yup'

type Props = {
	isOpen: boolean
	onClose: (dateRange?: [Date, Date]) => void
}

type FormValues = {}

function getMonday(date: string) {
	const d = new Date(date)
	const day = d.getDay(),
		diff = d.getDate() - day + (day == 0 ? -6 : 1) // adjust when day is sunday
	return new Date(d.setDate(diff))
}

const getWeekDateRange = (date: string): [string, string] => {
	const startDate = getMonday(date)
	const endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000)
	return [
		formatDate(startDate.toISOString(), 'date'),
		formatDate(endDate.toISOString(), 'date'),
	]
}

export const PrintModal = ({ isOpen, onClose }: Props) => {
	const [isLoading, setIsLoading] = useState(false)
	const [selectedDateRange, setSelectedDateRange] = useState<[string, string]>(
		getWeekDateRange(new Date().toISOString()),
	)
	const setWeekDateRange = (date: string) => {
		setSelectedDateRange(getWeekDateRange(date))
	}
	const [loaderValue, setLoaderValue] = useState(0)

	const handlePrint = () => {
		setIsLoading(true)
		onClose([new Date(selectedDateRange[0]), new Date(selectedDateRange[1])])
	}

	useEffect(() => {
		if (isLoading) {
			if (loaderValue <= 95) {
				new Promise(res =>
					setTimeout(
						() =>
							res(
								setLoaderValue(old => {
									let newValue = old + Math.ceil(Math.random() * 10)
									if (newValue > 99) return 99
									return newValue
								}),
							),
						1000,
					),
				)
			}
		}
	}, [isLoading, loaderValue])

	return (
		<ModalDialog
			header="Wydruk raportów"
			isOpen={isOpen}
			onClose={() => onClose()}
			onSave={handlePrint}
			confirmButtonLabel="Drukuj"
		>
			{' '}
			{isLoading ? (
				<>
					Generowanie wydruku...
					<Progress
						value={loaderValue}
						colorScheme="green"
						hasStripe
						isAnimated
					/>
				</>
			) : (
				<>
					<Box pb={4}>
						Wybrany okres od{' '}
						<strong>
							{formatDate(selectedDateRange[0], 'localeDateString')}
						</strong>{' '}
						do{' '}
						<strong>
							{formatDate(selectedDateRange[1], 'localeDateString')}
						</strong>
					</Box>
					<Datepicker
						date={selectedDateRange[0]}
						onDateChange={setWeekDateRange}
					/>
				</>
			)}
		</ModalDialog>
	)
}
