import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useDisclosure } from '@chakra-ui/react'
import {
	Column,
	useFilters,
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table'
import {
	Link,
	SelectFilter,
	Table,
	Tile,
	TileGrid,
} from '@kevea/react-components'
import { FaBoxes, FaInfo } from 'react-icons/fa'
import { Badge } from 'components/StatusBadge'
import { Announcement, TableAnnouncement } from 'models/announcement'
import { AnnouncementModal } from './AnnouncementModal'
import { AnnouncementService } from 'services/announcements'

export const AnnouncementList = () => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [loading, setLoading] = useState(false)
	const [selectedAnnouncement, setSelectedAnnouncement] = useState<
		Announcement | undefined
	>()
	const {
		data: announcements,
		isLoading,
		refetch,
	} = useQuery('fetchAnnouncements', Announcement.fetchAll)

	const data = useMemo(
		() =>
			announcements
				?.sort(
					(a, b) =>
						new Date(b.dateFrom).getTime() - new Date(a.dateFrom).getTime(),
				)
				?.map(announcement => new TableAnnouncement(announcement)) ?? [],
		[announcements],
	)

	const archiveOrDeleteAnnouncement = async (announcement: Announcement) => {
		setLoading(true)
		if (announcement.status === 'active') {
			const newDate = new Date(new Date().setHours(0, 0, 0, 0)).toISOString()
			AnnouncementService.put({ ...announcement, dateTo: newDate }).then(() => {
				setLoading(false)
				refetch()
			})
		} else {
			AnnouncementService.delete(announcement._id).then(() => {
				setLoading(false)
				refetch()
			})
		}
	}

	const columns = useMemo<Column<TableAnnouncement>[]>(
		() => [
			{
				Header: 'Nazwa',
				accessor: 'name',
				Cell: ({ row, value }) => (
					<Link
						onClick={() => {
							setSelectedAnnouncement(row.original.original)
							onOpen()
						}}
					>
						{value}
					</Link>
				),
			},
			{ Header: 'Data od', accessor: 'dateFrom' },
			{ Header: 'Data do', accessor: 'dateTo' },
			{ Header: 'Dodane przez', accessor: 'addedBy' },
			{
				Header: 'Status',
				accessor: 'status',
				filter: (rows, id, filterValue) =>
					rows.filter(
						row => filterValue === '' || row.values['status'] === filterValue,
					),
				Filter: ({ column }) => (
					<SelectFilter
						column={column}
						options={TableAnnouncement.statusOptions}
					/>
				),
				Cell: ({ row }) => <Badge announcement={row.original.original} />,
			},
		],
		[],
	)
	const tableInstance = useTable(
		{
			columns: columns,
			data: data,
			initialState: {
				filters: [
					{ id: 'status', value: Announcement.convertStatus('active') },
				],
			},
		},
		useGlobalFilter,
		useFilters,
		useSortBy,
		usePagination,
		useRowSelect,
	)
	return (
		<TileGrid>
			<AnnouncementModal
				isOpen={isOpen}
				onClose={() => {
					setSelectedAnnouncement(undefined)
					onClose()
					refetch()
				}}
				announcement={selectedAnnouncement ?? new Announcement()}
			/>
			<Tile headerIcon={<FaInfo />} header="Ogłoszenia">
				<Table
					tableInstance={tableInstance}
					loading={isLoading}
					buttons={[
						{
							label: 'Dodaj',
							type: 'add',
							action: () => {
								setSelectedAnnouncement(undefined)
								onOpen()
							},
						},
					]}
					menuOptions={{
						noSelection: [],
						singleSelection: [
							{
								label:
									tableInstance.selectedFlatRows[0]?.original?.status ===
									Announcement.convertStatus('active')
										? 'Archiwizuj'
										: 'Usuń',
								onClick: rows => {
									const announcement = rows[0].original
									archiveOrDeleteAnnouncement(announcement)
								},
							},
						],
						multipleSelection: [],
					}}
				/>
			</Tile>
		</TileGrid>
	)
}
