import { Select, useDisclosure } from '@chakra-ui/react'
import {
	AlertDialog,
	BreadcrumbData,
	DateFilter,
	formatDate,
	Link,
	SelectFilter,
	Table,
	Tile,
	useBreadcrumbs,
} from '@kevea/react-components'
import { Badge, StatusBadge } from 'components/StatusBadge'
import { Bill, TableBill } from 'models/bill'
import { Patient } from 'models/patient'
import { PatientContext } from 'pages/patients/Patient'
import patientRoutes from 'pages/patients/routes'
import React, { useMemo, useState } from 'react'
import { FaFileInvoiceDollar } from 'react-icons/fa'
import { useQuery } from 'react-query'
import { Navigate, useOutletContext, useParams } from 'react-router-dom'
import {
	Column,
	useFilters,
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table'
import { BillService, PatientBillService } from 'services/bill'
import { PatientService } from 'services/patient'
import patientBillRoutes from './patientRoutes'

export const BillList = () => {
	const { id: patientId } = useParams<{ id: string }>()
	const breadcrumbs = useBreadcrumbs([...patientRoutes, ...patientBillRoutes])
	const [isStatusChanging, setIsStatusChanging] = useState(false)

	const [isRemoving, setIsRemoving] = useState(false)

	const [selectedBill, setSelectedBill] = useState<Bill | undefined>(undefined)
	const removeDisclosure = useDisclosure()

	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
	const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth())

	const confirm = (bill: Bill) => {
		if (bill) {
			setIsStatusChanging(true)
			BillService.changeStatus(bill, 'confirmed').then(() => {
				refetchBills?.()
				setIsStatusChanging(false)
			})
		}
	}
	const pay = (bill: Bill) => {
		if (bill) {
			setIsStatusChanging(true)
			BillService.changeStatus(bill, 'paid').then(() => {
				refetchBills?.()
				setIsStatusChanging(false)
			})
		}
	}
	const cancel = (bill: Bill) => {
		if (bill) {
			setIsStatusChanging(true)
			BillService.changeStatus(bill, 'canceled').then(() => {
				refetchBills?.()
				setIsStatusChanging(false)
			})
		}
	}

	const fetchBills = async (month: number, year: number) => {
		if (patientId) {
			return PatientBillService.getAllWithParams(patientId, { year }).then(
				res => res.data,
			)
		} else {
			return BillService.getAllWithParams({ month, year }).then(res => res.data)
		}
	}

	const {
		data: bills,
		isLoading,
		refetch: refetchBills,
	} = useQuery(['fetchBills', selectedMonth, selectedYear], () =>
		fetchBills(selectedMonth, selectedYear),
	)
	const { data: patient, isLoading: isLoadingPatient } = useQuery(
		'fetchPatientForBill',
		() =>
			patientId ? PatientService.get(patientId).then(res => res.data) : null,
	)

	const data = useMemo(
		() => bills?.map(bill => new TableBill(bill)) ?? [],
		[bills],
	)

	const columns = useMemo<Column<TableBill>[]>(
		() => [
			{
				Header: 'Numer',
				accessor: 'number',
				Cell: ({ value, row }) => (
					<Link
						to={
							row.original.original._id
								? row.original.original._id
								: `new?patient=${JSON.stringify(
										row.original.original.patient,
								  )}&billNumberYear=${
										row.original.original.billNumberYear
								  }&billNumberMonth=${
										row.original.original.billNumberMonth
								  }&entries=${JSON.stringify(row.original.original.entries)}`
						}
					>
						{value}
					</Link>
				),
			},
			{ Header: 'Pacjent', accessor: 'patientName' },
			{ Header: 'Miesiąc', accessor: 'month' },
			{
				Header: 'Kwota',
				accessor: 'amount',
			},
			{
				Header: 'Status',
				accessor: 'status',
				Cell: ({ value, row }) => <Badge bill={row.original.original} />,
				Filter: ({ column }) => (
					<SelectFilter column={column} options={TableBill.statusOptions} />
				),
			},
		],
		[],
	)

	const tableInstance = useTable(
		{
			data,
			columns,
			initialState: {
				hiddenColumns: !patientId ? [] : ['patientName'],
				pageSize: 12,
			},
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
	)

	return (
		<Tile
			breadcrumbs={breadcrumbs.length === 0 ? undefined : breadcrumbs}
			header={
				patient
					? `${Patient.getFullName(patient)} - Lista Rachunków`
					: 'Lista Rachunków'
			}
			headerIcon={<FaFileInvoiceDollar />}
			headerButtons={
				<>
					<Select
						value={selectedYear}
						minW="fit-content"
						onChange={e => setSelectedYear(+e.target.value)}
					>
						{Array.from(
							{ length: Math.abs(2023 - new Date().getFullYear()) + 2 },
							(_, i) => i + 2023,
						).map(x => (
							<option value={x} key={x}>
								{x}
							</option>
						))}
					</Select>
					{!patientId && (
						<Select
							value={selectedMonth}
							minW="fit-content"
							onChange={e => setSelectedMonth(+e.target.value)}
						>
							<option value={0}>Styczeń</option>
							<option value={1}>Luty</option>
							<option value={2}>Marzec</option>
							<option value={3}>Kwiecień</option>
							<option value={4}>Maj</option>
							<option value={5}>Czerwiec</option>
							<option value={6}>Lipiec</option>
							<option value={7}>Sierpień</option>
							<option value={8}>Wrzesień</option>
							<option value={9}>Październik</option>
							<option value={10}>Listopad</option>
							<option value={11}>Grudzień</option>
						</Select>
					)}
				</>
			}
		>
			<AlertDialog
				header="Usuń Rachunek"
				onClose={removeDisclosure.onClose}
				isRemoving={isRemoving}
				isOpen={removeDisclosure.isOpen}
				onRemove={() => {
					const afterRemove = () => {
						setIsRemoving(false)
						setSelectedBill(undefined)
						removeDisclosure.onClose()
						refetchBills()
					}
					if (selectedBill?._id) {
						setIsRemoving(true)
						if (patientId) {
							PatientBillService.delete(selectedBill._id, patientId).then(
								res => {
									afterRemove()
								},
							)
						} else {
							BillService.delete(selectedBill._id).then(res => {
								afterRemove()
							})
						}
					}
				}}
			>
				Czy na pewno chcesz usunąć rachunek{' '}
				<strong>{selectedBill?.number}</strong>?
			</AlertDialog>
			<Table
				tableInstance={tableInstance}
				loading={isLoading || isStatusChanging}
				buttons={[
					{
						type: 'add',
						to: 'new',
					},
				]}
				menuOptions={{
					singleSelection: [
						{
							label: 'Potwierdzony',
							onClick: (bills: TableBill[]) => {
								confirm(bills[0]?.original)
							},
							disabled: !['new', 'paid'].includes(
								tableInstance.selectedFlatRows[0]?.original.original?.status,
							),
						},
						{
							label: 'Opłacony',
							onClick: (bills: TableBill[]) => {
								pay(bills[0]?.original)
							},
							disabled: !['confirmed'].includes(
								tableInstance.selectedFlatRows[0]?.original.original?.status,
							),
						},
						{
							label: 'Anulowany',
							onClick: (bills: TableBill[]) => {
								cancel(bills[0]?.original)
							},
							disabled: !['new', 'confirmed'].includes(
								tableInstance.selectedFlatRows[0]?.original.original?.status,
							),
						},
						{
							label: 'Usuń',
							onClick: (bills: TableBill[]) => {
								setSelectedBill(bills[0]?.original)
								removeDisclosure.onOpen()
							},
							disabled: !['new'].includes(
								tableInstance.selectedFlatRows[0]?.original.original?.status,
							),
						},
					],
				}}
			/>
		</Tile>
	)
}
