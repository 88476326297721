import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { KeveaProvider } from '@kevea/react-components'
import { extendTheme } from '@chakra-ui/react'
import { theme as chakraTheme } from '@chakra-ui/react'

const theme = extendTheme({
	colors: {
		accent: { ...chakraTheme.colors.green, 500: '#0F9858' }, // must be an object {100: '#123123', ..., 900: '#000000'}
		active: chakraTheme.colors.green, // must be an object {100: '#123123', ..., 900: '#000000'}
		dead: chakraTheme.colors.red, // must be an object {100: '#123123', ..., 900: '#000000'}
		former: chakraTheme.colors.blue, // must be an object {100: '#123123', ..., 900: '#000000'}
		future: chakraTheme.colors.orange, // must be an object {100: '#123123', ..., 900: '#000000'}
		cyan: chakraTheme.colors.cyan, // must be an object {100: '#123123', ..., 900: '#000000'}
		teal: chakraTheme.colors.teal, // must be an object {100: '#123123', ..., 900: '#000000'}
		gray: chakraTheme.colors.gray, // must be an object {100: '#123123', ..., 900: '#000000'}
	},
})

ReactDOM.render(
	<React.StrictMode>
		<KeveaProvider theme={theme}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</KeveaProvider>
	</React.StrictMode>,
	document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
