import { GridItem, Textarea, useDisclosure } from '@chakra-ui/react'
import {
	formatDate,
	Label,
	Tile,
	TileButton,
	TilePrintButton,
	TileSaveButton,
	useErrorToast,
	useSuccessToast,
} from '@kevea/react-components'
import { HeaderDatePicker } from 'components/HeaderDatePicker'
import {
	NavigateButton,
	NavigationButtonContainer,
} from 'components/NavigateButton'
import { Shift } from 'models/shift'
import React, { useEffect, useState } from 'react'
import { FaTasks } from 'react-icons/fa'
import { useOutletContext } from 'react-router-dom'
import { ShiftService } from 'services/shift'
import { ReportContext } from '../Report'
import { PrintModal } from './PrintModal'

export const ReportDashboard = () => {
	const {
		selectedDate,
		selectedShift,
		setSelectedDate,
		setSelectedShift,
		shiftSettings,
	} = useOutletContext<ReportContext>()
	const [isLoading, setIsLoading] = useState(false)
	const [shift, setShift] = useState<Shift | undefined>()
	const success = useSuccessToast()
	const error = useErrorToast()
	const printDisclosure = useDisclosure()

	const fetchShift = () => {
		setIsLoading(true)
		ShiftService.get(`${selectedDate}T${selectedShift.startTimeString}:00.000Z`)
			.then(res => res.data)
			.then(shift => {
				setShift(new Shift(shift))
				setIsLoading(false)
			})
	}

	useEffect(() => {
		fetchShift()
	}, [selectedShift, selectedDate])

	const handlePrintDisclosureOnClose = (dateRange?: [Date, Date]) => {
		if (dateRange) {
			ShiftService.printMultiple(dateRange[0], dateRange[1])
				.then(blob => {
					printDisclosure.onClose()
					if (blob) window.open(URL.createObjectURL(blob), '_blank')
				})
				.catch(e => {
					printDisclosure.onClose()
					error({ description: 'Wystąpił błąd podczas generowania wydruku' })
				})
		} else {
			printDisclosure.onClose()
		}
	}

	const updateShift = () => {
		if (shift) {
			setIsLoading(true)
			ShiftService.put(shift)
				.then(res => res.data)
				.then(shift => {
					setIsLoading(false)
					success({ description: 'Zapisano raport' })
				})
		}
	}
	return (
		<Tile
			isLoading={isLoading}
			grid
			header="Raport"
			headerIcon={<FaTasks />}
			titleElement={`${formatDate(selectedDate, 'localeDateString')} - zmiana ${
				selectedShift.name
			}`}
			headerButtons={
				<>
					<HeaderDatePicker
						selectedDate={selectedDate}
						setSelectedDate={setSelectedDate}
						selectedShift={selectedShift}
						setSelectedShift={setSelectedShift}
						shiftSettings={shiftSettings}
					/>
					<TileSaveButton onSave={updateShift} />
					<TilePrintButton
						options={[
							{
								fileName: `${new Date(
									selectedDate,
								).toLocaleDateString()} - zmiana ${selectedShift.name}.pdf`,
								label: 'Wydruk zmiany',
								promiseFunction: () =>
									ShiftService.print(
										`${selectedDate}T${selectedShift.startTimeString}:00.000Z`,
									),
							},
							{
								fileName: `${new Date(
									selectedDate,
								).toLocaleDateString()} - zmiana ${selectedShift.name}.pdf`,
								label: 'Wydruk okresowy',
								onClick: () => printDisclosure.onOpen(),
							},
						]}
					/>
				</>
			}
		>
			{printDisclosure.isOpen && (
				<PrintModal
					isOpen={printDisclosure.isOpen}
					onClose={handlePrintDisclosureOnClose}
				/>
			)}
			<GridItem rowSpan={2} colSpan={{ base: 2, md: 1 }}>
				<Label>Notatki dla personelu</Label>
				<Textarea
					height="200px"
					minW="300px"
					value={shift?.noteForEmployees}
					onChange={e =>
						setShift({ ...shift, noteForEmployees: e.target.value })
					}
				/>
				<Label>Notatki ze zmiany</Label>
				<Textarea
					height="200px"
					minW="300px"
					value={shift?.description}
					onChange={e => setShift({ ...shift, description: e.target.value })}
				/>
			</GridItem>
			<GridItem colSpan={{ base: 2, md: 1 }}>
				<NavigationButtonContainer>
					<NavigateButton label="Zadania" to="tasks" />
					<NavigateButton label="Notatki" to="notes" />
				</NavigationButtonContainer>
			</GridItem>
			<GridItem colSpan={{ base: 2, md: 1 }}></GridItem>
		</Tile>
	)
}
