import { ModuleRoute } from 'components/ModuleRoute'
import { RestrictRoute } from 'components/RestrictRoute'
import { FaFileInvoiceDollar, FaFileSignature } from 'react-icons/fa'
import { RouteObject } from 'react-router-dom'
import { Bill } from './Bill'
import { BillList } from './BillList'
import { AttachmentsWrapper } from './views/Attachments'
import { General } from './views/General'
import { GeneralEdit } from './views/GeneralEdit'

const billRoutes: RouteObject[] = [
	{
		path: 'bills',
		element: (
			<ModuleRoute type="CONTRACTS">
				<RestrictRoute module="bills" power="view">
					<BillList />
				</RestrictRoute>
			</ModuleRoute>
		),
		breadcrumb: 'Rozrachunki',
	},
	{
		path: 'bills/:billId',
		element: (
			<ModuleRoute type="CONTRACTS">
				<RestrictRoute module="bills" power="view">
					<Bill />
				</RestrictRoute>
			</ModuleRoute>
		),
		children: [
			{
				index: true,
				element: <General />,
				breadcrumb: 'Rachunek',
				breadcrumbIcon: <FaFileInvoiceDollar />,
			},
			{
				path: 'edit',
				element: <GeneralEdit />,
				breadcrumb: 'Edycja rachunku',
				breadcrumbIcon: <FaFileInvoiceDollar />,
			},
			{
				path: 'attachments',
				element: <AttachmentsWrapper />,
				breadcrumb: 'Lista załączników',
				breadcrumbIcon: <FaFileInvoiceDollar />,
			},
		],
	},
	{
		path: 'bills/new',
		element: (
			<ModuleRoute type="CONTRACTS">
				<Bill />
			</ModuleRoute>
		),
		children: [
			{
				index: true,
				element: <GeneralEdit />,
				breadcrumb: 'Nowy rachunek',
				breadcrumbIcon: <FaFileInvoiceDollar />,
			},
		],
	},
]

export default billRoutes
