import { FaTasks } from 'react-icons/fa'
import { RouteObject } from 'react-router-dom'
import { Report } from './Report'
import { ReportTasks } from './views/Tasks'
import { ReportDashboard } from './views/ReportDashboard'
import { ReportNotes } from './views/Notes'

const reportRoutes: RouteObject[] = [
	{
		path: 'reports',
		element: <Report />,
		children: [
			{
				index: true,
				element: <ReportDashboard />,
				breadcrumb: 'Raport',
				breadcrumbIcon: <FaTasks />,
			},
			{
				path: 'tasks',
				element: <ReportTasks />,
				breadcrumb: 'Zadania',
				breadcrumbIcon: <FaTasks />,
			},
			{
				path: 'notes',
				element: <ReportNotes />,
				breadcrumb: 'Notatki',
				breadcrumbIcon: <FaTasks />,
			},
		],
	},
]

export default reportRoutes
