import { Patient } from 'models/patient'
import { Attachments } from 'pages/Attachments'
import { useOutletContext } from 'react-router-dom'
import { BillContext } from '../Bill'

export const AttachmentsWrapper = () => {
	const { bill, breadcrumbs, refetch } = useOutletContext<BillContext>()

	return (
		<Attachments
			attachmentType="bills"
			idParamName="billId"
			breadcrumbs={breadcrumbs}
			onAction={refetch}
			header={`${bill ? bill.number : ''} - Lista załączników`}
		/>
	)
}
