import { IService } from 'models/service'
import React, { SetStateAction, useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { useRoutes } from 'react-router-dom'
import routes from 'routes'
import { getUser } from 'services'
import http from 'services/axios'
import { ServiceService } from 'services/service'
import { getLSService, setLSService, setLSServiceId } from 'utils/service'

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnMount: 'always',
			cacheTime: 0,
			refetchOnWindowFocus: false,
		},
	},
})

export const SelectedServiceContext = React.createContext<{
	selectedService: IService | undefined
	setSelectedService: (service?: IService) => void
}>(null)

function App() {
	http.defaults.headers.common['Authorization'] = `Bearer ${getUser()?.token}`

	return (
		<QueryClientProvider client={queryClient}>
			<ServiceContext />
		</QueryClientProvider>
	)
}

const ServiceContext = () => {
	const [selectedService, setSelectedService] = useState<IService | undefined>(
		getLSService(),
	)

	const {} = useQuery(
		['fetchService', selectedService?._id],
		() => selectedService?._id && ServiceService.get(selectedService._id),
		{
			enabled: Boolean(selectedService),
			refetchInterval: 60 * 1000,
			onSuccess: s => {
				setSelectedService(s.data)
				setLSService(s.data)
				setLSServiceId(s.data?._id)
			},
		},
	)

	return (
		<SelectedServiceContext.Provider
			value={{
				selectedService,
				setSelectedService: s => {
					setSelectedService(s)
					setLSService(s)
					setLSServiceId(s?._id)
				},
			}}
		>
			<Routing service={selectedService} />
		</SelectedServiceContext.Provider>
	)
}

type RoutingProps = {
	service: IService | undefined
}

const Routing = ({ service }: RoutingProps) => {
	const routing = useRoutes(routes)

	return routing
}

export default App
