import { Text, useDisclosure } from '@chakra-ui/react'
import {
	AlertDialog,
	Link,
	SelectFilter,
	Table,
	Tile,
} from '@kevea/react-components'
import { Medicine } from 'models/dictionaries/medicine'
import { Patient, PatientMedicine, TablePatientMedicine } from 'models/patient'
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Navigate, useOutletContext } from 'react-router-dom'
import {
	Cell,
	Column,
	useFilters,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table'
import { PatientContext } from '../Patient'
import { PatientMedicineService } from 'services/patientMedicine'
import { PatientMedicineModal } from './MedicineModal'
import { Badge } from 'components/StatusBadge'

export const PatientMedicines = () => {
	const { patient, refetch, titleElement, ...rest } =
		useOutletContext<PatientContext>()
	const [isRemoving, setIsRemoving] = useState(false)

	const [selectedPatientMedicine, setSelectedPatientMedicine] = useState<
		PatientMedicine | undefined
	>(undefined)
	const addDisclosure = useDisclosure()
	const removeDisclosure = useDisclosure()

	const {
		data: medicines,
		isLoading,
		refetch: refetchPatientMedicines,
	} = useQuery('fetchPatientMedicines', () =>
		patient ? PatientMedicine.fetchPatientMedicines(patient) : null,
	)

	const data = useMemo(
		() =>
			medicines?.map(
				patientMedicine => new TablePatientMedicine(patientMedicine),
			) ?? [],
		[medicines],
	)

	const columns = useMemo<Column<TablePatientMedicine>[]>(
		() => [
			{
				Header: 'Lek',
				accessor: 'name',
				Cell: ({ value, row }) => (
					<Link
						onClick={() => {
							setSelectedPatientMedicine(row.original.original)
							addDisclosure.onOpen()
						}}
					>
						{value}
					</Link>
				),
			},
			{
				Header: 'Dawkowanie',
				accessor: 'dose',
				Cell: ({ value }: { value: string }) => (
					<Text>
						{value.split('\n').map((value, idx) => (
							<Text key={idx}>{value}</Text>
						))}
					</Text>
				),
			},
			{
				Header: 'Opis',
				accessor: 'description',
			},
			{
				Header: 'Data rozpoczęcia',
				accessor: 'startDate',
			},
			{
				Header: 'Data zakończenia',
				accessor: 'endDate',
			},
			{
				Header: 'Status',
				accessor: 'status',
				Cell: ({ row, value }) => {
					return <Badge patientMedicine={row.original.original} />
				},
				filter: (rows, id, filterValue) =>
					rows.filter(
						row => filterValue === '' || row.values['status'] === filterValue,
					),
				Filter: ({ column }) => (
					<SelectFilter column={column} options={Medicine.statusOptions} />
				),
			},
		],
		[],
	)

	const tableInstance = useTable(
		{
			data,
			columns,
			initialState: { filters: [{ id: 'status', value: 'Aktywne' }] },
		},
		useFilters,
		useSortBy,
		usePagination,
		useRowSelect,
	)

	return patient ? (
		<Tile {...rest} header={`${Patient.getFullName(patient)} - Lista leków`}>
			{patient && (
				<PatientMedicineModal
					isOpen={addDisclosure.isOpen}
					selectedPatientMedicine={selectedPatientMedicine}
					onClose={() => {
						setSelectedPatientMedicine(undefined)
						addDisclosure.onClose()
						refetchPatientMedicines()
					}}
					patient={patient}
				/>
			)}
			<AlertDialog
				header="Usuń lek"
				onClose={removeDisclosure.onClose}
				isRemoving={isRemoving}
				isOpen={removeDisclosure.isOpen}
				onRemove={() => {
					if (selectedPatientMedicine?._id) {
						setIsRemoving(true)
						PatientMedicineService.delete(
							selectedPatientMedicine._id,
							patient._id,
						).then(res => {
							setIsRemoving(false)
							setSelectedPatientMedicine(undefined)
							removeDisclosure.onClose()
							refetchPatientMedicines()
						})
					}
				}}
			>
				Czy chcesz usunąć lek{' '}
				<strong>
					{selectedPatientMedicine?.medicine
						? Medicine.getNameWithDose(selectedPatientMedicine.medicine)
						: ''}
				</strong>
				?
			</AlertDialog>
			<Table
				tableInstance={tableInstance}
				loading={isLoading}
				buttons={[
					{
						type: 'add',
						action: () => {
							addDisclosure.onOpen()
						},
					},
				]}
				menuOptions={{
					singleSelection: [
						{
							label: 'Usuń',
							onClick: (patientMedicines: TablePatientMedicine[]) => {
								setSelectedPatientMedicine(patientMedicines[0]?.original)
								removeDisclosure.onOpen()
							},
						},
					],
				}}
			/>
		</Tile>
	) : (
		<Navigate to="/patients/new" />
	)
}
