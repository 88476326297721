import { TaskPlan } from 'models/taskPlan'
import { Service } from 'services'

const path = '/taskplans'
const objectPath = '/patients'

export class TaskPlanService extends Service<TaskPlan>({
	objectPath,
	path,
	objectService: true,
}) {}
