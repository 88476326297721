import { getBaseInstancePath, printConfig, Service } from 'services'
import { ContractTemplate } from 'models/contractTemplate'
import http from './axios'

const path = '/contractTemplates'
const objectPath = '/dictionaries'

export class ContractTemplateService extends Service<ContractTemplate>({
	path,
	objectPath,
	objectService: true,
}) {}
