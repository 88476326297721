import { Text, useDisclosure } from '@chakra-ui/react'
import {
	Dropdown,
	formatDate,
	InputField,
	ModalDialog,
	SelectField,
	TextAreaField,
	useErrorToast,
} from '@kevea/react-components'
import { Form, Formik, FormikProps } from 'formik'
import { NoteDictionary } from 'models/dictionaries/note'
import { Note } from 'models/note'
import { Patient } from 'models/patient'
import { PatientModalList } from 'pages/patients/PatientModalList'
import { NoteTypeModal } from 'pages/settings/views/dictionaries/notes/NoteTypeModal'
import { NoteTypeModalList } from 'pages/settings/views/dictionaries/notes/NoteTypeModalList'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { getUser } from 'services'
import { NoteService } from 'services/note'

type Props = {
	patient?: Patient
	selectedNote?: Note
	isOpen: boolean
	onClose: () => void
	date: string
}

export const NoteModal = (props: Props) => {
	const formik = useRef<FormikProps<Note>>(null)
	const [loading, setLoading] = useState(false)
	const [type, setType] = useState<NoteDictionary | null>(null)
	const [selectedPatient, setSelectedPatient] = useState(
		props.selectedNote?.patient,
	)
	const addDisclosure = useDisclosure()
	const showAllTypesDisclosure = useDisclosure()
	const showAllPatientsDisclosure = useDisclosure()
	const error = useErrorToast()

	const { data: noteTypes, refetch: refetchNoteTypes } = useQuery(
		'fetchNoteTypes',
		NoteDictionary.fetchNoteTypes,
	)
	const { data: patientsRaw, refetch: refetchPatients } = useQuery(
		'fetchPatients',
		() => Patient.fetchPatients(),
		{ enabled: !props.patient },
	)

	const patients = useMemo<Patient[]>(
		() => patientsRaw?.map(patient => new Patient(patient)) ?? [],
		[patientsRaw],
	)

	useEffect(() => {
		if (props.isOpen) {
			setType(props.selectedNote?.noteType ?? null)
			setSelectedPatient(props.patient ?? props.selectedNote?.patient)
			refetchNoteTypes()
			refetchPatients()
		}
	}, [props.isOpen])

	useEffect(() => {
		formik.current?.setFieldValue('noteTypeId', type?._id)
		formik.current?.setFieldValue('patientId', selectedPatient?._id)
		formik.current?.setFieldValue('patient', selectedPatient)
		refetchNoteTypes()
		refetchPatients()
	}, [type])

	const handleSubmit = (values: Note) => {
		if (selectedPatient) {
			if (formik.current) {
				const date = new Date(values.date).toISOString()
				let newNote = new Note({ ...values, noteType: type, date })
				setLoading(true)
				if (props.selectedNote?._id) {
					NoteService.put(newNote, selectedPatient._id).then(() => {
						setLoading(false)
						props.onClose()
					})
				} else {
					const user = getUser()
					let addedBy = ''
					if (user) {
						addedBy = user.firstName + ' ' + user.lastName
					}
					NoteService.post({...newNote, addedBy}, selectedPatient._id).then(() => {
						setLoading(false)
						props.onClose()
					})
				}
			}
		} else {
			error({ description: 'Wybierz pacjenta' })
		}
	}

	return (
		<>
			{props.isOpen && (
				<ModalDialog
					isLoading={loading}
					header={props.selectedNote ? 'Edytuj notatkę' : 'Dodaj notatkę'}
					onClose={props.onClose}
					onSave={() => formik.current?.submitForm()}
					isOpen={props.isOpen}
				>
					{!props.patient && !props.selectedNote?.patient && (
						<>
							<PatientModalList
								isOpen={showAllPatientsDisclosure.isOpen}
								onClose={patient => {
									if (patient) setSelectedPatient(new Patient(patient))
									showAllPatientsDisclosure.onClose()
								}}
							/>
							<Dropdown
								displayAccessor="fullName"
								items={patients}
								selectedItem={selectedPatient}
								setSelectedItem={setSelectedPatient}
								placeholder="Wybierz pacjenta"
								label="Pacjent"
								render={(patient: Patient) => patient.fullName}
								numberOfResults={6}
								onShowAllClick={showAllPatientsDisclosure.onOpen}
							/>
						</>
					)}
					<NoteTypeModal
						isOpen={addDisclosure.isOpen}
						onClose={note => {
							if (note) setType(note)
							addDisclosure.onClose()
						}}
						selectedNote={undefined}
					/>
					<NoteTypeModalList
						isOpen={showAllTypesDisclosure.isOpen}
						onClose={note => {
							if (note) setType(note)
							showAllTypesDisclosure.onClose()
						}}
					/>
					<Formik
						validateOnBlur={false}
						validateOnChange={false}
						validationSchema={Note.validationSchema}
						innerRef={formik}
						onSubmit={handleSubmit}
						initialValues={{
							...new Note({
								...props.selectedNote,
								patient: props.patient,
								date: formatDate(
									props.selectedNote?.date ??
										`${props.date}T${formatDate(
											new Date().toISOString(),
											'time',
										)}`,
									'datetime-local',
								),
							}),
						}}
					>
						<Form>
							{noteTypes && (
								<Dropdown
									placeholder="Inna"
									label="Rodzaj notatki"
									displayAccessor="name"
									numberOfResults={5}
									items={noteTypes}
									render={(noteType: NoteDictionary) => (
										<>
											<Text>{noteType.name}</Text>
											<Text fontSize="sm" color="gray.500">
												{noteType.description}
											</Text>
										</>
									)}
									selectedItem={type}
									setSelectedItem={setType}
									onAddNewClick={addDisclosure.onOpen}
									onShowAllClick={showAllTypesDisclosure.onOpen}
								/>
							)}
							{!type && (
								<InputField name="name" placeholder="Nazwa" label="Nazwa" />
							)}
							<InputField
								name="date"
								placeholder="Data i godzina"
								label="Data i godzina"
								type="datetime-local"
								autoFocus
							/>
							<TextAreaField
								name="description"
								placeholder="Opis"
								label="Opis"
							/>
						</Form>
					</Formik>
				</ModalDialog>
			)}
		</>
	)
}
