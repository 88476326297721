import { InputField, ModalDialog, TextAreaField } from '@kevea/react-components'
import { Form, Formik, FormikProps } from 'formik'
import { NoteDictionary } from 'models/dictionaries/note'
import { useRef, useState } from 'react'
import { NoteDictionaryService } from 'services/dictionaries/note'

type Props = {
	selectedNote: NoteDictionary | undefined
	isOpen: boolean
	onClose: (note?: NoteDictionary) => void
}

export const NoteTypeModal = (props: Props) => {
	const formik = useRef<FormikProps<NoteDictionary>>(null)
	const [loading, setLoading] = useState(false)

	const handleSubmit = (values: NoteDictionary) => {
		if (formik.current) {
			const newNoteDictionary = { ...props.selectedNote, ...values }
			setLoading(true)
			if (props.selectedNote?._id) {
				NoteDictionaryService.put(newNoteDictionary)
					.then(res => res.data)
					.then(note => {
						setLoading(false)
						props.onClose(note)
					})
			} else {
				NoteDictionaryService.post(newNoteDictionary)
					.then(res => res.data)
					.then(note => {
						setLoading(false)
						props.onClose(note)
					})
			}
		}
	}

	return (
		<>
			{props.isOpen && (
				<ModalDialog
					isLoading={loading}
					header={
						props.selectedNote ? 'Edytuj typ notatki' : 'Dodaj typ notatki'
					}
					onClose={() => props.onClose()}
					onSave={() => formik.current?.submitForm()}
					isOpen={props.isOpen}
				>
					<Formik
						validateOnBlur={false}
						validateOnChange={false}
						validationSchema={NoteDictionary.validationSchema}
						innerRef={formik}
						onSubmit={handleSubmit}
						initialValues={
							props.selectedNote
								? new NoteDictionary(props.selectedNote)
								: new NoteDictionary()
						}
					>
						<Form>
							<InputField
								name="name"
								placeholder="Nazwa"
								label="Nazwa"
								isRequired
							/>
							<TextAreaField
								name="description"
								placeholder="Opis"
								label="Opis"
							/>
						</Form>
					</Formik>
				</ModalDialog>
			)}
		</>
	)
}
