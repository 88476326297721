import { Measurement } from 'models/measurement'
import { SavedMenus } from 'models/savedMenus'
import { getBaseInstancePath, Service } from 'services'
import http from './axios'

const path = '/savedMenus'

export class SavedMenusService extends Service<SavedMenus>({
	path,
}) {}
