import { RHMModule } from 'models/service'
import React, { ReactElement, ReactNode } from 'react'
import { Navigate } from 'react-router'
import { isModuleEnabled } from 'routes'

type Props = {
	type: RHMModule | RHMModule[]
	children: ReactElement
}

export const ModuleRoute = ({ type, children }: Props) => {
	if (Array.isArray(type)) {
		if (type.some(t => isModuleEnabled(t))) {
			return children
		}
	} else if (isModuleEnabled(type)) {
		return children
	}
	return <Navigate to="/" />
}
