import { ModuleRoute } from 'components/ModuleRoute'
import { FaBoxes, FaSlidersH, FaTabletAlt, FaUsers } from 'react-icons/fa'
import { RouteObject } from 'react-router-dom'
import { General } from './views/General'
import { GeneralEdit } from './views/GeneralEdit'
import { WarehouseDocument } from './WarehouseDocument'
import { WarehouseDocumentList } from './WarehouseDocumentList'

const warehouseDocumentRoutes: RouteObject[] = [
	{
		path: 'warehouseDocuments',
		element: (
			<ModuleRoute type="MEDICINE_WAREHOUSE">
				<WarehouseDocumentList />
			</ModuleRoute>
		),
		breadcrumb: 'Lista Zamówień',
	},
	{
		path: 'warehouseDocuments/:id',
		element: (
			<ModuleRoute type="MEDICINE_WAREHOUSE">
				<WarehouseDocument />
			</ModuleRoute>
		),
		children: [
			{
				index: true,
				element: <General />,
				breadcrumb: 'Dokument magazynowy',
				breadcrumbIcon: <FaBoxes />,
			},
			{
				path: 'edit',
				element: <GeneralEdit />,
				breadcrumb: 'Edycja dokumentu magazynowego',
				breadcrumbIcon: <FaBoxes />,
			},
		],
	},
	{
		path: 'warehouseDocuments/new',
		element: (
			<ModuleRoute type="MEDICINE_WAREHOUSE">
				<WarehouseDocument />
			</ModuleRoute>
		),
		children: [
			{
				index: true,
				element: <GeneralEdit />,
				breadcrumb: 'Nowy dokument magazynowy',
				breadcrumbIcon: <FaBoxes />,
			},
		],
	},
]

export default warehouseDocumentRoutes
