import { AxiosResponse } from 'axios'
import { Contract } from 'models/contract'
import { getBaseInstancePath, printConfig, Service } from 'services'
import http from './axios'

const path = '/contracts'

export class ContractService extends Service<Contract>({
	path,
}) {
	static async changeStatus(
		contract: Contract,
		status: number,
	): Promise<AxiosResponse<Contract>> {
		const order: Contract = { ...contract }
		order.status = status
		return http.put(getBaseInstancePath(path) + `/${contract._id}`, order)
	}

	static async print(id: string): Promise<Blob> {
		return http
			.get<Blob>(`${getBaseInstancePath(path)}/${id}/print`, printConfig)
			.then(res => res.data)
	}
}
