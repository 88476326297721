import { useDisclosure } from '@chakra-ui/react'
import { AlertDialog, Link, Table, Tile } from '@kevea/react-components'
import { TaskType, TableTaskType } from 'models/dictionaries/taskType'
import { SettingsContext } from 'pages/settings/Settings'
import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useOutletContext } from 'react-router-dom'
import {
	Cell,
	Column,
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table'
import { TaskTypeService } from 'services/dictionaries/task'
import { TaskTypeModal } from './TaskTypeModal'

export const TaskTypeList = () => {
	const { instance, refetch, ...rest } = useOutletContext<SettingsContext>()
	const removeDisclosure = useDisclosure()
	const [isRemoving, setIsRemoving] = useState(false)
	const addDisclosure = useDisclosure()
	const [selectedTaskType, setSelectedTaskType] = useState<
		TaskType | undefined
	>(undefined)

	const {
		data: taskType,
		isLoading,
		refetch: refetchTaskTypes,
	} = useQuery('fetchTaskTypes', TaskType.fetchTaskTypes)

	const data = useMemo(
		() => taskType?.map(taskType => new TableTaskType(taskType)) || [],
		[taskType],
	)

	const columns = useMemo<Column<TableTaskType>[]>(
		() => [
			{
				Header: 'Nazwa',
				accessor: 'name',
				Cell: cell => (
					<Link
						onClick={() => {
							setSelectedTaskType(cell.row.original.original)
							addDisclosure.onOpen()
						}}
					>
						{cell.value}
					</Link>
				),
			},
			{
				Header: 'Wymaga pielęgniarki',
				accessor: 'requiresNurse',
			},
			{
				Header: 'Opis',
				accessor: 'description',
			},
		],
		[],
	)

	const tableInstance = useTable(
		{ columns, data },
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
	)

	return (
		<Tile {...rest}>
			<AlertDialog
				header="Usuń rodzaj zadania"
				onClose={removeDisclosure.onClose}
				isRemoving={isRemoving}
				isOpen={removeDisclosure.isOpen}
				onRemove={() => {
					if (selectedTaskType?._id) {
						setIsRemoving(true)
						TaskTypeService.delete(selectedTaskType._id).then(() => {
							setIsRemoving(false)
							setSelectedTaskType(undefined)
							removeDisclosure.onClose()
							refetchTaskTypes()
						})
					}
				}}
			>
				Czy chcesz usunąć rodzaj zadania{' '}
				<strong>{selectedTaskType?.name}</strong>?
			</AlertDialog>
			<TaskTypeModal
				selectedTaskType={selectedTaskType}
				isOpen={addDisclosure.isOpen}
				onClose={taskType => {
					addDisclosure.onClose()
					refetchTaskTypes()
				}}
			/>
			<Table
				tableInstance={tableInstance}
				loading={isLoading}
				buttons={[
					{
						label: 'Dodaj',
						type: 'add',
						action: () => {
							setSelectedTaskType(undefined)
							addDisclosure.onOpen()
						},
					},
				]}
				menuOptions={{
					singleSelection: [
						{
							label: 'Usuń',
							onClick: taskTypes => {
								setSelectedTaskType(taskTypes[0])
								removeDisclosure.onOpen()
							},
						},
					],
				}}
			/>
		</Tile>
	)
}
