import { GridItem, Heading, Text, useDisclosure } from '@chakra-ui/react'
import {
	AddressCell,
	DataDisplay,
	DataDisplayRow,
	ModalDialog,
	Table,
	Tile,
	TileButton,
	TileEditButton,
	TilePrintButton,
} from '@kevea/react-components'
import {
	NavigateButton,
	NavigationButtonContainer,
} from 'components/NavigateButton'
import {
	Patient,
	IRelatedPerson,
	RelatedPerson,
	TPatient,
	Disablity,
} from 'models/patient'
import { useMemo, useState } from 'react'
import { FaTag } from 'react-icons/fa'
import { useOutletContext } from 'react-router-dom'
import { Column, useTable } from 'react-table'
import { isModuleEnabled } from 'routes'
import { PatientService } from 'services/patient'
import { restrict } from 'utils/user'
import { PatientContext } from '../Patient'
import { PrintTagModal } from './PrintTagModal'

export const General = () => {
	const { patient, refetch, ...rest } = useOutletContext<PatientContext>()

	const { isOpen, onOpen, onClose } = useDisclosure()

	const rows = useMemo<DataDisplayRow<TPatient>[]>(
		() => [
			{ accessor: 'room', label: 'Numer pokoju' },
			{ accessor: 'sex', label: 'Płeć', render: Patient.convertSex },
			{
				accessor: 'dateOfBirth',
				label: 'Data urodzenia',
				type: 'date',
			},
			{
				accessor: 'admissionDate',
				label: 'Data przyjęcia',
				type: 'date',
			},
			{
				accessor: 'dateOfDischarge',
				label: 'Data wypisu',
				type: 'date',
				hidden: patient?.status !== 2,
			},
			{
				accessor: 'dateOfDeath',
				label: 'Data śmierci',
				type: 'date',
				hidden: (_: string, patient: Patient) => patient.status !== 3,
			},
			{ accessor: 'birthPlace', label: 'Miejsce urodzenia' },
			{ accessor: 'pesel', label: 'PESEL' },
			{
				accessor: 'idCardNumber',
				label: 'Nr dowodu osobistego',
				textProps: { casing: 'uppercase' },
			},
			{
				accessor: 'idIssuer',
				label: 'Organ wydający dowód osobisty',
				textProps: { casing: 'uppercase' },
			},
			{
				accessor: 'idExpiryDate',
				label: 'Data ważności dowodu osobistego',
				type: 'date',
			},
			{
				accessor: 'zusNumber',
				label: 'Nr ZUS',
				textProps: { casing: 'uppercase' },
			},
			{ accessor: 'nip', label: 'NIP' },
			{
				accessor: 'previousAddress',
				label: 'Poprzedni adres',
				type: 'address',
			},
			{
				accessor: 'placeOfResidence',
				label: 'Adres zameldowania',
				type: 'address',
			},
			{
				accessor: 'incapacitated',
				label: 'Ubezwłasnowolniony',
				type: 'yes/no',
			},
			{
				accessor: 'dateOfIncapacitation',
				label: 'Data ubezwłasnowolnienia',
				type: 'date',
				hidden: (_: boolean, patient: Patient) => !patient.incapacitated,
			},
			{
				accessor: 'disability',
				label: 'Stopień niepełnosprawności',
				render: (disability: Disablity) =>
					Patient.convertDisablityLevel(disability.level),
			},
			{
				accessor: 'disability',
				label: 'Symbole niepełnosprawności',
				render: (disability: Disablity) =>
					disability.symbols && disability.symbols.length > 0
						? disability.symbols.join(', ')
						: '-',
				hidden: (disablity: Disablity) => !disablity.level,
			},
			{
				accessor: (patient: Patient) => {
					return patient.disability?.expiryDate
				},
				label: 'Data ważności orzeczenia',
				type: 'date',
				hidden: (_: Disablity, patient: Patient) => !patient.disability.level,
			},
			{
				accessor: 'incapableOfIndependentExistence',
				label: 'Niezdolność do samodzielnej egzystencji',
				type: 'yes/no',
			},
			{
				accessor: 'requireDiapersOrder',
				label: 'Wymaga zlecenia na pieluchomajtki',
				type: 'yes/no',
			},
			{
				accessor: 'phoneNumber',
				label: 'Numer telefonu',
			},
		],
		[],
	)

	const columns = useMemo<Column<IRelatedPerson>[]>(
		() => [
			{
				Header: 'Imię i Nazwisko',
				accessor: 'name',
			},
			{
				Header: 'Numer Telefonu',
				accessor: 'phoneNumber',
			},
			{
				Header: 'E-Mail',
				accessor: 'emailAddress',
			},
			{
				Header: 'Adres',
				accessor: 'address',
				Cell: AddressCell,
			},
			{
				Header: 'Relacja',
				accessor: 'relation',
				Cell: cell => <Text>{RelatedPerson.convertRelation(cell.value)}</Text>,
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	)

	const relatedPersons = useMemo<IRelatedPerson[]>(
		() => patient?.relatedPersons || [],
		[patient],
	)

	const tableInstance = useTable({ data: relatedPersons, columns: columns })

	return patient ? (
		<Tile
			grid
			{...rest}
			headerButtons={
				<>
					<TilePrintButton
						options={[
							{
								fileName: 'Karta Informacyjna ' + Patient.getFullName(patient),
								label: 'Karta Informacyjna',
								promiseFunction: () =>
									PatientService.infoCard(patient._id || ''),
							},
							{
								fileName: 'Arkusz Ewidencyjny ' + Patient.getFullName(patient),
								label: 'Arkusz Ewidencyjny',
								promiseFunction: () =>
									PatientService.recordSheet(patient._id || ''),
							},
							{
								fileName: 'Przyjmowane leki ' + Patient.getFullName(patient),
								label: 'Przyjmowane leki',
								promiseFunction: () =>
									PatientService.printMedicines(patient._id || ''),
							},
						]}
					/>
					<TileButton rightIcon={<FaTag />} onClick={onOpen}>
						Etykieta
					</TileButton>
					<TileEditButton />
				</>
			}
		>
			<PrintTagModal
				id={patient._id}
				isOpen={isOpen}
				onClose={onClose}
				onSave={() => {
					onClose()
				}}
			/>
			<GridItem colSpan={{ base: 2, lg: 1 }}>
				<DataDisplay data={patient} rows={rows} />
			</GridItem>
			<GridItem colSpan={{ base: 2, lg: 1 }}>
				<NavigationButtonContainer>
					{restrict('patient', 'measurements') && (
						<NavigateButton label="Pomiary" to="measurements" />
					)}
					{restrict('patient', 'tasks') && (
						<NavigateButton label="Zadania" to="tasks" />
					)}
					{restrict('patient', 'taskPlans') && (
						<NavigateButton label="Harmonogram Zadań" to="taskplans" />
					)}
					{restrict('patient', 'notes') && (
						<NavigateButton label="Notatki" to="notes" />
					)}
					{restrict('patient', 'patientMedicines') && (
						<NavigateButton label="Przyjmowane Leki" to="medicines" />
					)}
					{restrict('patient', 'deposits') && (
						<NavigateButton label="Depozyty" to="deposits" />
					)}
					{isModuleEnabled('MEDICINE_WAREHOUSE') &&
						restrict('patient', 'warehouse') && (
							<NavigateButton label="Magazyn leków" to="medicinesstock" />
						)}
					{isModuleEnabled('CONTRACTS') && restrict('patient', 'bills') && (
						<NavigateButton label="Rachunki" to="bills" />
					)}
					<NavigateButton label="Załączniki" to="attachments" />
				</NavigationButtonContainer>
			</GridItem>
			<GridItem colSpan={2}>
				<Heading size="md">Osoby powiązane</Heading>
				<Table hideNumberOfRows tableInstance={tableInstance} />
			</GridItem>
		</Tile>
	) : null
}
