import { TaskType } from 'models/dictionaries/taskType'
import { Service } from 'services'

const path = '/taskTypes'
const objectPath = '/dictionaries'

export class TaskTypeService extends Service<TaskType>({
	objectPath,
	path,
	objectService: true,
}) {}
