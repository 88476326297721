import { Text, useDisclosure } from '@chakra-ui/react'
import {
	Dropdown,
	formatDate,
	InputField,
	ModalDialog,
	SelectField,
	TextAreaField,
	useErrorToast,
} from '@kevea/react-components'
import { Form, Formik, FormikProps } from 'formik'
import { DiapersOrder } from 'models/diapersOrder'
import { NoteDictionary } from 'models/dictionaries/note'
import { Note } from 'models/note'
import { Patient } from 'models/patient'
import { PatientModalList } from 'pages/patients/PatientModalList'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { DiapersOrderService } from 'services/diapersOrder'

type Props = {
	diapersOrder?: DiapersOrder
	isOpen: boolean
	onClose: (diapersOrder?: DiapersOrder) => void
	confirm?: boolean
	isLoading?: boolean
}

export const DiapersOrderModal = (props: Props) => {
	const formik = useRef<FormikProps<DiapersOrder>>(null)
	const [loading, setLoading] = useState(false)
	const [selectedPatient, setSelectedPatient] = useState(
		props.diapersOrder?.patient,
	)
	const showAllPatientsDisclosure = useDisclosure()
	const error = useErrorToast()

	const { data: patientsRaw, refetch: refetchPatients } = useQuery(
		'fetchPatients',
		() => Patient.fetchPatients(),
		{ enabled: !props.diapersOrder?._id },
	)

	const patients = useMemo<Patient[]>(
		() => patientsRaw?.map(patient => new Patient(patient)) ?? [],
		[patientsRaw],
	)

	useEffect(() => {
		if (props.isOpen) {
			setSelectedPatient(props.diapersOrder?.patient)
			refetchPatients()
		}
	}, [props.isOpen])

	useEffect(() => {
		formik.current?.setFieldValue('patient', selectedPatient)
		refetchPatients()
	}, [selectedPatient])

	const handleSubmit = (values: DiapersOrder) => {
		if (selectedPatient) {
			if (formik.current) {
				let newDiapersOrder = new DiapersOrder(values)
				if (props.confirm) {
					props.onClose(newDiapersOrder)
				} else {
					setLoading(true)
					if (props.diapersOrder?._id) {
						DiapersOrderService.put(newDiapersOrder).then(() => {
							setLoading(false)
							props.onClose(newDiapersOrder)
						})
					} else {
						DiapersOrderService.post(newDiapersOrder).then(() => {
							setLoading(false)
							props.onClose(newDiapersOrder)
						})
					}
				}
			}
		} else {
			error({ description: 'Wybierz pacjenta' })
		}
	}

	return (
		<>
			{props.isOpen && (
				<ModalDialog
					isLoading={loading || props.isLoading}
					header={
						props.confirm
							? 'Potwierdź zlecenie'
							: props.diapersOrder
							? 'Edytuj zlecenie'
							: 'Dodaj zlecenie'
					}
					onClose={() => props.onClose()}
					onSave={() => formik.current?.submitForm()}
					isOpen={props.isOpen}
				>
					{!props.diapersOrder?._id && (
						<>
							<PatientModalList
								isOpen={showAllPatientsDisclosure.isOpen}
								onClose={patient => {
									if (patient) setSelectedPatient(new Patient(patient))
									showAllPatientsDisclosure.onClose()
								}}
							/>
							<Dropdown
								displayAccessor="fullName"
								items={patients}
								selectedItem={selectedPatient}
								setSelectedItem={setSelectedPatient}
								placeholder="Wybierz pacjenta"
								label="Pacjent"
								render={(patient: Patient) => patient.fullName}
								numberOfResults={6}
								onShowAllClick={showAllPatientsDisclosure.onOpen}
							/>
						</>
					)}
					<Formik
						validateOnBlur={false}
						validateOnChange={false}
						validationSchema={DiapersOrder.validationSchema}
						innerRef={formik}
						onSubmit={handleSubmit}
						initialValues={
							props.confirm && props.diapersOrder
								? new DiapersOrder({ ...props.diapersOrder, status: 2 })
								: new DiapersOrder(props.diapersOrder)
						}
					>
						<Form>
							<InputField
								name="prescriptionNumber"
								placeholder="Numer NFZ"
								label="Numer NFZ"
								autoFocus
							/>
							<InputField
								name="dateOfIssue"
								placeholder="Data wystawienia"
								label="Data wystawienia"
								type="date"
							/>
							<InputField
								name="finishDate"
								placeholder="Data ważności"
								label="Data ważności"
								type="date"
								min={
									formik.current?.values['dateOfIssue'] ??
									formatDate(new Date().toISOString(), 'date')
								}
							/>
						</Form>
					</Formik>
				</ModalDialog>
			)}
		</>
	)
}
