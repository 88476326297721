import React from 'react'
import { Navigate } from 'react-router'
import { restrict } from 'utils/user'

type Props = {
	module:
		| 'patient'
		| 'report'
		| 'orders'
		| 'nfz_orders'
		| 'contracts'
		| 'bills'
		| 'warehouse'
		| 'menu'
		| 'settings'
	power:
		| 'view'
		| 'bills'
		| 'warehouse'
		| 'deposits'
		| 'edit'
		| 'measurements'
		| 'notes'
		| 'patientMedicines'
		| 'taskPlans'
		| 'tasks'
		| 'contractTemplates'
		| 'devices'
		| 'users'
		| 'dictionaries'
	children: JSX.Element
}

export const RestrictRoute = ({ module, power, children }: Props) => {
	return restrict(module, power) ? children : <Navigate to="/" replace />
}
