import {
	Box,
	Button,
	Checkbox,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
} from '@chakra-ui/react'
import {
	Dropdown,
	formatDate,
	InputField,
	Link,
	ModalDialog,
	SelectField,
	TextAreaField,
	Tile,
	TileGrid,
	useErrorToast,
} from '@kevea/react-components'
import { SelectedServiceContext } from 'App'
import { Form, Formik, FormikProps } from 'formik'
import { Dish } from 'models/dictionaries/dish'
import { NoteDictionary } from 'models/dictionaries/note'
import { Menu, MenuDay } from 'models/menu'
import { Note } from 'models/note'
import { Patient } from 'models/patient'
import { PatientModalList } from 'pages/patients/PatientModalList'
import { DishModalList } from 'pages/settings/views/dictionaries/dishes/DishModalList'
import { NoteTypeModal } from 'pages/settings/views/dictionaries/notes/NoteTypeModal'
import { NoteTypeModalList } from 'pages/settings/views/dictionaries/notes/NoteTypeModalList'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { NoteService } from 'services/note'
import { NA_WZGORZU_ID } from './Menu'

// function to get polish weekday name from date
function getPolishWeekday(date: Date): string {
	switch (date.getDay()) {
		case 0:
			return 'Niedziela'
		case 1:
			return 'Poniedziałek'
		case 2:
			return 'Wtorek'
		case 3:
			return 'Środa'
		case 4:
			return 'Czwartek'
		case 5:
			return 'Piątek'
		case 6:
			return 'Sobota'
		default:
			return 'Niedziela'
	}
}

type DishesTableProps = {
	menus: Menu[]
	addDish: (dish: Dish) => void
	removeDish: (newMenus: Menu[]) => void
}

function renderDishNameWithCalories(menu: Menu): string {
	if (menu.dish?.calories || menu.dish?.weight) {
		return `${menu.dish?.name} (${menu.dish?.calories ?? 0} kcal, ${
			menu.dish?.weight ?? 0
		} g)`
	}
	return menu.dish?.name
}

const DishesTable = ({ addDish, menus, removeDish }: DishesTableProps) => {
	const { isOpen, onClose, onOpen } = useDisclosure()

	return (
		<Table>
			<DishModalList
				addDish
				isOpen={isOpen}
				onClose={dish => {
					onClose()
					if (dish) addDish(dish)
				}}
			/>
			<Thead>
				<Tr>
					<Th>Danie</Th>
					<Th />
				</Tr>
			</Thead>
			<Tbody>
				{menus.length === 0 ? (
					<Tr>
						<Td colSpan={2} textAlign="center">
							<Text>Brak dań</Text>
						</Td>
					</Tr>
				) : (
					menus.map((menu, idx) => (
						<Tr key={idx}>
							<Td>{renderDishNameWithCalories(menu)}</Td>
							<Td>
								<Link
									textAlign="center"
									color="remove.500"
									onClick={() => {
										const newMenus = menus.filter((_, i) => i !== idx)
										removeDish(newMenus)
									}}
								>
									Usuń
								</Link>
							</Td>
						</Tr>
					))
				)}
				<Tr>
					<Td colSpan={2} textAlign="center">
						<Link color="add.500" onClick={onOpen}>
							Dodaj
						</Link>
					</Td>
				</Tr>
			</Tbody>
		</Table>
	)
}

type Props = {
	selectedMenuDay: MenuDay
	isOpen: boolean
	onClose: (menuDay?: MenuDay) => void
}

export const MenuModal = ({ isOpen, onClose, selectedMenuDay }: Props) => {
	const [menuDay, setMenuDay] = useState(selectedMenuDay)
	const [mealTypes, setMealTypes] = useState([
		{
			name: 'Śniadanie',
			type: 0,
			hasDiabeticVariant: selectedMenuDay.dishes.some(d => d.type === 10),
		},
		{
			name: 'Drugie śniadanie',
			type: 3,
			hasDiabeticVariant: selectedMenuDay.dishes.some(d => d.type === 13),
		},
		{
			name: 'Obiad',
			type: 1,
			hasDiabeticVariant: selectedMenuDay.dishes.some(d => d.type === 11),
		},
		{
			name: 'Podwieczorek',
			type: 4,
			hasDiabeticVariant: selectedMenuDay.dishes.some(d => d.type === 14),
		},
		{
			name: 'Kolacja',
			type: 2,
			hasDiabeticVariant: selectedMenuDay.dishes.some(d => d.type === 12),
		},
	])
	const { selectedService } = useContext(SelectedServiceContext)

	useEffect(() => {
		if (isOpen) {
			setMenuDay(selectedMenuDay)
		}
	}, [isOpen, selectedMenuDay])

	const addDish = (type: number, dish: Dish) => {
		setMenuDay(menuDay => ({
			...menuDay,
			dishes: [...menuDay.dishes, { type, dish, date: menuDay.date }],
		}))
	}

	const removeDish = (type: number, newMenus: Menu[]) => {
		setMenuDay({
			...menuDay,
			dishes: [
				...menuDay.dishes.filter(dish => dish.type !== type),
				...newMenus,
			],
		})
	}
	return (
		<>
			{isOpen && (
				<ModalDialog
					header={`${getPolishWeekday(new Date(menuDay.date))} - ${formatDate(
						menuDay.date,
						'localeDateString',
					)}`}
					onClose={() => onClose()}
					onSave={() => onClose(menuDay)}
					isOpen={isOpen}
					size="2xl"
				>
					<TileGrid maxH="80vh" overflowY="auto">
						{mealTypes.map(d => (
							<>
								<Tile
									key={d.type}
									minH="0px"
									header={d.name}
									headerButtons={
										selectedService._id === NA_WZGORZU_ID &&
										mealTypes.find(m => m.type === d.type)
											.hasDiabeticVariant === false && (
											<Link
												onClick={() => {
													const type = mealTypes.find(m => m.type === d.type)
													type.hasDiabeticVariant = true
													setMealTypes([...mealTypes])
												}}
											>
												Dodaj wariant cukrzycowy
											</Link>
										)
									}
									bgColor="gray.50"
									overflow="hidden"
								>
									<DishesTable
										menus={menuDay.dishes.filter(dish => dish.type === d.type)}
										addDish={dish => addDish(d.type, dish)}
										removeDish={newMenus => removeDish(d.type, newMenus)}
									/>
								</Tile>
								{d.hasDiabeticVariant && (
									<Tile
										key={d.type + 'diabetictable'}
										minH="0px"
										header={d.name + ' - ' + 'Wariant cukrzycowy'}
										bgColor="yellow.50"
										headerButtons={
											<Link
												onClick={() => {
													const dishesToCopy = menuDay.dishes.filter(
														dish => dish.type === d.type,
													)
													const dishesWithoutCurrent = menuDay.dishes.filter(
														dish => dish.type !== d.type + 10,
													)
													setMenuDay(menuDay => ({
														...menuDay,
														dishes: [
															...dishesWithoutCurrent,
															...dishesToCopy.map(d => ({
																...d,
																type: d.type + 10,
															})),
														],
													}))
												}}
											>
												Kopiuj <em>{d.name}</em>
											</Link>
										}
										overflow="hidden"
									>
										<DishesTable
											menus={menuDay.dishes.filter(
												dish => dish.type === d.type + 10,
											)}
											addDish={dish => addDish(d.type + 10, dish)}
											removeDish={newMenus => removeDish(d.type + 10, newMenus)}
										/>
									</Tile>
								)}
							</>
						))}
					</TileGrid>
				</ModalDialog>
			)}
		</>
	)
}
