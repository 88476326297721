import { Note } from 'models/note'
import { Service } from 'services'

const path = '/notes'
const objectPath = '/patients'

export class NoteService extends Service<Note>({
	objectPath,
	path,
	objectService: true,
}) {}
