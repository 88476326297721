import axios, { AxiosError } from 'axios'
import { logout } from 'services'

const http = axios.create({
	baseURL: process.env.REACT_APP_AXIOS_BASE_URL || '/api',
	validateStatus: status => {
		switch (status) {
			case 200:
				return true
			case 401:
				logout()
				return false
			default:
				return false
		}
	},
})

export type APIError = {
	response?: AxiosError & { data: any }
	request?: AxiosError
}

export default http
