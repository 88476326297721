import { Dish } from 'models/dictionaries/dish'
import { Service } from 'services'

const path = '/dishes'
const objectPath = '/dictionaries'

export class DishService extends Service<Dish>({
	objectPath,
	path,
	objectService: true,
}) {}
