import { Box, Button, Switch } from '@chakra-ui/react'
import { ModalDialog, Table, Link, Label } from '@kevea/react-components'
import { CurrentUserContext } from 'layout/Layout'
import { IService } from 'models/service'
import React, { useContext, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Column, useTable, usePagination } from 'react-table'
import { logout } from 'services'
import { ServiceService } from 'services/service'
import { chooseService } from 'utils/service'

interface ChooseServiceModalProps {
	isOpen: boolean
	onClose: () => void
}

export const ChooseServiceModal: React.FC<ChooseServiceModalProps> = ({
	isOpen,
	onClose,
}) => {
	const [showAllInstances, setShowAllInstances] = useState(false)
	const currentUser = useContext(CurrentUserContext)

	const fetchServices = async () => {
		return ServiceService.getAll()
			.then(res => res.data)
			.then(services => services.filter(service => service.type === 'rhm'))
	}

	const { data: services, isLoading } = useQuery(
		'fetchServices',
		fetchServices,
		{ enabled: isOpen },
	)

	const columns = useMemo<Column<IService>[]>(
		() => [
			{
				accessor: '_id',
			},
			{
				Header: 'Nazwa',
				accessor: 'name',

				Cell: cell => (
					<Link
						onClick={() => {
							chooseService(cell.row.original)
							onClose()
						}}
					>
						{cell.value}
					</Link>
				),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	)

	const data = useMemo(
		() =>
			services?.filter(i =>
				!showAllInstances
					? currentUser.instanceRoles.map(x => x.service).includes(i._id)
					: services,
			) || [],
		[services, showAllInstances],
	)

	const tableInstance = useTable(
		{
			columns: columns,
			data: data,
			initialState: { hiddenColumns: ['_id'] },
		},
		usePagination,
	)

	return (
		<ModalDialog
			size="4xl"
			header="Wybierz Placówkę"
			isOpen={isOpen}
			onClose={onClose}
			closeOnEsc={false}
			closeOnOverlayClick={false}
			disableClose={true}
			customFooter={<></>}
		>
			{currentUser.role === 'keveaAdmin' && (
				<>
					<Label>Pokaż wszystkie instancje</Label>
					<Switch
						isChecked={showAllInstances}
						onChange={e => {
							setShowAllInstances(e.target.checked)
						}}
					/>
				</>
			)}
			<Table tableInstance={tableInstance} loading={isLoading} />
			<Box marginTop={8} display="flex" width="100%" justifyContent="end">
				<Button onClick={logout}>Wyloguj się</Button>
			</Box>
		</ModalDialog>
	)
}
