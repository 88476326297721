import { GridItem, useDisclosure } from '@chakra-ui/react'
import {
	DataDisplay,
	DataDisplayRow,
	Tile,
	TileEditButton,
	TilePrintButton,
	MenuButton,
	Link,
	useBreadcrumbs,
} from '@kevea/react-components'
import {
	NavigateButton,
	NavigationButtonContainer,
} from 'components/NavigateButton'
import { ContractTemplate, TContractTemplate } from 'models/contractTemplate'
import { useMemo } from 'react'
import { useOutletContext } from 'react-router-dom'
import { styles } from 'utils/quill'
import { ContractTemplateContext } from '../ContractTemplate'
import contractTemplateRoutes from '../routes'

export const General = () => {
	const { contractTemplate, refetch, ...rest } =
		useOutletContext<ContractTemplateContext>()

	const rows = useMemo<DataDisplayRow<TContractTemplate>[]>(
		() => [
			{ accessor: 'type', label: 'Typ', render: ContractTemplate.convertType },
		],
		[],
	)

	return contractTemplate ? (
		<Tile
			grid
			{...rest}
			headerButtons={
				<>
					<TileEditButton />
				</>
			}
		>
			<GridItem colSpan={{ base: 2, lg: 1 }}>
				<DataDisplay data={contractTemplate} rows={rows} />
			</GridItem>
			<GridItem colSpan={2}>
				<iframe
					style={{ width: '100%', minHeight: '800px' }}
					srcDoc={`<html><head><style>body {font-family: 'Helvetica', 'Arial', sans-serif;} p {font-size: 13px; line-height: 20px;}</style></head><body>${contractTemplate.template}</body></html>`}
				></iframe>
			</GridItem>
		</Tile>
	) : null
}
