import { Deposit } from 'models/deposit'
import { getBaseInstancePath, printConfig, Service } from 'services'
import http from './axios'

const path = '/deposits'
const objectPath = '/patients'

export class DepositService extends Service<Deposit>({
	objectPath,
	path,
	objectService: true,
}) {
	static async print(patientId: string): Promise<Blob> {
		return http
			.get<Blob>(
				`${getBaseInstancePath(objectPath)}/${patientId}${path}/print`,
				printConfig,
			)
			.then(res => res.data)
	}
}
