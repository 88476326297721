import {
	OutletContext,
	TileGrid,
	useBreadcrumbs,
	useNotFoundToast,
} from '@kevea/react-components'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import {
	Outlet,
	useNavigate,
	useOutletContext,
	useParams,
	useSearchParams,
} from 'react-router-dom'
import { BillService, PatientBillService } from 'services/bill'
import { Bill as BillModel, BillEntry } from 'models/bill'
import { StatusBadge } from 'components/StatusBadge'
import { PatientService } from 'services/patient'
import patientBillRoutes from './patientRoutes'
import { FaFileInvoiceDollar } from 'react-icons/fa'
import patientRoutes from 'pages/patients/routes'
import { Patient } from 'models/patient'
import { AttachmentService } from 'services/attachment'

export type BillContext = OutletContext & {
	bill: BillModel | undefined
}

export const Bill = () => {
	const { id: patientId } = useParams<{ id: string }>()
	const { billId } = useParams<{
		billId: string
	}>()
	let [searchParams, setSearchParams] = useSearchParams()

	const toast = useNotFoundToast()
	const navigate = useNavigate()

	const fetchBill = async (): Promise<BillModel> => {
		if (billId && billId !== 'new') {
			try {
				let res
				if (patientId) {
					res = await PatientBillService.get(billId, patientId)
				} else {
					res = await BillService.get(billId)
				}
				const entriesWithAttachment = res.data.entries.filter(e =>
					Boolean(e.attachmentId),
				)
				const attachmentIds = (
					await AttachmentService.getAll(billId, 'bills')
				).data.map(a => a._id)
				const entriesWithoutAttachment = entriesWithAttachment.filter(
					e => !attachmentIds.includes(e.attachmentId),
				)
				if (entriesWithoutAttachment.length > 0) {
					for (let e of res.data.entries) {
						if (entriesWithoutAttachment.find(x => x.no === e.no)) {
							e.attachmentId = undefined
						}
					}
					return (await BillService.put(res.data)).data
				} else {
					return res.data
				}
			} catch (e) {
				toast()
				if (patientId) {
					navigate(`/patients/${patientId}/bills`)
				} else {
					navigate(`/bills`)
				}
				return undefined
			}
		} else {
			if (searchParams.get('patient')) {
				const patient: Patient = JSON.parse(searchParams.get('patient'))
				const entries: BillEntry[] = JSON.parse(searchParams.get('entries'))
				const billNumberMonth = +searchParams.get('billNumberMonth')
				const billNumberYear = +searchParams.get('billNumberYear')
				return new BillModel({
					billNumberMonth,
					billNumberYear,
					entries,
					patient,
				})
			} else {
				return new BillModel()
			}
		}
	}
	const {
		data: bill,
		isLoading,
		isRefetching,
		refetch,
	} = useQuery('fetchBill', fetchBill)

	const breadcrumbs = useBreadcrumbs(
		patientId
			? [...patientRoutes, ...patientBillRoutes]
			: [
					{
						path: 'bills',
						breadcrumb: 'Rozrachunki',
					},
					{
						path: 'bills/:billId',
						element: <Bill />,
						children: [
							{
								index: true,
								breadcrumb: 'Rachunek',
								breadcrumbIcon: <FaFileInvoiceDollar />,
							},
							{
								path: 'edit',
								breadcrumb: 'Edycja rachunku',
								breadcrumbIcon: <FaFileInvoiceDollar />,
							},
							{
								path: 'attachments',
								breadcrumb: 'Lista załączników',
								breadcrumbIcon: <FaFileInvoiceDollar />,
							},
						],
					},
					{
						path: 'bills/new',
						element: <Bill />,
						children: [
							{
								index: true,
								breadcrumb: 'Nowy rachunek',
								breadcrumbIcon: <FaFileInvoiceDollar />,
							},
						],
					},
			  ],
	)
	const context: BillContext = {
		breadcrumbs: breadcrumbs,
		bill: bill,
		titleElement: bill ? <StatusBadge bill={bill} /> : null,
		refetch,
	}

	useEffect(() => {
		refetch()
	}, [billId])

	return (
		<TileGrid isLoading={isLoading || isRefetching}>
			<Outlet context={context} />
		</TileGrid>
	)
}
