import { InputField, ModalDialog, TextAreaField } from '@kevea/react-components'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { Announcement } from 'models/announcement'
import React, { useRef, useState } from 'react'
import { getUser } from 'services'
import { AnnouncementService } from 'services/announcements'
import { AuthenticationService } from 'services/authentication'

type Props = {
	isOpen: boolean
	onClose: () => void
	announcement: Announcement
}

export const AnnouncementModal = ({ isOpen, announcement, onClose }: Props) => {
	const [loading, setLoading] = useState(false)

	const formik = useRef<FormikProps<Announcement>>(null)

	const handleSubmit = (
		values: Announcement,
		{}: FormikHelpers<Announcement>,
	) => {
		setLoading(true)
		if (values.dateTo) {
			values.dateTo = new Date(
				new Date(values.dateTo).setHours(0, 0, 0, 0),
			).toISOString()
		}
		values.dateFrom = new Date(
			new Date(values.dateFrom).setHours(0, 0, 0, 0),
		).toISOString()

		if (announcement._id) {
			AnnouncementService.put({ ...values, _id: announcement._id }).then(() => {
				setLoading(false)
				onClose()
			})
		} else {
			const user = getUser()
			const userName = user ? user.firstName + ' ' + user.lastName : undefined
			values.addedBy = userName
			AnnouncementService.post(values).then(() => {
				setLoading(false)
				onClose()
			})
		}
	}

	return (
		<ModalDialog
			isOpen={isOpen}
			header={announcement._id ? 'Edytuj ogłoszenie' : 'Dodaj ogłoszenie'}
			onClose={onClose}
			isLoading={loading}
			onSave={() => formik.current?.submitForm()}
		>
			<Formik
				innerRef={formik}
				validateOnBlur={false}
				validateOnChange={false}
				onSubmit={handleSubmit}
				validationSchema={Announcement.schema}
				initialValues={new Announcement(announcement)}
			>
				<Form>
					<InputField name="name" label="Nazwa" placeholder="Nazwa" />
					<InputField name="dateFrom" label="Data od" type="date" />
					<InputField name="dateTo" label="Data do" type="date" />
					<TextAreaField name="description" label="Opis" placeholder="Opis" />
				</Form>
			</Formik>
		</ModalDialog>
	)
}
