import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { UserPermissions } from 'models/permissions'
import { IUser } from 'models/user'
import { LooseAutocomplete } from 'types'
import { getLSServiceId } from 'utils/service'
import http from './axios'

export const FULL_PERMISSIONS: Readonly<UserPermissions> =
	Object.freeze<UserPermissions>({
		patient: [
			'view',
			// 'edit',
			'bills',
			'deposits',
			'measurements',
			'notes',
			'patientMedicines',
			'taskPlans',
			'tasks',
			'warehouse',
		],
		report: ['view'],
		orders: [
			'view',
			// 'edit'
		],
		nfz_orders: [
			'view',
			// 'edit'
		],
		contracts: [
			'view',
			// 'edit'
		],
		bills: [
			'view',
			// 'edit'
		],
		warehouse: [
			'view',
			// 'edit'
		],
		menu: [
			'view',
			// 'edit'
		],
		settings: ['view', 'contractTemplates', 'devices', 'users', 'dictionaries'],
		event_log: ['view'],
	})

export const localStorageUserKey = 'currentUser'

export const getToken = (): string => {
	return getUser()?.token ?? ''
}

export const getUser = (): IUser | undefined => {
	const userString = localStorage.getItem(localStorageUserKey)
	if (userString) {
		const user: IUser = JSON.parse(userString)
		return user
	}
}
export const getUserPermissions = (): UserPermissions | undefined => {
	const user = getUser()
	if (user.role === 'keveaAdmin') return FULL_PERMISSIONS
	if (user.role === 'admin') return FULL_PERMISSIONS
	const selectedServiceId = getLSServiceId()
	return user?.instanceRoles?.find(
		instance => instance.service === selectedServiceId,
	)?.permissions
}

export const isAuthenticated = (): boolean => {
	return localStorage.getItem(localStorageUserKey) ? true : false
}

export const logout = () => {
	localStorage.removeItem(localStorageUserKey)
	window.location.href = '/login'
}

export const setUser = (user: IUser) => {
	localStorage.setItem(localStorageUserKey, JSON.stringify(user))
}

export const getBaseInstancePath = (path: string): string => {
	return `/instance/${getLSServiceId()}` + path
}

export type ServiceType =
	| {
			path: string
			outsideInstance?: boolean
	  } & (
			| {
					objectService: true
					objectPath: string
			  }
			| {
					objectService?: false
					objectPath?: never
			  }
	  )

export function Service<T extends { _id?: string }>({
	objectPath,
	outsideInstance,
	objectService,
	path,
}: ServiceType) {
	abstract class Service {
		static async getAll(objId?: string): Promise<AxiosResponse<T[]>> {
			return http.get(
				resolvePath({
					path,
					objectService,
					objectPath,
					outsideInstance,
					objId,
				}),
			)
		}
		static async get(id: string, objId?: string): Promise<AxiosResponse<T>> {
			return http.get(
				resolvePath({
					path,
					id,
					objectService,
					objectPath,
					outsideInstance,
					objId,
				}),
			)
		}
		static async post(obj: T, objId?: string): Promise<AxiosResponse<T>> {
			return http.post(
				resolvePath({
					path,
					objectService,
					objectPath,
					outsideInstance,
					objId,
				}),
				obj,
			)
		}
		static async put(obj: T, objId?: string): Promise<AxiosResponse<T>> {
			if (obj._id) {
				return http.put(
					resolvePath({
						path,
						id: obj._id,
						objectService,
						objectPath,
						outsideInstance,
						objId,
					}),
					obj,
				)
			} else {
				return Promise.reject('Given Object has no `_id` attribute')
			}
		}
		static async delete(
			id: string,
			objId?: string,
		): Promise<AxiosResponse<boolean>> {
			return http.delete(
				resolvePath({
					path,
					id,
					objectService,
					objectPath,
					outsideInstance,
					objId,
				}),
			)
		}
	}
	return Service
}
export const printConfig: AxiosRequestConfig = {
	headers: { Accept: 'application/pdf' },
	responseType: 'blob',
}

type ResolvePathType = {
	path: string
	outsideInstance?: boolean
	objId?: string
	id?: string
	objectService?: boolean
	objectPath?: string
}

export const resolvePath = ({
	path,
	id,
	objId,
	objectService,
	objectPath,
	outsideInstance,
}: ResolvePathType): string => {
	let basePath = ''

	if (objectService && !objectPath) throw new Error('Path requires objectPath')

	if (outsideInstance) {
		if (objectService) {
			if (!objectPath) throw new Error('Path requires objectPath')
			basePath = objectPath
		} else {
			basePath = path
		}
	} else {
		if (objectService) {
			if (!objectPath) throw new Error('Path requires objectPath')
			basePath = getBaseInstancePath(objectPath)
		} else {
			basePath = getBaseInstancePath(path)
		}
	}

	if (objectService) {
		if (objId) {
			basePath += `/${objId}` + path
		} else {
			basePath += path
		}
	}
	if (id) {
		basePath += `/${id}`
	}
	return basePath
}
