import { useDisclosure } from '@chakra-ui/react'
import {
	AlertDialog,
	BreadcrumbData,
	DateFilter,
	formatDate,
	Link,
	SelectFilter,
	Table,
	Tile,
} from '@kevea/react-components'
import { HeaderDatePicker } from 'components/HeaderDatePicker'
import { Badge } from 'components/StatusBadge'
import { Patient } from 'models/patient'
import { RhmShiftSettings } from 'models/service'
import { TableTask, Task } from 'models/task'
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Navigate } from 'react-router-dom'
import {
	Cell,
	Column,
	useFilters,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table'
import { TaskService } from 'services/task'
import { TaskModal } from './TaskModal'

export function polishPlurals(
	singularNominativ: string,
	pluralNominativ: string,
	pluralGenitive: string,
	value: number,
) {
	value = Math.abs(value)
	if (value === 1) {
		return singularNominativ
	} else if (
		value % 10 >= 2 &&
		value % 10 <= 4 &&
		(value % 100 < 10 || value % 100 >= 20)
	) {
		return pluralNominativ
	} else {
		return pluralGenitive
	}
}

export type TaskProps = {
	patient?: Patient
	breadcrumbs?: BreadcrumbData<string>[]
	selectedDate: string
	setSelectedDate: (date: string) => void
	selectedShift?: RhmShiftSettings
	setSelectedShift?: (shift: RhmShiftSettings) => void
	shiftSettings?: RhmShiftSettings[]
}

export const Tasks = ({
	patient,
	breadcrumbs,
	selectedDate,
	setSelectedDate,
	selectedShift,
	setSelectedShift,
	shiftSettings,
}: TaskProps) => {
	const [isRemoving, setIsRemoving] = useState(false)
	const [selectedTask, setSelectedTask] = useState<Task | undefined>(undefined)
	const addDisclosure = useDisclosure()
	const removeDisclosure = useDisclosure()

	const {
		data: tasks,
		isLoading,
		isRefetching,
		refetch: refetchTasks,
	} = useQuery('fetchTasks', () =>
		Task.fetchTasks(selectedDate, patient, selectedShift),
	)

	const data = useMemo(
		() => tasks?.map(task => new TableTask(task)) ?? [],
		[tasks],
	)

	const columns = useMemo<Column<TableTask>[]>(
		() => [
			{
				Header: 'Nazwa',
				accessor: 'title',
				Cell: ({ value, row }) => (
					<Link
						onClick={() => {
							setSelectedTask(row.original.original)
							addDisclosure.onOpen()
						}}
					>
						{value}
					</Link>
				),
			},
			{
				Header: 'Godzina',
				accessor: 'time',
			},
			{
				Header: 'Pacjent',
				accessor: 'patient',
			},
			{
				Header: 'Przypisane do',
				accessor: 'assignTo',
			},
			{
				Header: 'Wykonane przez',
				accessor: 'completedBy',
			},
			{
				Header: 'Status',
				accessor: 'status',
				Cell: ({ row }) => <Badge task={row.original.original} />,
				Filter: ({ column }) => (
					<SelectFilter column={column} options={TableTask.options} />
				),
			},
		],
		[],
	)

	const tableInstance = useTable(
		{
			data,
			columns,
			autoResetFilters: false,
			initialState: {
				hiddenColumns: [patient ? 'patient' : ''],
			},
		},
		useFilters,
		useSortBy,
		usePagination,
		useRowSelect,
	)

	useEffect(() => {
		refetchTasks()
	}, [selectedDate, selectedShift])

	return (
		<Tile
			breadcrumbs={breadcrumbs}
			header={`Zadania ${formatDate(selectedDate, 'localeDateString')} - ${
				patient
					? Patient.getFullName(patient)
					: selectedShift
					? 'Zmiana ' + selectedShift.name
					: ''
			}`}
			headerButtons={
				<HeaderDatePicker
					selectedDate={selectedDate}
					setSelectedDate={setSelectedDate}
					selectedShift={selectedShift}
					setSelectedShift={setSelectedShift}
					shiftSettings={shiftSettings}
				/>
			}
		>
			<TaskModal
				date={selectedDate}
				isOpen={addDisclosure.isOpen}
				selectedTask={selectedTask}
				onClose={() => {
					setSelectedTask(undefined)
					addDisclosure.onClose()
					refetchTasks()
				}}
				patient={patient}
			/>
			<AlertDialog
				header="Usuń Zadanie"
				onClose={removeDisclosure.onClose}
				isRemoving={isRemoving}
				isOpen={removeDisclosure.isOpen}
				onRemove={() => {
					if (tableInstance.selectedFlatRows.length > 0) {
						setIsRemoving(true)
						const promises: Promise<any>[] = []
						for (let row of tableInstance.selectedFlatRows) {
							if (
								(row.original.original?._id ||
									row.original.original?.taskPlan) &&
								row.original.original.patient._id
							) {
								promises.push(
									TaskService.delete(
										row.original.original,
										row.original.original.patient._id,
									),
								)
							}
						}
						Promise.allSettled(promises).then(res => {
							setIsRemoving(false)
							setSelectedTask(undefined)
							removeDisclosure.onClose()
							refetchTasks()
						})
					}
				}}
			>
				{tableInstance.selectedFlatRows.length === 1 ? (
					<>
						Czy chcesz usunąć zadanie{' '}
						<strong>
							{selectedTask?.type
								? selectedTask.type.name ?? ''
								: selectedTask?.title ?? ''}
						</strong>{' '}
						z dnia{' '}
						<strong>
							{formatDate(selectedTask?.date ?? '', 'localeString')}
						</strong>{' '}
						dla{' '}
						<strong>
							{selectedTask?.patient &&
								Patient.getFullName(selectedTask.patient)}
						</strong>
						?
					</>
				) : (
					<>
						Czy chcesz usunąć{' '}
						<strong>{tableInstance.selectedFlatRows.length}</strong>{' '}
						{polishPlurals(
							'zadanie',
							'zadania',
							'zadań',
							tableInstance.selectedFlatRows.length,
						)}
						?
					</>
				)}
			</AlertDialog>
			<Table
				tableInstance={tableInstance}
				loading={isLoading || isRefetching}
				buttons={[
					{
						type: 'add',
						action: () => {
							addDisclosure.onOpen()
						},
					},
				]}
				menuOptions={{
					singleSelection: [
						{
							label: 'Usuń',
							onClick: (tasks: TableTask[]) => {
								setSelectedTask(tasks[0]?.original)
								removeDisclosure.onOpen()
							},
						},
					],
					multipleSelection: [
						{
							label: 'Usuń',
							onClick: (tasks: TableTask[]) => {
								removeDisclosure.onOpen()
							},
						},
					],
				}}
			/>
		</Tile>
	)
}
