import {
	OutletContext,
	TileGrid,
	useBreadcrumbs,
	useNotFoundToast,
} from '@kevea/react-components'
import { StatusBadge } from 'components/StatusBadge'
import { WarehouseDocument as WarehouseDocumentClass } from 'models/warehouseDocument'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { Outlet, useNavigate, useParams } from 'react-router'
import { WarehouseDocumentService } from 'services/warehouseDocument'
import warehouseDocumentRoutes from './routes'
export type WarehouseDocumentContext = OutletContext & {
	warehouseDocument: WarehouseDocumentClass | undefined
}

export const WarehouseDocument = () => {
	const { id } = useParams<{ id: string }>()
	const toast = useNotFoundToast()
	const navigate = useNavigate()

	const fetchDocument = () => {
		if (id) {
			return WarehouseDocumentService.get(id)
				.then(res => res.data)
				.catch(e => {
					toast()
					navigate('/warehouse')
					return undefined
				})
		}
	}
	const {
		data: warehouseDocument,
		isLoading,
		isRefetching,
		refetch,
	} = useQuery('fetchWarehouseDocument', fetchDocument)

	const breadcrumbs = useBreadcrumbs(warehouseDocumentRoutes)
	const context: WarehouseDocumentContext = {
		breadcrumbs: breadcrumbs,
		warehouseDocument,
		refetch,
		titleElement: warehouseDocument ? (
			<StatusBadge warehouseDocument={warehouseDocument} />
		) : null,
	}

	useEffect(() => {
		refetch()
	}, [id])

	return (
		<TileGrid isLoading={isLoading || isRefetching}>
			<Outlet context={context} />
		</TileGrid>
	)
}
