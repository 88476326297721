import { formatDate } from '@kevea/react-components'
import { DeviceService } from 'services/device'
import { DishService } from 'services/dictionaries/dish'
import * as yup from 'yup'
export class Device {
	constructor(device?: Device) {
		this._id = device?._id
		this.name = device?.name ?? ''
		this.token = device?.token ?? ''
		this.expiry = device?.expiry
			? formatDate(device.expiry, 'localeString')
			: ''
	}
	_id: string | undefined
	name?: string
	token?: string
	expiry?: string

	static validationSchema = yup.object({
		name: yup.string().required('Nazwa jest wymagana'),
	})
	static fetchDevices = async () => {
		return DeviceService.getAll().then(res => res.data)
	}
}
