import {
	Box,
	Button,
	Center,
	Flex,
	GridItem,
	Input,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Select,
	SimpleGrid,
	SimpleGridProps,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
} from '@chakra-ui/react'
import { formatDate, Label, Link } from '@kevea/react-components'
import {
	Dose,
	IPatientMedicineDose,
	IPatientNotPreciseMedicineDose,
	NotPreciseDose,
} from 'models/patient'
import React, { ReactNode, useEffect, useState } from 'react'
import { renderDoseValue } from 'utils/fractions'
import { KvTime } from 'utils/kvTime'

const getIntValue = (value: string): string => {
	return value.split('.')[0]
}
const getFractionValue = (value: string): string => {
	return value.includes('.') ? '0.' + value.split('.')[1] : '0'
}
const getNewIntValue = (value: string, newInt: string): string => {
	return newInt + '.' + value.split('.')[1]
}
const getNewFractionValue = (value: string, newFraction: string): string => {
	return (parseInt(value.split('.')[0]) + parseFloat(newFraction)).toFixed(2)
}

type Props = {
	dose: Dose
	isPrecise: boolean
	setDose: (dose: Dose, isPrecise: boolean) => void
}

export const Dosage = ({ dose, setDose, isPrecise: isPreciseProp }: Props) => {
	const [notPreciseDose, setNotPreciseDose] =
		useState<IPatientNotPreciseMedicineDose>(
			!isPreciseProp && !Array.isArray(dose) ? dose : new NotPreciseDose(),
		)
	const [preciseDose, setPreciseDose] = useState<IPatientMedicineDose[]>(
		isPreciseProp && Array.isArray(dose) ? dose : [],
	)
	const [isPrecise, setIsPrecise] = useState(isPreciseProp)

	useEffect(() => {
		let newDose: Dose | undefined
		if (isPrecise) {
			newDose = preciseDose
		} else {
			newDose = notPreciseDose
		}
		setDose(newDose, isPrecise)
	}, [notPreciseDose, preciseDose, isPrecise])

	return (
		<Box>
			<Label>Dawkowanie</Label>
			<Box border="1px solid" borderColor="gray.300" borderRadius="md">
				<Tabs
					colorScheme="accent"
					index={isPrecise ? 1 : 0}
					onChange={index => setIsPrecise(Boolean(index))}
				>
					<TabList>
						<Tab>Porowe</Tab>
						<Tab>Precyzyjne</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<NotPreciseDosePanel
								notPreciseDose={notPreciseDose}
								setNotPreciseDose={setNotPreciseDose}
							/>
						</TabPanel>
						<TabPanel>
							<PreciseDosePanel
								preciseDose={preciseDose}
								setPreciseDose={setPreciseDose}
							/>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
		</Box>
	)
}

type DosePanelGridProps = {
	children: ReactNode
} & SimpleGridProps

const DosePanelGrid = ({ children, ...props }: DosePanelGridProps) => {
	return (
		<SimpleGrid columns={3} columnGap={4} rowGap={1} {...props}>
			{children}
		</SimpleGrid>
	)
}
const DosePanelGridHr = () => {
	return (
		<GridItem colSpan={3} borderTop="1px solid" borderColor="gray.300" my={1} />
	)
}

type DosePanelGridHeaderProps = {
	children?: ReactNode
}

const DosePanelGridHeader = ({ children }: DosePanelGridHeaderProps) => {
	return (
		<GridItem>
			<Text fontWeight="semibold" fontSize="sm" textAlign="center">
				{children}
			</Text>
		</GridItem>
	)
}

type DosePanelInputProps = {
	intValue: string
	setIntValue: (value: string) => void
	fractionValue: string
	setFractionValue: (value: string) => void
}

const DosePanelInput = ({
	fractionValue,
	intValue,
	setFractionValue,
	setIntValue,
}: DosePanelInputProps) => {
	return (
		<Flex justifyContent="center" gap={1}>
			<NumberInput
				variant="flushed"
				width="64px"
				isValidCharacter={value => /\d/.test(value)}
				min={0}
				max={99}
				onChange={value =>
					setIntValue(
						isNaN(parseFloat(value))
							? '0'
							: value.length === 2 &&
							  value[0] === '0' &&
							  !isNaN(parseInt(value[1] ?? '0'))
							? value.slice(1)
							: value,
					)
				}
				value={intValue}
			>
				<NumberInputField
					paddingX={0}
					paddingRight={6}
					textAlign="center"
					onFocus={e => {
						e.target.setSelectionRange(
							e.target.value.length,
							e.target.value.length,
						)
					}}
				/>
				<NumberInputStepper>
					<NumberIncrementStepper border="none" />
					<NumberDecrementStepper border="none" />
				</NumberInputStepper>
			</NumberInput>
			<Select
				variant="flushed"
				width="fit-content"
				value={fractionValue}
				onChange={e => setFractionValue(e.target.value)}
			>
				<option value="0">-</option>
				<option value="0.25">¼</option>
				<option value="0.33">⅓</option>
				<option value="0.50">½</option>
				<option value="0.67">⅔</option>
				<option value="0.75">¾</option>
			</Select>
		</Flex>
	)
}

type NotPreciseDosePanelProps = {
	notPreciseDose: IPatientNotPreciseMedicineDose
	setNotPreciseDose: (dose: IPatientNotPreciseMedicineDose) => void
}

const NotPreciseDosePanel = ({
	notPreciseDose,
	setNotPreciseDose,
}: NotPreciseDosePanelProps) => {
	const [dose, setDose] = useState<{
		morning: string
		noon: string
		evening: string
	}>({
		morning: notPreciseDose.morning.toString(),
		noon: notPreciseDose.noon.toString(),
		evening: notPreciseDose.evening.toString(),
	})

	useEffect(() => {
		setNotPreciseDose({
			evening: parseFloat(dose.evening),
			morning: parseFloat(dose.morning),
			noon: parseFloat(dose.noon),
		})
	}, [dose])

	return (
		<DosePanelGrid>
			<DosePanelGridHeader>Rano</DosePanelGridHeader>
			<DosePanelGridHeader>W południe</DosePanelGridHeader>
			<DosePanelGridHeader>Wieczorem</DosePanelGridHeader>
			<DosePanelGridHr />
			<DosePanelInput
				fractionValue={getFractionValue(dose.morning)}
				setFractionValue={value =>
					setDose({
						...dose,
						morning: getNewFractionValue(dose.morning, value),
					})
				}
				intValue={getIntValue(dose.morning)}
				setIntValue={intValue => {
					setDose({ ...dose, morning: getNewIntValue(dose.morning, intValue) })
				}}
			/>
			<DosePanelInput
				setFractionValue={value =>
					setDose({ ...dose, noon: getNewFractionValue(dose.noon, value) })
				}
				fractionValue={getFractionValue(dose.noon)}
				intValue={getIntValue(dose.noon)}
				setIntValue={intValue => {
					setDose({ ...dose, noon: getNewIntValue(dose.noon, intValue) })
				}}
			/>
			<DosePanelInput
				setFractionValue={value =>
					setDose({
						...dose,
						evening: getNewFractionValue(dose.evening, value),
					})
				}
				fractionValue={getFractionValue(dose.evening)}
				intValue={getIntValue(dose.evening)}
				setIntValue={intValue => {
					setDose({ ...dose, evening: getNewIntValue(dose.evening, intValue) })
				}}
			/>
		</DosePanelGrid>
	)
}

type PreciseDosePanelProps = {
	preciseDose: IPatientMedicineDose[]
	setPreciseDose: (dose: IPatientMedicineDose[]) => void
}

const PreciseDosePanel = ({
	preciseDose,
	setPreciseDose,
}: PreciseDosePanelProps) => {
	const [dose, setDose] = useState('0')
	const [time, setTime] = useState('12:00')

	const addDose = () => {
		setPreciseDose([
			...preciseDose,
			{ amount: parseFloat(dose), dayTime: KvTime.getMinutes(time) },
		])
		setDose('0')
		setTime('12:00')
	}

	const removeDose = (idx: number) => {
		setPreciseDose(preciseDose.filter((_, i) => idx !== i))
	}

	return (
		<DosePanelGrid>
			<DosePanelGridHeader>Dawka</DosePanelGridHeader>
			<DosePanelGridHeader>Godzina</DosePanelGridHeader>
			<DosePanelGridHeader></DosePanelGridHeader>
			<DosePanelGridHr />
			<GridItem colSpan={3} h="150px" maxH="150px" overflowY="auto">
				{preciseDose
					.sort((a, b) => a.dayTime - b.dayTime)
					.map((dose, idx, doses) => (
						<DosePanelGrid
							key={idx}
							borderBottom={idx === doses.length - 1 ? 'none' : '1px solid'}
							borderColor="gray.300"
							py={2}
						>
							<Text textAlign="center">{renderDoseValue(dose.amount)}</Text>
							<Text textAlign="center">
								{KvTime.getTimeFromMinutes(dose.dayTime)}
							</Text>
							<Link
								textAlign="center"
								color="remove.500"
								onClick={() => removeDose(idx)}
							>
								Usuń
							</Link>
						</DosePanelGrid>
					))}
			</GridItem>
			<DosePanelGridHr />
			<GridItem colSpan={3} pt={1}>
				<DosePanelGrid>
					<DosePanelInput
						setFractionValue={value =>
							setDose(getNewFractionValue(dose, value))
						}
						fractionValue={getFractionValue(dose)}
						intValue={getIntValue(dose)}
						setIntValue={intValue => {
							setDose(getNewIntValue(dose, intValue))
						}}
					/>
					<Input
						type="time"
						value={time}
						onChange={e => setTime(e.target.value)}
					/>
					<Center>
						<Button
							size="sm"
							colorScheme="add"
							onClick={addDose}
							disabled={
								!KvTime.timeStringRegEx.test(time) || parseFloat(dose) < 0
							}
							width={20}
						>
							Dodaj
						</Button>
					</Center>
				</DosePanelGrid>
			</GridItem>
		</DosePanelGrid>
	)
}
