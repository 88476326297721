import { formatDate } from '@kevea/react-components'
import { EventLogService } from 'services/eventLog'
import { restrict } from 'utils/user'
import * as yup from 'yup'

export class EventLog {
	_id?: string
	type: 'accident' | 'selfHarm' | 'bodilyInjury'
	kind: 'individual' | 'mass'
	subType: string
	reporterName: string
	date: string
	description: string
	place: string
	nextSteps: string

	constructor(eventLog?: undefined | EventLog) {
		this._id = eventLog?._id
		this.type = eventLog?.type ?? 'accident'
		this.reporterName = eventLog?.reporterName ?? ''
		this.date = eventLog?.date
			? formatDate(new Date(eventLog.date).toISOString(), 'date')
			: formatDate(new Date().toISOString(), 'date')
		this.description = eventLog?.description ?? ''
		this.place = eventLog?.place ?? ''
		this.nextSteps = eventLog?.nextSteps ?? ''
		this.subType = eventLog?.subType ?? 'Inny'
		this.kind = eventLog?.kind ?? 'individual'
	}

	static schema = yup.object({
		type: yup.string().required('Typ zdarzenia jest wymagany'),
		reporterName: yup.string().required('Osoba zgłaszająca jest wymagana'),
		date: yup.string().required('Data zdarzenia jest wymagana'),
		description: yup.string().required('Opis zdarzenia jest wymagany'),
		place: yup.string().required('Miejsce zdarzenia jest wymagane'),
		nextSteps: yup.string().required('Pole jest wymagane'),
	})

	static async fetchAll() {
		if (!restrict('event_log', 'view', true)) return []
		return EventLogService.getAll().then(res => res.data)
	}
	static convertEventType(type: EventLog['type']): string {
		switch (type) {
			case 'accident':
				return 'Wypadek'
			case 'bodilyInjury':
				return 'Obrażenia ciała'
			case 'selfHarm':
				return 'Samookaleczanie'
		}
	}
	static convertEventKind(type: EventLog['kind']): string {
		switch (type) {
			case 'individual':
				return 'Indywidualny'
			case 'mass':
				return 'Masowy'
		}
	}
	static subTypesIndividual: string[] = [
		'Inny',
		'Wypadek związany z przemieszczaniem się: stłuczenie, złamanie, skręcenie, upadek z dużej wysokości',
		'Urazy takie jak: zatrucie, rozległe oparzenie, rozległe rany powstałe w wyniku urazów',
		'Porażenia prądem, zatrucia substancjami chemicznymi lub biologicznymi',
	]
	static subTypesMass: string[] = [
		'Inny',
		'Wypadki komunikacyjne, w których uczestniczy więcej niż 1 osoba',
		'Spowodowanie zagrożenia wybuchem, pożarem, katastrofy budowlanej, przemocy - akty terroru',
	]
}

export class TableEventLog {
	_id: string
	type: string
	reporterName: string
	date: string
	original: EventLog

	constructor(event: EventLog) {
		this._id = event._id
		this.type = EventLog.convertEventType(event.type)
		this.reporterName = event.reporterName
		this.date = formatDate(event.date, 'localeDateString')
		this.original = event
	}
}
