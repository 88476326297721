import { Link, ModalDialog, Table } from '@kevea/react-components'
import { Product } from 'models/dictionaries/product'
import { useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
	Cell,
	Column,
	useGlobalFilter,
	usePagination,
	useSortBy,
	useTable,
} from 'react-table'

type Props = {
	isOpen: boolean
	onClose: (product?: Product) => void
	hideProductIds?: string[]
}

export const ProductModalList = ({
	isOpen,
	onClose,
	hideProductIds,
}: Props) => {
	const {
		data: products,
		isLoading,
		refetch,
	} = useQuery('fetchProducts', Product.fetchProducts, { enabled: isOpen })

	useEffect(() => {
		if (isOpen) refetch()
	}, [isOpen])

	const data = useMemo(
		() =>
			(products
				?.map(
					product =>
						product._id &&
						!hideProductIds?.includes(product._id) &&
						new Product(product),
				)
				.filter(product => product) as Product[]) ?? [],
		[products],
	)

	const columns = useMemo<Column<Product>[]>(
		() => [
			{
				Header: 'Nazwa',
				accessor: 'name',
				Cell: cell => (
					<Link
						onClick={() => {
							onClose(cell.row.original)
						}}
					>
						{cell.value}
					</Link>
				),
			},
			{ Header: 'Jednostka', accessor: 'unit' },
		],
		[],
	)

	const tableInstance = useTable(
		{ columns, data },
		useGlobalFilter,
		useSortBy,
		usePagination,
	)

	return (
		<ModalDialog
			tableModal
			isOpen={isOpen}
			onClose={() => onClose()}
			header="Wybierz produkt"
		>
			<Table tableInstance={tableInstance} loading={isLoading} />
		</ModalDialog>
	)
}
