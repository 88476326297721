import { Patient } from 'models/patient'
import { Attachments } from 'pages/Attachments'
import { useOutletContext } from 'react-router-dom'
import { PatientContext } from '../Patient'

export const AttachmentsWrapper = () => {
	const { patient, breadcrumbs } = useOutletContext<PatientContext>()

	return (
		<Attachments
			attachmentType="patients"
			breadcrumbs={breadcrumbs}
			header={`${
				patient ? Patient.getFullName(patient) : ''
			} - Lista załączników`}
		/>
	)
}
