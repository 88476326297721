export class KvTime {
	static timeStringRegEx = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
	private static handleTimeString = (
		minutes: number,
		hours: number,
	): string => {
		if (minutes < 0 || minutes > 59) throw new Error('minutes is not valid')
		if (hours < 0 || hours > 23) throw new Error('hours is not valid')
		return `${hours < 10 ? `0${hours}` : hours}:${
			minutes < 10 ? `0${minutes}` : minutes
		}`
	}

	static getTimeFromDate = (dateString: string): string => {
		const date = new Date(dateString)
		if (Object.prototype.toString.call(date) === '[object Date]') {
			// it is a date
			if (isNaN(date.getTime())) {
				throw new Error('Date is not valid')
			} else {
				// date is valid
				const minutes = date.getMinutes()
				const hours = date.getHours()
				return KvTime.handleTimeString(minutes, hours)
			}
		} else {
			throw new Error('This is not a date')
		}
	}

	static getTimeFromMinutes = (minutesInput: number) => {
		if (minutesInput < 0 || minutesInput > 1439) throw new Error('Invalid time')
		const minutes = minutesInput % 60
		const hours = (minutesInput - minutes) / 60
		return KvTime.handleTimeString(minutes, hours)
	}

	static getMinutes = (timeString: string): number => {
		if (KvTime.timeStringRegEx.test(timeString)) {
			const [hours, minutes] = timeString.split(':')
			if (!hours || !minutes) throw new Error()
			return +hours * 60 + +minutes
		} else {
			throw new Error('timeString is not valid')
		}
	}
}
