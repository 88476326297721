import { Button, Center, Td, useDisclosure } from '@chakra-ui/react'
import { InputField, Link, Table } from '@kevea/react-components'
import {
	OrderPatient,
	Order,
	TableOrderPatient,
	TableOrderMedicine,
	OrderMedicine,
} from 'models/order'
import React, { useMemo, useState } from 'react'
import { CellProps, Column, useTable } from 'react-table'
import { OrderPatientModal } from './OrderPatientsModal'

type MedicineTableProps = {
	order: Order
	setPatientsField?: (patients: OrderPatient[]) => void
}

export const MedicineTable: React.FC<MedicineTableProps> = ({
	order,
	setPatientsField,
}) => {
	const [selectedPatient, setSelectedPatient] = useState<
		OrderPatient | undefined
	>(undefined)
	const [selectedMedicine, setSelectedMedicine] = useState<
		OrderMedicine | undefined
	>(undefined)
	const openDisclosure = useDisclosure()

	const removePatient = (id: string, rows: TableOrderPatient[]) => {
		if (order.patients && setPatientsField && id) {
			const patients = Array.from(
				rows.map(tablePatient => tablePatient.original),
			)
			const idx = patients.findIndex(patient => patient.patient?._id === id)
			patients.splice(idx, 1)
			setPatientsField(patients)
		}
	}

	const removeMedicine = (patientId: string, medicineId: string) => {
		if (order.patients && setPatientsField && patientId && medicineId) {
			const patients = Array.from(
				tableInstance.rows.map(row => row.original.original),
			)
			const patientIdx = patients.findIndex(
				patient => patient.patient?._id === patientId,
			)
			if (patientIdx !== -1) {
				const patient = patients[patientIdx]
				if (patient) {
					const medicines = Array.from(patient?.medicines || [])
					const idx = medicines.findIndex(
						medicine => medicine.medicine?._id === medicineId,
					)
					medicines.splice(idx, 1)
					patient.medicines = medicines
					if (patient.medicines.length === 0) {
						removePatient(
							patientId,
							tableInstance.rows.map(row => row.original),
						)
					} else {
						setPatientsField(patients)
					}
				}
			}
		}
	}

	const data = useMemo(
		() => order.patients?.map(patient => new TableOrderPatient(patient)) ?? [],
		[order.patients],
	)

	const columns = useMemo<Column<TableOrderPatient>[]>(
		() => [
			{
				accessor: 'medicines',
				nestedColumns: [
					{
						Header: 'Lek',
						accessor: 'medicineName',
						Cell: (obj: TableOrderMedicine, row) =>
							setPatientsField ? (
								<Link
									onClick={() => {
										setSelectedMedicine(obj.original)
										setSelectedPatient(row.original.original)
										openDisclosure.onOpen()
									}}
								>
									{obj.medicineName}
								</Link>
							) : (
								<>{obj.medicineName}</>
							),
					},
					{
						Header: 'Nazwa Powszechna',
						Cell: (obj: TableOrderMedicine) =>
							obj.original.medicine?.commonName,
					},
					{
						Header: 'Producent',
						Cell: (obj: TableOrderMedicine) =>
							obj.original.medicine?.entityResponsible,
					},
					{
						Header: 'Ilość',
						accessor: 'amount',
					},
					{
						Header: '',
						id: 'remove-medicine',
						Cell: (obj: TableOrderMedicine, row) => (
							<Link
								textAlign="center"
								color="remove.500"
								onClick={() =>
									removeMedicine(
										row.original.original.patient?._id ?? '',
										obj.original.medicine?._id ?? '',
									)
								}
							>
								Usuń
							</Link>
						),
					},
				],
			},
			{
				Header: 'Imię i nazwisko',
				accessor: 'patientName',
				width: 300,
			},
			{
				Header: 'Numer recepty',
				accessor: 'prescriptionNumber',
				Cell: ({ value, row }) =>
					row.original.medicines.some(
						orderMed => orderMed.original.medicine?.prescripted,
					) ? (
						setPatientsField ? (
							<InputField
								name={`patients[${row.index}].prescriptionNumber`}
								placeholder="Numer recepty"
							/>
						) : (
							<>{value || '-'}</>
						)
					) : (
						<>-</>
					),
			},
			{ id: 'padding' },
			{ id: 'padding2' },
			{
				id: 'add',
				Header: '',
				Cell: ({ row, rows }: CellProps<TableOrderPatient>) => (
					<Link
						textAlign="center"
						color="add.500"
						onClick={() => {
							setSelectedPatient(row.original.original)
							openDisclosure.onOpen()
						}}
					>
						Dodaj lek
					</Link>
				),
			},
			{
				Header: '',
				id: 'remove-patient',
				Cell: ({ row, rows }: CellProps<TableOrderPatient>) => (
					<Link
						textAlign="center"
						color="remove.500"
						onClick={() =>
							removePatient(
								row.original.original.patient?._id ?? '',
								rows.map(row => row.original),
							)
						}
					>
						Usuń
					</Link>
				),
			},
		],
		[],
	)

	const selectedPatientIds = useMemo(
		() => order.patients?.map(patient => patient.patient?._id ?? '') ?? [],
		[order.patients, selectedPatient],
	)
	const selectedMedicineIds = useMemo(
		() =>
			selectedPatient
				? selectedPatient.medicines?.map(med => med.medicine?._id ?? '') ?? []
				: [],
		[order.patients, selectedPatient],
	)

	const tableInstance = useTable({
		columns: columns,
		data: data,
		initialState: {
			hiddenColumns: setPatientsField
				? []
				: ['remove-patient', 'remove-medicine', 'add'],
		},
	})
	return (
		<div>
			<OrderPatientModal
				initialMedicine={selectedMedicine?.medicine}
				isOpen={openDisclosure.isOpen}
				alreadySelectedPatientIds={selectedPatientIds}
				alreadySelectedMedicineIds={selectedMedicineIds}
				initialPatient={selectedPatient?.patient}
				orderMedicine={selectedMedicine}
				onClose={({ patient, medicine }) => {
					if (patient && medicine && setPatientsField) {
						const patients = order.patients
							? [
									...tableInstance.rows
										.map(row => row.original)
										.map(row => row.original),
							  ]
							: []
						const patientIdx = patients.findIndex(
							p => p.patient?._id === patient._id,
						)
						if (patientIdx !== -1) {
							let oldPatient = patients[patientIdx]
							if (oldPatient) {
								const medicineIdx =
									oldPatient.medicines?.findIndex(
										med => med.medicine?._id === medicine.medicine?._id,
									) ?? -1
								if (medicineIdx !== -1) {
									let oldMedicine = oldPatient.medicines?.[medicineIdx]
									if (oldMedicine) {
										oldMedicine = medicine
										if (oldPatient.medicines) {
											oldPatient.medicines[medicineIdx] = oldMedicine
										}
									}
								} else {
									oldPatient.medicines?.push(medicine)
								}
							}
						} else {
							patients.push({
								patient,
								medicines: [medicine],
								prescriptionNumber: '',
							})
						}
						setPatientsField(patients)
					}
					setSelectedMedicine(undefined)
					setSelectedPatient(undefined)
					openDisclosure.onClose()
				}}
			/>
			<Table
				hideNumberOfRows
				tableInstance={tableInstance}
				footer={
					setPatientsField ? (
						<Td colSpan={tableInstance.visibleColumns.length}>
							<Center>
								<Button
									size="sm"
									colorScheme="add"
									onClick={() => {
										setSelectedPatient(undefined)
										openDisclosure.onOpen()
									}}
								>
									Dodaj
								</Button>
							</Center>
						</Td>
					) : null
				}
			/>
		</div>
	)
}
