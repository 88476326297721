import { IMedicineGroup, Medicine } from 'models/dictionaries/medicine'
import { resolvePath, Service } from 'services'
import http from 'services/axios'
import { AxiosResponse } from 'axios'

const path = '/medicines'
const objectPath = '/dictionaries'

export class MedicineService extends Service<Medicine>({
	objectPath,
	path,
	objectService: true,
}) {
	static async getFiltered({
		filter,
	}: {
		filter?: string
	}): Promise<AxiosResponse<Medicine[]>> {
		return await http.get(
			resolvePath({
				path,
				objectPath,
				objectService: true,
			}) +
				'?filter=' +
				filter ?? '',
		)
	}
	static async getFilteredGroups({
		filter,
	}: {
		filter?: string
	}): Promise<AxiosResponse<IMedicineGroup[]>> {
		return await http.get(
			resolvePath({
				path,
				objectPath,
				objectService: true,
			}) +
				'?filter=' +
				filter ?? '',
		)
	}
	static async getPaginated({
		filter,
		page,
		rows,
	}: {
		filter?: string
		page: number
		rows?: number
	}): Promise<
		AxiosResponse<{
			data: Medicine[]
			totalPages: number
			pageNo: number
			canNext: boolean
			canPrevious: boolean
		}>
	> {
		return await http.get(
			resolvePath({
				path,
				objectPath,
				objectService: true,
			}) +
				'?filter=' +
				(filter ?? '') +
				'&page=' +
				page +
				'&rows=' +
				(rows ?? '10'),
		)
	}
}
