import { Text, useDisclosure, Box, Flex } from '@chakra-ui/react'
import {
	Dropdown,
	InputField,
	ModalDialog,
	TextAreaField,
	useErrorToast,
} from '@kevea/react-components'
import { Dosage } from 'components/Dosage'
import { Form, Formik, FormikProps } from 'formik'
import { Medicine } from 'models/dictionaries/medicine'
import { NotPreciseDose, Patient, PatientMedicine } from 'models/patient'
import { MedicineModalList } from 'pages/settings/views/dictionaries/medicines/MedicineModalList'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { PatientMedicineService } from 'services/patientMedicine'
import debounce from 'lodash.debounce'

type Props = {
	patient: Patient
	selectedPatientMedicine?: PatientMedicine
	isOpen: boolean
	onClose: () => void
}

export const PatientMedicineModal = (props: Props) => {
	const formik = useRef<FormikProps<PatientMedicine>>(null)
	const [loading, setLoading] = useState(false)
	const [medicine, setMedicine] = useState<Medicine | undefined>(
		props.selectedPatientMedicine?.medicine
			? new Medicine(props.selectedPatientMedicine.medicine)
			: undefined,
	)
	const [medicines, setMedicines] = useState<Medicine[]>([])
	const showAllDisclosure = useDisclosure()
	const [dose, setDose] = useState(
		props.selectedPatientMedicine?.preciseDose ??
			props.selectedPatientMedicine?.dose ??
			new NotPreciseDose(),
	)
	const [isPrecise, setIsPrecise] = useState(
		props.selectedPatientMedicine?.isPrecise ?? false,
	)
	const error = useErrorToast()

	const handleFilterChange = useCallback(
		debounce((filter: string) => {
			if (filter) {
				Medicine.fetchMedicines({ filter }).then(medicines =>
					setMedicines(medicines.map(med => new Medicine(med))),
				)
			} else {
				setMedicines([])
			}
		}, 100),
		[],
	)

	useEffect(() => {
		if (props.isOpen) {
			setMedicine(
				props.selectedPatientMedicine?.medicine
					? new Medicine(props.selectedPatientMedicine.medicine)
					: undefined,
			)
			// refetchMedicines()
		}
	}, [props.isOpen])

	useEffect(() => {
		formik.current?.setFieldValue('medicineId', medicine?._id)
		// refetchMedicines()
	}, [medicine])

	const handleSubmit = (values: PatientMedicine) => {
		if (formik.current) {
			if (medicine) {
				let newPatientMedicine = new PatientMedicine({
					...values,
					patient: props.patient,
					medicine: medicine,
				})
				if (values.endDate) {
					const newDateEnd = new Date(values.endDate)
					newDateEnd.setHours(23, 59, 59, 999)
					newPatientMedicine.endDate = newDateEnd.toISOString()
				}
				if (values.startDate) {
					const newDateStart = new Date(values.startDate)
					newDateStart.setHours(0, 0, 0, 0)
					newPatientMedicine.startDate = newDateStart.toISOString()
				}
				if (isPrecise && Array.isArray(dose)) {
					newPatientMedicine.isPrecise = true
					newPatientMedicine.preciseDose = dose
					delete newPatientMedicine.dose
				} else if (!Array.isArray(dose)) {
					newPatientMedicine.isPrecise = false
					newPatientMedicine.dose = dose
					delete newPatientMedicine.preciseDose
				}

				setLoading(true)
				if (props.selectedPatientMedicine?._id) {
					PatientMedicineService.put(
						newPatientMedicine,
						props.patient._id,
					).then(() => {
						setLoading(false)
						props.onClose()
					})
				} else {
					PatientMedicineService.post(
						newPatientMedicine,
						props.patient._id,
					).then(() => {
						setLoading(false)
						props.onClose()
					})
				}
			} else {
				error({ description: 'Wybierz lek' })
			}
		}
	}

	return (
		<>
			{props.isOpen && (
				<ModalDialog
					isLoading={loading}
					header={
						props.selectedPatientMedicine
							? 'Edytuj lek pacjenta'
							: 'Dodaj lek pacjenta'
					}
					onClose={props.onClose}
					onSave={() => formik.current?.submitForm()}
					isOpen={props.isOpen}
				>
					<MedicineModalList
						isOpen={showAllDisclosure.isOpen}
						onClose={medicine => {
							if (medicine) setMedicine(new Medicine(medicine))
							showAllDisclosure.onClose()
						}}
					/>
					<Formik
						validateOnBlur={false}
						validateOnChange={false}
						validationSchema={PatientMedicine.validationSchema}
						innerRef={formik}
						onSubmit={handleSubmit}
						initialValues={{
							...new PatientMedicine({
								...props.selectedPatientMedicine,
								patient: props.patient,
								dose: props.selectedPatientMedicine?.dose ?? {
									evening: 1,
									morning: 1,
									noon: 2,
								},
							}),
						}}
					>
						<Form>
							<Dropdown
								placeholder="Wybierz lek"
								label="Lek"
								displayAccessor="nameWithDose"
								numberOfResults={6}
								serverSideFilter
								onFilterChange={handleFilterChange}
								items={medicines}
								render={(medicine: Medicine) => (
									<Box>
										<Text fontWeight="semibold" isTruncated>
											{medicine.nameWithDose}
										</Text>
										<Flex justifyContent="space-between">
											<Box>{medicine.commonName}</Box>
											<Box>{medicine.entityResponsible}</Box>
										</Flex>
									</Box>
								)}
								selectedItem={medicine}
								setSelectedItem={setMedicine}
								onShowAllClick={showAllDisclosure.onOpen}
							/>
							<InputField
								name="startDate"
								placeholder="Data rozpoczęcia"
								label="Data rozpoczęcia"
								type="date"
							/>
							<InputField
								name="endDate"
								placeholder="Data zakończenia"
								label="Data zakończenia"
								type="date"
							/>
							<Dosage
								dose={
									props.selectedPatientMedicine?.isPrecise
										? props.selectedPatientMedicine?.preciseDose
										: props.selectedPatientMedicine?.dose ??
										  new NotPreciseDose()
								}
								isPrecise={props.selectedPatientMedicine?.isPrecise ?? false}
								setDose={(dose, isPrecise) => {
									if (formik.current) {
										setDose(dose)
										setIsPrecise(isPrecise)
									}
								}}
							/>

							<TextAreaField
								name="description"
								placeholder="Opis"
								label="Opis"
							/>
						</Form>
					</Formik>
				</ModalDialog>
			)}
		</>
	)
}
