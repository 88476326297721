import { formatDate } from '@kevea/react-components'
import { NoteDictionary } from './dictionaries/note'
import { Patient } from './patient'
import * as yup from 'yup'
import { RhmShiftSettings } from './service'
import { NoteService } from 'services/note'
import { ShiftService } from 'services/shift'
import { Medicine } from './dictionaries/medicine'
import { PatientService } from 'services/patient'

export class Stock {
	constructor(stock: Stock) {
		this._id = stock._id
		this.medicineGroup = stock.medicineGroup
		this.quantity = stock.quantity
	}
	_id?: string
	medicineGroup?: Medicine
	quantity?: number
	static fetchPatientMedicinesStock = async (patient: Patient) => {
		if (patient?._id) {
			return PatientService.getMedicineStock(patient._id)
		}
	}
	static validationSchema = yup.object({})
}

export class TableStock {
	constructor(stock: Stock) {
		this._id = stock._id
		this.name = stock.medicineGroup.name
		this.nameWithDose = Medicine.getNameWithDose(stock.medicineGroup)
		this.medicineGroup = stock.medicineGroup
		this.quantity = stock.quantity + ' dwk.'
	}
	_id?: string
	name?: string
	nameWithDose?: string
	medicineGroup?: Medicine
	quantity?: string
}
