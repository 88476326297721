import { formatDate } from '@kevea/react-components'
import { DiapersOrderService } from 'services/diapersOrder'
import { getColorCSSVariable } from 'utils/chakra'
import * as yup from 'yup'
import { Patient } from './patient'
//statusy: 0- Niepotwierdzone, 1- Wysłane do ośrodka, 2- Potwierdzone, 3- Wysłane do hurtowni, 4- Zrealizowane:Aktywne, 5- Zrealizowane:Zakończone, 6- Anulowane

export type TDiapersOrder = {
	_id: string
	number: string
	orderNumber: number
	orderNumberYear: number
	status: number
	dateOfIssue: string
	finishDate: string
	patient: Patient
	prescriptionNumber: string
}

export class DiapersOrder {
	constructor(order?: DiapersOrder) {
		this._id = order?._id
		this.number = order?.number
		this.orderNumber = order?.orderNumber
		this.orderNumberYear = order?.orderNumberYear
		this.status = order?.status || 0
		this.dateOfIssue = order?.dateOfIssue
			? formatDate(order.dateOfIssue, 'date')
			: formatDate(new Date().toISOString(), 'date')
		this.finishDate = order?.finishDate
			? formatDate(order.finishDate, 'date')
			: ''
		this.prescriptionNumber = order?.prescriptionNumber ?? ''
		this.patient = order?.patient
	}
	_id: string | undefined
	number: string | undefined
	orderNumber: number | undefined
	orderNumberYear: number | undefined
	status: number
	dateOfIssue: string
	finishDate: string
	patient: Patient | undefined
	prescriptionNumber: string

	static checkStatus = (
		allowedStatuses: number | number[],
		diapersOrder: DiapersOrder,
	): boolean => {
		if (Array.isArray(allowedStatuses)) {
			return allowedStatuses.includes(diapersOrder.status)
		} else {
			return allowedStatuses === diapersOrder.status
		}
	}

	static convertStatus = (status: number): string => {
		switch (status) {
			case 0:
				return 'Niepotwierdzone'
			case 1:
				return 'Wysłane do ośrodka'
			case 2:
				return 'Potwierdzone'
			case 3:
				return 'Wysłane do hurtowni'
			case 4:
				return 'Zrealizowane (Aktywne)'
			case 5:
				return 'Zrealizowane (Zakończone)'
			case 6:
				return 'Anulowane'
			default:
				return `${status}`
		}
	}
	static getStatusColor = (status: number): string => {
		switch (status) {
			case 0:
				return 'future'
			case 1:
				return 'former'
			case 2:
				return 'active'
			case 3:
				return 'former'
			case 4:
				return 'active'
			case 5:
				return 'active'
			case 6:
				return 'dead'
			default:
				return `${status}`
		}
	}
	static statusOptions = [
		{
			label: DiapersOrder.convertStatus(0),
			value: 0,
		},
		{
			label: DiapersOrder.convertStatus(1),
			value: 1,
		},
		{
			label: DiapersOrder.convertStatus(2),
			value: 2,
		},
		{
			label: DiapersOrder.convertStatus(3),
			value: 3,
		},
		{
			label: DiapersOrder.convertStatus(4),
			value: 4,
		},
		{
			label: DiapersOrder.convertStatus(5),
			value: 5,
		},
		{
			label: DiapersOrder.convertStatus(6),
			value: 6,
		},
	]
	static validationSchema = yup.object({
		dateOfIssue: yup.date().required('Data wydania jest wymagana'),
		prescriptionNumber: yup.string().when('status', {
			is: 2,
			then: schema => schema.required('Numer recepty jest wymagany'),
		}),
		finishDate: yup
			.date()
			.when('status', {
				is: 2,
				then: schema => schema.required('Data ważności jest wymagana'),
			})
			.min(
				yup.ref('dateOfIssue'),
				'Data ważności nie może być wcześniejsza niż data wystawienia',
			),
	})

	static fetchDiapersOrders = async () => {
		return DiapersOrderService.getAll().then(res => res.data)
	}
}
export class DiapersOrderTable {
	constructor(order: DiapersOrder) {
		this._id = order._id || ''
		this.number = order.number ?? ''
		this.status = DiapersOrder.convertStatus(order.status)
		this.dateOfIssue = formatDate(order.dateOfIssue, 'localeDateString')
		this.finishDate = order.finishDate
			? formatDate(order.finishDate, 'localeDateString')
			: ''
		this.patientName = order.patient ? Patient.getFullName(order.patient) : ''
		this.prescriptionNumber = order.prescriptionNumber ?? ''
		this.original = order
	}
	_id: string
	number: string
	status: string
	dateOfIssue: string
	finishDate: string
	prescriptionNumber: string
	patientName: string
	original: DiapersOrder
	static statusOptions = [
		{
			label: DiapersOrder.convertStatus(0),
			value: DiapersOrder.convertStatus(0),
		},
		{
			label: DiapersOrder.convertStatus(1),
			value: DiapersOrder.convertStatus(1),
		},
		{
			label: DiapersOrder.convertStatus(2),
			value: DiapersOrder.convertStatus(2),
		},
		{
			label: DiapersOrder.convertStatus(3),
			value: DiapersOrder.convertStatus(3),
		},
		{
			label: DiapersOrder.convertStatus(4),
			value: DiapersOrder.convertStatus(4),
		},
		{
			label: DiapersOrder.convertStatus(5),
			value: DiapersOrder.convertStatus(5),
		},
		{
			label: DiapersOrder.convertStatus(6),
			value: DiapersOrder.convertStatus(6),
		},
	]
}
