import { Patient } from './patient'
import * as yup from 'yup'
import { formatDate } from '@kevea/react-components'
import { TaskType } from './dictionaries/taskType'
import { Measurement } from './measurement'
import { TaskService } from 'services/task'
import { ShiftService } from 'services/shift'
import { RhmShiftSettings } from './service'
import { User } from './user'

export class Task {
	constructor(task: Task) {
		this._id = task._id
		this.title = task.title ?? ''
		this.type = task.type ?? null
		this.patient = task.patient
		this.description = task.description ?? ''
		this.date = task.date ?? new Date().toISOString()
		this.time = formatDate(task.date ?? new Date().toISOString(), 'time')
		this.status = task.status ?? 1
		this.assignTo = task.assignTo
		this.completedBy = task.completedBy
		this.completedAt = task.completedAt
		this.taskPlan = task.taskPlan
		this.rawValue =
			task.rawValue ??
			(task.measurement ? Measurement.getRawValue(task.measurement) : '')
		this.value2 = task.value2 ?? task.measurement?.value2
		this.value3 = task?.value3 ?? task?.measurement?.value3
	}

	_id?: string | undefined
	title?: string
	type?: TaskType | null
	taskTypeId?: string | undefined
	patient: Patient
	patientId?: string | undefined
	description?: string
	date?: string
	time?: string
	status?: number // 1-todo 2-done 3-canceled
	assignTo?: User | undefined
	completedBy?: User | undefined
	completedAt?: string | undefined
	taskPlan?: string | undefined
	rawValue?: string
	value2?: number
	value3?: number
	measurement?: Measurement | undefined

	static convertStatus = (status: number): string => {
		switch (status) {
			case 1:
				return 'Do wykonania'
			case 2:
				return 'Wykonane'
			case 3:
				return 'Anulowane'
			default:
				return `${status}`
		}
	}
	static getStatusColor = (status: number): string => {
		switch (status) {
			case 1:
				return 'future'
			case 2:
				return 'active'
			case 3:
				return 'dead'
			default:
				return `${status}`
		}
	}

	static options = [
		{
			label: Task.convertStatus(1),
			value: 1,
		},
		{
			label: Task.convertStatus(2),
			value: 2,
		},
		{
			label: Task.convertStatus(3),
			value: 3,
		},
	]

	static validationSchema = yup.object({
		patient: yup.object().required(),
		title: yup.string().when('taskTypeId', {
			is: (taskTypeId: string | undefined) => taskTypeId === undefined,
			then: yup.string().required('Nazwa jest wymagana'),
		}),
		date: yup.string().required('Data i godzina jest wymagana'),
	})

	static fetchTasks = async (
		date: string,
		patient?: Patient,
		shift?: RhmShiftSettings,
	) => {
		if (patient?._id) {
			return TaskService.getDay(patient._id, date).then(res => res.data)
		} else if (shift) {
			return ShiftService.getTasks(
				`${date}T${shift.startTimeString}:00.000Z`,
			).then(res => res.data)
		}
	}
}
export class TableTask {
	constructor(task: Task) {
		this._id = task._id ?? ''
		this.title = task.type ? task.type.name ?? '' : task.title ?? ''
		this.description = task.description ?? ''
		this.time = task.date ? formatDate(task.date, 'time') : ''
		this.filterDate = task.date ? formatDate(task.date, 'date') : ''
		this.status = task.status ? Task.convertStatus(task.status) : ''
		this.assignTo = task.assignTo ? User.getFullName(task.assignTo) : ''
		this.completedBy = task.completedBy
			? User.getFullName(task.completedBy)
			: ''
		this.completedAt = task.completedAt
			? formatDate(task.completedAt, 'localeString')
			: ''
		this.measurementValue =
			task.type?.requiresMeasurement && task.measurement
				? Measurement.getValue(task.measurement)
				: ''
		this.patient = Patient.getFullName(task.patient)
		this.original = task
	}

	_id: string
	title: string
	description: string
	time: string
	filterDate: string
	status: string
	assignTo: string
	completedBy: string
	completedAt: string
	measurementValue: string
	patient: string
	original: Task

	static options = [
		{
			label: Task.convertStatus(1),
			value: Task.convertStatus(1),
		},
		{
			label: Task.convertStatus(2),
			value: Task.convertStatus(2),
		},
		{
			label: Task.convertStatus(3),
			value: Task.convertStatus(3),
		},
	]
}
