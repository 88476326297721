import { Button, Center, Td, useDisclosure } from '@chakra-ui/react'
import { InputField, Link, Table } from '@kevea/react-components'
import { PatientMedicinePicker } from 'components/PatientMedicinePicker'
import { IMedicineGroup } from 'models/dictionaries/medicine'
import { Patient } from 'models/patient'
import {
	IWarehouseDocumentEntry,
	TableWarehouseEntry,
	WarehouseDocument,
} from 'models/warehouseDocument'
import React, { useMemo, useState } from 'react'
import { CellProps, Column, useTable } from 'react-table'

type MedicineTableProps = {
	docEntries: IWarehouseDocumentEntry[]
	setEntries?: (entries: IWarehouseDocumentEntry[]) => void
}

export const MedicineTable: React.FC<MedicineTableProps> = ({
	docEntries,
	setEntries,
}) => {
	const [selectedPatient, setSelectedPatient] = useState<Patient | undefined>(
		undefined,
	)
	const [selectedMedicineGroup, setSelectedMedicineGroup] = useState<
		IMedicineGroup | undefined
	>(undefined)
	const openDisclosure = useDisclosure()

	const removePatient = (id: string) => {
		if (docEntries && setEntries && id) {
			const entries = Array.from(docEntries)
			setEntries(entries.filter(e => e.patient._id !== id))
		}
	}

	const removeEntry = (patientId: string, medicineGroupId: string) => {
		if (docEntries && setEntries && patientId && medicineGroupId) {
			const entries = Array.from(docEntries)
			const entryIdx = entries.findIndex(
				e =>
					e.patient._id === patientId &&
					e.medicineGroup._id === medicineGroupId,
			)
			if (entryIdx !== -1) {
				entries.splice(entryIdx, 1)
				setEntries(entries)
			}
		}
	}

	const data = useMemo<
		{
			patient: IWarehouseDocumentEntry['patient']
			entries: TableWarehouseEntry[]
		}[]
	>(() => {
		const usedPatientIds = new Set<string>()
		const patientsWithEntries: {
			patient: IWarehouseDocumentEntry['patient']
			entries: TableWarehouseEntry[]
		}[] = []
		docEntries.forEach(e => {
			if (!usedPatientIds.has(e.patient._id)) {
				usedPatientIds.add(e.patient._id)
				patientsWithEntries.push({
					patient: e.patient,
					entries: docEntries
						.filter(x => x.patient._id === e.patient._id)
						.map(x => new TableWarehouseEntry(x)),
				})
			}
		})

		return patientsWithEntries
	}, [docEntries])

	const columns = useMemo<
		Column<{
			patient: IWarehouseDocumentEntry['patient']
			entries: TableWarehouseEntry[]
		}>[]
	>(
		() => [
			{
				accessor: 'entries',
				nestedColumns: [
					{
						Header: 'Lek',
						accessor: 'entries',
						Cell: (entry: TableWarehouseEntry, row) =>
							setEntries ? (
								<Link
									onClick={() => {
										setSelectedMedicineGroup(entry.original.medicineGroup)
										setSelectedPatient(entry.original.patient)
										openDisclosure.onOpen()
									}}
								>
									{entry.medicineNameWithDose}
								</Link>
							) : (
								<>{entry.medicineNameWithDose}</>
							),
					},
					{
						Header: 'Nazwa Powszechna',
						Cell: (entry: TableWarehouseEntry) =>
							entry.original.medicineGroup.commonName,
					},
					{
						Header: 'Ilość',
						accessor: 'quantity',
					},
					{
						Header: '',
						id: 'remove-medicine',
						Cell: (entry: TableWarehouseEntry, row) => (
							<Link
								textAlign="center"
								color="remove.500"
								onClick={() =>
									removeEntry(
										entry.original.patient._id,
										entry.original.medicineGroup._id,
									)
								}
							>
								Usuń
							</Link>
						),
					},
				],
			},
			{
				Header: 'Imię i nazwisko',
				id: 'patientName',
				accessor: 'patient',
				Cell: ({ value }) => Patient.getFullName(value),
				width: 300,
			},
			{ id: 'padding2' },
			{
				id: 'add',
				accessor: 'patient',
				Header: '',
				Cell: ({ row }) => (
					<Link
						textAlign="center"
						color="add.500"
						onClick={() => {
							setSelectedPatient(row.original.patient)
							openDisclosure.onOpen()
						}}
					>
						Dodaj lek
					</Link>
				),
			},
			{
				Header: '',
				accessor: 'patient',
				id: 'remove-patient',
				Cell: ({ row }) => (
					<Link
						textAlign="center"
						color="remove.500"
						onClick={() => removePatient(row.original.patient?._id)}
					>
						Usuń
					</Link>
				),
			},
		],
		[docEntries],
	)

	const tableInstance = useTable({
		columns: columns,
		data: data,
		initialState: {
			hiddenColumns: setEntries
				? []
				: ['remove-patient', 'remove-medicine', 'add'],
		},
	})
	return (
		<div>
			<PatientMedicinePicker
				initialValues={{
					patient: selectedPatient,
					medicineGroup: selectedMedicineGroup,
				}}
				isOpen={openDisclosure.isOpen}
				onClose={({ patient, medicineGroup, quantity }) => {
					if (patient && medicineGroup && setEntries && quantity) {
						const entries = Array.from(docEntries)
						if (selectedMedicineGroup && selectedPatient) {
							let entryIdx = entries.findIndex(
								e =>
									e.patient._id === selectedPatient._id &&
									e.medicineGroup._id === selectedMedicineGroup._id,
							)
							entries[entryIdx].quantity = quantity
						} else {
							const indexOfEntryAlreadyInPatient = entries.findIndex(
								e =>
									e.patient._id === patient._id &&
									e.medicineGroup._id === medicineGroup._id,
							)
							if (indexOfEntryAlreadyInPatient !== -1) {
								entries[indexOfEntryAlreadyInPatient].medicineGroup =
									medicineGroup
								entries[indexOfEntryAlreadyInPatient].quantity =
									entries[indexOfEntryAlreadyInPatient].quantity + quantity
							} else {
								entries.push({
									medicineGroup,
									patient,
									quantity,
									medicineStockGroup: medicineGroup.stockGroup,
								})
							}
						}
						setEntries(entries)
					}
					setSelectedMedicineGroup(null)
					setSelectedPatient(null)
					openDisclosure.onClose()
				}}
			/>
			<Table
				hideNumberOfRows
				tableInstance={tableInstance}
				footer={
					setEntries ? (
						<Td colSpan={tableInstance.visibleColumns.length}>
							<Center>
								<Button
									size="sm"
									colorScheme="add"
									onClick={() => {
										setSelectedPatient(null)
										openDisclosure.onOpen()
									}}
								>
									Dodaj
								</Button>
							</Center>
						</Td>
					) : null
				}
			/>
		</div>
	)
}
