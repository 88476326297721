import { formatDate } from '@kevea/react-components'
import { Notes } from 'pages/notes/Notes'
import React, { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { PatientContext } from '../Patient'

type Props = {}

export const PatientNotes = (props: Props) => {
	const { patient, breadcrumbs } = useOutletContext<PatientContext>()

	const [selectedDate, setSelectedDate] = useState(
		formatDate(new Date().toISOString(), 'date'),
	)

	return (
		<Notes
			selectedDate={selectedDate}
			setSelectedDate={setSelectedDate}
			patient={patient}
			breadcrumbs={breadcrumbs}
		/>
	)
}
