import { Attachments } from 'pages/Attachments'
import { useOutletContext } from 'react-router-dom'
import { ContractContext } from '../Contract'

export const AttachmentsWrapper = () => {
	const { breadcrumbs, contract } = useOutletContext<ContractContext>()

	return (
		<Attachments
			attachmentType="contracts"
			breadcrumbs={breadcrumbs}
			header={`${contract?.number} - Lista załączników`}
		/>
	)
}
