import { Text, useDisclosure } from '@chakra-ui/react'
import {
	Dropdown,
	InputField,
	ModalDialog,
	NumberField,
	SelectField,
} from '@kevea/react-components'
import { Form, Formik, FormikProps } from 'formik'
import { TaskType } from 'models/dictionaries/taskType'
import { Patient } from 'models/patient'
import { TaskPlan } from 'models/taskPlan'
import { TaskTypeModal } from 'pages/settings/views/dictionaries/tasks/TaskTypeModal'
import { TaskTypeModalList } from 'pages/settings/views/dictionaries/tasks/TaskTypeModalList'
import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { TaskPlanService } from 'services/taskPlan'

type Props = {
	patient: Patient
	selectedTaskPlan?: TaskPlan
	isOpen: boolean
	onClose: () => void
}

export const TaskPlanModal = (props: Props) => {
	const formik = useRef<FormikProps<TaskPlan>>(null)
	const [loading, setLoading] = useState(false)
	const [type, setType] = useState<TaskType | null>(null)
	const addDisclosure = useDisclosure()
	const showAllDisclosure = useDisclosure()

	const { data: taskTypes, refetch: refetchTaskTypes } = useQuery(
		'fetchTaskTypes',
		TaskType.fetchTaskTypes,
	)

	useEffect(() => {
		if (props.isOpen) {
			setType(props.selectedTaskPlan?.type ?? null)
			refetchTaskTypes()
		}
	}, [props.isOpen])

	useEffect(() => {
		formik.current?.setFieldValue('taskTypeId', type?._id)
		refetchTaskTypes()
	}, [type])

	const handleSubmit = (values: TaskPlan) => {
		if (formik.current) {
			const date = new Date(`${values.startDate}T${values.time}`).toISOString()
			let newTaskPlan = new TaskPlan({
				...values,
				type,
				date,
			})
			setLoading(true)
			if (props.selectedTaskPlan?._id) {
				TaskPlanService.put(newTaskPlan, props.patient._id).then(() => {
					setLoading(false)
					props.onClose()
				})
			} else {
				TaskPlanService.post(newTaskPlan, props.patient._id).then(() => {
					setLoading(false)
					props.onClose()
				})
			}
		}
	}

	return (
		<>
			{props.isOpen && (
				<ModalDialog
					isLoading={loading}
					header={
						props.selectedTaskPlan
							? 'Edytuj harmonogram zadania'
							: 'Dodaj harmonogram zadania'
					}
					onClose={props.onClose}
					onSave={() => formik.current?.submitForm()}
					isOpen={props.isOpen}
				>
					<Formik
						validateOnBlur={false}
						validateOnChange={false}
						validationSchema={TaskPlan.validationSchema}
						innerRef={formik}
						onSubmit={handleSubmit}
						initialValues={{
							...new TaskPlan({
								...props.selectedTaskPlan,
								patient: props.patient,
							}),
						}}
					>
						<Form>
							{taskTypes && (
								<Dropdown
									placeholder="Inna"
									label="Rodzaj zadania"
									displayAccessor="name"
									numberOfResults={5}
									items={taskTypes}
									render={(taskType: TaskType) => (
										<>
											<Text>{taskType.name}</Text>
											<Text fontSize="sm" color="gray.500">
												{taskType.description}
											</Text>
										</>
									)}
									selectedItem={type}
									setSelectedItem={setType}
									onAddNewClick={addDisclosure.onOpen}
									onShowAllClick={showAllDisclosure.onOpen}
								/>
							)}
							<TaskTypeModal
								isOpen={addDisclosure.isOpen}
								onClose={taskType => {
									if (taskType) setType(taskType)
									addDisclosure.onClose()
								}}
								selectedTaskType={undefined}
							/>
							<TaskTypeModalList
								isOpen={showAllDisclosure.isOpen}
								onClose={taskType => {
									if (taskType) setType(taskType)
									showAllDisclosure.onClose()
								}}
							/>
							{!type && (
								<InputField
									isRequired
									name="title"
									placeholder="Nazwa"
									label="Nazwa"
								/>
							)}
							<InputField
								isRequired
								name="startDate"
								placeholder="Data rozpoczęcia"
								label="Data rozpoczęcia"
								type="date"
							/>
							<InputField
								isRequired
								name="time"
								placeholder="Godzina wykonania"
								label="Godzina wykonania"
								type="time"
							/>
							<NumberField
								isRequired
								name="recuringEvery"
								placeholder="Powtarzaj co (ilość dni)"
								label="Powtarzaj co (ilość dni)"
								min={1}
							/>
							<InputField
								name="endDate"
								placeholder="Data zakończenia"
								label="Data zakończenia"
								type="date"
							/>
						</Form>
					</Formik>
				</ModalDialog>
			)}
		</>
	)
}
