import {
	InputField,
	ModalDialog,
	SelectField,
	SwitchField,
	TextAreaField,
} from '@kevea/react-components'
import { Form, Formik, FormikProps } from 'formik'
import { TaskType } from 'models/dictionaries/taskType'
import { Measurement } from 'models/measurement'
import { useRef, useState } from 'react'
import { TaskTypeService } from 'services/dictionaries/task'

type Props = {
	selectedTaskType: TaskType | undefined
	isOpen: boolean
	onClose: (taskType?: TaskType) => void
}

export const TaskTypeModal = (props: Props) => {
	const formik = useRef<FormikProps<TaskType>>(null)
	const [loading, setLoading] = useState(false)

	const handleSubmit = (values: TaskType) => {
		if (formik.current) {
			const newTaskType = { ...props.selectedTaskType, ...values }
			setLoading(true)
			if (props.selectedTaskType?._id) {
				TaskTypeService.put(newTaskType)
					.then(res => res.data)
					.then(taskType => {
						setLoading(false)
						props.onClose(taskType)
					})
			} else {
				TaskTypeService.post(newTaskType)
					.then(res => res.data)
					.then(taskType => {
						setLoading(false)
						props.onClose(taskType)
					})
			}
		}
	}

	return (
		<>
			{props.isOpen && (
				<ModalDialog
					isLoading={loading}
					header={
						props.selectedTaskType
							? 'Edytuj rodzaj zadania'
							: 'Dodaj rodzaj zadania'
					}
					onClose={() => props.onClose()}
					onSave={() => formik.current?.submitForm()}
					isOpen={props.isOpen}
				>
					<Formik
						validateOnBlur={false}
						validateOnChange={false}
						validationSchema={TaskType.validationSchema}
						innerRef={formik}
						onSubmit={handleSubmit}
						initialValues={
							props.selectedTaskType
								? new TaskType(props.selectedTaskType)
								: new TaskType()
						}
					>
						<Form>
							<InputField
								name="name"
								placeholder="Nazwa"
								label="Nazwa"
								isRequired
							/>
							<SwitchField name="requiresNurse" label="Wymaga pielęgniarki" />
							<SelectField
								label="Wymaga pomiaru"
								name="requiresMeasurement"
								options={[
									{ label: 'Nie', value: null },
									{ label: Measurement.convertType(1), value: 1 },
									{ label: Measurement.convertType(2), value: 2 },
									{ label: Measurement.convertType(3), value: 3 },
									{ label: Measurement.convertType(4), value: 4 },
									{ label: Measurement.convertType(5), value: 5 },
									{ label: Measurement.convertType(6), value: 6 },
									{ label: Measurement.convertType(7), value: 7 },
									{ label: Measurement.convertType(8), value: 8 },
									{ label: Measurement.convertType(9), value: 9 },
									{ label: Measurement.convertType(10), value: 10 },
									{ label: Measurement.convertType(11), value: 11 },
								]}
							/>
							<SelectField
								label="Podanie leku"
								name="dispachMedicine"
								options={[
									{ label: 'Nie', value: null },
									{ label: 'Rano', value: 1 },
									{ label: 'Południe', value: 2 },
									{ label: 'Wieczór', value: 3 },
								]}
							/>
							<TextAreaField
								name="description"
								placeholder="Opis"
								label="Opis"
							/>
						</Form>
					</Formik>
				</ModalDialog>
			)}
		</>
	)
}
