import { AxiosResponse } from 'axios'
import { Bill } from 'models/bill'
import {
	getBaseInstancePath,
	printConfig,
	resolvePath,
	Service,
} from 'services'
import http from './axios'

const path = '/bills'
const objectPath = '/patients'

export class PatientBillService extends Service<Bill>({
	objectPath,
	path,
	objectService: true,
}) {
	static async getAllWithParams(objId: string, { year }: { year: number }) {
		return http.get<Bill[]>(
			resolvePath({
				path: path + '?year=' + year,
				objectService: true,
				objectPath,
				objId,
			}),
		)
	}
}
export class BillService extends Service<Bill>({
	path,
}) {
	static async getAllWithParams({
		month,
		year,
	}: {
		month: number
		year: number
	}) {
		return http.get<Bill[]>(
			resolvePath({
				path: path + '?year=' + year + '&month=' + month,
			}),
		)
	}
	static async changeStatus(bill: Bill, status: Bill['status']) {
		return http.put<Bill>(
			resolvePath({
				path: path + `/${bill._id}`,
			}),
			{ ...bill, status },
		)
	}
	static async print(id: string): Promise<Blob> {
		return http
			.get<Blob>(`${getBaseInstancePath(path)}/${id}/print`, printConfig)
			.then(res => res.data)
	}
}
