import { DiapersOrder } from 'models/diapersOrder'
import { AlertDialog, MenuItemProps } from '@kevea/react-components'
import React, { ReactNode, useState } from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { Patient } from 'models/patient'
import { DiapersOrderService } from 'services/diapersOrder'
import { DiapersOrderModal } from './DiapersOrderModal'

type Props = {
	diapersOrder: DiapersOrder | undefined
	refetch: VoidFunction
	children: ReactNode
	setSelectedRows?: (val: boolean) => void
}

type DiapersOrderStatusChangerContextType = {
	menuOptions: MenuItemProps[]
	isLoading: boolean
}

export const DiapersOrderStatusChangerContext =
	React.createContext<DiapersOrderStatusChangerContextType>({
		menuOptions: [],
		isLoading: false,
	})

export const DiapersOrderStatusChanger = ({
	refetch,
	diapersOrder,
	children,
	setSelectedRows,
}: Props) => {
	const [isLoading, setisLoading] = useState(false)
	const removeDisclosure = useDisclosure()
	const cancelDisclosure = useDisclosure()
	const confirmDisclosure = useDisclosure()

	const changeStatus = (status: number) => {
		if (diapersOrder) {
			switch (status) {
				case 1:
					return sendOrderToCenter(diapersOrder)
				case 2:
					return confirmDisclosure.onOpen()
				case 3:
					return sendOrderToWholesaler(diapersOrder)
				case 4:
					return markCompleted(diapersOrder)
				case 6:
					return cancelDisclosure.onOpen()
				case -1:
					return removeDisclosure.onOpen()
			}
		}
	}

	const endAction = () => {
		setisLoading(false)
		refetch()
		setSelectedRows?.(false)
	}

	const sendOrderToCenter = (diapersOrder: DiapersOrder) => {
		setisLoading(true)
		DiapersOrderService.changeStatus(diapersOrder, 1).then(() => {
			endAction()
		})
	}
	const confirmOrder = (diapersOrder?: DiapersOrder) => {
		if (diapersOrder) {
			setisLoading(true)
			DiapersOrderService.changeStatus(diapersOrder, 2).then(() => {
				confirmDisclosure.onClose()
				endAction()
			})
		} else {
			confirmDisclosure.onClose()
			endAction()
		}
	}
	const sendOrderToWholesaler = (diapersOrder: DiapersOrder) => {
		setisLoading(true)
		DiapersOrderService.changeStatus(diapersOrder, 3).then(() => {
			endAction()
		})
	}
	const markCompleted = (diapersOrder: DiapersOrder) => {
		setisLoading(true)
		DiapersOrderService.changeStatus(diapersOrder, 4).then(() => {
			endAction()
		})
	}
	const cancelOrder = (diapersOrder: DiapersOrder) => {
		setisLoading(true)
		DiapersOrderService.changeStatus(diapersOrder, 6).then(() => {
			cancelDisclosure.onClose()
			endAction()
		})
	}
	const removeOrder = (diapersOrder: DiapersOrder) => {
		if (diapersOrder._id) {
			setisLoading(true)
			DiapersOrderService.delete(diapersOrder._id).then(() => {
				removeDisclosure.onClose()
				endAction()
			})
		} else {
			removeDisclosure.onClose()
		}
	}

	return (
		<DiapersOrderStatusChangerContext.Provider
			value={{
				isLoading,
				menuOptions: [
					{
						label: 'Wyślij do ośrodka',
						onClick: () => {
							changeStatus(1)
						},
						disabled: diapersOrder
							? !DiapersOrder.checkStatus(0, diapersOrder)
							: true,
					},
					{
						label: 'Potwierdź',
						onClick: () => {
							changeStatus(2)
						},
						disabled: diapersOrder
							? !DiapersOrder.checkStatus(1, diapersOrder)
							: true,
					},
					{
						label: 'Wyślij do hurtowni',
						onClick: () => {
							changeStatus(3)
						},
						disabled: diapersOrder
							? !DiapersOrder.checkStatus(2, diapersOrder)
							: true,
					},
					{
						label: 'Oznacz jako zrealizowane',
						onClick: () => {
							changeStatus(4)
						},
						disabled: diapersOrder
							? !DiapersOrder.checkStatus(3, diapersOrder)
							: true,
					},
					{
						label: 'Anuluj',
						onClick: () => {
							changeStatus(6)
						},
						disabled: diapersOrder
							? !DiapersOrder.checkStatus([1, 2], diapersOrder)
							: true,
					},
					{
						label: 'Usuń',
						onClick: () => {
							changeStatus(-1)
						},
						disabled: diapersOrder
							? !DiapersOrder.checkStatus(0, diapersOrder)
							: true,
					},
				],
			}}
		>
			{diapersOrder && (
				<>
					<AlertDialog
						isRemoving={isLoading}
						isOpen={cancelDisclosure.isOpen}
						onClose={() => {
							cancelDisclosure.onClose()
							refetch()
						}}
						header="Anuluj zlecenie"
						onRemove={() => cancelOrder(diapersOrder)}
						confirmButtonLabel="Anuluj zlecenie"
					>
						Czy na pewno chcesz anulować zlecenie{' '}
						<strong>{diapersOrder.number}</strong> dla pacjenta{' '}
						<strong>
							{diapersOrder.patient
								? Patient.getFullName(diapersOrder.patient)
								: ''}
						</strong>
						?
					</AlertDialog>
					<AlertDialog
						isRemoving={isLoading}
						isOpen={removeDisclosure.isOpen}
						onClose={removeDisclosure.onClose}
						header="Usuń zlecenie"
						onRemove={() => removeOrder(diapersOrder)}
					>
						Czy na pewno chcesz usunąć zlecenie{' '}
						<strong>{diapersOrder.number}</strong> dla pacjenta{' '}
						<strong>
							{diapersOrder.patient
								? Patient.getFullName(diapersOrder.patient)
								: ''}
						</strong>
						?
					</AlertDialog>
					<DiapersOrderModal
						isLoading={isLoading}
						isOpen={confirmDisclosure.isOpen}
						onClose={order => {
							confirmOrder(order)
						}}
						confirm
						diapersOrder={diapersOrder}
					/>
				</>
			)}
			{children}
		</DiapersOrderStatusChangerContext.Provider>
	)
}
