import { AxiosResponse } from 'axios'
import { MenuDay } from 'models/menu'
import { getBaseInstancePath, printConfig } from 'services'
import http from './axios'

const path = '/menu'

export class MenuService {
	static async getRange(
		startDate: string,
		endDate: string,
	): Promise<AxiosResponse<MenuDay[]>> {
		return http.get(
			`${getBaseInstancePath(path)}/?fromDate=${startDate}&toDate=${endDate}`,
		)
	}
	static async updateDay(day: MenuDay): Promise<AxiosResponse<MenuDay>> {
		return http.put(`${getBaseInstancePath(path)}/${day.date}`, day)
	}
	static async copyFromRange(
		startDate: string,
		endDate: string,
	): Promise<AxiosResponse<MenuDay[]>> {
		const menuDays = await MenuService.getRange(startDate, endDate)
		return http.put(`${getBaseInstancePath(path)}/`, menuDays)
	}
	static async updateRange(menuDays: MenuDay[]) {
		return http.put(`${getBaseInstancePath(path)}/`, menuDays)
	}
	static async print(startDate: string, endDate: string): Promise<Blob> {
		return http
			.get<Blob>(
				`${getBaseInstancePath(
					path,
				)}/print?fromDate=${startDate}&toDate=${endDate}`,
				printConfig,
			)
			.then(res => res.data)
	}
	static async printAllergens(): Promise<Blob> {
		return http
			.get<Blob>(`${getBaseInstancePath(path)}/printAllergens`, printConfig)
			.then(res => res.data)
	}
}
