import { formatDate } from '@kevea/react-components'
import { Patient } from './patient'
import * as yup from 'yup'
import { BillService } from 'services/bill'

export type TBill = {
	_id: string
	number: string
	status: string
	patient: Patient
	amount: number
	paymentDate: string
	dateOfIssue: string
	billNumberMonth: number
	billNumberYear: number
}

export class BillEntry {
	constructor(
		entry?: Partial<BillEntry> & { no: number; type: BillEntry['type'] },
	) {
		this.no = entry?.no
		this.name = entry?.name ?? ''
		this.price = entry?.price ?? 0
		this.quantity = entry?.quantity ?? 1
		this.amount = entry?.amount ?? 0
		this.invoiceNumber = entry?.invoiceNumber ?? ''
		this.attachmentId = entry?.attachmentId
		this.fileToUpload = entry?.fileToUpload
		this.type = entry?.type
	}
	no: number
	name: string
	price: number
	quantity: number
	amount: number
	invoiceNumber: string
	attachmentId: string | undefined
	fileToUpload?: File
	type: 'contract' | 'invoice' | 'other'

	static entryTypeOptions: { label: string; value: BillEntry['type'] }[] = [
		{ label: 'Inne', value: 'other' },
		{ label: 'Faktura', value: 'invoice' },
	]

	static validationSchema = yup.object({
		name: yup.string().required('Nazwa jest wymagana'),
		quantity: yup
			.number()
			.required('Ilość jest wymagana')
			.min(1, 'Nieprawidłowa ilość'),
		price: yup
			.number()
			.required('Ilość jest wymagana')
			.min(0, 'Nieprawidłowa cena'),
		invoiceNumber: yup.string().when('type', {
			is: 'invoice',
			then: yup.string().required('Numer faktury jest wymagany'),
		}),
	})
}

export class Bill {
	constructor(bill?: Partial<Bill>) {
		this._id = bill?._id
		this.number = bill?.number
		this.status = bill?.status ?? 'new'
		this.patient = bill?.patient
		this.billNumberMonth = bill?.billNumberMonth ?? new Date().getMonth()
		this.billNumberYear = bill?.billNumberYear ?? new Date().getFullYear()
		this.amount = bill?.amount
		this.dateOfIssue = bill?.dateOfIssue
			? formatDate(new Date(bill.dateOfIssue).toISOString(), 'date')
			: formatDate(new Date().toISOString(), 'date')
		this.paymentDate = bill?.paymentDate
			? formatDate(new Date(bill.paymentDate).toISOString(), 'date')
			: formatDate(
					new Date(
						new Date().setMonth(new Date(this.dateOfIssue).getMonth() + 1),
					).toISOString(),
					'date',
			  )

		this.entries = bill?.entries ?? []
	}
	_id?: string
	number?: string
	patient: Patient
	status?: 'virtual' | 'new' | 'confirmed' | 'paid' | 'canceled'
	amount: number
	dateOfIssue: string
	paymentDate: string
	billNumberMonth: number
	billNumberYear: number
	entries: BillEntry[]

	static validationSchema = yup.object({
		dateOfIssue: yup.date().required('Data wystawienia jest wymagana'),
		billNumberMonth: yup
			.number()
			.required('Miesiąc rozliczeniowy jest wymagany'),
		billNumberYear: yup.number().required('Rok rozliczeniowy jest wymagany'),
		validUntil: yup
			.date()
			.min(
				yup.ref('dateOfIssue'),
				'Data płatnoście nie może być wcześniejsza niż data wystawienia',
			),
		patient: yup.mixed().required('Pacjent jest wymagany'),
	})
	static fetchBills = async (patientId: string) => {
		return BillService.getAll(patientId).then(res => res.data)
	}

	static convertStatus = (status: Bill['status']): string => {
		switch (status) {
			case 'virtual':
				return 'Niepotwierdzony'
			case 'new':
				return 'Nowy'
			case 'confirmed':
				return 'Potwierdzony'
			case 'paid':
				return 'Opłacony'
			case 'canceled':
				return 'Anulowany'
			default:
				return `${status}`
		}
	}
	static convertMonth = (monthNo: Bill['billNumberMonth']): string => {
		switch (monthNo) {
			case 0:
				return 'Styczeń'
			case 1:
				return 'Luty'
			case 2:
				return 'Marzec'
			case 3:
				return 'Kwiecień'
			case 4:
				return 'Maj'
			case 5:
				return 'Czerwiec'
			case 6:
				return 'Lipiec'
			case 7:
				return 'Sierpień'
			case 8:
				return 'Wrzesień'
			case 9:
				return 'Październik'
			case 10:
				return 'Listopad'
			case 11:
				return 'Grudzień'
			default:
				return `${monthNo}`
		}
	}

	static getStatusColor = (status: Bill['status']): string => {
		switch (status) {
			case 'virtual':
				return 'gray'
			case 'new':
				return 'future'
			case 'confirmed':
				return 'teal'
			case 'paid':
				return 'active'
			case 'canceled':
				return 'dead'
			default:
				return `${status}`
		}
	}
	static statusOptions: { label: string; value: Bill['status'] }[] = [
		{
			label: Bill.convertStatus('virtual'),
			value: 'virtual',
		},
		{
			label: Bill.convertStatus('new'),
			value: 'new',
		},
		{
			label: Bill.convertStatus('confirmed'),
			value: 'confirmed',
		},
		{
			label: Bill.convertStatus('paid'),
			value: 'paid',
		},
		{
			label: Bill.convertStatus('canceled'),
			value: 'canceled',
		},
	]
}

export class TableBill {
	constructor(bill: Bill) {
		this._id = bill._id
		this.original = bill
		this.number = bill.number ?? 'R-X/20XX'
		this.patientName = bill.patient ? Patient.getFullName(bill.patient) : ''
		this.status = Bill.convertStatus(bill.status)
		this.amount = bill.amount ? `${bill.amount.toFixed(2)} zł` : '-'
		this.month = Bill.convertMonth(bill.billNumberMonth)
		this.dateOfIssue = bill.dateOfIssue
			? formatDate(new Date(bill.dateOfIssue).toISOString(), 'date')
			: ''
	}
	_id: string | undefined
	number: string
	patientName: string
	status: string
	month: string
	amount: string
	dateOfIssue: string
	original: Bill

	static statusOptions = [
		{
			label: Bill.convertStatus('virtual'),
			value: Bill.convertStatus('virtual'),
		},
		{
			label: Bill.convertStatus('new'),
			value: Bill.convertStatus('new'),
		},
		{
			label: Bill.convertStatus('confirmed'),
			value: Bill.convertStatus('confirmed'),
		},
		{
			label: Bill.convertStatus('paid'),
			value: Bill.convertStatus('paid'),
		},
		{
			label: Bill.convertStatus('canceled'),
			value: Bill.convertStatus('canceled'),
		},
	]
}
