import { Order, TOrder } from 'models/order'
import {
	AlertDialog,
	MenuItemProps,
	useErrorToast,
} from '@kevea/react-components'
import React, { ReactNode, useState } from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { OrderService } from 'services/order'
import { useNavigate } from 'react-router'
import axios from 'services/axios'
import { getBaseInstancePath } from 'services'

type Props = {
	order: Order | undefined
	refetch: VoidFunction
	children: ReactNode
	setSelectedRows?: (val: boolean) => void
}

type OrderStatusChangerContextType = {
	menuOptions: MenuItemProps[]
	isLoading: boolean
}

export const OrderStatusChangerContext =
	React.createContext<OrderStatusChangerContextType>({
		menuOptions: [],
		isLoading: false,
	})

export const OrderStatusChanger = ({
	refetch,
	order,
	children,
	setSelectedRows,
}: Props) => {
	const [isLoading, setisLoading] = useState(false)
	const removeDisclosure = useDisclosure()
	const cancelDisclosure = useDisclosure()
	const error = useErrorToast()

	const navigate = useNavigate()

	const changeStatus = (status: TOrder['status'] | 'remove') => {
		if (order) {
			switch (status) {
				case 'toConfirm':
					return orderToConfirm(order)
				case 'canceled':
					return cancelDisclosure.onOpen()
				case 'completed':
					return markCompleted(order)
				case 'confirmed':
					return confirmOrder(order)
				case 'sendToFacility':
					return sendOrderToFacility(order)
				case 'sendToSupplier':
					return sendOrderToSupplier(order)
				case 'remove':
					return removeDisclosure.onOpen()
			}
		}
	}

	const endAction = () => {
		setisLoading(false)
		refetch()
		setSelectedRows?.(false)
	}

	const sendOrderToFacility = (order: Order) => {
		setisLoading(true)
		OrderService.changeStatus(order, 'sendToFacility').then(() => {
			endAction()
		})
	}
	const orderToConfirm = (order: Order) => {
		setisLoading(true)
		OrderService.changeStatus(order, 'toConfirm').then(() => {
			endAction()
		})
	}
	const confirmOrder = (order: Order) => {
		setisLoading(true)
		OrderService.changeStatus(order, 'confirmed').then(() => {
			endAction()
		})
	}
	const sendOrderToSupplier = (order: Order) => {
		setisLoading(true)
		if (
			order.patients
				?.map(patient =>
					patient.medicines?.some(
						medicine =>
							medicine.medicine?.prescripted && !patient.prescriptionNumber,
					),
				)
				.some(Boolean)
		) {
			error({ description: 'Wprowadź wszystkie wymagane numery recept' })
			setisLoading(false)
		} else {
			OrderService.changeStatus(order, 'sendToSupplier').then(() => {
				endAction()
			})
		}
	}
	const markCompleted = (order: Order) => {
		setisLoading(true)
		OrderService.changeStatus(order, 'completed').then(() => {
			endAction()
		})
	}
	const cancelOrder = (order: Order) => {
		setisLoading(true)
		OrderService.changeStatus(order, 'canceled').then(() => {
			cancelDisclosure.onClose()
			endAction()
		})
	}
	const removeOrder = (order: Order) => {
		if (order._id) {
			setisLoading(true)
			OrderService.delete(order._id).then(() => {
				removeDisclosure.onClose()
				navigate('/orders')
			})
		} else {
			removeDisclosure.onClose()
		}
	}

	return (
		<OrderStatusChangerContext.Provider
			value={{
				isLoading,
				menuOptions:
					order?.type === 'medicine'
						? [
								{
									label: Order.convertStatus('confirmed'),
									onClick: () => {
										changeStatus('confirmed')
									},
									disabled: order.status === 'confirmed',
								},
								{
									label: 'Generuj PZ',
									onClick: () => {
										axios.post(
											getBaseInstancePath(`/orders/${order._id}/generateGR`),
										)
									},
									disabled:
										order.status !== 'completed' ||
										order.warehouseDocument !== undefined,
								},
								{
									label: Order.convertStatus('sendToFacility'),
									onClick: () => {
										changeStatus('sendToFacility')
									},
									disabled: order.status === 'sendToFacility',
								},
								{
									label: Order.convertStatus('sendToSupplier'),
									onClick: () => {
										changeStatus('sendToSupplier')
									},
									disabled: order.status === 'sendToSupplier',
								},
								{
									label: Order.convertStatus('completed'),
									onClick: () => {
										changeStatus('completed')
									},
									disabled: order.status === 'completed',
								},
								{
									label: Order.convertStatus('canceled'),
									onClick: () => {
										changeStatus('canceled')
									},
									disabled: Order.checkStatus(['canceled', 'new'], order),
								},
								{
									label: 'Usuń',
									onClick: () => {
										changeStatus('remove')
									},
									disabled: order ? !Order.checkStatus(['new'], order) : true,
								},
						  ]
						: [
								{
									label: 'Do potwierdzenia',
									onClick: () => {
										changeStatus('toConfirm')
									},
									disabled: order ? !Order.checkStatus(['new'], order) : true,
								},
								{
									label: 'Potwierdź',
									onClick: () => {
										changeStatus('confirmed')
									},
									disabled: order
										? !Order.checkStatus(['new', 'toConfirm'], order)
										: true,
								},
								{
									label: 'Oznacz jako zrealizowane',
									onClick: () => {
										changeStatus('completed')
									},
									disabled: order
										? !Order.checkStatus(['confirmed'], order)
										: true,
								},
								{
									label: 'Anuluj',
									onClick: () => {
										changeStatus('canceled')
									},
									disabled: order
										? !Order.checkStatus(['confirmed', 'toConfirm'], order)
										: true,
								},
								{
									label: 'Usuń',
									onClick: () => {
										changeStatus('remove')
									},
									disabled: order ? !Order.checkStatus(['new'], order) : true,
								},
						  ],
			}}
		>
			{order && (
				<>
					<AlertDialog
						isRemoving={isLoading}
						isOpen={cancelDisclosure.isOpen}
						onClose={() => {
							cancelDisclosure.onClose()
							refetch()
						}}
						header="Anuluj zamówienie"
						onRemove={() => cancelOrder(order)}
						confirmButtonLabel="Anuluj zamówienie"
					>
						Czy na pewno chcesz anulować zamówienie{' '}
						<strong>{order.number}</strong>?
					</AlertDialog>
					<AlertDialog
						isRemoving={isLoading}
						isOpen={removeDisclosure.isOpen}
						onClose={removeDisclosure.onClose}
						header="Usuń zamówienie"
						onRemove={() => removeOrder(order)}
					>
						Czy na pewno chcesz usunąć zamówienie{' '}
						<strong>{order.number}</strong>?
					</AlertDialog>
				</>
			)}
			{children}
		</OrderStatusChangerContext.Provider>
	)
}
