import { Box } from '@chakra-ui/react'
import {
	InputField,
	ModalDialog,
	NumberField,
	SelectField,
} from '@kevea/react-components'
import { FileInput } from 'components/FileInput'
import { Form, Formik, FormikProps } from 'formik'
import { BillEntry } from 'models/bill'
import React, { useEffect, useRef, useState } from 'react'

type Props = {
	isOpen: boolean
	onClose: (entry?: BillEntry, file?: File) => void
	entries: BillEntry[]
	selectedEntry?: BillEntry
}

export const AddEntryModal = ({
	entries,
	isOpen,
	onClose,
	selectedEntry,
}: Props) => {
	const formik = useRef<FormikProps<BillEntry>>(null)
	const [uploading, setUploading] = useState(false)
	const [file, setFile] = useState<File | undefined>(undefined)

	useEffect(() => {
		setFile(undefined)
	}, [isOpen])

	const handleSubmit = (values: BillEntry) => {
		values.amount = values.quantity * values.price
		values.quantity = parseFloat(`${values.quantity}`)
		values.price = parseFloat(`${values.price}`)
		if (values.type !== 'invoice') {
			delete values.invoiceNumber
		}
		onClose(values, file)
	}

	return (
		<ModalDialog
			header={selectedEntry ? 'Edytuj pozycję' : 'Dodaj pozycję'}
			isOpen={isOpen}
			onClose={() => onClose()}
			onSave={() => formik.current?.submitForm()}
		>
			<Formik
				innerRef={formik}
				initialValues={
					selectedEntry
						? new BillEntry(selectedEntry)
						: new BillEntry({
								no:
									entries?.length > 0
										? Math.max(...entries.map(e => e.no)) + 1
										: 1,
								type: 'other',
						  })
				}
				validationSchema={BillEntry.validationSchema}
				onSubmit={handleSubmit}
			>
				{({ values }) => (
					<Form>
						{values.type !== 'contract' && (
							<>
								<SelectField options={BillEntry.entryTypeOptions} name="type" />
								<InputField name="name" placeholder="Nazwa" label="Nazwa" />
							</>
						)}
						{values.type === 'invoice' && (
							<>
								<InputField
									name="invoiceNumber"
									placeholder="Numer faktury"
									label="Numer faktury"
								/>
							</>
						)}
						{/* <NumberField
						name="quantity"
						placeholder="Ilość"
						label="Ilość"
						min={1}
					/> */}
						<NumberField
							name="price"
							placeholder="Cena"
							label="Cena"
							min={0}
							inputRightAddon="zł"
						/>
						<Box pt={4} />
						<FileInput
							showProgress={uploading}
							onDrop={files => setFile(files[0])}
						/>
					</Form>
				)}
			</Formik>
		</ModalDialog>
	)
}
