import React, { useEffect } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { isAuthenticated } from 'services'
import { AuthenticationService } from 'services/authentication'
import http from 'services/axios'

interface LoginPageProps {}

const Login: React.FC<LoginPageProps> = () => {
	const ssoToken = useSearchParams()[0].get('ssoToken')

	useEffect(() => {
		if (ssoToken) {
			AuthenticationService.login(ssoToken).then(res => {
				window.location.href = `${window.location.href.split('/login')[0]}/`
			})
		} else {
			http.get<{ ssoUrl: string }>('/loginOptions').then(res => {
				window.location.href = res.data.ssoUrl
			})
		}
	}, [ssoToken])

	return isAuthenticated() ? <Navigate to="/" /> : <></>
}

export default Login
