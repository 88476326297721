import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import {
	Dropdown,
	Label,
	ModalDialog,
	RadioGroupField,
	SelectField,
} from '@kevea/react-components'
import { Form, Formik, FormikProps } from 'formik'
import { Patient } from 'models/patient'
import React, { useRef, useState } from 'react'
import { saveAs } from 'file-saver'
import { Parser } from '@json2csv/plainjs'
import { flatten } from '@chakra-ui/utils'

type Props = {
	selectedPatients: Patient[]
	allPatients: Patient[]
	isOpen: boolean
	onClose: () => void
}

type ExportOptions = {
	type: 'json' | 'csv'
	export: 'all' | 'selection'
}

export const ExportListModal = ({
	isOpen,
	onClose,
	selectedPatients,
	allPatients,
}: Props) => {
	const formik = useRef<FormikProps<ExportOptions>>(null)
	const handleSave = (values: ExportOptions) => {
		const toExport =
			values.export === 'selection' ? selectedPatients : allPatients
		if (values.type === 'csv') {
			const flattend = toExport.map(p => flatten(p))
			const csv = new Parser().parse(flattend)
			const bytes = new TextEncoder().encode(csv)
			saveAs(
				new Blob([bytes], { type: 'text/csv;charset=utf-8' }),
				'patients.csv',
			)
		} else if (values.type === 'json') {
			const bytes = new TextEncoder().encode(JSON.stringify(toExport, null, 2))
			saveAs(
				new Blob([bytes], { type: 'application/json;charset=utf-8' }),
				'patients.json',
			)
		}
		onClose()
	}

	return (
		<ModalDialog
			header="Eksport listy pacjentów"
			isOpen={isOpen}
			onClose={() => onClose()}
			confirmButtonLabel="Eksportuj"
			onSave={() => formik.current?.handleSubmit()}
		>
			<Formik
				innerRef={formik}
				onSubmit={handleSave}
				initialValues={{
					type: 'csv',
					export: selectedPatients.length > 0 ? 'selection' : 'all',
				}}
			>
				<Form>
					<RadioGroupField
						label="Format"
						name="type"
						options={[
							{ label: 'CSV', value: 'csv' },
							{ label: 'JSON', value: 'json' },
						]}
					/>
					{selectedPatients.length > 0 && (
						<RadioGroupField
							name="export"
							label="Wyeksportuj"
							options={[
								{ label: 'Wszystkich pacjentów', value: 'all' },
								{
									label: `Tylko zaznaczonych (${selectedPatients.length})`,
									value: 'selection',
								},
							]}
						/>
					)}
				</Form>
			</Formik>
		</ModalDialog>
	)
}
