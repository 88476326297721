import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import {
	Dropdown,
	Label,
	ModalDialog,
	SelectField,
} from '@kevea/react-components'
import { Form, Formik, FormikProps } from 'formik'
import { Patient } from 'models/patient'
import React, { useRef, useState } from 'react'

export type PrintOptions = {
	columns: ('pesel' | 'dateOfBirth' | 'admissionDate' | 'dateOfDeath')[]
	status: 0 | 1 | 2 | 3
}

type Props = {
	isOpen: boolean
	onClose: (options?: PrintOptions) => void
}

export const PrintListModal = ({ isOpen, onClose }: Props) => {
	const formik = useRef<FormikProps<PrintOptions>>(null)
	const handleSave = (values: PrintOptions) => {
		onClose(values)
	}

	return (
		<ModalDialog
			header="Wydruk listy pacjentów"
			isOpen={isOpen}
			onClose={() => onClose()}
			confirmButtonLabel="Drukuj"
			onSave={() => formik.current?.handleSubmit()}
		>
			<Formik
				innerRef={formik}
				onSubmit={handleSave}
				initialValues={{ columns: ['dateOfBirth', 'pesel'], status: 0 }}
			>
				{({ setFieldValue, values }) => (
					<Form>
						<SelectField
							label="Status"
							name="status"
							options={Patient.statusOptions}
						/>
						<Label>Kolumny</Label>
						<CheckboxGroup
							value={values.columns}
							onChange={v => setFieldValue('columns', v)}
						>
							{[
								{ value: 'pesel', label: 'PESEL' },
								{ value: 'dateOfBirth', label: 'Data urodzenia' },
								{ value: 'admissionDate', label: 'Data przyjęcia' },
								{ value: 'dateOfDeath', label: 'Data zgonu' },
							].map(v => (
								<>
									<Checkbox key={v.value} value={v.value} colorScheme="green">
										{v.label}
									</Checkbox>
									<br />
								</>
							))}
						</CheckboxGroup>
					</Form>
				)}
			</Formik>
		</ModalDialog>
	)
}
