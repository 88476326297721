import { EventLog } from 'models/eventLog'
import { getBaseInstancePath, printConfig, Service } from 'services'
import http from './axios'

const path = '/eventLog'

export class EventLogService extends Service<EventLog>({
	path,
}) {
	static async export(startDate: Date, endDate: Date): Promise<Blob> {
		return http
			.get<Blob>(
				`${getBaseInstancePath(
					path,
				)}/export?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
				printConfig,
			)
			.then(res => res.data)
	}
}
