import { Patient } from './patient'
import * as yup from 'yup'
import { formatDate } from '@kevea/react-components'
import { TaskType } from './dictionaries/taskType'
import { TaskTypeService } from 'services/dictionaries/task'

export class TaskPlan {
	constructor(taskPlan: TaskPlan) {
		this._id = taskPlan._id
		this.title = taskPlan.title ?? ''
		this.type = taskPlan.type ?? null
		this.patient = taskPlan.patient
		this.description = taskPlan.description ?? ''
		this.date = taskPlan.date ?? new Date().toISOString()
		this.endDate = taskPlan.endDate ? formatDate(taskPlan.endDate, 'date') : ''
		this.recuringEvery = taskPlan.recuringEvery ?? 1
		this.taskTypeId = taskPlan.taskTypeId
		this.startDate = formatDate(
			taskPlan.date ?? new Date().toISOString(),
			'date',
		)
		this.time = formatDate(taskPlan.date ?? new Date().toISOString(), 'time')
	}

	_id?: string | undefined
	title?: string
	type?: TaskType | null
	patient: Patient
	description?: string
	date?: string
	endDate?: string
	recuringEvery?: number
	taskTypeId?: string | undefined
	startDate?: string
	time?: string

	static validationSchema = yup.object({
		title: yup.string().when('taskTypeId', {
			is: (taskTypeId: string | undefined) => taskTypeId === undefined,
			then: yup.string().required('Nazwa jest wymagana'),
		}),
		date: yup.string().required('Data i godzina jest wymagana'),
		recuringEvery: yup
			.number()
			.required('Powtarzaj co jest wymagane')
			.moreThan(0, 'Wartość musi być większa od 0'),
	})
}
export class TableTaskPlan {
	constructor(taskPlan: TaskPlan) {
		this._id = taskPlan._id ?? ''
		this.title = taskPlan.type?.name ?? taskPlan.title ?? ''
		this.description = taskPlan.description ?? ''
		this.date = taskPlan.date ? formatDate(taskPlan.date, 'date') : ''
		this.time = taskPlan.date ? formatDate(taskPlan.date, 'time') : ''
		this.endDate = taskPlan.endDate ? formatDate(taskPlan.endDate, 'date') : ''
		this.recuringEvery = taskPlan.recuringEvery
			? taskPlan.recuringEvery === 1
				? `${taskPlan.recuringEvery} dzień`
				: `${taskPlan.recuringEvery} dni`
			: ''
		this.original = taskPlan
	}

	_id: string
	title: string
	description: string
	date: string
	time: string
	endDate: string
	recuringEvery: string
	original: TaskPlan
}
