import { GridItem, useDisclosure } from '@chakra-ui/react'
import {
	DataDisplay,
	DataDisplayRow,
	Tile,
	TileEditButton,
	TilePrintButton,
	MenuButton,
	Link,
} from '@kevea/react-components'
import {
	NavigateButton,
	NavigationButtonContainer,
} from 'components/NavigateButton'
import { Order, TOrder } from 'models/order'
import { Patient } from 'models/patient'
import { WarehouseDocument } from 'models/warehouseDocument'
import { useMemo } from 'react'
import { useOutletContext } from 'react-router-dom'
import { OrderService } from 'services/order'
import { OrderContext } from '../Order'
import {
	OrderStatusChanger,
	OrderStatusChangerContext,
} from '../OrderStatusChanger'
import { MedicineTable } from './MedicineTable'
import { ProductTable } from './ProductTable'

export const General = () => {
	const { order, refetch, ...rest } = useOutletContext<OrderContext>()

	const rows = useMemo<DataDisplayRow<TOrder>[]>(
		() => [
			{ accessor: 'foreignNumber', label: 'Numer obcy' },
			{
				accessor: 'warehouseDocument',
				label: 'Dokument magazynowy',
				render: (value: WarehouseDocument) =>
					value ? (
						<Link to={'/warehouseDocuments/' + value._id}>{value.number}</Link>
					) : (
						'-'
					),
			},
		],
		[],
	)

	return order && refetch ? (
		<OrderStatusChanger order={order} refetch={refetch}>
			<OrderStatusChangerContext.Consumer>
				{({ isLoading, menuOptions }) => (
					<Tile
						isLoading={isLoading}
						grid
						{...rest}
						headerButtons={
							<>
								<MenuButton options={menuOptions} />
								<TilePrintButton
									options={[
										{
											fileName: 'Zamówienie ' + order.number,
											label: 'Wydruk zamówienia',
											promiseFunction: () =>
												OrderService.print(order._id ?? ''),
										},
									]}
								/>
								{order.type === 'product' &&
									Order.checkStatus(['new', 'toConfirm'], order) && (
										<TileEditButton />
									)}
								{order.type === 'medicine' &&
									Order.checkStatus(
										['new', 'sendToFacility', 'confirmed'],
										order,
									) && <TileEditButton />}
							</>
						}
					>
						<GridItem colSpan={{ base: 2, lg: 1 }}>
							<DataDisplay data={order} rows={rows} />
						</GridItem>
						<GridItem colSpan={2}>
							{order.type === 'medicine' ? (
								<MedicineTable order={order} />
							) : (
								<ProductTable order={order} />
							)}
						</GridItem>
						<GridItem colSpan={{ base: 2, lg: 1 }}>
							<NavigationButtonContainer>
								<NavigateButton label="Załączniki" to="attachments" />
							</NavigationButtonContainer>
						</GridItem>
					</Tile>
				)}
			</OrderStatusChangerContext.Consumer>
		</OrderStatusChanger>
	) : null
}
