import { useDisclosure } from '@chakra-ui/react'
import { AlertDialog, Link, Table, Tile } from '@kevea/react-components'
import { User, UserTable } from 'models/user'
import { SettingsContext } from 'pages/settings/Settings'
import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useOutletContext } from 'react-router-dom'
import {
	Cell,
	Column,
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table'
import { UserModal } from './UserModal'

export const UserList = () => {
	const { instance, refetch, ...rest } = useOutletContext<SettingsContext>()
	const addDisclosure = useDisclosure()
	const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined)

	const {
		data: users,
		isLoading,
		refetch: refetchUsers,
	} = useQuery('fetchUsers', User.fetchUsers)

	const data = useMemo(
		() => users?.map(user => new UserTable(user)) || [],
		[users],
	)

	const columns = useMemo<Column<UserTable>[]>(
		() => [
			{
				Header: 'Imię i Nazwisko',
				accessor: 'name',
				Cell: ({ row, value }) => (
					<Link
						onClick={() => {
							setSelectedUser(row.original.original)
							addDisclosure.onOpen()
						}}
					>
						{value}
					</Link>
				),
			},
			{
				Header: 'Adres Email',
				accessor: 'email',
			},
			{
				Header: 'Dostęp mobilny',
				accessor: 'mobileAccess',
			},
		],
		[],
	)

	const tableInstance = useTable(
		{ columns, data },
		useGlobalFilter,
		useSortBy,
		usePagination,
	)

	return (
		<Tile {...rest}>
			{selectedUser && (
				<UserModal
					user={selectedUser}
					isOpen={addDisclosure.isOpen}
					onClose={() => {
						addDisclosure.onClose()
						refetchUsers()
					}}
				/>
			)}
			<Table tableInstance={tableInstance} loading={isLoading} />
		</Tile>
	)
}
