import { WarehouseDocumentService } from 'services/warehouseDocument'
import {
	IMedicineGroup,
	IMedicineStockGroup,
	Medicine,
} from './dictionaries/medicine'
import { Patient } from './patient'
import * as yup from 'yup'

export class WarehouseDocument {
	_id?: string
	number?: string
	documentType: 'gr' | 'gd'
	status: 'new' | 'completed'
	type: 'medicine' | 'product'
	entries: IWarehouseDocumentEntry[]

	constructor(doc?: WarehouseDocument) {
		this._id = doc?._id
		this.number = doc?.number
		this.documentType = doc?.documentType ?? 'gd'
		this.status = doc?.status ?? 'new'
		this.type = doc?.type ?? 'medicine'
		this.entries = doc?.entries ?? []
	}

	static getStatusColor(status: WarehouseDocument['status']): string {
		switch (status) {
			case 'new':
				return 'former'
			case 'completed':
				return 'active'
			default:
				return 'dead'
		}
	}
	static documentTypeOptions = [
		{ label: this.convertDocumentType('gd'), value: 'gd' },
		{ label: this.convertDocumentType('gr'), value: 'gr' },
	]
	static checkStatus(
		allowedStatuses: WarehouseDocument['status'][],
		wd: WarehouseDocument,
	) {
		return allowedStatuses.includes(wd.status)
	}

	static convertStatus(status: WarehouseDocument['status']): string {
		switch (status) {
			case 'new':
				return 'Nowy'
			case 'completed':
				return 'Zakończony'
			default:
				return `${status}`
		}
	}
	static convertDocumentType(
		status: WarehouseDocument['documentType'],
	): string {
		switch (status) {
			case 'gr':
				return 'Przyjęcie zewnętrzne'
			case 'gd':
				return 'Podanie leku'
			default:
				return `${status}`
		}
	}
	static validationSchema = yup.object({
		entries: yup.array().min(1, 'Lista podanych leków nie może być pusta'),
	})

	static async fetchWarehouseDocuments(): Promise<WarehouseDocument[]> {
		return WarehouseDocumentService.getAll().then(res => res.data)
	}
}

export class TableWarehouseDocument {
	_id?: string
	number?: string
	documentType: 'gr' | 'gd'
	status: 'new' | 'completed'
	type: 'medicine' | 'product'
	entries: TableWarehouseEntry[]
	original: WarehouseDocument

	constructor(doc: WarehouseDocument) {
		this._id = doc._id
		this.number = doc.number
		this.documentType = doc.documentType
		this.status = doc.status
		this.type = doc.type
		this.entries = doc.entries.map(entry => new TableWarehouseEntry(entry))
		this.original = doc
	}

	static statusOptions = [
		{ value: 'new', label: WarehouseDocument.convertStatus('new') },
		{ value: 'completed', label: WarehouseDocument.convertStatus('completed') },
	]
}

export interface IWarehouseDocumentEntry {
	_id?: string
	medicineGroup: IMedicineGroup
	medicineStockGroup?: IMedicineStockGroup
	patient: Patient
	quantity: number
}
export class TableWarehouseEntry {
	patientName: string
	medicineNameWithDose: string
	quantity: number
	original: IWarehouseDocumentEntry

	constructor(entry: IWarehouseDocumentEntry) {
		this.patientName = Patient.getFullName(entry.patient)
		this.medicineNameWithDose =
			entry.medicineGroup.name + ' (' + entry.medicineGroup.dose + ')'
		this.quantity = entry.quantity
		this.original = entry
	}
}
