import { InputField, ModalDialog, TextAreaField } from '@kevea/react-components'
import { AllergensPicker } from 'components/AllergensPicker'
import { Form, Formik, FormikProps } from 'formik'
import { Dish } from 'models/dictionaries/dish'
import { useRef, useState } from 'react'
import { DishService } from 'services/dictionaries/dish'

type Props = {
	selectedDish: Dish | undefined
	isOpen: boolean
	onClose: (dish?: Dish) => void
}

export const DishModal = (props: Props) => {
	const formik = useRef<FormikProps<Dish>>(null)
	const [loading, setLoading] = useState(false)

	const handleSubmit = (values: Dish) => {
		if (formik.current) {
			const newDish = { ...props.selectedDish, ...values }
			setLoading(true)
			if (props.selectedDish?._id) {
				DishService.put(newDish)
					.then(res => res.data)
					.then(dish => {
						setLoading(false)
						props.onClose(dish)
					})
			} else {
				DishService.post(newDish)
					.then(res => res.data)
					.then(dish => {
						setLoading(false)
						props.onClose(dish)
					})
			}
		}
	}

	return (
		<>
			{props.isOpen && (
				<ModalDialog
					isLoading={loading}
					header={props.selectedDish ? 'Edytuj danie' : 'Dodaj danie'}
					onClose={() => props.onClose()}
					onSave={() => formik.current?.submitForm()}
					isOpen={props.isOpen}
				>
					<Formik
						validateOnBlur={false}
						validateOnChange={false}
						validationSchema={Dish.validationSchema}
						innerRef={formik}
						onSubmit={handleSubmit}
						initialValues={
							props.selectedDish ? new Dish(props.selectedDish) : new Dish()
						}
					>
						<Form>
							<InputField
								name="name"
								placeholder="Nazwa"
								label="Nazwa"
								isRequired
							/>

							<TextAreaField
								name="description"
								placeholder="Opis"
								label="Opis"
							/>
							<InputField
								name="calories"
								type="number"
								placeholder="Kalorie"
								label="Kalorie"
								inputRightAddon="kcal"
							/>
							<InputField
								name="weight"
								type="number"
								placeholder="Gramatura"
								inputRightAddon="g"
								label="Gramatura"
							/>
							<AllergensPicker name="allergens" />
						</Form>
					</Formik>
				</ModalDialog>
			)}
		</>
	)
}
