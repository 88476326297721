import { FormControl, FormErrorMessage, GridItem } from '@chakra-ui/react'
import {
	Tile,
	TileSaveButton,
	TileCancelButton,
	SelectField,
} from '@kevea/react-components'
import { AxiosResponse } from 'axios'
import { StatusBadge } from 'components/StatusBadge'
import { Form, Formik, FormikProps } from 'formik'
import { WarehouseDocument } from 'models/warehouseDocument'
import { useRef, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { WarehouseDocumentService } from 'services/warehouseDocument'
import { WarehouseDocumentContext } from '../WarehouseDocument'
import { MedicineTable } from './MedicineTable'

export const GeneralEdit = () => {
	const { warehouseDocument, refetch, ...rest } =
		useOutletContext<WarehouseDocumentContext>()
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const [entries, setEntries] = useState(warehouseDocument?.entries ?? [])

	const formik = useRef<FormikProps<WarehouseDocument>>(null)

	const handleSubmit = (values: WarehouseDocument) => {
		const afterSubmit = (response?: AxiosResponse<WarehouseDocument>) => {
			setLoading(false)
			if (warehouseDocument?.number) refetch?.()
			navigate('/warehouseDocuments/' + response?.data?._id ?? '')
		}
		setLoading(true)
		const wd: WarehouseDocument = {
			...values,
			entries,
		}
		if (wd?._id) {
			WarehouseDocumentService.put(wd).then(afterSubmit)
		} else {
			WarehouseDocumentService.post(wd).then(afterSubmit)
		}
	}

	return (
		<Formik
			validateOnBlur={false}
			validateOnChange={false}
			onSubmit={handleSubmit}
			initialValues={new WarehouseDocument(warehouseDocument)}
			innerRef={formik}
			validationSchema={WarehouseDocument.validationSchema}
		>
			{({ errors, setFieldValue, values }) => (
				<Form>
					<Tile
						grid
						{...rest}
						titleElement={<StatusBadge warehouseDocument={warehouseDocument} />}
						headerButtons={
							<>
								<TileSaveButton
									onSave={() => formik.current?.submitForm()}
									isLoading={loading}
								/>
								<TileCancelButton
									isLoading={loading}
									onCancel={() =>
										warehouseDocument?._id
											? navigate('/warehouseDocuments/' + warehouseDocument._id)
											: navigate('/warehouseDocuments')
									}
								/>
							</>
						}
					>
						<GridItem colSpan={{ base: 2, lg: 1 }}>
							{!warehouseDocument?.number && (
								<SelectField
									options={WarehouseDocument.documentTypeOptions}
									name="documentType"
									label="Rodzaj dokumentu"
								/>
							)}
						</GridItem>
						<GridItem colSpan={2}>
							<FormControl isInvalid={Boolean(errors.entries)}>
								{values.type === 'medicine' ? (
									<>
										<MedicineTable
											docEntries={entries}
											setEntries={e => {
												setFieldValue('entries', e)
												setEntries(e)
											}}
										/>
									</>
								) : (
									<>
										products
										{/* <ProductTable
											order={values}
											setProductsField={products =>
												setFieldValue('products', products)
											}
										/> */}
									</>
								)}
								{errors.entries && (
									<FormErrorMessage>{errors.entries}</FormErrorMessage>
								)}
							</FormControl>
						</GridItem>
					</Tile>
				</Form>
			)}
		</Formik>
	)
}
