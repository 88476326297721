import {
	OutletContext,
	TileGrid,
	useBreadcrumbs,
	useNotFoundToast,
} from '@kevea/react-components'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { StatusBadge } from 'components/StatusBadge'
import { ContractService } from 'services/contract'
import { Contract as ContractClass } from 'models/contract'
import contractsRoutes from './routes'

export type ContractContext = OutletContext & {
	contract?: ContractClass
}

export const Contract = () => {
	const { id } = useParams<{ id: string }>()
	const toast = useNotFoundToast()
	const navigate = useNavigate()

	const fetchContract = () => {
		if (id) {
			return ContractService.get(id)
				.then(res => res.data)
				.then(c => {
					const newC = new ContractClass(c)
					if (newC.hasThirdParty) {
						if (
							newC.thirdParty.identification.type === 'idCard' &&
							!newC.thirdParty.identification.idNumber
						) {
							newC.thirdParty.identification.type = undefined
						}
					}
					return newC
				})
				.catch(e => {
					toast()
					navigate('/contracts')
					return undefined
				})
		}
	}
	const {
		data: contract,
		isLoading,
		isRefetching,
		refetch,
	} = useQuery(['fetchContract', id], fetchContract)

	const breadcrumbs = useBreadcrumbs(contractsRoutes)
	const context: ContractContext = {
		breadcrumbs: breadcrumbs,
		contract,
		refetch,
		titleElement: contract ? <StatusBadge contract={contract} /> : null,
	}

	useEffect(() => {
		refetch()
	}, [id])

	return (
		<TileGrid isLoading={isLoading || isRefetching}>
			<Outlet context={context} />
		</TileGrid>
	)
}
