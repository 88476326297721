import { Text, useDisclosure } from '@chakra-ui/react'
import {
	AlertDialog,
	Link,
	SelectFilter,
	Table,
	Tile,
} from '@kevea/react-components'
import { Medicine } from 'models/dictionaries/medicine'
import { Patient, PatientMedicine, TablePatientMedicine } from 'models/patient'
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Navigate, useOutletContext } from 'react-router-dom'
import {
	Cell,
	Column,
	useFilters,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table'
import { PatientContext } from '../Patient'
import { PatientMedicineService } from 'services/patientMedicine'
import { PatientMedicineModal } from './MedicineModal'
import { Badge } from 'components/StatusBadge'
import { Stock, TableStock } from 'models/stock'

export const PatientMedicinesStock = () => {
	const { patient, refetch, titleElement, ...rest } =
		useOutletContext<PatientContext>()
	const [isRemoving, setIsRemoving] = useState(false)

	const [selectedStock, setSelectedPatientMedicine] = useState<
		Stock | undefined
	>(undefined)
	const addDisclosure = useDisclosure()
	const removeDisclosure = useDisclosure()

	const {
		data: stocks,
		isLoading,
		refetch: refetchPatientMedicines,
	} = useQuery('fetchPatientMedicines', () =>
		patient ? Stock.fetchPatientMedicinesStock(patient) : null,
	)

	const data = useMemo(
		() => stocks?.map(stock => new TableStock(stock)) ?? [],
		[stocks],
	)

	const columns = useMemo<Column<TableStock>[]>(
		() => [
			{
				Header: 'Lek',
				accessor: 'nameWithDose',
			},
			{
				Header: 'Ilość',
				accessor: 'quantity',
			},
		],
		[],
	)

	const tableInstance = useTable(
		{
			data,
			columns,
		},
		useFilters,
		useSortBy,
		usePagination,
		useRowSelect,
	)

	return patient ? (
		<Tile {...rest} header={`${Patient.getFullName(patient)} - Magazyn leków`}>
			<Table
				tableInstance={tableInstance}
				loading={isLoading}
				menuOptions={{}}
			/>
		</Tile>
	) : (
		<Navigate to="/patients/new" />
	)
}
