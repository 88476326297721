import * as yup from 'yup'
import { Contract } from './contract'
import { ContractAnnexService } from 'services/contractAnnex'
import { ContractTemplate } from './contractTemplate'
import { formatDate } from '@kevea/react-components'

export class ContractAnnex {
	constructor(annex: Partial<ContractAnnex> & { contract: Contract }) {
		this._id = annex._id
		this.number = annex.number ?? ''
		this.status = annex.status ?? 1
		this.monthlyCharge = annex.contract.monthlyCharge
		this.isChangingMonthlyCharge = annex.isChangingMonthlyCharge ?? true
		this.contract = annex.contract
		this.contractTemplate = annex?.contractTemplate ?? null
		this.dateOfConclusion =
			annex?.dateOfConclusion ?? formatDate(new Date().toISOString(), 'date')
		this.validFrom =
			annex?.validFrom ?? formatDate(new Date().toISOString(), 'date')
		this.validUntil = annex?.validUntil
	}
	_id?: string
	isChangingMonthlyCharge: boolean
	monthlyCharge?: number
	validFrom: string
	validUntil?: string
	dateOfConclusion: string
	status: number
	number: string
	contract: Contract
	contractTemplate: ContractTemplate | null

	static validationSchema = yup.object({
		monthlyCharge: yup.number().when('isChangingMonthlyCharge', {
			is: true,
			then: n =>
				n
					.required('Miesięczna opłata jest wymagana')
					.min(1, 'Minimalna opłata to 1zł'),
		}),
		contractTemplate: yup.mixed().required('Szablon aneksu jest wymagany'),
		validFrom: yup.date().required('Data rozpoczęcia aneksu jest wymagana'),
		validUntil: yup
			.date()
			.min(
				yup.ref('validFrom'),
				'Data zakończenia nie może być wcześniejsza niż data rozpoczęcia',
			),
		dateOfConclusion: yup
			.date()
			.required('Data podpisania aneksu jest wymagana'),
	})
	static fetchAnnexes = async (contractId: string) => {
		return ContractAnnexService.getAll(contractId).then(res => res.data)
	}

	static convertStatus = (status: number): string => {
		switch (status) {
			case 1:
				return 'Niepotwierdzony'
			case 2:
				return 'Aktywny'
			case 3:
				return 'Rozwiązany'
			default:
				return `${status}`
		}
	}
	static getStatusColor = (status: number): string => {
		switch (status) {
			case 1:
				return 'future'
			case 2:
				return 'active'
			case 3:
				return 'dead'
			default:
				return `${status}`
		}
	}
	static statusOptions = [
		{
			label: ContractAnnex.convertStatus(1),
			value: 1,
		},
		{
			label: ContractAnnex.convertStatus(2),
			value: 2,
		},
		{
			label: ContractAnnex.convertStatus(3),
			value: 3,
		},
	]
}

export class TableContractAnnex {
	constructor(annex: ContractAnnex) {
		this._id = annex._id
		this.original = annex
		this.number = annex.number ?? ''
		this.status = ContractAnnex.convertStatus(annex.status)
		this.monthlyCharge = annex.isChangingMonthlyCharge
			? annex.monthlyCharge.toString() + ' zł'
			: '-'
		this.contractTemplateName = annex.contractTemplate?.name ?? ''

		this.dateOfConclusion = annex.dateOfConclusion
			? formatDate(new Date(annex.dateOfConclusion).toISOString(), 'date')
			: ''
		this.validFrom = annex.dateOfConclusion
			? formatDate(new Date(annex.validFrom).toISOString(), 'date')
			: ''
		this.validUntil = annex.validUntil
			? formatDate(new Date(annex.validUntil).toISOString(), 'date')
			: '-'
	}
	_id: string | undefined
	number: string
	status: string
	original: ContractAnnex
	monthlyCharge: string
	contractTemplateName: string
	validFrom: string
	validUntil: string
	dateOfConclusion: string

	static statusOptions = [
		{
			label: ContractAnnex.convertStatus(1),
			value: ContractAnnex.convertStatus(1),
		},
		{
			label: ContractAnnex.convertStatus(2),
			value: ContractAnnex.convertStatus(2),
		},
		{
			label: ContractAnnex.convertStatus(3),
			value: ContractAnnex.convertStatus(3),
		},
	]
}
