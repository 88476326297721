import {
	InputField,
	ModalDialog,
	SelectField,
	TextAreaField,
} from '@kevea/react-components'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { EventLog } from 'models/eventLog'
import React, { useRef, useState } from 'react'
import { EventLogService } from 'services/eventLog'

type Props = {
	isOpen: boolean
	onClose: () => void
	eventLog: EventLog
}

export const EventLogModal = ({ isOpen, eventLog, onClose }: Props) => {
	const [loading, setLoading] = useState(false)

	const formik = useRef<FormikProps<EventLog>>(null)

	const handleSubmit = (values: EventLog, {}: FormikHelpers<EventLog>) => {
		setLoading(true)
		if (values.type === 'selfHarm') {
			values.kind = 'individual'
			values.subType = 'Inny'
		}
		if (eventLog._id) {
			EventLogService.put({ ...values, _id: eventLog._id }).then(() => {
				setLoading(false)
				onClose()
			})
		} else {
			EventLogService.post(values).then(() => {
				setLoading(false)
				onClose()
			})
		}
	}

	return (
		<ModalDialog
			isOpen={isOpen}
			header={eventLog._id ? 'Edytuj zgłoszenie' : 'Dodaj zgłoszenie'}
			onClose={onClose}
			isLoading={loading}
			onSave={() => formik.current?.submitForm()}
		>
			<Formik
				innerRef={formik}
				validateOnBlur={false}
				validateOnChange={false}
				onSubmit={handleSubmit}
				validationSchema={EventLog.schema}
				initialValues={new EventLog(eventLog)}
			>
				{({ values }) => (
					<Form>
						<SelectField
							name="type"
							label="Typ"
							options={[
								{
									label: EventLog.convertEventType('accident'),
									value: 'accident',
								},
								{
									label: EventLog.convertEventType('bodilyInjury'),
									value: 'bodilyInjury',
								},
								{
									label: EventLog.convertEventType('selfHarm'),
									value: 'selfHarm',
								},
							]}
						/>
						{values.type !== 'selfHarm' ? (
							<>
								<SelectField
									name="kind"
									label="Rodzaj"
									options={[
										{
											label: EventLog.convertEventKind('individual'),
											value: 'individual',
										},
										{
											label: EventLog.convertEventKind('mass'),
											value: 'mass',
										},
									]}
								/>
								<SelectField
									name="subType"
									label="Podtyp"
									options={(values.kind === 'individual'
										? EventLog.subTypesIndividual
										: EventLog.subTypesMass
									).map(x => ({ value: x, label: x }))}
								/>
							</>
						) : (
							<></>
						)}

						<InputField
							name="reporterName"
							label="Osoba zgłaszająca"
							placeholder="Osoba zgłaszająca"
						/>
						<InputField
							name="date"
							type="date"
							label="Data zgłoszenia"
							placeholder="Data zgłoszenia"
						/>

						<InputField
							name="place"
							label="Miejsce zdarzenia"
							placeholder="Miejsce zdarzenia"
						/>
						<TextAreaField
							name="nextSteps"
							label="Kolejne kroki"
							placeholder="Kolejne kroki"
						/>

						<TextAreaField name="description" label="Opis" placeholder="Opis" />
					</Form>
				)}
			</Formik>
		</ModalDialog>
	)
}
