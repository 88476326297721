import { FormControl, FormErrorMessage } from '@chakra-ui/react'
import {
	Dropdown,
	InputField,
	ModalDialog,
	NumberField,
	SwitchField,
} from '@kevea/react-components'
import { Form, Formik, FormikProps } from 'formik'
import { Contract } from 'models/contract'
import { ContractAnnex } from 'models/contractAnnex'
import { ContractTemplate } from 'models/contractTemplate'
import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { ContractAnnexService } from 'services/contractAnnex'

type Props = {
	contract: Contract
	isOpen: boolean
	onClose: () => void
}

export const AddAnnexModal = ({ contract, onClose, isOpen }: Props) => {
	const [isLoading, setIsLoading] = useState(false)

	const [selectedContractTemplate, setSelectedContractTemplate] =
		useState<ContractTemplate | null>(null)

	useEffect(() => {
		setSelectedContractTemplate(null)
	}, [isOpen])

	const { data: contractTemplates } = useQuery('fetchContractTemplates', () =>
		ContractTemplate.fetchContractTemplates().then(x =>
			x.filter(t => t.type === 'contractAnnex'),
		),
	)

	const changeContractTemplate = (tpl?: ContractTemplate) => {
		setSelectedContractTemplate(tpl)
		formik.current?.setFieldValue('contractTemplate', tpl)
	}

	const formik =
		useRef<FormikProps<ContractAnnex & { unlimitedPeriod: boolean }>>(null)

	const handleSubmit = (
		values: ContractAnnex & { unlimitedPeriod: boolean },
	) => {
		setIsLoading(true)

		if (values.unlimitedPeriod) {
			values.validUntil = null
		}
		ContractAnnexService.post(values, contract._id)
			.then(() => {
				setIsLoading(false)
				onClose()
			})
			.catch(() => {
				setIsLoading(false)
				onClose()
			})
	}

	return (
		<ModalDialog
			header="Dodaj aneks umowy"
			onClose={onClose}
			onSave={() => formik.current?.submitForm()}
			isOpen={isOpen}
			isLoading={isLoading}
		>
			<Formik
				validateOnBlur={false}
				validateOnChange={false}
				validationSchema={ContractAnnex.validationSchema}
				innerRef={formik}
				onSubmit={handleSubmit}
				initialValues={{
					...new ContractAnnex({
						contract: contract,
					}),
					unlimitedPeriod: true,
				}}
			>
				{({ values, errors }) => (
					<Form>
						<FormControl isInvalid={Boolean(errors.contractTemplate)}>
							<Dropdown
								displayAccessor="name"
								items={contractTemplates ?? []}
								selectedItem={selectedContractTemplate}
								setSelectedItem={changeContractTemplate}
								placeholder="Wybierz szablon aneksu"
								label="Szablon aneksu"
								render={(tpl: ContractTemplate) => tpl.name}
								numberOfResults={6}
								name="contractTemplate"
							/>
							{errors.contractTemplate && (
								<FormErrorMessage>{errors.contractTemplate}</FormErrorMessage>
							)}
						</FormControl>
						<InputField
							name="dateOfConclusion"
							placeholder="Data podpisania"
							label="Data podpisania"
							type="date"
						/>
						<InputField
							name="validFrom"
							placeholder="Ważny od"
							label="Ważny od"
							type="date"
						/>
						<SwitchField name="unlimitedPeriod" label="Umowa bezterminowa" />
						{!values.unlimitedPeriod && (
							<InputField
								name="validUntil"
								placeholder="Ważny do"
								label="Ważny do"
								type="date"
							/>
						)}
						<SwitchField
							name="isChangingMonthlyCharge"
							label="Aneks zmienia opłatę miesięczną"
						/>
						{values.isChangingMonthlyCharge && (
							<NumberField
								name="monthlyCharge"
								placeholder="Miesięczna opłata"
								label="Miesięczna opłata"
								inputRightAddon="zł"
								hideStepper
								defaultValue={1}
								min={1}
							/>
						)}
					</Form>
				)}
			</Formik>
		</ModalDialog>
	)
}
