import {
	Box,
	Button,
	MenuItem,
	MenuList,
	useDisclosure,
} from '@chakra-ui/react'
import {
	Navbar,
	Sidebar,
	SidebarItem,
	UserAvatar,
} from '@kevea/react-components'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
	FaAddressCard,
	FaBoxes,
	FaCaretDown,
	FaChartBar,
	FaClipboardList,
	FaFileContract,
	FaFileInvoiceDollar,
	FaFirstAid,
	FaInfo,
	FaShoppingCart,
	FaSignOutAlt,
	FaSlidersH,
	FaTasks,
	FaUtensils,
} from 'react-icons/fa'
import { getUser, logout } from 'services'
import { ServiceService } from 'services/service'
import { ChooseServiceModal } from 'components/ChooseServiceModal'
import { AxiosError } from 'axios'
import { Layout as KvLayout } from '@kevea/react-components'
import { getFullName, restrict } from 'utils/user'
import { IUser } from 'models/user'
import { useQuery } from 'react-query'
import { isModuleEnabled } from 'routes'
import { SelectedServiceContext } from 'App'

interface LayoutProps {}

export const CurrentUserContext = React.createContext(getUser())

export const Layout: React.FC<LayoutProps> = () => {
	const [loading, setLoading] = useState(false)
	const user = getUser()
	const chooseServiceDisclosure = useDisclosure()
	const { selectedService, setSelectedService } = useContext(
		SelectedServiceContext,
	)

	useEffect(() => {
		if (selectedService?._id) {
			setLoading(true)
			ServiceService.get(selectedService?._id)
				.then(res => res.data)
				.then(service => {
					setSelectedService(service)
					setLoading(false)
				})
				.catch((e: AxiosError) => {
					if (e.response?.status === 403) {
						setSelectedService()
					}
					chooseServiceDisclosure.onOpen()
				})
		} else {
			chooseServiceDisclosure.onOpen()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedService?._id])

	const sidebarItems = useMemo(() => {
		const items: SidebarItem[] = [
			{
				label: 'Dashboard',
				path: '/',
				icon: <FaChartBar />,
			},
			restrict('patient', 'view') && {
				label: 'Pacjenci',
				path: '/patients',
				icon: <FaAddressCard />,
			},
			restrict('report', 'view') && {
				label: 'Raport',
				path: '/reports',
				icon: <FaTasks />,
			},
			restrict('event_log', 'view', true) && {
				label: 'Rejestr zdarzeń nadzywczajnych',
				path: '/event-log',
				icon: <FaClipboardList />,
			},
			{
				label: 'Ogłoszenia',
				path: '/announcements',
				icon: <FaInfo />,
			},
			isModuleEnabled('KITCHEN') || isModuleEnabled('MEDICINE_WAREHOUSE')
				? restrict('orders', 'view') && {
						label: 'Zamówienia',
						path: '/orders',
						icon: <FaShoppingCart />,
				  }
				: null,
			 isModuleEnabled('NFZ_ORDERS')
			 	? restrict('nfz_orders', 'view') && {
			 			label: 'Zlecenia NFZ',
			 			path: '/nfz-orders',
			 			icon: <FaFirstAid />,
			 	  }
			 	: null,
			isModuleEnabled('CONTRACTS')
				? restrict('contracts', 'view') && {
						label: 'Umowy',
						path: '/contracts',
						icon: <FaFileContract />,
				  }
				: null,
			isModuleEnabled('CONTRACTS')
				? restrict('bills', 'view') && {
						label: 'Rozrachunki',
						path: '/bills',
						icon: <FaFileInvoiceDollar />,
				  }
				: null,
			isModuleEnabled('MEDICINE_WAREHOUSE')
				? restrict('warehouse', 'view') && {
						label: 'Magazyn',
						path: '/warehouseDocuments',
						icon: <FaBoxes />,
				  }
				: null,
			isModuleEnabled('KITCHEN')
				? restrict('menu', 'view') && {
						label: 'Jadłospis',
						path: '/menu',
						icon: <FaUtensils />,
				  }
				: null,
			,
			restrict('settings', 'view') && {
				label: 'Ustawienia',
				path: '/settings',
				icon: <FaSlidersH />,
			},
		]
		return items.filter(Boolean)
	}, [selectedService])

	return (
		<CurrentUserContext.Provider value={user}>
			<ChooseServiceModal
				isOpen={chooseServiceDisclosure.isOpen}
				onClose={chooseServiceDisclosure.onClose}
			/>
			{user.role === 'keveaAdmin' &&
				!user.instanceRoles
					.map(x => x.service)
					.includes(selectedService?._id) && (
					<Box
						bg="red.500"
						position="absolute"
						left={0}
						right={0}
						bottom={0}
						zIndex={999999}
						color="white"
						fontSize={20}
						textAlign="center"
						opacity={0.8}
					>
						Uwaga znajdujesz się w instancji klienta
					</Box>
				)}
			<KvLayout
				navbar={{
					logo: (
						<img
							alt="Logo KeVea RHM"
							style={{ height: 30 }}
							src={process.env.PUBLIC_URL + '/kv-logo.png'}
						/>
					),
					rightElement: <RightElement user={user} logout={logout} />,
					middleElement: (
						<Button
							rightIcon={<FaCaretDown />}
							size="sm"
							isLoading={!selectedService || loading}
							onClick={() => chooseServiceDisclosure.onOpen()}
						>
							{selectedService?.name}
						</Button>
					),
				}}
				sidebar={{
					sidebarItems,
				}}
			/>
		</CurrentUserContext.Provider>
	)
}
const RightElement: React.FC<{ user?: IUser; logout: VoidFunction }> = ({
	user,
	logout,
}) => {
	return (
		<UserAvatar name={getFullName(user)} bgColor="white">
			<MenuList>
				<MenuItem icon={<FaSignOutAlt />} color="red" onClick={logout}>
					Wyloguj się
				</MenuItem>
			</MenuList>
		</UserAvatar>
	)
}
