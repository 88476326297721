import { GridItem, useDisclosure } from '@chakra-ui/react'
import {
	DataDisplay,
	DataDisplayRow,
	Tile,
	TileEditButton,
	TilePrintButton,
	MenuButton,
	Link,
	useBreadcrumbs,
	Table,
	useErrorToast,
} from '@kevea/react-components'
import {
	NavigateButton,
	NavigationButtonContainer,
} from 'components/NavigateButton'
import { StatusBadge } from 'components/StatusBadge'
import { BillEntry, TBill, Bill as BillModel } from 'models/bill'
import { Patient } from 'models/patient'
import { useMemo, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Column, useTable } from 'react-table'
import { AttachmentService } from 'services/attachment'
import { BillService } from 'services/bill'
import { Bill, BillContext } from '../Bill'

export const General = () => {
	const { bill, refetch, ...rest } = useOutletContext<BillContext>()
	const [isStatusChanging, setIsStatusChanging] = useState(false)
	const error = useErrorToast()

	const confirm = (bill: BillModel) => {
		if (bill) {
			setIsStatusChanging(true)
			BillService.changeStatus(bill, 'confirmed').then(() => {
				refetch?.()
				setIsStatusChanging(false)
			})
		}
	}
	const pay = (bill: BillModel) => {
		if (bill) {
			setIsStatusChanging(true)
			BillService.changeStatus(bill, 'paid').then(() => {
				refetch?.()
				setIsStatusChanging(false)
			})
		}
	}
	const cancel = (bill: BillModel) => {
		if (bill) {
			setIsStatusChanging(true)
			BillService.changeStatus(bill, 'canceled').then(() => {
				refetch?.()
				setIsStatusChanging(false)
			})
		}
	}
	const downloadFile = async (_id: string): Promise<Blob | undefined> => {
		if (bill?._id) {
			return AttachmentService.downloadFile(bill._id, 'bills', _id)
				.then(res => res.data)
				.catch(() => {
					error({ description: 'Wystąpił błąd podczas pobierania pliku' })
					return undefined
				})
		}

		return undefined
	}

	const rows = useMemo<DataDisplayRow<TBill>[]>(
		() => [
			{
				accessor: 'patient',
				label: 'Pacjent',
				render: p => Patient.getFullName(p),
			},
			{
				accessor: 'dateOfIssue',
				label: 'Data wystawienia',
				type: 'date',
			},
			{
				accessor: 'paymentDate',
				label: 'Data płatności',
				type: 'date',
			},
			{
				accessor: 'amount',
				label: 'Wartość',
				render: v => `${v?.toFixed(2) ?? ''} zł`,
			},
			{
				accessor: 'billNumberMonth',
				label: 'Okres rozliczeniowy',
				render: (_, bill: BillModel) =>
					`${BillModel.convertMonth(bill.billNumberMonth)} ${
						bill.billNumberYear
					}`,
			},
		],
		[],
	)

	const columns = useMemo<Column<BillEntry>[]>(
		() => [
			{
				Header: 'Lp.',
				accessor: 'no',
			},
			{
				Header: 'Nazwa',
				accessor: 'name',
				Cell: ({ row, value }) =>
					row.original.type === 'invoice'
						? `${value} (${row.original.invoiceNumber})`
						: value,
			},
			// {
			// 	Header: 'Ilość',
			// 	accessor: 'quantity',
			// },
			{
				Header: 'Cena',
				accessor: 'price',
				Cell: ({ value }) => `${value?.toFixed(2) ?? ''} zł`,
			},
			// {
			// 	Header: 'Wartość',
			// 	accessor: 'amount',
			// 	Cell: ({ value }) => `${value?.toFixed(2) ?? ''} zł`,
			// },
			{
				Header: 'Załącznik',
				accessor: 'attachmentId',
				Cell: ({ row }) =>
					row.original.attachmentId || row.original.fileToUpload ? (
						<Link
							onClick={() => {
								const attachmentId = row.original.attachmentId
								if (attachmentId) {
									downloadFile(attachmentId).then(blob => {
										if (blob) window.open(URL.createObjectURL(blob), '_blank')
									})
								} else {
									window.open(
										URL.createObjectURL(row.original.fileToUpload),
										'_blank',
									)
								}
							}}
						>
							Otwórz
						</Link>
					) : (
						'-'
					),
			},
		],
		[],
	)

	const entriesTableData = useMemo(() => bill?.entries ?? [], [bill])

	const tableInstance = useTable({
		data: entriesTableData,
		columns: columns,
	})

	return bill ? (
		<Tile
			grid
			isLoading={isStatusChanging}
			{...rest}
			titleElement={<StatusBadge bill={bill} />}
			headerButtons={
				<>
					<MenuButton
						options={[
							{
								label: 'Potwierdzony',
								onClick: () => {
									confirm(bill)
								},
								disabled: !['new', 'paid'].includes(bill.status),
							},
							{
								label: 'Opłacony',
								onClick: () => {
									pay(bill)
								},
								disabled: !['confirmed'].includes(bill.status),
							},
							{
								label: 'Anulowany',
								onClick: () => {
									cancel(bill)
								},
								disabled: !['new', 'confirmed'].includes(bill.status),
							},
						]}
					/>
					<TilePrintButton
						options={[
							{
								fileName: bill.number,
								label: 'Wydruk rachunku',
								promiseFunction: () => BillService.print(bill._id),
							},
						]}
					/>
					<TileEditButton />
				</>
			}
		>
			<GridItem colSpan={{ base: 2, xl: 1 }}>
				<DataDisplay data={bill} rows={rows} />
			</GridItem>
			<GridItem colSpan={{ base: 2, xl: 1 }}>
				<NavigationButtonContainer>
					<NavigateButton label="Załączniki" to="attachments" />
				</NavigationButtonContainer>
			</GridItem>
			<GridItem colSpan={2}>
				<Table tableInstance={tableInstance} />
			</GridItem>
		</Tile>
	) : null
}
