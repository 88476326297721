import { Link, ModalDialog, SelectFilter, Table } from '@kevea/react-components'
import { Medicine } from 'models/dictionaries/medicine'
import { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import {
	Column,
	Cell,
	useTable,
	useGlobalFilter,
	useFilters,
	useSortBy,
	usePagination,
} from 'react-table'

type Props = {
	isOpen: boolean
	onClose: (medicine?: Medicine) => void
}

export const MedicineModalList = ({ isOpen, onClose }: Props) => {
	const [page, setPage] = useState(0)
	const [filter, setFilter] = useState('')

	const {
		data: paginatedData,
		isLoading,
		isFetching,
		refetch,
	} = useQuery(
		['fetchMedicinesPaginated', { page, filter }],
		() => Medicine.fetchMedicinesPaginated({ filter, page, rows: 6 }),
		{ keepPreviousData: true },
	)

	useEffect(() => {
		if (isOpen) refetch()
	}, [isOpen])

	const data = useMemo(
		() =>
			(paginatedData?.data
				?.map(medicine => medicine._id && new Medicine(medicine))
				.filter(med => med) as Medicine[]) ?? [],
		[paginatedData],
	)

	const columns = useMemo<Column<Medicine>[]>(
		() => [
			{
				Header: 'Nazwa',
				accessor: 'name',
				Cell: cell => (
					<Link
						onClick={() => {
							onClose(cell.row.original)
						}}
					>
						{cell.value}
					</Link>
				),
			},
			{ Header: 'Dawka', accessor: 'dose' },
			{ Header: 'Nazwa powszechna', accessor: 'commonName' },
			{ Header: 'Producent', accessor: 'entityResponsible' },
		],
		[],
	)

	const tableInstance = useTable(
		{
			columns,
			data,
			manualPagination: true,
			manualGlobalFilter: true,
			initialState: { pageIndex: 0 },
			pageCount: paginatedData?.totalPages,
		},
		useGlobalFilter,
		usePagination,
	)

	useEffect(() => {
		const { pageIndex, globalFilter } = tableInstance.state
		setPage(pageIndex)
		setFilter(globalFilter)
	}, [tableInstance.state.globalFilter, tableInstance.state.pageIndex])

	return (
		<ModalDialog
			tableModal
			isOpen={isOpen}
			onClose={() => onClose()}
			header="Wybierz lek"
		>
			<Table
				hideNumberOfRows
				tableInstance={tableInstance}
				loading={isLoading || isFetching}
			/>
		</ModalDialog>
	)
}
