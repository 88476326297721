import { Select } from '@chakra-ui/react'
import { Label, ModalDialog, useErrorToast } from '@kevea/react-components'
import React, { useState } from 'react'
import { PatientService } from 'services/patient'

type Props = {
	isOpen: boolean
	onClose: VoidFunction
	onSave: (timeOfDay: string) => void
	id: string
}

export const PrintTagModal = ({ isOpen, onClose, onSave, id }: Props) => {
	const [isPrintingTag, setIsPrintingTag] = useState(false)
	const [timeOfDay, setTimeOfDay] = useState('rano')
	const error = useErrorToast()

	return (
		<ModalDialog
			header="Wygeneruj etykietę"
			isOpen={isOpen}
			isLoading={isPrintingTag}
			onClose={onClose}
			onSave={() => {
				setIsPrintingTag(true)
				PatientService.printTag(id, timeOfDay)
					.then(blob => {
						const pdf = new Blob([blob], { type: 'application/pdf' })
						const pdfURL = URL.createObjectURL(pdf)
						const pdfWindow = window.open(pdfURL, '_blank')
						if (pdfWindow) {
							pdfWindow.focus()
							pdfWindow.print()
						}
					})
					.then(() => {
						setIsPrintingTag(false)
						onClose()
					})
					.catch(() => {
						error({
							description: 'Wystąpił błąd podczas generowania wydruku',
						})
						{
							setIsPrintingTag(false)
							onClose()
						}
					})
			}}
		>
			<Label>Pora dnia:</Label>
			<Select value={timeOfDay} onChange={e => setTimeOfDay(e.target.value)}>
				<option value="rano">rano</option>
				<option value="południe">południe</option>
				<option value="wieczór">wieczór</option>
			</Select>
		</ModalDialog>
	)
}
