import { PatientMedicine } from 'models/patient'
import { Service } from 'services'

const path = '/medicines'
const objectPath = '/patients'

export class PatientMedicineService extends Service<PatientMedicine>({
	objectPath,
	path,
	objectService: true,
}) {}
