import { ModuleRoute } from 'components/ModuleRoute'
import { RestrictRoute } from 'components/RestrictRoute'
import { FaFileSignature } from 'react-icons/fa'
import { RouteObject } from 'react-router-dom'
import { ContractTemplate } from './ContractTemplate'
import { ContractTemplateList } from './ContractTemplateList'
import { General } from './views/General'
import { GeneralEdit } from './views/GeneralEdit'

const contractTemplateRoutes: RouteObject[] = [
	{
		path: '/settings/contractTemplates',
		element: (
			<ModuleRoute type="CONTRACTS">
				<RestrictRoute module="settings" power="contractTemplates">
					<ContractTemplateList />
				</RestrictRoute>
			</ModuleRoute>
		),
		breadcrumb: 'Szablony umów',
	},
	{
		path: '/settings/contractTemplates/:id',
		element: (
			<ModuleRoute type="CONTRACTS">
				<ContractTemplate />
			</ModuleRoute>
		),
		children: [
			{
				index: true,
				element: <General />,
				breadcrumb: 'Szablon umowy',
				breadcrumbIcon: <FaFileSignature />,
			},
			{
				path: 'edit',
				element: <GeneralEdit />,
				breadcrumb: 'Edycja szablonu',
				breadcrumbIcon: <FaFileSignature />,
			},
		],
	},
	{
		path: 'contractTemplates/new',
		element: (
			<ModuleRoute type="CONTRACTS">
				<GeneralEdit />
			</ModuleRoute>
		),
		children: [
			{
				index: true,
				element: <GeneralEdit />,
				breadcrumb: 'Nowy szablon',
				breadcrumbIcon: <FaFileSignature />,
			},
		],
	},
]

export default contractTemplateRoutes
