import { NoteDictionaryService } from 'services/dictionaries/note'
import * as yup from 'yup'
export class NoteDictionary {
	constructor(noteDictionary?: NoteDictionary) {
		this._id = noteDictionary?._id
		this.name = noteDictionary?.name ?? ''
		this.description = noteDictionary?.description ?? ''
	}
	_id: string | undefined
	name: string
	description: string

	static validationSchema = yup.object({
		name: yup.string().required('Nazwa jest wymagana'),
	})
	static fetchNoteTypes = async () => {
		return NoteDictionaryService.getAll().then(res => res.data)
	}
}
