import {
	Button,
	Center,
	Flex,
	FormControl,
	FormErrorMessage,
	GridItem,
	Td,
	Text,
	useDisclosure,
} from '@chakra-ui/react'
import {
	AddressCell,
	AlertDialog,
	Table,
	Tile,
	Link,
	TileSaveButton,
	TileCancelButton,
	InputField,
	AddressFields,
	SwitchField,
	InputFieldFlex,
	SelectField,
	MaskedInputField,
	Dropdown,
	NumberField,
	useErrorToast,
} from '@kevea/react-components'
import { AxiosResponse } from 'axios'
import { Form, Formik, FormikProps } from 'formik'
import { Contract, ContractThirdParty } from 'models/contract'
import { ContractTemplate } from 'models/contractTemplate'
import { Patient } from 'models/patient'
import { PatientModalList } from 'pages/patients/PatientModalList'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { ContractService } from 'services/contract'
import { ContractContext } from '../Contract'

export const GeneralEdit = () => {
	const { contract, refetch, ...rest } = useOutletContext<ContractContext>()
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const { data: patientsRaw, refetch: refetchPatients } = useQuery(
		'fetchPatients',
		() => Patient.fetchPatients(true),
	)
	const [selectedPatient, setSelectedPatient] = useState(
		contract?.patient ? new Patient(contract.patient) : undefined,
	)

	const {
		data: contractTemplates,
		isLoading,
		refetch: refetchContractTemplates,
	} = useQuery('fetchContractTemplates', () =>
		ContractTemplate.fetchContractTemplates().then(x =>
			x.filter(t => t.type === 'contract'),
		),
	)

	const showAllPatientsDisclosure = useDisclosure()
	const error = useErrorToast()
	const patients = useMemo<Patient[]>(
		() => patientsRaw?.map(patient => new Patient(patient)) ?? [],
		[patientsRaw],
	)

	const [selectedContractTemplate, setSelectedContractTemplate] =
		useState<ContractTemplate | null>(contract?.contractTemplate ?? null)

	const formik = useRef<FormikProps<Contract>>(null)

	useEffect(() => {
		changePatient(new Patient(contract?.patient))
		changeContractTemplate(
			contract?.contractTemplate
				? new ContractTemplate(contract?.contractTemplate)
				: null,
		)
	}, [contract])

	const changePatient = (patient?: Patient) => {
		setSelectedPatient(patient)
		formik.current?.setFieldValue('patient', patient?._id)
	}
	const changeContractTemplate = (tpl?: ContractTemplate) => {
		setSelectedContractTemplate(tpl)
		formik.current?.setFieldValue('contractTemplate', tpl)
	}

	const handleSubmit = (values: Contract & { unlimitedPeriod?: boolean }) => {
		const afterSubmit = (response?: AxiosResponse<Contract>) => {
			setLoading(false)
			navigate('/contracts/' + response?.data?._id ?? '')
			refetch()
		}
		if (selectedPatient) {
			setLoading(true)
			if (values.unlimitedPeriod) {
				values.validUntil = null
			}
			const newContract: Contract = {
				...values,
				contractTemplate: selectedContractTemplate,
			}
			if (contract?._id) {
				ContractService.put(newContract).then(afterSubmit)
			} else {
				ContractService.post(newContract).then(afterSubmit)
			}
		} else {
			error({ description: 'Wybierz pacjenta' })
		}
	}

	return (
		<Formik
			validateOnBlur={false}
			validateOnChange={false}
			onSubmit={handleSubmit}
			initialValues={{ ...new Contract(contract), unlimitedPeriod: true }}
			innerRef={formik}
			validationSchema={Contract.validationSchema}
		>
			{({ errors, values, setFieldValue }) => (
				<Form>
					<Tile
						grid
						{...rest}
						titleElement={null}
						headerButtons={
							<>
								<TileSaveButton
									onSave={() => formik.current?.submitForm()}
									isLoading={loading}
								/>
								<TileCancelButton
									isLoading={loading}
									onCancel={() =>
										contract?._id
											? navigate('/contracts/' + contract._id)
											: navigate('/contracts')
									}
								/>
							</>
						}
					>
						<GridItem colSpan={{ base: 2, lg: 1 }}>
							<PatientModalList
								isOpen={showAllPatientsDisclosure.isOpen}
								onClose={patient => {
									if (patient) changePatient(new Patient(patient))
									showAllPatientsDisclosure.onClose()
								}}
							/>
							<InputField
								name="ownNumber"
								placeholder="Własny numer"
								label="Własny numer"
							/>
							<FormControl isInvalid={Boolean(errors.patient)}>
								<Dropdown
									displayAccessor="fullName"
									items={patients}
									selectedItem={selectedPatient}
									setSelectedItem={changePatient}
									placeholder="Wybierz pacjenta"
									label="Pacjent"
									render={(patient: Patient) => patient.fullName}
									numberOfResults={6}
									onShowAllClick={showAllPatientsDisclosure.onOpen}
									name="patient"
								/>
								{errors.patient && (
									<FormErrorMessage>{errors.patient}</FormErrorMessage>
								)}
							</FormControl>
							<FormControl isInvalid={Boolean(errors.contractTemplate)}>
								<Dropdown
									displayAccessor="name"
									items={contractTemplates ?? []}
									selectedItem={selectedContractTemplate}
									setSelectedItem={changeContractTemplate}
									placeholder="Wybierz szablon umowy"
									label="Szablon umowy"
									render={(tpl: ContractTemplate) => tpl.name}
									numberOfResults={6}
									name="contractTemplate"
								/>
								{errors.contractTemplate && (
									<FormErrorMessage>{errors.contractTemplate}</FormErrorMessage>
								)}
							</FormControl>
							<InputField
								name="dateOfConclusion"
								placeholder="Data podpisania"
								label="Data podpisania"
								type="date"
							/>
							<InputField
								name="validFrom"
								placeholder="Ważna od"
								label="Ważna od"
								onChange={e => {
									setFieldValue('validFrom', e.target.value)
									setFieldValue(
										'paymentDate',
										new Date(e.target.value).getDate(),
									)
								}}
								type="date"
							/>
							<SwitchField name="unlimitedPeriod" label="Umowa bezterminowa" />
							{!values.unlimitedPeriod && (
								<InputField
									name="validUntil"
									placeholder="Ważna do"
									label="Ważna do"
									type="date"
								/>
							)}
							<NumberField
								name="monthlyCharge"
								placeholder="Miesięczna opłata"
								label="Miesięczna opłata"
								inputRightAddon="zł"
								hideStepper
								defaultValue={0}
								min={1}
							/>
							<SelectField
								name="paymentDate"
								label="Dzień płatności"
								options={Array.from({ length: 31 }, (_, i) => i + 1).map(i => ({
									label: `${i}. dnia każdego miesiąca`,
									value: i,
								}))}
							/>
						</GridItem>
						<GridItem colSpan={{ base: 2, lg: 1 }}>
							<SwitchField name="hasThirdParty" label="Osoba trzecia" />
							{values.hasThirdParty && (
								<>
									<InputFieldFlex>
										<InputField
											name="thirdParty.firstName"
											placeholder="Imię"
											label="Imię"
										/>
										<InputField
											name="thirdParty.lastName"
											placeholder="Nazwisko"
											label="Nazwisko"
										/>
									</InputFieldFlex>
									<SelectField
										label="Rodzaj dokumentu tożsamości"
										name="thirdParty.identification.type"
										options={[
											{
												label:
													ContractThirdParty.convertIdentificationType(
														'idCard',
													),
												value: 'idCard',
											},
											{
												label:
													ContractThirdParty.convertIdentificationType(
														'passport',
													),
												value: 'passport',
											},
											{
												label: 'Brak',
												value: undefined,
											},
										]}
									/>
									{values.thirdParty.identification.type === 'idCard' ? (
										<>
											<InputFieldFlex>
												<MaskedInputField
													mask="aaa000000"
													name="thirdParty.identification.idNumber"
													label="Numer dowodu osobistego"
													placeholder="AAA999999"
												/>
												<InputField
													name="thirdParty.identification.idIssuer"
													placeholder="Organ wydający"
													label="Organ wydający"
												/>
											</InputFieldFlex>
											<InputField
												name="thirdParty.identification.idExpiryDate"
												placeholder="Data ważności dowodu osobistego"
												label="Data ważności dowodu osobistego"
												type="date"
											/>
										</>
									) : values.thirdParty.identification.type === 'passport' ? (
										<>
											<MaskedInputField
												mask="aa0000000"
												name="thirdParty.identification.passportNumber"
												label="Numer paszportu"
												placeholder="AA9999999"
											/>
											<InputField
												name="thirdParty.identification.passportExpiryDate"
												placeholder="Data ważności paszportu"
												label="Data ważności paszportu"
												type="date"
											/>
										</>
									) : (
										<></>
									)}
									<InputField
										name="thirdParty.pesel"
										placeholder="PESEL"
										label="PESEL"
									/>
									<InputField
										name="thirdParty.dateOfBirth"
										placeholder="Data urodzenia"
										label="Data urodzenia"
										type="date"
									/>
									<AddressFields
										accessor="thirdParty.address"
										label="Adres Zamieszkania"
									/>

									<InputField
										name="thirdParty.phoneNumber"
										placeholder="Telefon kontaktowy"
										label="Telefon kontaktowy"
									/>
									<InputField
										name="thirdParty.emailAddress"
										placeholder="Adres Email"
										label="Adres Email"
									/>
								</>
							)}
						</GridItem>
						<GridItem colSpan={2}></GridItem>
					</Tile>
				</Form>
			)}
		</Formik>
	)
}
