import {
	AddressFields,
	InputField,
	ModalDialog,
	SelectField,
} from '@kevea/react-components'
import { Form, Formik, FormikProps } from 'formik'
import { IRelatedPerson, RelatedPerson } from 'models/patient'
import React, { useRef } from 'react'

type Props = {
	selectedPersonIndex?: number | undefined
	relatedPersons: IRelatedPerson[]
	setRelatedPersons: (relatedPerson: IRelatedPerson[]) => void
	isOpen: boolean
	onClose: () => void
}

export const RelatedPersonModal = (props: Props) => {
	const formik = useRef<FormikProps<RelatedPerson>>(null)

	const handleSubmit = () => {
		if (formik.current?.values) {
			if (props.selectedPersonIndex !== undefined) {
				props.relatedPersons[props.selectedPersonIndex] = formik.current.values
				props.setRelatedPersons([...props.relatedPersons])
			} else {
				props.setRelatedPersons([
					...props.relatedPersons,
					formik.current.values,
				])
			}
			props.onClose()
		}
	}

	return (
		<ModalDialog
			header={
				props.selectedPersonIndex !== undefined
					? 'Edytuj Osobę Powiązaną'
					: 'Dodaj Osobę Powiązaną'
			}
			onClose={props.onClose}
			onSave={() => formik.current?.submitForm()}
			isOpen={props.isOpen}
		>
			<Formik
				validateOnBlur={false}
				validateOnChange={false}
				validationSchema={RelatedPerson.validationSchema}
				innerRef={formik}
				onSubmit={handleSubmit}
				initialValues={
					props.selectedPersonIndex !== undefined
						? {
								...new RelatedPerson(),
								...props.relatedPersons[props.selectedPersonIndex],
						  }
						: new RelatedPerson()
				}
			>
				<Form>
					<InputField
						isRequired
						name="name"
						placeholder="Imię i Nazwisko"
						label="Imię i Nazwisko"
					/>
					<InputField
						name="emailAddress"
						placeholder="Adres E-Mail"
						label="Adres E-Mail"
					/>
					<InputField
						name="phoneNumber"
						placeholder="Numer Telefonu"
						label="Numer Telefonu"
					/>
					<AddressFields accessor="address" label="Adres Zameldowania" />
					<SelectField
						label="Relacja"
						options={RelatedPerson.relationOptions}
						name="relation"
					/>
				</Form>
			</Formik>
		</ModalDialog>
	)
}
