import { UserService } from 'services/user'
import * as yup from 'yup'
import { UserPermissions } from './permissions'
export class User {
	constructor(user?: User) {
		this._id = user?._id
		this.firstName = user?.firstName ?? ''
		this.lastName = user?.lastName ?? ''
		this.email = user?.email ?? ''
		this.pinCode = user?.pinCode ?? new UserPinCode()
	}
	_id: string | undefined
	email?: string
	firstName?: string
	lastName?: string
	pinCode: UserPinCode

	static validationSchema = yup.object({
		pinCode: yup.object({
			pinCode: yup.string().when('allowed', {
				is: true,
				then: yup
					.string()
					.required('Kod pin jest wymagany')
					.matches(/^[0-9]*$/, 'Pin może składać się tylko z cyfr')
					.length(6, 'Kod pin musi mieć dokładnie 6 znaków'),
			}),
		}),
	})
	static fetchUsers = async () => {
		return UserService.getAll().then(res => res.data)
	}
	static getFullName = (user?: User): string => {
		if (user) return `${user.firstName} ${user.lastName ?? ''}`
		return ''
	}
}
export class UserTable {
	constructor(user: User) {
		this._id = user._id
		this.name = User.getFullName(user)
		this.email = user.email ?? ''
		this.mobileAccess = user.pinCode?.allowed ? 'TAK' : 'NIE'
		this.original = user
	}
	_id: string | undefined
	email: string
	name: string
	mobileAccess: string
	original: User
}
export class UserPinCode {
	allowed: boolean = false
	pinCode: string = ''
}

export interface IUser {
	_id: string
	role: string
	email: string
	firstName: string
	lastName: string
	token: string
	instanceRoles: {
		service: string
		role: string
		permissions?: UserPermissions
	}[]
}
