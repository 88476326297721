import {
	OutletContext,
	TileGrid,
	useBreadcrumbs,
	useNotFoundToast,
} from '@kevea/react-components'
import { ContractTemplate as ContractTemplateModel } from 'models/contractTemplate'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { ContractTemplateService } from 'services/contractTemplate'
import contractTemplateRoutes from './routes'

export type ContractTemplateContext = OutletContext & {
	contractTemplate: ContractTemplateModel | undefined
}

export const ContractTemplate = () => {
	const { id } = useParams<{ id: string }>()
	const toast = useNotFoundToast()
	const navigate = useNavigate()

	const fetchContractTemplate = async () => {
		if (id && id !== 'new') {
			try {
				const res = await ContractTemplateService.get(id)
				return res.data
			} catch (e) {
				toast()
				navigate('/settings/contractTemplates')
				return undefined
			}
		}
	}
	const {
		data: contractTemplate,
		isLoading,
		isRefetching,
		refetch,
	} = useQuery('fetchContractTemplate', fetchContractTemplate)

	const breadcrumbs = useBreadcrumbs(contractTemplateRoutes)
	const context: ContractTemplateContext = {
		breadcrumbs: breadcrumbs,
		contractTemplate: contractTemplate,
		titleElement: contractTemplate?.name ?? '',
		refetch,
	}

	useEffect(() => {
		refetch()
	}, [id])

	return (
		<TileGrid isLoading={isLoading || isRefetching}>
			<Outlet context={context} />
		</TileGrid>
	)
}
