import { Text, useDisclosure } from '@chakra-ui/react'
import {
	AlertDialog,
	Link,
	SelectFilter,
	Table,
	Tile,
	TileGrid,
} from '@kevea/react-components'
import {
	DiapersOrderStatusChanger,
	DiapersOrderStatusChangerContext,
} from 'pages/diapersOrders/DiapersOrderStatusChanger'
import { DiapersOrder, DiapersOrderTable } from 'models/diapersOrder'
import { Patient } from 'models/patient'
import React, { useContext, useMemo, useState } from 'react'
import { FaIdCard, FaMedkit } from 'react-icons/fa'
import { useQuery } from 'react-query'
import {
	Cell,
	Column,
	useFilters,
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table'
import { DiapersOrderModal } from './DiapersOrderModal'
import { getColorCSSVariable } from 'utils/chakra'
import { Badge } from 'components/StatusBadge'

export const DiapersOrderList = () => {
	const {
		data: diapersOrders,
		isLoading,
		refetch,
	} = useQuery('fetchDiapersOrders', DiapersOrder.fetchDiapersOrders)
	const openDisclosure = useDisclosure()

	const data = useMemo(
		() => diapersOrders?.map(order => new DiapersOrderTable(order)) ?? [],
		[diapersOrders],
	)

	const columns = useMemo<Column<DiapersOrderTable>[]>(
		() => [
			{
				Header: 'Numer',
				accessor: 'number',
				Cell: ({ row, value }) => (
					<Link to={row.original.original._id}>{value}</Link>
				),
			},
			{ Header: 'Pacjent', accessor: 'patientName' },
			{ Header: 'Numer NFZ', accessor: 'prescriptionNumber' },
			{ Header: 'Data wystawienia', accessor: 'dateOfIssue' },
			{ Header: 'Data ważności', accessor: 'finishDate' },
			{
				Header: 'Status',
				accessor: 'status',
				Filter: ({ column }) => (
					<SelectFilter
						column={column}
						options={DiapersOrderTable.statusOptions}
					/>
				),
				Cell: ({ row }) => <Badge diaperOrder={row.original.original} />,
			},
		],
		[],
	)
	const tableInstance = useTable(
		{ columns: columns, data: data },
		useGlobalFilter,
		useFilters,
		useSortBy,
		usePagination,
		useRowSelect,
	)
	return (
		<TileGrid>
			<DiapersOrderStatusChanger
				setSelectedRows={tableInstance.toggleAllRowsSelected}
				diapersOrder={tableInstance.selectedFlatRows[0]?.original.original}
				refetch={refetch}
			>
				<Tile headerIcon={<FaMedkit />} header="Zlecenia NFZ">
					<DiapersOrderModal
						isOpen={openDisclosure.isOpen}
						onClose={() => {
							openDisclosure.onClose()
							refetch()
						}}
					/>
					<DiapersOrderStatusChangerContext.Consumer>
						{({ menuOptions, isLoading: isLoadingContext }) => (
							<Table
								tableInstance={tableInstance}
								loading={isLoading || isLoadingContext}
								buttons={[
									{
										label: 'Dodaj',
										type: 'add',
										action: openDisclosure.onOpen,
									},
								]}
								menuOptions={{
									noSelection: [],
									singleSelection: menuOptions,
									multipleSelection: [],
								}}
							/>
						)}
					</DiapersOrderStatusChangerContext.Consumer>
				</Tile>
			</DiapersOrderStatusChanger>
		</TileGrid>
	)
}
