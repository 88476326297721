import { AxiosResponse } from 'axios'
import { ContractAnnex } from 'models/contractAnnex'
import { getBaseInstancePath, printConfig, Service } from 'services'
import http from './axios'

const path = '/annexes'
const objectPath = '/contracts'

export class ContractAnnexService extends Service<ContractAnnex>({
	objectPath,
	path,
	objectService: true,
}) {
	static async changeStatus(
		annex: ContractAnnex,
		status: number,
	): Promise<AxiosResponse<ContractAnnex>> {
		const newAnnex: ContractAnnex = { ...annex }
		newAnnex.status = status
		return http.put(
			getBaseInstancePath(objectPath) +
				`/${annex.contract._id}${path}/${annex._id}`,
			newAnnex,
		)
	}
	static async print(annexId: string, contractId: string) {
		return http
			.get<Blob>(
				`${getBaseInstancePath(
					objectPath,
				)}/${contractId}${path}/${annexId}/print`,
				printConfig,
			)
			.then(res => res.data)
	}
}
