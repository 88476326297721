import { ModuleRoute } from 'components/ModuleRoute'
import { FaFileContract } from 'react-icons/fa'
import { RouteObject } from 'react-router-dom'
import { Contract } from './Contract'
import { ContractList } from './ContractList'
import { AttachmentsWrapper } from './views/Attachments'
import { General } from './views/General'
import { GeneralEdit } from './views/GeneralEdit'

const contractsRoutes: RouteObject[] = [
	{
		path: 'contracts',
		element: (
			<ModuleRoute type="CONTRACTS">
				<ContractList />
			</ModuleRoute>
		),
		breadcrumb: 'Lista Umów',
	},
	{
		path: 'contracts/:id',
		element: (
			<ModuleRoute type="CONTRACTS">
				<Contract />
			</ModuleRoute>
		),
		children: [
			{
				index: true,
				element: <General />,
				breadcrumb: 'Umowa',
				breadcrumbIcon: <FaFileContract />,
			},
			{
				path: 'edit',
				element: <GeneralEdit />,
				breadcrumb: 'Edycja umowy',
				breadcrumbIcon: <FaFileContract />,
			},
			{
				path: 'attachments',
				element: <AttachmentsWrapper />,
				breadcrumb: 'Lista załączników',
				breadcrumbIcon: <FaFileContract />,
			},
		],
	},
	{
		path: 'contracts/new',
		element: (
			<ModuleRoute type="CONTRACTS">
				<Contract />
			</ModuleRoute>
		),
		children: [
			{
				index: true,
				element: <GeneralEdit />,
				breadcrumb: 'Nowa umowa',
				breadcrumbIcon: <FaFileContract />,
			},
		],
	},
]

export default contractsRoutes
