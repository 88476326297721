import { TaskTypeService } from 'services/dictionaries/task'
import * as yup from 'yup'

export class TaskType {
	constructor(taskType?: TaskType) {
		this._id = taskType?._id
		this.name = taskType?.name ?? ''
		this.description = taskType?.description ?? ''
		this.requiresNurse = taskType?.requiresNurse ?? false
		this.requiresMeasurement = taskType?.requiresMeasurement ?? null
		this.dispachMedicine = taskType?.dispachMedicine ?? null
	}

	_id?: string | undefined
	name?: string
	description?: string
	requiresNurse?: boolean
	dispachMedicine?: number
	requiresMeasurement?: number | null //null - doesn't require measurement, 1- blood pressure; 2- blood sugar level; 3 - body temperature; 4 - heart rate; 5 - blood saturation; 6 - weight; 7 - height
	static fetchTaskTypes = async () => {
		return TaskTypeService.getAll().then(res => res.data)
	}
	static validationSchema = yup.object({
		name: yup.string().required('Nazwa jest wymagana'),
	})
}
export class TableTaskType {
	constructor(taskType: TaskType) {
		this._id = taskType?._id
		this.name = taskType.name ?? ''
		this.description = taskType.description ?? ''
		this.requiresNurse = taskType.requiresNurse ? 'TAK' : 'NIE'
		this.original = taskType
	}

	_id: string | undefined
	name: string
	description: string
	requiresNurse: string
	original: TaskType
}
