import { humanFileSize } from 'utils/file'
import * as yup from 'yup'

export class Attachment {
	constructor(attachment?: Attachment) {
		this._id = attachment?._id
		this.name = attachment?.name ?? ''
		this.description = attachment?.description ?? ''
		this.fileName = attachment?.fileName ?? ''
		this.fileExtension = attachment?.fileExtension ?? ''
		this.fileSize = attachment?.fileSize
	}
	_id?: string | undefined
	name: string
	fileExtension?: string | undefined
	fileSize?: number | undefined
	description?: string
	fileName: string

	static validationSchema = yup.object({
		name: yup.string().required('Nazwa jest wymagana'),
	})
}

export class TableAttachment {
	constructor(attachment: Attachment) {
		this._id = attachment._id ?? ''
		this.name = attachment.name
		this.fileExtension = attachment.fileExtension ?? ''
		this.fileSize = attachment.fileSize
			? humanFileSize(attachment.fileSize)
			: ''
		this.description = attachment.description ?? ''
		this.fileName = attachment.fileName ?? ''
		this.original = attachment
	}
	_id: string
	name: string
	fileExtension: string
	fileSize: string
	description: string
	fileName: string
	original: Attachment
}
