import { Box, Flex, GridItem, Progress } from '@chakra-ui/react'
import {
	DataDisplay,
	DataDisplayRow,
	Label,
	Tile,
	useBreadcrumbs,
} from '@kevea/react-components'
import {
	NavigateButton,
	NavigationButtonContainer,
} from 'components/NavigateButton'
import { Patient } from 'models/patient'
import { TService } from 'models/service'
import React, { useMemo } from 'react'
import { FaSlidersH } from 'react-icons/fa'
import { useQuery } from 'react-query'
import { useOutletContext } from 'react-router-dom'
import { isModuleEnabled } from 'routes'
import { restrict } from 'utils/user'
import { SettingsContext } from '../Settings'

export const General = () => {
	const { instance, refetch, breadcrumbs, ...rest } =
		useOutletContext<SettingsContext>()

	const { data: patients, isLoading } = useQuery('fetchPatients', () =>
		Patient.fetchPatients(true),
	)

	const patientCount = useMemo(() => patients?.length ?? 0, [patients])
	const maxPatients = useMemo(
		() =>
			instance?.rhmConfig?.planConfig?.maxPatients ?? Number.MAX_SAFE_INTEGER,
		[instance],
	)
	const isMaxPatientsUnlimited = useMemo(
		() => maxPatients === Number.MAX_SAFE_INTEGER,
		[maxPatients],
	)

	const rows = useMemo<DataDisplayRow<TService>[]>(
		() => [
			{ accessor: 'name', label: 'Skrócona nazwa' },
			{ accessor: (s: TService) => s.rhmConfig.fullName, label: 'Nazwa firmy' },
			{
				// @ts-ignore
				accessor: (s: TService) => s.rhmConfig.address,
				label: 'Adres',
				type: 'address',
			},
			{ accessor: (s: TService) => s.rhmConfig.vatNo, label: 'NIP' },
			{ accessor: (s: TService) => s.rhmConfig.regon, label: 'REGON' },
			{
				accessor: (s: TService) => s.rhmConfig.phoneNo,
				label: 'Numer telefonu',
			},
			{ accessor: (s: TService) => s.rhmConfig.email, label: 'Adres email' },
		],
		[],
	)

	return (
		<Tile
			grid
			{...rest}
			header="Ustawienia placówki"
			headerIcon={<FaSlidersH />}
		>
			<GridItem colSpan={{ base: 2, lg: 1 }}>
				<DataDisplay data={instance} rows={rows} />
			</GridItem>
			<GridItem colSpan={{ base: 2, lg: 1 }}>
				<NavigationButtonContainer>
					{restrict('settings', 'dictionaries') && (
						<NavigateButton label="Słowniki" to="dictionaries" />
					)}
					{restrict('settings', 'users') && (
						<NavigateButton label="Użytkownicy" to="users" />
					)}
					{isModuleEnabled('CONTRACTS') &&
						restrict('settings', 'contractTemplates') && (
							<NavigateButton label="Szablony umów" to="contractTemplates" />
						)}
					{restrict('settings', 'devices') && (
						<NavigateButton label="Urządzenia" to="devices" />
					)}
				</NavigationButtonContainer>
			</GridItem>
			{!isMaxPatientsUnlimited && (
				<GridItem colSpan={{ base: 2, lg: 1 }}>
					<Label>Liczba pacjentów: {`${patientCount} / ${maxPatients}`}</Label>
					<Progress
						hasStripe
						max={maxPatients}
						value={patientCount}
						colorScheme={patientCount >= maxPatients ? 'dead' : 'active'}
					/>
					<Flex justifyContent="space-between">
						<Box>0</Box>
						<Box>{instance.rhmConfig?.planConfig?.maxPatients ?? 0}</Box>
					</Flex>
					{patientCount >= maxPatients && (
						<h3>
							Limit pacjentów został osiągnięty, skontaktuj się z nami w celu
							jego zwiększenia.
						</h3>
					)}
				</GridItem>
			)}
		</Tile>
	)
}
