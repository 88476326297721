import React from 'react'
import { Editor as TinyEditor } from '@tinymce/tinymce-react'

export type Props = {
	value: string
	onChange: (html: string) => void
	openTemplateModal: VoidFunction
}

export const Editor = React.forwardRef<TinyEditor, Props>(
	({ onChange, value, openTemplateModal }, ref) => {
		return (
			<TinyEditor
				ref={ref}
				value={value}
				onEditorChange={onChange}
				tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
				init={{
					setup: editor => {
						editor.ui.registry.addButton('section', {
							text: '§',
							tooltip: 'Wstaw paragraf',
							onAction: () => editor.insertContent('§'),
						})
						editor.ui.registry.addButton('tpl', {
							icon: 'code-sample',
							tooltip: 'Wstaw szablon',
							onAction: openTemplateModal,
						})
					},
					height: 800,
					pagebreak_separator:
						'<p style="text-align: center">{-----pagebreak-----}</p>',
					pagebreak_split_block: true,
					promotion: false,
					style_formats: [
						{ title: 'Tytuł', format: 'h3' },
						{ title: 'Nagłówek', format: 'h4' },
					],
					content_style:
						"body {font-family: 'Helvetica', 'Arial', sans-serif;} p {font-size: 15px; line-height: 20px;}",
					menubar: false,
					language: 'pl',
					plugins: ['lists', 'pagebreak', 'table'],
					toolbar: [
						{ name: 'history', items: ['undo', 'redo'] },
						{ name: 'styles', items: ['styles', 'superscript', 'subscript'] },
						{ name: 'formatting', items: ['bold', 'italic', 'pagebreak'] },
						{ name: 'template', items: ['section', 'tpl', 'table'] },
						{
							name: 'alignment',
							items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify'],
						},
						{ name: 'indentation', items: ['outdent', 'indent'] },
						{ name: 'lists', items: ['numlist', 'bullist'] },
					],
				}}
			/>
		)
	},
)
