import { Box, Button, Flex, IconButton } from '@chakra-ui/react'
import {
	InputField,
	ModalDialog,
	useSuccessToast,
} from '@kevea/react-components'
import { Form, Formik, FormikProps } from 'formik'
import { Device } from 'models/device'
import { QRCodeSVG } from 'qrcode.react'
import { useEffect, useRef, useState } from 'react'
import { FaCopy } from 'react-icons/fa'
import { DeviceService } from 'services/device'

type Props = {
	isOpen: boolean
	onClose: () => void
}

export const DeviceModal = (props: Props) => {
	const formik = useRef<FormikProps<Device>>(null)
	const [loading, setLoading] = useState(false)
	const [qrCodeValue, setQrCodeValue] = useState<string | undefined>()

	const success = useSuccessToast()

	const handleSubmit = (values: Device) => {
		if (formik.current) {
			setLoading(true)
			DeviceService.register()
				.then(res => res.data)
				.then(device => {
					device.name = values.name
					setQrCodeValue(JSON.stringify(device))
					setLoading(false)
				})
		}
	}

	useEffect(() => {
		setQrCodeValue(undefined)
	}, [props.isOpen])

	const copyToClipboard = () => {
		if (qrCodeValue) {
			navigator.clipboard.writeText(qrCodeValue)
			success({ description: 'Skopiowano kod do schowka' })
		}
	}

	return (
		<>
			{props.isOpen && (
				<ModalDialog
					isLoading={loading}
					header="Dodaj urządzenie"
					onClose={() => props.onClose()}
					onSave={() => formik.current?.submitForm()}
					isOpen={props.isOpen}
					disableSave={Boolean(qrCodeValue)}
				>
					<Formik
						validateOnBlur={false}
						validateOnChange={false}
						validationSchema={Device.validationSchema}
						innerRef={formik}
						onSubmit={handleSubmit}
						initialValues={new Device()}
					>
						<Form>
							<InputField
								name="name"
								placeholder="Nazwa"
								label="Nazwa"
								disabled={Boolean(qrCodeValue)}
								isRequired
							/>
						</Form>
					</Formik>
					<Flex w="full" justifyContent="center" pt={8}>
						{qrCodeValue && (
							<QRCodeSVG
								value={qrCodeValue}
								style={{ width: '100%', height: '250px' }}
							/>
						)}
					</Flex>
					{qrCodeValue && localStorage.getItem('bigos') && (
						<Flex justifyContent="right">
							<IconButton
								aria-label="copy code"
								icon={<FaCopy />}
								onClick={copyToClipboard}
							/>
						</Flex>
					)}
				</ModalDialog>
			)}
		</>
	)
}
