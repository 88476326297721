import { formatDate } from '@kevea/react-components'
import { NoteDictionary } from './dictionaries/note'
import { Patient } from './patient'
import * as yup from 'yup'
import { RhmShiftSettings } from './service'
import { NoteService } from 'services/note'
import { ShiftService } from 'services/shift'
import { DepositService } from 'services/deposit'

export class Deposit {
	constructor(
		deposit: Partial<Omit<Deposit, 'patient'>> & {
			patient: Deposit['patient']
		},
	) {
		this._id = deposit?._id
		this.patient = deposit.patient
		this.name = deposit?.name ?? ''
		this.description = deposit?.description ?? ''
		this.amount = deposit?.amount ?? 1
	}
	_id?: string | undefined
	name?: string
	amount?: number
	description?: string
	patient?: Patient

	static validationSchema = yup.object({
		name: yup.string().required('Nazwa jest wymagana'),
		amount: yup.number().required('Ilość jest wymagana'),
	})

	static fetchDeposits = async (patient: Patient) => {
		return DepositService.getAll(patient._id).then(res => res.data)
	}
}
