import { useDisclosure } from '@chakra-ui/react'
import {
	AlertDialog,
	DateFilter,
	formatDate,
	Link,
	SelectFilter,
	Table,
	Tile,
	useErrorToast,
} from '@kevea/react-components'
import { Measurement, TableMeasurement } from 'models/measurement'
import { Patient } from 'models/patient'
import React, { useMemo, useState } from 'react'
import { FaPrint } from 'react-icons/fa'
import { useQuery } from 'react-query'
import { Navigate, useOutletContext } from 'react-router-dom'
import {
	Cell,
	Column,
	useFilters,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table'
import { MeasurementService } from 'services/measurement'
import { PatientService } from 'services/patient'
import { PatientContext } from '../Patient'
import { MeasurementModal } from './MeasurementModal'
import { MeasurementPrintModal } from './MeasurementPrintModal'

export const Measurements = () => {
	const { patient, refetch, titleElement, ...rest } =
		useOutletContext<PatientContext>()
	const [isRemoving, setIsRemoving] = useState(false)
	const [loading, setLoading] = useState(false)
	const error = useErrorToast()

	const [selectedMeasurement, setSelectedMeasurement] = useState<
		Measurement | undefined
	>(undefined)
	const addDisclosure = useDisclosure()
	const removeDisclosure = useDisclosure()
	const printDisclosure = useDisclosure()

	const fetchMeasurements = async () => {
		if (patient) {
			return MeasurementService.getAll(patient._id)
				.then(res => res.data)
				.then(m =>
					m.sort(
						(a, b) =>
							new Date(b.measurementDate).getTime() -
							new Date(a.measurementDate).getTime(),
					),
				)
		}
	}

	const {
		data: measurements,
		isLoading,
		refetch: refetchMeasurements,
	} = useQuery('fetchMeasurements', fetchMeasurements)

	const data = useMemo(
		() =>
			measurements?.map(measurement => new TableMeasurement(measurement)) ?? [],
		[measurements],
	)

	const columns = useMemo<Column<TableMeasurement>[]>(
		() => [
			{
				Header: 'Data Wykonania',
				accessor: 'measurementDate',
				Cell: ({ value, row }) => (
					<Link
						onClick={() => {
							setSelectedMeasurement(row.original.original)
							addDisclosure.onOpen()
						}}
					>
						{value}
					</Link>
				),
			},
			{
				Header: 'Rodzaj',
				accessor: 'type',
				Filter: ({ column }) => (
					<SelectFilter
						column={column}
						options={Measurement.typeOptionsTable}
					/>
				),
			},
			{
				Header: 'Wartość',
				accessor: 'parsedValue',
			},
			{
				accessor: 'filterDate',
				Filter: DateFilter,
			},
		],
		[],
	)

	const handlePrintDisclosureOnClose = (
		dateRange?: [Date, Date],
		types?: number[],
	) => {
		if (dateRange) {
			setLoading(true)
			printDisclosure.onClose()
			PatientService.printMeasurements(
				patient._id,
				dateRange[0],
				dateRange[1],
				types,
			)
				.then(blob => {
					if (blob) window.open(URL.createObjectURL(blob), '_blank')
					setLoading(false)
				})
				.catch(e => {
					error({ description: 'Wystąpił błąd podczas generowania wydruku' })
					setLoading(false)
				})
		} else {
			printDisclosure.onClose()
		}
	}

	const tableInstance = useTable(
		{ data, columns, initialState: { hiddenColumns: ['filterDate'] } },
		useFilters,
		useSortBy,
		usePagination,
		useRowSelect,
	)

	return patient ? (
		<Tile {...rest} header={`${Patient.getFullName(patient)} - Lista Pomiarów`}>
			{patient && (
				<MeasurementModal
					isOpen={addDisclosure.isOpen}
					selectedMeasurement={selectedMeasurement}
					onClose={() => {
						setSelectedMeasurement(undefined)
						addDisclosure.onClose()
						refetchMeasurements()
					}}
					patient={patient}
				/>
			)}
			{printDisclosure.isOpen && (
				<MeasurementPrintModal
					isOpen={printDisclosure.isOpen}
					onClose={handlePrintDisclosureOnClose}
				/>
			)}
			<AlertDialog
				header="Usuń Pomiar"
				onClose={removeDisclosure.onClose}
				isRemoving={isRemoving}
				isOpen={removeDisclosure.isOpen}
				onRemove={() => {
					if (selectedMeasurement?._id) {
						setIsRemoving(true)
						MeasurementService.delete(
							selectedMeasurement._id,
							patient._id,
						).then(res => {
							setIsRemoving(false)
							setSelectedMeasurement(undefined)
							removeDisclosure.onClose()
							refetchMeasurements()
						})
					}
				}}
			>
				Czy chcesz usunąć pomiar typu{' '}
				<strong>
					{selectedMeasurement?.type &&
						Measurement.convertType(selectedMeasurement.type)}
				</strong>{' '}
				z dnia{' '}
				<strong>
					{formatDate(selectedMeasurement?.measurementDate ?? '', 'date')}
				</strong>
				?
			</AlertDialog>
			<Table
				tableInstance={tableInstance}
				loading={isLoading || loading}
				buttons={[
					{
						type: 'add',
						action: () => {
							addDisclosure.onOpen()
						},
					},
					{
						type: 'print',
						action: () => {
							printDisclosure.onOpen()
						},
					},
				]}
				menuOptions={{
					singleSelection: [
						{
							label: 'Usuń',
							onClick: (measurements: TableMeasurement[]) => {
								setSelectedMeasurement(measurements[0]?.original)
								removeDisclosure.onOpen()
							},
						},
					],
				}}
			/>
		</Tile>
	) : (
		<Navigate to="/patients/new" />
	)
}
