import { AxiosResponse } from 'axios'
import { Order, TOrder } from 'models/order'
import { getBaseInstancePath, printConfig, Service } from 'services'
import http from './axios'

const path = '/orders'

export class OrderService extends Service<Order>({
	path,
}) {
	static async changeStatus(
		order: Order,
		status: TOrder['status'],
	): Promise<AxiosResponse<Order>> {
		const newOrder: Order = { ...order }
		order.status = status
		return http.put(getBaseInstancePath(path) + `/${ newOrder._id}`, order)
	}

	static async print(id: string): Promise<Blob> {
		return http
			.get<Blob>(`${getBaseInstancePath(path)}/${id}/print`, printConfig)
			.then(res => res.data)
	}
}
