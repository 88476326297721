import { MedicineService } from 'services/dictionaries/medicine'
import * as yup from 'yup'
export class Medicine {
	constructor(medicine?: Medicine) {
		this._id = medicine?._id
		this.name = medicine?.name ?? ''
		this.dose = medicine?.dose ?? ''
		this.commonName = medicine?.commonName ?? ''
		this.previousName = medicine?.previousName ?? ''
		this.pharmaceuticalFormName = medicine?.pharmaceuticalFormName ?? ''
		this.type = medicine?.type ?? ''
		this.entityResponsible = medicine?.entityResponsible ?? ''
		this.procedureType = medicine?.procedureType ?? ''
		this.authorizationExpiration = medicine?.authorizationExpiration ?? ''
		this.authorizationNumber = medicine?.authorizationNumber ?? ''
		this.gracePeriod = medicine?.gracePeriod ?? ''
		this.status = medicine?.status ?? ''
		this.rplId = medicine?.rplId
		this.activeSubstance = medicine?.activeSubstance ?? []
		this.atcCode = medicine?.atcCode ?? []
		this.packages = medicine?.packages ?? []
		this.nameWithDose = medicine ? Medicine.getNameWithDose(medicine) : ''
		this.prescripted = medicine?.prescripted ?? false
	}

	_id?: string
	name: string
	commonName: string
	previousName: string
	pharmaceuticalFormName: string
	type: string
	dose: string
	entityResponsible: string
	procedureType: string
	authorizationNumber: string
	authorizationExpiration: string
	gracePeriod: string
	status: string
	rplId?: number
	activeSubstance: IMedicineActiveSubstance[]
	atcCode: string[]
	packages: IMedicinePackage[]
	prescripted: boolean
	nameWithDose: string

	static getNameWithDose = (medicine: Medicine | IMedicineGroup): string => {
		return medicine.dose ? `${medicine.name} (${medicine.dose})` : medicine.name
	}
	static fetchMedicines = async ({ filter }: { filter?: string }) => {
		return MedicineService.getFiltered({ filter }).then(res => res.data)
	}
	static fetchMedicineGroups = async ({ filter }: { filter?: string }) => {
		return MedicineService.getFilteredGroups({ filter }).then(res => res.data)
	}
	static fetchMedicinesPaginated = async ({
		filter,
		page,
		rows,
	}: {
		filter?: string
		page: number
		rows?: number
	}) => {
		return MedicineService.getPaginated({ filter, page, rows }).then(
			res => res.data,
		)
	}

	static convertStatus = (status: string): string => {
		switch (status) {
			case 'active':
				return 'Aktywne'
			case 'inactive':
				return 'Nieaktywne'
			default:
				return `${status}`
		}
	}
	static getStatusColor = (status: string): string => {
		switch (status) {
			case 'active':
				return 'active'
			case 'inactive':
				return 'gray'
			default:
				return `${status}`
		}
	}
	static statusOptions = [
		{
			label: Medicine.convertStatus('active'),
			value: Medicine.convertStatus('active'),
		},
		{
			label: Medicine.convertStatus('inactive'),
			value: Medicine.convertStatus('inactive'),
		},
	]
}

export interface IMedicinePackage {
	gtin: string
	availability: string
	deleted: boolean
	euNumber: string
	reseller: string
	packageEntity: IMedicinePackageEntity[]
	rplId: number
}
export interface IMedicinePackageEntity {
	volume: number
	unit: string
	additionalInfo: string
	packageAmount: number
	packageType: string
}
export interface IMedicineActiveSubstance {
	name: string
	substanceAmount: string
	substanceUnit: string
	preparationAmount: string
	preparationUnit: string
	otherAmountDescription: string
}
export interface IMedicineGroup {
	_id?: string
	name: string
	commonName: string
	type: string
	dose: string
	prescripted: boolean
	stockGroup: IMedicineStockGroup
}
export interface IMedicineStockGroup {
	_id?: string
	commonName: string
	type: string
	dose: string
}
