import { ModuleRoute } from 'components/ModuleRoute'
import { FaFileInvoiceDollar, FaFileSignature } from 'react-icons/fa'
import { RouteObject } from 'react-router-dom'
import { Bill } from './Bill'
import { BillList } from './BillList'
import { AttachmentsWrapper } from './views/Attachments'
import { General } from './views/General'
import { GeneralEdit } from './views/GeneralEdit'

const patientBillRoutes: RouteObject[] = [
	{
		path: '/patients/:id/bills',
		element: (
			<ModuleRoute type="CONTRACTS">
				<BillList />
			</ModuleRoute>
		),
		breadcrumb: 'Rozrachunki',
	},
	{
		path: '/patients/:id/bills/:billId',
		element: (
			<ModuleRoute type="CONTRACTS">
				<Bill />
			</ModuleRoute>
		),
		children: [
			{
				index: true,
				element: <General />,
				breadcrumb: 'Rachunek',
				breadcrumbIcon: <FaFileInvoiceDollar />,
			},
			{
				path: 'edit',
				element: <GeneralEdit />,
				breadcrumb: 'Edycja rachunku',
				breadcrumbIcon: <FaFileInvoiceDollar />,
			},
			{
				path: 'attachments',
				element: <AttachmentsWrapper />,
				breadcrumb: 'Lista załączników',
				breadcrumbIcon: <FaFileInvoiceDollar />,
			},
		],
	},
	{
		path: '/patients/:id/bills/new',
		element: (
			<ModuleRoute type="CONTRACTS">
				<Bill />
			</ModuleRoute>
		),
		children: [
			{
				index: true,
				element: <GeneralEdit />,
				breadcrumb: 'Nowy rachunek',
				breadcrumbIcon: <FaFileInvoiceDollar />,
			},
		],
	},
]

export default patientBillRoutes
