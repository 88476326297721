import { useDisclosure } from '@chakra-ui/react'
import { AlertDialog, Link, Table, Tile } from '@kevea/react-components'
import {
	ContractTemplate,
	TableContractTemplate,
} from 'models/contractTemplate'
import { User, UserTable } from 'models/user'
import { SettingsContext } from 'pages/settings/Settings'
import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useOutletContext } from 'react-router-dom'
import {
	Cell,
	Column,
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table'
import { ContractTemplateService } from 'services/contractTemplate'

export const ContractTemplateList = () => {
	const { instance, refetch, ...rest } = useOutletContext<SettingsContext>()
	const removeDisclosure = useDisclosure()
	const [selectedContractTemplate, setSelectedContractTemplate] = useState<
		TableContractTemplate | undefined
	>(undefined)

	const {
		data: contractTemplates,
		isLoading,
		refetch: refetchContractTemplates,
	} = useQuery(
		'fetchContractTemplates',
		ContractTemplate.fetchContractTemplates,
	)

	const data = useMemo(
		() =>
			contractTemplates?.map(
				contractTemplate => new TableContractTemplate(contractTemplate),
			) || [],
		[contractTemplates],
	)

	const [isRemoving, setIsRemoving] = useState(false)
	const removePatient = () => {
		if (selectedContractTemplate) {
			setIsRemoving(true)
			ContractTemplateService.delete(selectedContractTemplate.original._id)
				.then(() => {
					setIsRemoving(false)
					removeDisclosure.onClose()
					refetch()
					setSelectedContractTemplate(undefined)
				})
				.catch(() => {
					setIsRemoving(false)
					removeDisclosure.onClose()
				})
		}
	}

	const columns = useMemo<Column<TableContractTemplate>[]>(
		() => [
			{
				Header: 'Nazwa',
				accessor: 'name',
				Cell: ({ row, value }) => (
					<Link to={row.original.original._id}>{value}</Link>
				),
			},
			{
				Header: 'Typ',
				accessor: 'type',
			},
		],
		[],
	)

	const tableInstance = useTable(
		{ columns, data },
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
	)

	return (
		<Tile {...rest}>
			<AlertDialog
				isRemoving={isRemoving}
				isOpen={removeDisclosure.isOpen}
				onClose={removeDisclosure.onClose}
				header="Usuń Pacjenta"
				onRemove={removePatient}
			>
				Czy na pewno chcesz usunąć szablon umowy ?
			</AlertDialog>
			<Table
				tableInstance={tableInstance}
				loading={isLoading}
				buttons={[
					{
						label: 'Dodaj',
						type: 'add',
						to: 'new',
					},
				]}
				menuOptions={{
					singleSelection: [
						{
							label: 'Usuń',
							onClick: contractTemplates => {
								setSelectedContractTemplate(contractTemplates[0])
								removeDisclosure.onOpen()
							},
						},
					],
				}}
			/>
		</Tile>
	)
}
