import { Select, IconButton } from '@chakra-ui/react'
import { Datepicker, formatDate } from '@kevea/react-components'
import { RhmShiftSettings } from 'models/service'
import React from 'react'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'

export type Props = {
	selectedShift?: RhmShiftSettings
	setSelectedShift?: (shift: RhmShiftSettings) => void
	shiftSettings?: RhmShiftSettings[]
	selectedDate: string
	setSelectedDate: (date: string) => void
}

export const HeaderDatePicker = ({
	selectedDate,
	selectedShift,
	setSelectedDate,
	setSelectedShift,
	shiftSettings,
}: Props) => {
	return (
		<>
			<IconButton
				size="sm"
				icon={<FaAngleLeft />}
				aria-label="Wczoraj"
				onClick={() =>
					setSelectedDate(
						formatDate(
							new Date(
								Date.parse(selectedDate) - 24 * 60 * 60 * 1000,
							).toISOString(),
							'date',
						),
					)
				}
			/>
			<Datepicker date={selectedDate} onDateChange={setSelectedDate} />
			<IconButton
				size="sm"
				icon={<FaAngleRight />}
				aria-label="Jutro"
				onClick={() =>
					setSelectedDate(
						formatDate(
							new Date(
								Date.parse(selectedDate) + 24 * 60 * 60 * 1000,
							).toISOString(),
							'date',
						),
					)
				}
			/>
			{selectedShift && setSelectedShift && shiftSettings && (
				<Select
					w="fit-content"
					size="sm"
					value={selectedShift.name}
					onChange={e =>
						setSelectedShift(
							shiftSettings.find(shift => shift.name === e.target.value) ??
								selectedShift,
						)
					}
				>
					{shiftSettings.map((shift, idx) => (
						<option key={idx} value={shift.name}>
							{shift.name}
						</option>
					))}
				</Select>
			)}
		</>
	)
}
