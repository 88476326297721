import { Address, formatDate } from '@kevea/react-components'
import { Patient } from './patient'
import * as yup from 'yup'
import { OrderService } from 'services/order'
import { Product } from './dictionaries/product'
import { Medicine } from './dictionaries/medicine'
import { WarehouseDocument } from './warehouseDocument'
import { isModuleEnabled } from 'routes'

export type TOrder = {
	_id: string
	number: string
	status:
		| 'new'
		| 'toConfirm'
		| 'sendToFacility'
		| 'confirmed'
		| 'sendToSupplier'
		| 'completed'
		| 'canceled'
	type: 'medicine' | 'product'
	foreignNumber: string
	patients: OrderPatient[]
	products: OrderProduct[]
	warehouseDocument: WarehouseDocument
}
export class OrderPatient {
	constructor(patient?: OrderPatient) {
		this.patient = patient?.patient
		this.medicines = patient?.medicines ?? []
		this.prescriptionNumber = patient?.prescriptionNumber ?? ''
	}
	prescriptionNumber?: string
	patient?: Patient
	medicines?: OrderMedicine[]
}
export class TableOrderPatient {
	constructor(patient: OrderPatient) {
		this.patientName = patient.patient
			? Patient.getFullName(patient.patient)
			: ''
		this.medicines =
			patient.medicines?.map(medicine => new TableOrderMedicine(medicine)) ?? []
		this.prescriptionNumber = patient.prescriptionNumber ?? ''
		this.original = patient
	}
	patientName: string
	medicines: TableOrderMedicine[]
	prescriptionNumber: string
	original: OrderPatient
}
export class OrderMedicine {
	constructor(medicine?: OrderMedicine) {
		this.amount = medicine?.amount ?? 1
		this.medicine = medicine?.medicine
		this.patient = medicine?.patient
	}
	amount?: number
	medicine?: Medicine
	patient?: Patient
	static validationSchema = yup.object({
		medicine: yup.object().required('Wybierz lek'),
		patient: yup.object().required('Wybierz pacjenta'),
		amount: yup
			.number()
			.min(1, 'Wartość niepoprawna')
			.required('Ilość jest wymagana'),
	})
}
export class TableOrderMedicine {
	constructor(medicine: OrderMedicine) {
		this.amount = medicine.amount?.toString() ?? ''
		this.medicineName = medicine.medicine
			? Medicine.getNameWithDose(medicine.medicine)
			: ''
		this.original = medicine
	}
	amount?: string
	medicineName?: string
	original: OrderMedicine
}
export class OrderProduct {
	constructor(product?: OrderProduct) {
		this.product = product && new Product(product?.product)
		this.quantity = product?.quantity ?? 1
	}

	product?: Product
	quantity?: number

	static validationSchema = yup.object({
		product: yup.object().required('Wybierz produkt'),
		quantity: yup
			.number()
			.min(1, 'Wartość niepoprawna')
			.required('Ilość jest wymagana'),
	})
}
export class TableOrderProduct {
	constructor(product: OrderProduct) {
		this.original = product
		this.productName = product.product?.name ?? ''
		this.quantity = product.product
			? `${product.quantity} ${product.product.unit}`
			: ''
	}
	productName: string
	quantity: string
	original: OrderProduct
}

export class Order {
	static checkStatus(allowedStatuses: TOrder['status'][], order: Order) {
		return allowedStatuses.includes(order.status)
	}
	constructor(order?: Order) {
		this._id = order?._id
		this.number = order?.number
		this.status = order?.status ?? 'new'
		this.type =
			order?.type ?? isModuleEnabled('MEDICINE_WAREHOUSE')
				? 'medicine'
				: 'product'
		this.foreignNumber = order?.foreignNumber ?? ''
		this.patients = order?.patients ?? []
		this.products = order?.products ?? []
		this.warehouseDocument = order?.warehouseDocument
	}
	_id?: string
	number?: string
	status:
		| 'new'
		| 'toConfirm'
		| 'sendToFacility'
		| 'confirmed'
		| 'sendToSupplier'
		| 'completed'
		| 'canceled'
	type: 'medicine' | 'product'
	foreignNumber?: string
	patients?: OrderPatient[]
	products?: OrderProduct[]
	warehouseDocument?: WarehouseDocument

	static validationSchema = yup.object({
		type: yup
			.string()
			.oneOf(['medicine', 'product'] as TOrder['type'][])
			.required('Typ zamówienia jest wymagany'),
		products: yup.array().when('type', {
			is: 'product',
			then: yup
				.array()
				.min(1, 'Dodaj przynajmniej jeden produkt do zamówienia'),
		}),
		patients: yup.array().when('type', {
			is: 'medicine',
			then: yup
				.array()
				.min(1, 'Dodaj przynajmniej jednego pacjenata do zamówienia'),
		}),
	})
	static fetchOrders = async () => {
		return OrderService.getAll().then(res => res.data)
	}

	static convertType = (type: TOrder['type']): string => {
		switch (type) {
			case 'medicine':
				return 'Zamówienie leków'
			case 'product':
				return 'Zamówienie produktów'
		}
	}

	static convertStatus = (status: TOrder['status']): string => {
		switch (status) {
			case 'new':
				return 'Nowe'
			case 'toConfirm':
				return 'Do potwierdzenia'
			case 'confirmed':
				return 'Potwierdzone'
			case 'sendToFacility':
				return 'Wysłane do ośrodka'
			case 'sendToSupplier':
				return 'Wysłane do dostawcy'
			case 'completed':
				return 'Zrealizowane'
			case 'canceled':
				return 'Anulowane'
			default:
				return `${status}`
		}
	}
	static getStatusColor = (status: TOrder['status']): string => {
		switch (status) {
			case 'new':
				return 'future'
			case 'toConfirm':
				return 'cyan'
			case 'confirmed':
				return 'green'
			case 'sendToFacility':
				return 'former'
			case 'sendToSupplier':
				return 'teal'
			case 'completed':
				return 'gray'
			case 'canceled':
				return 'dead'
			default:
				return `${status}`
		}
	}
	static statusOptions = [
		{
			label: Order.convertStatus('new'),
			value: 'new',
		},
		{
			label: Order.convertStatus('toConfirm'),
			value: 'toConfirm',
		},
		{
			label: Order.convertStatus('confirmed'),
			value: 'confirmed',
		},
		{
			label: Order.convertStatus('sendToFacility'),
			value: 'sendToFacility',
		},
		{
			label: Order.convertStatus('sendToSupplier'),
			value: 'sendToSupplier',
		},
		{
			label: Order.convertStatus('completed'),
			value: 'completed',
		},
		{
			label: Order.convertStatus('canceled'),
			value: 'canceled',
		},
	]

	static typeOptions = [
		{ label: Order.convertType('medicine'), value: 'medicine' },
		{ label: Order.convertType('product'), value: 'product' },
	]
}

export class TableOrder {
	constructor(order: Order) {
		this._id = order._id
		this.original = order
		this.number = order.number ?? ''
		this.status = Order.convertStatus(order.status)
		this.warehouseDocument = order.warehouseDocument
	}
	_id: string | undefined
	number: string
	status: string
	original: Order
	warehouseDocument?: WarehouseDocument

	static statusOptions = [
		{
			label: Order.convertStatus('new'),
			value: Order.convertStatus('new'),
		},
		{
			label: Order.convertStatus('toConfirm'),
			value: Order.convertStatus('toConfirm'),
		},
		{
			label: Order.convertStatus('confirmed'),
			value: Order.convertStatus('confirmed'),
		},
		{
			label: Order.convertStatus('sendToFacility'),
			value: Order.convertStatus('sendToFacility'),
		},
		{
			label: Order.convertStatus('sendToSupplier'),
			value: Order.convertStatus('sendToSupplier'),
		},
		{
			label: Order.convertStatus('completed'),
			value: Order.convertStatus('completed'),
		},
		{
			label: Order.convertStatus('canceled'),
			value: Order.convertStatus('canceled'),
		},
	]

	static typeOptions = [{ label: Order.convertStatus }]
}
