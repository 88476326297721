import {
	Tile,
	InputField,
	TileSaveButton,
	TileCancelButton,
	Label,
	Dropdown,
} from '@kevea/react-components'
import { AxiosResponse } from 'axios'
import { Formik, Form, FormikProps, useField } from 'formik'
import { ContractTemplate } from 'models/contractTemplate'
import { SettingsContext } from 'pages/settings/Settings'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router'
import { ContractTemplateService } from 'services/contractTemplate'
import { TemplateModal } from 'pages/settings/views/contractTemplates/views/TemplateModal'
import { ContractTemplateContext } from '../ContractTemplate'
import {
	Box,
	Button,
	Text,
	useDisclosure,
	Select,
	FormErrorMessage,
	FormControl,
} from '@chakra-ui/react'
import { TbBraces } from 'react-icons/tb'
import { useQuery } from 'react-query'
import { Editor } from 'components/Editor'
import { Editor as TinyEditor } from '@tinymce/tinymce-react'

type Props = {}

export const GeneralEdit = (props: Props) => {
	const { contractTemplate, refetch, ...rest } =
		useOutletContext<ContractTemplateContext>()
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const { isOpen, onOpen, onClose } = useDisclosure()
	const insertText = (text: string) => {
		if (editor.current) {
			if (text === '{{signatures}}') {
				editor.current.editor.execCommand(
					'insertHTML',
					false,
					'<table style="width: 100%; text-align: center; font-size: 14px; margin-top: 100px;"><tbody><tr><td style="width: 50%;">........................................</td><td style="width: 50%;">........................................</td></tr><tr><td style="width: 50%;">Usługodawca</td><td style="width: 50%;">Usługobiorca</td></tr></tbody></table>',
				)
			} else {
				editor.current.editor.insertContent(text)
			}
		}
	}
	const formik = useRef<FormikProps<ContractTemplate>>(null)
	const editor = useRef<TinyEditor>(null)

	const handleSubmit = (values: ContractTemplate) => {
		const afterSubmit = (response?: AxiosResponse<ContractTemplate>) => {
			setLoading(false)
			navigate('/settings/contractTemplates/' + response?.data?._id ?? '')
		}
		setLoading(true)
		const newContractTemplate: ContractTemplate = {
			...values,
		}

		if (contractTemplate?._id) {
			ContractTemplateService.put(newContractTemplate)
				.then(contractTemplate => {
					return contractTemplate
				})
				.then(afterSubmit)
		} else {
			ContractTemplateService.post(newContractTemplate).then(afterSubmit)
		}
	}
	return (
		<Formik
			validateOnBlur={false}
			validateOnChange={false}
			onSubmit={handleSubmit}
			initialValues={{
				_id: contractTemplate?._id ?? '',
				name: contractTemplate?.name ?? '',
				type: contractTemplate?.type ?? 'contract',
				template: contractTemplate?.template ?? '',
				archived: undefined,
			}}
			innerRef={formik}
			validationSchema={ContractTemplate.validationSchema}
		>
			{({ values, setFieldValue, errors }) => (
				<Form>
					<TemplateModal
						isOpen={isOpen}
						onClose={onClose}
						insertText={insertText}
					/>
					<Tile
						{...rest}
						headerButtons={
							<>
								<TileSaveButton
									onSave={() => formik.current?.submitForm()}
									isLoading={loading}
								/>
								<TileCancelButton
									isLoading={loading}
									onCancel={() =>
										contractTemplate?._id
											? navigate(
													'/settings/contractTemplates/' + contractTemplate._id,
											  )
											: navigate('/settings/contractTemplates')
									}
								/>
							</>
						}
					>
						<InputField name="name" placeholder="Nazwa" label="Nazwa" />
						<Label>Typ</Label>
						<Select
							value={values.type}
							onChange={e => {
								setFieldValue('type', e.target.value)
							}}
						>
							<option value={'contract'}>Umowa</option>
							<option value={'contractAnnex'}>Aneks umowy</option>
						</Select>
						<Label>Szablon</Label>
						<FormControl isInvalid={Boolean(errors?.template)}>
							<Editor
								ref={editor}
								openTemplateModal={onOpen}
								value={values.template}
								onChange={html => setFieldValue('template', html)}
							/>
							{errors?.template && (
								<FormErrorMessage>{errors?.template}</FormErrorMessage>
							)}
						</FormControl>
					</Tile>
				</Form>
			)}
		</Formik>
	)
}
