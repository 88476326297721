import {
	formatDate,
	InputField,
	MaskedInputField,
	ModalDialog,
	NumberField,
	SelectField,
	TextAreaField,
} from '@kevea/react-components'
import { Form, Formik, FormikProps } from 'formik'
import { Measurement } from 'models/measurement'
import { Patient } from 'models/patient'
import React, { useEffect, useRef, useState } from 'react'
import { MeasurementService } from 'services/measurement'

type Props = {
	patient: Patient
	selectedMeasurement?: Measurement
	isOpen: boolean
	onClose: () => void
}

export const MeasurementModal = (props: Props) => {
	const formik = useRef<FormikProps<Measurement>>(null)
	const [loading, setLoading] = useState(false)
	const [type, setType] = useState<number>(1)

	useEffect(() => {
		setType(props.selectedMeasurement?.type ?? 1)
	}, [props.isOpen])

	const handleSubmit = (values: Measurement) => {
		if (formik.current) {
			let newMeasurement = { ...new Measurement(values), ...values }
			newMeasurement = Measurement.parseValue(newMeasurement)
			if (validateValues(formik.current, newMeasurement)) {
				setLoading(true)
				newMeasurement.measurementDate = new Date(
					newMeasurement.measurementDate,
				).toISOString()
				if (props.selectedMeasurement?._id) {
					MeasurementService.put(newMeasurement, props.patient._id).then(() => {
						setLoading(false)
						props.onClose()
					})
				} else {
					MeasurementService.post(newMeasurement, props.patient._id).then(
						() => {
							setLoading(false)
							props.onClose()
						},
					)
				}
			}
		}
	}

	return (
		<>
			{props.isOpen && (
				<ModalDialog
					isLoading={loading}
					header={props.selectedMeasurement ? 'Edytuj Pomiar' : 'Dodaj Pomiar'}
					onClose={props.onClose}
					onSave={() => formik.current?.submitForm()}
					isOpen={props.isOpen}
				>
					<Formik
						validateOnBlur={false}
						validateOnChange={false}
						validationSchema={Measurement.validationSchema}
						innerRef={formik}
						onSubmit={handleSubmit}
						initialValues={
							props.selectedMeasurement
								? {
										...new Measurement({
											...props.selectedMeasurement,
											patient: props.patient,
										}),
										rawValue: Measurement.getRawValue(
											props.selectedMeasurement,
										),
										value: undefined,
										value2: props.selectedMeasurement.value2 ?? 0,
										value3: props.selectedMeasurement.value3 ?? 0,
								  }
								: new Measurement({ patient: props.patient })
						}
					>
						<Form>
							<SelectField
								label="Rodzaj Pomiaru"
								options={Measurement.typeOptions}
								name="type"
								onChange={value => setType(value)}
							/>
							<MeasurementValueField type={type} />
							<InputField
								name="measurementDate"
								placeholder="Data i godzina"
								label="Data i godzina"
								type="datetime-local"
							/>
							<TextAreaField
								name="note"
								placeholder="Notatki"
								label="Notatki"
							/>
						</Form>
					</Formik>
				</ModalDialog>
			)}
		</>
	)
}

export type MeasurementValueFieldProps = {
	type: number
	disabled?: boolean
}

export const MeasurementValueField = ({
	type,
	disabled,
}: MeasurementValueFieldProps) => {
	const props = { label: 'Wartość', name: 'rawValue', placeholder: 'Wartość' }
	switch (type) {
		case 1:
			return (
				<MaskedInputField disabled={disabled} mask="00[0]/00[0]" {...props} />
			)
		case 2:
			return (
				<NumberField
					disabled={disabled}
					inputRightAddon="mg/dl"
					precision={0}
					{...props}
				/>
			)
		case 3:
			return (
				<NumberField
					disabled={disabled}
					inputRightAddon="&#176;C"
					min={30}
					max={45}
					step={0.1}
					{...props}
				/>
			)
		case 4:
			return (
				<NumberField
					disabled={disabled}
					inputRightAddon="/min"
					min={0}
					max={300}
					precision={0}
					{...props}
				/>
			)
		case 5:
			return (
				<NumberField
					disabled={disabled}
					inputRightAddon="%"
					min={50}
					max={100}
					precision={0}
					{...props}
				/>
			)
		case 6:
			return (
				<NumberField
					disabled={disabled}
					inputRightAddon="kg"
					min={30}
					max={250}
					step={0.1}
					{...props}
				/>
			)
		case 7:
			return (
				<NumberField
					disabled={disabled}
					inputRightAddon="cm"
					min={100}
					max={250}
					step={0.1}
					{...props}
				/>
			)
		case 8:
			return (
				<NumberField
					disabled={disabled}
					inputRightAddon="ml"
					min={0}
					max={10000}
					step={0.1}
					{...props}
				/>
			)
		case 9:
			return (
				<>
					<MaskedInputField
						disabled={disabled}
						mask="00[0]/00[0]"
						label="Ciśnienie"
						name="rawValue"
						placeholder="Ciśnienie"
					/>
					<NumberField
						disabled={disabled}
						inputRightAddon="/min"
						min={0}
						max={300}
						precision={0}
						label="Tętno"
						name="value3"
						placeholder="Tętno"
					/>
				</>
			)
		case 10:
			return (
				<>
					<NumberField
						disabled={disabled}
						name="rawValue"
						inputRightAddon="mg/dl"
						precision={0}
						label="Glukoza"
						placeholder="Glukoza"
					/>
					<NumberField
						disabled={disabled}
						name="value2"
						inputRightAddon="j."
						precision={0}
						label="Insulina"
						placeholder="Insulina"
					/>
				</>
			)
		case 11:
			return (
				<SelectField
					disabled={disabled}
					label="Wartość"
					{...props}
					options={Measurement.stoolOptions}
				/>
			)
		default:
			return <InputField {...props} />
	}
}
export const validateValues = (
	formik: FormikProps<any>,
	measurement: Measurement,
	hideErrors?: boolean,
) => {
	const throwError = (field?: string) => {
		if (hideErrors) return false
		if (field) {
			formik.setFieldError(field, 'Wartość niepoprawna')
		} else {
			formik.setFieldError('rawValue', 'Wartość niepoprawna')
		}
		return false
	}

	const { type, value, value2, value3 } = measurement

	switch (type) {
		case 1:
			if (value === undefined) return throwError()
			if (value2 === undefined) return throwError()
			if (isNaN(value) || isNaN(value2)) return throwError()
			break
		case 2:
			if (value === undefined) return throwError()
			if (isNaN(value)) return throwError()
			break
		case 3:
			if (value === undefined) return throwError()
			if (isNaN(value)) return throwError()
			break
		case 4:
			if (value === undefined) return throwError()
			if (isNaN(value)) return throwError()
			break
		case 5:
			if (value === undefined) return throwError()
			if (isNaN(value)) return throwError()
			break
		case 6:
			if (value === undefined) return throwError()
			if (isNaN(value)) return throwError()
			break
		case 7:
			if (value === undefined) return throwError()
			if (isNaN(value)) return throwError()
			break
		case 8:
			if (value === undefined) return throwError()
			if (isNaN(value)) return throwError()
			break

		case 9:
			if (value === undefined) return throwError()
			if (value2 === undefined) return throwError()
			if (value3 === undefined || isNaN(value3)) return throwError('value3')
			if (isNaN(value) || isNaN(value2)) return throwError()
			break
		case 10:
			if (value === undefined) return throwError()
			if (value2 === undefined || isNaN(value2)) return throwError('value2')
			if (isNaN(value)) return throwError()
			break
		case 11:
			if (value === undefined) return throwError()
			if (isNaN(value)) return throwError()
			break
		default:
			break
	}
	return true
}
