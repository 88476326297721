import {
	Box,
	Button,
	CloseButton,
	Flex,
	Heading,
	IconButton,
	ListItem,
	OrderedList,
	Select,
	Tag,
	TagCloseButton,
	TagLabel,
	UnorderedList,
	useDisclosure,
} from '@chakra-ui/react'
import { InputField, Label, ModalDialog } from '@kevea/react-components'
import { useField, useFormikContext } from 'formik'
import React, { useMemo } from 'react'
import { FaRegQuestionCircle } from 'react-icons/fa'

type Props = {
	name: string
}

export const allergens = [
	{
		label:
			'1 - Zboża zawierające gluten (pszenica, żyto, jęczmień, owies, orkisz, orkisz) oraz produkty pochodne',
		value: 1,
	},
	{ label: '2 - Skorupiaki oraz produkty pochodne', value: 2 },
	{ label: '3 - Jaja i produkty pochodne', value: 3 },
	{ label: '4 - Ryby i produkty pochodne', value: 4 },
	{
		label: '5 - Orzeszki ziemnie, orzechy arachidowe oraz produkty pochodne',
		value: 5,
	},
	{ label: '6 - Soja i produkty pochodne', value: 6 },
	{ label: '7 - Mleko i produkty pochodne', value: 7 },
	{
		label:
			'8 - Orzechy: migdały, orzechy laskowe, włoskie, nerkowca, brazylijskie, pistacjowe oraz produkty pochodne',
		value: 8,
	},
	{ label: '9 - Seler i produkty pochodne', value: 9 },
	{ label: '10 - Gorczyca i produkty pochodne', value: 10 },
	{
		label: '11 - Nasiona sezamu i produkty pochodne',
		value: 11,
	},
	{
		label:
			'12 - Dwutlenek siarki i siarczany w stężeniach powyżej 10mg/kg lub 10mg/ w przeliczeniu na S02',
		value: 12,
	},
	{ label: '13 - Łubin i produkty pochodne', value: 13 },
	{ label: '14 - Mięczaki i produkty pochodne', value: 14 },
]

export const AllergensPicker = ({ name }: Props) => {
	const [{ value }, {}, { setValue }] = useField(name)

	const options = useMemo(
		() =>
			value && Array.isArray(value)
				? allergens.filter(option => !value?.includes(option.value))
				: allergens,
		[value],
	)

	return (
		<Box>
			<Label>Alergeny:</Label>
			{value &&
				Array.isArray(value) &&
				value.map(
					allergenValue =>
						typeof allergenValue === 'number' && (
							<Symbol
								label={allergens[allergenValue - 1].label}
								name={name}
								allergenValue={allergenValue}
								key={allergenValue}
							/>
						),
				)}
			<Select
				marginTop={2}
				placeholder="Wybierz alergen"
				value=""
				onChange={e => {
					setValue([...value, +e.target.value])
				}}
			>
				{options.map(({ label, value }) => (
					<option key={value} value={value}>
						{label}
					</option>
				))}
			</Select>
		</Box>
	)
}

export const Symbol = ({
	allergenValue,
	name,
	label,
}: {
	allergenValue: number
	label: string
	name: string
}) => {
	const [{ value }, {}, { setValue }] = useField(name)

	const removeSymbol = () => {
		setValue(value.filter((s: number) => s !== allergenValue))
	}

	return (
		<Tag marginRight={2} title={label}>
			<TagLabel>{allergenValue}</TagLabel>
			<TagCloseButton onClick={removeSymbol} />
		</Tag>
	)
}
