import { Button, Center, Td, useDisclosure } from '@chakra-ui/react'
import { Link, Table } from '@kevea/react-components'
import { OrderProduct, Order, TableOrderProduct } from 'models/order'
import React, { useCallback, useMemo, useState } from 'react'
import { CellProps, Column, useTable } from 'react-table'
import { OrderProductModal } from './OrderProductModal'

type ProductTableProps = {
	order: Order
	setProductsField?: (products: OrderProduct[]) => void
}

export const ProductTable: React.FC<ProductTableProps> = ({
	order,
	setProductsField,
}) => {
	const [selectedProduct, setSelectedProduct] = useState<
		OrderProduct | undefined
	>(undefined)

	const openDisclosure = useDisclosure()

	const data = useMemo(
		() => order.products?.map(product => new TableOrderProduct(product)) ?? [],
		[order.products],
	)

	const removeProduct = (id: string, rows: TableOrderProduct[]) => {
		if (order.products && setProductsField && id) {
			const products = Array.from(
				rows.map(tableProduct => tableProduct.original),
			)
			const idx = products.findIndex(product => product.product?._id === id)
			products.splice(idx, 1)
			setProductsField(products)
		}
	}

	const columns = useMemo<Column<TableOrderProduct>[]>(
		() => [
			{
				Header: 'Produkt',
				accessor: 'productName',
				Cell: ({ value, row }) =>
					setProductsField ? (
						<Link
							onClick={() => {
								setSelectedProduct(row.original.original)
								openDisclosure.onOpen()
							}}
						>
							{value}
						</Link>
					) : (
						<>{value}</>
					),
			},
			{
				Header: 'Ilość',
				accessor: 'quantity',
			},
			{
				Header: '',
				id: 'remove',
				Cell: ({ row, rows }: CellProps<TableOrderProduct>) => (
					<Link
						textAlign="center"
						color="remove.500"
						onClick={() =>
							removeProduct(
								row.original.original.product?._id ?? '',
								rows.map(row => row.original),
							)
						}
					>
						Usuń
					</Link>
				),
			},
		],
		[],
	)

	const tableInstance = useTable({
		columns: columns,
		data: data,
		initialState: { hiddenColumns: setProductsField ? [] : ['remove'] },
	})

	const selectedProductIds = useMemo(
		() => order.products?.map(product => product.product?._id ?? '') ?? [],
		[order.products],
	)

	return (
		<div>
			<OrderProductModal
				isOpen={openDisclosure.isOpen}
				orderProduct={selectedProduct}
				onClose={orderProduct => {
					if (orderProduct && setProductsField) {
						if (selectedProduct) {
							const products = order.products
								? [
										...tableInstance.rows
											.map(row => row.original)
											.map(row => row.original),
								  ]
								: []
							const idx = products.findIndex(
								product => product.product?._id === orderProduct?.product?._id,
							)
							if (idx !== -1) products[idx] = orderProduct
							setProductsField(products)
						} else {
							setProductsField([...(order.products ?? []), orderProduct])
						}
					}
					openDisclosure.onClose()
				}}
				alreadySelectedProductIds={selectedProductIds}
			/>
			<Table
				hideNumberOfRows
				tableInstance={tableInstance}
				footer={
					setProductsField ? (
						<Td colSpan={tableInstance.visibleColumns.length}>
							<Center>
								<Button
									size="sm"
									colorScheme="add"
									onClick={() => {
										setSelectedProduct(undefined)
										openDisclosure.onOpen()
									}}
								>
									Dodaj
								</Button>
							</Center>
						</Td>
					) : null
				}
			/>
		</div>
	)
}
