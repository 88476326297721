import { AxiosResponse } from 'axios'
import { Task } from 'models/task'
import { resolvePath, Service } from 'services'
import { getFullDayDates } from 'utils/time'
import http from './axios'

const path = '/tasks'
const objectPath = '/patients'

export class TaskService {
	static async getDay(
		patientId: string,
		date: string,
	): Promise<AxiosResponse<Task[]>> {
		const [fromDate, toDate] = getFullDayDates(new Date(date))

		return http.get(
			`${resolvePath({
				path,
				objectPath,
				objectService: true,
				objId: patientId,
			})}?fromDate=${fromDate}&toDate=${toDate}`,
		)
	}
	static async post(
		task: Task,
		patientId: string,
	): Promise<AxiosResponse<Task>> {
		if (!task.date) throw new Error('Date not provided')
		const [fromDate] = getFullDayDates(new Date(task.date))

		return http.post(
			`${resolvePath({
				path,
				objectPath,
				objectService: true,
				objId: patientId,
			})}?date=${fromDate}`,
			task,
		)
	}
	static async put(
		task: Task,
		patientId: string,
		originalDate: string,
	): Promise<AxiosResponse<Task>> {
		if (!task.date) throw new Error('Date not provided')
		const id = task.taskPlan
			? `${task.taskPlan}?date=${originalDate}`
			: task._id

		return http.put(
			`${resolvePath({
				path,
				objectPath,
				objectService: true,
				objId: patientId,
			})}/${id}`,
			task,
		)
	}
	static async delete(task: Task, patientId: string): Promise<AxiosResponse> {
		const id = task.taskPlan ? `${task.taskPlan}?date=${task.date}` : task._id

		return http.delete(
			`${resolvePath({
				path,
				objectPath,
				objectService: true,
				objId: patientId,
			})}/${id}`,
		)
	}
}
