import {
	Box,
	Flex,
	FormControl,
	FormErrorMessage,
	useDisclosure,
	Text,
} from '@chakra-ui/react'
import { Dropdown, ModalDialog, NumberField } from '@kevea/react-components'
import { Form, Formik, FormikProps } from 'formik'
import debounce from 'lodash.debounce'
import { IMedicineGroup, Medicine } from 'models/dictionaries/medicine'
import { Patient } from 'models/patient'
import { PatientModalList } from 'pages/patients/PatientModalList'
import { MedicineModalList } from 'pages/settings/views/dictionaries/medicines/MedicineModalList'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import * as yup from 'yup'

type Props = {
	initialValues: {
		patient: Patient | null
		medicineGroup: IMedicineGroup | null
		quantity?: number
	}
	isOpen: boolean
	onClose: ({
		medicineGroup,
		patient,
		quantity,
	}: {
		patient: Patient
		medicineGroup: IMedicineGroup
		quantity: number
	}) => void
}

export const PatientMedicinePicker = ({
	initialValues,
	isOpen,
	onClose,
}: Props) => {
	const [selectedPatient, setSelectedPatient] = useState(initialValues.patient)
	const [selectedMedicineGroup, setSelectedMedicineGroup] = useState(
		initialValues.medicineGroup,
	)
	const [medicineGroups, setMedicineGroups] = useState<
		(IMedicineGroup & { nameWithDose: string })[]
	>([])

	const formik = useRef<FormikProps<Props['initialValues']>>(null)

	const handleSubmit = (values: Props['initialValues']) => {
		onClose({
			medicineGroup: values.medicineGroup,
			patient: values.patient,
			quantity: parseFloat(`${values.quantity}`),
		})
	}

	useEffect(() => {
		if (isOpen) {
			setSelectedPatient(initialValues.patient)
			setSelectedMedicineGroup(initialValues.medicineGroup)
		}
	}, [isOpen, initialValues])

	const { data: patientsRaw } = useQuery(
		'fetchPatients',
		() => Patient.fetchPatients(),
		{ enabled: isOpen },
	)

	const patients = useMemo(() => {
		return patientsRaw?.map(p => new Patient(p)) ?? []
	}, [patientsRaw])

	const showAllMedicinesDisclosure = useDisclosure()
	const showAllPatientsDisclosure = useDisclosure()

	const changePatient = (patient?: Patient) => {
		formik.current?.setFieldValue(
			'patient',
			patient ? new Patient(patient) : undefined,
		)
		setSelectedPatient(new Patient(patient))
	}
	const changeMedicineGroup = (medicineGroup?: IMedicineGroup) => {
		formik.current?.setFieldValue('medicineGroup', medicineGroup)
		setSelectedMedicineGroup(medicineGroup)
	}
	const handleFilterChange = useCallback(
		debounce((filter: string) => {
			if (filter) {
				Medicine.fetchMedicineGroups({ filter }).then(x =>
					setMedicineGroups(
						x.map(m => ({ ...m, nameWithDose: Medicine.getNameWithDose(m) })),
					),
				)
			} else {
				setMedicineGroups([])
			}
		}, 100),
		[],
	)

	return (
		<ModalDialog
			header={'Edytuj pozycję'}
			isOpen={isOpen}
			onClose={() =>
				onClose({ medicineGroup: null, patient: null, quantity: null })
			}
			onSave={() => formik.current?.submitForm()}
		>
			<PatientModalList
				isOpen={showAllPatientsDisclosure.isOpen}
				onClose={patient => {
					if (patient) changePatient(patient)
					showAllPatientsDisclosure.onClose()
				}}
			/>
			{/* <MedicineModalList
				isOpen={showAllMedicinesDisclosure.isOpen}
				onClose={medicine => {
					if (medicine) changeMedicineGroup(medicine)
					showAllMedicinesDisclosure.onClose()
				}}
			/> */}
			<Formik
				validateOnBlur={false}
				validateOnChange={false}
				innerRef={formik}
				onSubmit={handleSubmit}
				initialValues={{ ...initialValues }}
				validationSchema={yup.object({
					patient: yup.mixed().required('Pacjent jest wymagany'),
					medicineGroup: yup.mixed().required('Lek jest wymagany'),
					quantity: yup
						.number()
						.required('Ilość jest wyamgana')
						.min(1, 'Niepoprawna ilość'),
				})}
			>
				{({ errors, values }) => (
					<Form>
						<FormControl isInvalid={Boolean(errors.patient)}>
							<Dropdown
								displayAccessor="fullName"
								items={patients}
								selectedItem={selectedPatient}
								setSelectedItem={changePatient}
								placeholder="Wybierz pacjenta"
								label="Pacjent"
								render={(patient: Patient) => patient.fullName}
								numberOfResults={6}
								onShowAllClick={showAllPatientsDisclosure.onOpen}
								name="patient"
								disabled={Boolean(initialValues.patient)}
							/>
							{errors.patient && (
								<FormErrorMessage>{errors.patient}</FormErrorMessage>
							)}
						</FormControl>
						<FormControl isInvalid={Boolean(errors.medicineGroup)}>
							<Dropdown
								disabled={Boolean(initialValues.medicineGroup)}
								displayAccessor="nameWithDose"
								items={medicineGroups.map(m => ({
									...m,
									nameWithDose: Medicine.getNameWithDose(m),
								}))}
								selectedItem={selectedMedicineGroup}
								setSelectedItem={changeMedicineGroup}
								serverSideFilter
								onFilterChange={handleFilterChange}
								placeholder="Wybierz lek"
								label="Lek"
								render={(
									medicineGroup: IMedicineGroup & { nameWithDose: string },
								) => (
									<Box>
										<Text fontWeight="semibold" isTruncated>
											{medicineGroup.nameWithDose}
										</Text>
										<Flex justifyContent="space-between">
											<Box>{medicineGroup.commonName}</Box>
										</Flex>
									</Box>
								)}
								numberOfResults={6}
								// onShowAllClick={showAllMedicinesDisclosure.onOpen}
								name="medicineGroup"
							/>
							{errors.medicineGroup && (
								<FormErrorMessage>{errors.medicineGroup}</FormErrorMessage>
							)}
						</FormControl>
						<NumberField
							autoFocus
							name="quantity"
							placeholder="Ilość"
							label="Ilość"
							min={1}
						/>
					</Form>
				)}
			</Formik>
		</ModalDialog>
	)
}
