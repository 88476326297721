import { Text, useDisclosure } from '@chakra-ui/react'
import {
	Dropdown,
	InputField,
	ModalDialog,
	SelectField,
	useErrorToast,
} from '@kevea/react-components'
import { Form, Formik, FormikProps } from 'formik'
import { CurrentUserContext } from 'layout/Layout'
import { TaskType } from 'models/dictionaries/taskType'
import { Measurement } from 'models/measurement'
import { Patient } from 'models/patient'
import { Task } from 'models/task'
import { User } from 'models/user'
import { PatientModalList } from 'pages/patients/PatientModalList'
import { TaskTypeModal } from 'pages/settings/views/dictionaries/tasks/TaskTypeModal'
import { TaskTypeModalList } from 'pages/settings/views/dictionaries/tasks/TaskTypeModalList'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { TaskService } from 'services/task'
import {
	MeasurementValueField,
	validateValues,
} from '../patients/views/MeasurementModal'

type Props = {
	patient?: Patient
	selectedTask?: Task
	isOpen: boolean
	onClose: () => void
	date: string
}

export const TaskModal = (props: Props) => {
	const formik = useRef<FormikProps<Task>>(null)
	const [loading, setLoading] = useState(false)
	const [type, setType] = useState<TaskType | null>(null)
	const addDisclosure = useDisclosure()
	const showAllTypesDisclosure = useDisclosure()
	const showAllPatientsDisclosure = useDisclosure()
	const [selectedPatient, setSelectedPatient] = useState(
		props.patient ?? props.selectedTask?.patient,
	)
	const [selectedEmployee, setSelectedEmployee] = useState<User | null>(
		props.selectedTask?.assignTo || null,
	)
	const error = useErrorToast()

	const { data: taskTypes, refetch: refetchTaskTypes } = useQuery(
		'fetchTaskTypes',
		TaskType.fetchTaskTypes,
	)
	const { data: patientsRaw, refetch: refetchPatients } = useQuery(
		'fetchPatients',
		() => Patient.fetchPatients(),
		{ enabled: !props.patient },
	)
	const { data: employees, refetch: refetchEmployees } = useQuery(
		'fetchEmployees',
		User.fetchUsers,
	)

	const patients = useMemo<Patient[]>(
		() => patientsRaw?.map(patient => new Patient(patient)) ?? [],
		[patientsRaw],
	)

	useEffect(() => {
		setType(props.selectedTask?.type ?? null)
		setSelectedPatient(props.patient ?? props.selectedTask?.patient)
		setSelectedEmployee(props.selectedTask?.assignTo || null)
	}, [props.isOpen])

	useEffect(() => {
		formik.current?.setFieldValue('taskTypeId', type?._id)
		formik.current?.setFieldValue('type', type)
		formik.current?.setFieldValue('patientId', selectedPatient?._id)
		formik.current?.setFieldValue('patient', selectedPatient)
		refetchTaskTypes()
		refetchPatients()
	}, [type, selectedPatient])

	const currentUser = useContext(CurrentUserContext)

	const handleSubmit = (values: Task) => {
		if (selectedPatient) {
			const submit = (task: Task) => {
				if (task.measurement) {
					task.measurement.measurementDate = new Date(task.date).toISOString()
				}
				setLoading(true)
				// if (props.selectedTask?.status !== task.status && task.status === 2) {
				// 	task.completedBy = { ...currentUser, pinCode: undefined }
				// 	task.completedAt = new Date().toISOString()
				// }
				// ! ISSUES WHEN COMPLETING TASKS AS KEVEA ADMIN CAREFUL WHEN IMPLEMENTING!
				if (task._id || task.taskPlan) {
					TaskService.put(
						task,
						selectedPatient._id ?? '',
						props.selectedTask?.date ?? '',
					)
						.then(res => res.data)
						.then(task => {
							setLoading(false)
							setSelectedPatient(undefined)
							props.onClose()
						})
				} else {
					TaskService.post(task, selectedPatient._id ?? '')
						.then(res => res.data)
						.then(task => {
							setLoading(false)
							setSelectedPatient(undefined)
							props.onClose()
						})
				}
			}

			if (formik.current) {
				const task = new Task({
					...props.selectedTask,
					...values,
					assignTo: selectedEmployee,
				})
				if (!task.taskPlan) {
					task.date = new Date(`${props.date}T${values.time}`).toISOString()
					task.time = values.time
				}
				if (task.type?.requiresMeasurement) {
					if (!task.measurement)
						task.measurement = new Measurement({
							patient: selectedPatient,
							measurementDate: new Date(task.date).toISOString(),
						})
					task.measurement.rawValue = task.rawValue
					task.measurement.value2 = task.value2
					task.measurement.value3 = task.value3
					task.measurement.type = task.type.requiresMeasurement
					task.measurement = Measurement.parseValue(task.measurement)
				}
				if (
					task.type?.requiresMeasurement &&
					task.status === 2 &&
					task.measurement
				) {
					if (validateValues(formik.current, task.measurement)) {
						submit(task)
					}
				} else {
					if (
						task.measurement &&
						!validateValues(formik.current, task.measurement, true)
					)
						delete task.measurement
					submit(task)
				}
			}
		} else {
			error({ description: 'Wybierz pacjenta' })
		}
	}

	return (
		<>
			{props.isOpen && (
				<ModalDialog
					isLoading={loading}
					header={props.selectedTask ? 'Edytuj zadanie' : 'Dodaj zadanie'}
					onClose={props.onClose}
					onSave={() => formik.current?.submitForm()}
					isOpen={props.isOpen}
				>
					<TaskTypeModal
						isOpen={addDisclosure.isOpen}
						onClose={taskType => {
							if (taskType) setType(taskType)
							addDisclosure.onClose()
						}}
						selectedTaskType={type ?? undefined}
					/>
					<TaskTypeModalList
						isOpen={showAllTypesDisclosure.isOpen}
						onClose={taskType => {
							if (taskType) setType(taskType)
							showAllTypesDisclosure.onClose()
						}}
					/>
					<Formik
						validateOnBlur={false}
						validateOnChange={false}
						validationSchema={Task.validationSchema}
						innerRef={formik}
						onSubmit={handleSubmit}
						initialValues={
							props.selectedTask
								? new Task({
										...props.selectedTask,
										rawValue: props.selectedTask.measurement
											? Measurement.getRawValue(props.selectedTask.measurement)
											: '',
								  })
								: new Task({ patient: selectedPatient || new Patient() })
						}
					>
						<Form>
							{!props.patient && !props.selectedTask?.patient && (
								<>
									<PatientModalList
										isOpen={showAllPatientsDisclosure.isOpen}
										onClose={patient => {
											if (patient) setSelectedPatient(new Patient(patient))
											showAllPatientsDisclosure.onClose()
										}}
									/>
									<Dropdown
										displayAccessor="fullName"
										items={patients}
										selectedItem={selectedPatient}
										setSelectedItem={setSelectedPatient}
										placeholder="Wybierz pacjenta"
										label="Pacjent"
										render={(patient: Patient) => patient.fullName}
										numberOfResults={6}
										onShowAllClick={showAllPatientsDisclosure.onOpen}
									/>
								</>
							)}
							{taskTypes && (
								<Dropdown
									disabled={Boolean(props.selectedTask?.taskPlan)}
									label="Rodzaj zadania"
									placeholder="Inne"
									items={taskTypes}
									displayAccessor="name"
									selectedItem={type}
									setSelectedItem={setType}
									onAddNewClick={addDisclosure.onOpen}
									onShowAllClick={showAllTypesDisclosure.onOpen}
									render={(taskType: TaskType) => (
										<>
											<Text>{taskType.name}</Text>
											<Text fontSize="sm" color="gray.500">
												{taskType.description}
											</Text>
										</>
									)}
								/>
							)}
							{type?.requiresMeasurement && (
								<MeasurementValueField
									type={type.requiresMeasurement}
									disabled={Boolean(props.selectedTask?.measurement)}
								/>
							)}
							{!type && (
								<InputField
									name="title"
									placeholder="Nazwa"
									label="Nazwa"
									isRequired
								/>
							)}
							<InputField
								disabled={Boolean(props.selectedTask?.taskPlan)}
								name="time"
								placeholder="Godzina wykonania"
								label="Godzina wykonania"
								type="time"
								autoFocus
							/>
							<SelectField
								name="status"
								label="Status"
								options={Task.options}
							/>
							<SelectField
								name="assignTo"
								value={selectedEmployee ? selectedEmployee._id : ''}
								label="Przypisane do"
								onChange={value => {
									setSelectedEmployee(employees?.find(e => e._id === value))
								}}
								options={[
									{ label: 'Brak', value: '' },
									...employees?.map(employee => ({
										value: employee._id,
										label: employee.firstName + ' ' + employee.lastName,
									})),
								]}
							/>
						</Form>
					</Formik>
				</ModalDialog>
			)}
		</>
	)
}
