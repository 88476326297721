import { useDisclosure } from '@chakra-ui/react'
import { AlertDialog, Link, Table, Tile } from '@kevea/react-components'
import { Dish } from 'models/dictionaries/dish'
import { SettingsContext } from 'pages/settings/Settings'
import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useOutletContext } from 'react-router-dom'
import {
	Cell,
	Column,
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table'
import { DishService } from 'services/dictionaries/dish'
import { DishModal } from './DishModal'

export const DishList = () => {
	const { instance, refetch, ...rest } = useOutletContext<SettingsContext>()
	const removeDisclosure = useDisclosure()
	const [isRemoving, setIsRemoving] = useState(false)
	const addDisclosure = useDisclosure()
	const [selectedDish, setSelectedDish] = useState<Dish | undefined>(undefined)

	const {
		data: dishes,
		isLoading,
		refetch: refetchDishes,
	} = useQuery('fetchDishes', Dish.fetchDishes)

	const data = useMemo(
		() => dishes?.map(dish => new Dish(dish)) || [],
		[dishes],
	)

	const columns = useMemo<Column<Dish>[]>(
		() => [
			{
				Header: 'Nazwa',
				accessor: 'name',
				Cell: cell => (
					<Link
						onClick={() => {
							setSelectedDish(cell.row.original)
							addDisclosure.onOpen()
						}}
					>
						{cell.value}
					</Link>
				),
			},
			{
				Header: 'Kaloryczność',
				accessor: 'calories',
				Cell: cell => (cell.value !== undefined ? cell.value + ' kcal' : '-'),
			},
			{
				Header: 'Gramatura',
				accessor: 'weight',
				Cell: cell => (cell.value !== undefined ? cell.value + ' g' : '-'),
			},
			{
				Header: 'Opis',
				accessor: 'description',
			},
		],
		[],
	)

	const tableInstance = useTable(
		{ columns, data },
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
	)

	return (
		<Tile {...rest}>
			<AlertDialog
				header="Usuń danie"
				onClose={removeDisclosure.onClose}
				isRemoving={isRemoving}
				isOpen={removeDisclosure.isOpen}
				onRemove={() => {
					if (selectedDish?._id) {
						setIsRemoving(true)
						DishService.delete(selectedDish._id).then(() => {
							setIsRemoving(false)
							setSelectedDish(undefined)
							removeDisclosure.onClose()
							refetchDishes()
						})
					}
				}}
			>
				Czy chcesz usunąć danie <strong>{selectedDish?.name}</strong>?
			</AlertDialog>
			<DishModal
				selectedDish={selectedDish}
				isOpen={addDisclosure.isOpen}
				onClose={dish => {
					addDisclosure.onClose()
					refetchDishes()
				}}
			/>
			<Table
				tableInstance={tableInstance}
				loading={isLoading}
				buttons={[
					{
						label: 'Dodaj',
						type: 'add',
						action: () => {
							setSelectedDish(undefined)
							addDisclosure.onOpen()
						},
					},
				]}
				menuOptions={{
					singleSelection: [
						{
							label: 'Usuń',
							onClick: dishes => {
								setSelectedDish(dishes[0])
								removeDisclosure.onOpen()
							},
						},
					],
				}}
			/>
		</Tile>
	)
}
