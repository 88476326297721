import { AxiosResponse } from 'axios'
import { DiapersOrder } from 'models/diapersOrder'
import { getBaseInstancePath, printConfig, Service } from 'services'
import http from './axios'

const path = '/diapersOrders'

export class DiapersOrderService extends Service<DiapersOrder>({
	path,
}) {
	static async changeStatus(
		diapersOrder: DiapersOrder,
		status: number,
	): Promise<AxiosResponse<DiapersOrder>> {
		const order: DiapersOrder = { ...diapersOrder }
		order.status = status
		return http.put(getBaseInstancePath(path) + `/${diapersOrder._id}`, order)
	}
	static async print(id: string): Promise<Blob> {
		return http
			.get<Blob>(`${getBaseInstancePath(path)}/${id}/print`, printConfig)
			.then(res => res.data)
	}
}
