import { ModuleRoute } from 'components/ModuleRoute'
import { RestrictRoute } from 'components/RestrictRoute'
import { Attachments } from 'pages/Attachments'
import { FaMedkit } from 'react-icons/fa'
import { RouteObject } from 'react-router-dom'
import { DiapersOrder } from './DiapersOrder'
import { DiapersOrderList } from './DiapersOrderList'
import { AttachmentsWrapper } from './views/Attachments'
import { General } from './views/General'

const diapersOrdersRoutes: RouteObject[] = [
	{
		path: 'nfz-orders',
		element: (
			<ModuleRoute type="NFZ_ORDERS">
				<RestrictRoute module="nfz_orders" power="view">
					<DiapersOrderList />
				</RestrictRoute>
			</ModuleRoute>
		),
		breadcrumb: 'Lista Zleceń NFZ',
	},
	{
		path: 'nfz-orders/:id',
		element: (
			<ModuleRoute type="NFZ_ORDERS">
				<RestrictRoute module="nfz_orders" power="view">
					<DiapersOrder />
				</RestrictRoute>
			</ModuleRoute>
		),
		children: [
			{
				index: true,
				element: <General />,
				breadcrumb: 'Zlecenie NFZ',
				breadcrumbIcon: <FaMedkit />,
			},
			{
				path: 'attachments',
				element: <AttachmentsWrapper />,
				breadcrumb: 'Lista załączników',
				breadcrumbIcon: <FaMedkit />,
			},
		],
	},
]

export default diapersOrdersRoutes
