import { Tasks } from 'pages/tasks/Tasks'
import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { ReportContext } from '../Report'

type Props = {}

export const ReportTasks = (props: Props) => {
	const {
		breadcrumbs,
		selectedDate,
		selectedShift,
		setSelectedDate,
		setSelectedShift,
		shiftSettings,
	} = useOutletContext<ReportContext>()

	return (
		<Tasks
			selectedDate={selectedDate}
			setSelectedDate={setSelectedDate}
			breadcrumbs={breadcrumbs}
			selectedShift={selectedShift}
			setSelectedShift={setSelectedShift}
			shiftSettings={shiftSettings}
		/>
	)
}
