import { NoteDictionary } from 'models/dictionaries/note'
import { Service } from 'services'

const path = '/noteTypes'
const objectPath = '/dictionaries'

export class NoteDictionaryService extends Service<NoteDictionary>({
	objectPath,
	path,
	objectService: true,
}) {}
