import * as yup from 'yup'
import { ContractTemplateService } from 'services/contractTemplate'

export type TContractTemplate = {
	_id: string
	name: string
	type: ContractTemplate['type']
	template: string
}

export class ContractTemplate {
	_id: string | undefined
	name: string
	type: 'contract' | 'contractAnnex'
	template: string
	archived: boolean
	constructor(contract?: ContractTemplate) {
		this._id = contract?._id ?? ''
		this.name = contract?.name ?? ''
		this.template = contract?.template ?? null
		this.archived = contract?.archived ?? false
	}

	static validationSchema = yup.object({
		_id: yup.string(),
		name: yup.string().required('Nazwa jest wymgana'),
		type: yup.string().oneOf(['contract', 'contractAnnex']),
		template: yup.string().required('Szablon nie może być pusty'),
	})

	static convertType = (type: ContractTemplate['type']): string => {
		switch (type) {
			case 'contract':
				return 'Umowa'
			case 'contractAnnex':
				return 'Aneks umowy'
			default:
				return `${type}`
		}
	}

	static fetchContractTemplates = async () => {
		return ContractTemplateService.getAll().then(res => res.data)
	}
}

export class TableContractTemplate {
	name: string
	type: string
	original: ContractTemplate
	constructor(contract: ContractTemplate) {
		this.name = contract.name ?? ''
		this.type = ContractTemplate.convertType(contract.type) ?? ''
		this.original = contract ?? new ContractTemplate()
	}
}
