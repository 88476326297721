import { Input, useDisclosure } from '@chakra-ui/react'
import {
	AlertDialog,
	InputField,
	Label,
	ModalDialog,
	Table,
	TableButton,
} from '@kevea/react-components'
import { Form, Formik, FormikProps } from 'formik'
import { Menu, MenuDay } from 'models/menu'
import { SavedMenus } from 'models/savedMenus'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FaPaste } from 'react-icons/fa'
import { useQuery } from 'react-query'
import { Column, useTable } from 'react-table'
import { SavedMenusService } from 'services/savedMenus'
import * as yup from 'yup'

type Props = {
	isOpen: boolean
	onClose: (currentMenuDays: MenuDay[], menuDays?: MenuDay[]) => void
	currentMenuDays: MenuDay[]
}

export function CopyModal({ isOpen, onClose, currentMenuDays }: Props) {
	const {
		data: savedMenus,
		isLoading,
		refetch,
	} = useQuery({
		queryKey: 'savedMenus',
		queryFn: async () => SavedMenusService.getAll().then(res => res.data),
	})

	const [selectedSavedMenuId, setSelectedSavedMenuId] = useState<
		string | undefined
	>()
	const [selectedMenuDays, setSelectedMenuDays] = useState<
		MenuDay[] | undefined
	>(undefined)

	const removeDisclosure = useDisclosure()

	const saveDisclosure = useDisclosure()
	const copyDisclosure = useDisclosure()

	const handleRemove = async () => {
		await SavedMenusService.delete(selectedSavedMenuId)
		setSelectedSavedMenuId(undefined)
		refetch()
		removeDisclosure.onClose()
	}

	const columns = useMemo<Column<SavedMenus>[]>(
		() => [
			{
				Header: 'Nazwa',
				accessor: 'name',
			},
			{
				accessor: '_id',
				id: 'paste',
				Cell: cell => (
					<TableButton
						action={() => {
							setSelectedMenuDays(cell.row.original.menuDays)
							if (currentMenuDays.some(x => x.dishes.length > 0)) {
								copyDisclosure.onOpen()
							} else {
								onClose(currentMenuDays, cell.row.original.menuDays)
							}
						}}
						icon={<FaPaste />}
						label="Wklej"
					/>
				),
			},
			{
				accessor: '_id',
				id: 'delete',
				Cell: cell => (
					<TableButton
						action={() => {
							setSelectedSavedMenuId(cell.value)
							removeDisclosure.onOpen()
						}}
						type="remove"
						label="Usuń"
					/>
				),
			},
		],
		[currentMenuDays],
	)

	const tableInstance = useTable({ columns: columns, data: savedMenus ?? [] })

	return (
		<ModalDialog
			isLoading={isLoading}
			header="Kopiuj/Wklej jadłospis"
			size="2xl"
			isOpen={isOpen}
			onClose={() => onClose(currentMenuDays)}
			customFooter={
				<TableButton label="Zamknij" action={() => onClose(currentMenuDays)} />
			}
		>
			<AlertDialog
				header="Usuń zapisany jadłospis"
				isOpen={removeDisclosure.isOpen}
				onClose={() => removeDisclosure.onClose()}
				onRemove={() => handleRemove()}
			>
				Czy na pewno chcesz usunąć zapisany jadłospis?
			</AlertDialog>
			<AlertDialog
				header="Wklej jadłospis"
				isOpen={copyDisclosure.isOpen}
				onClose={() => {
					setSelectedMenuDays(undefined)
					copyDisclosure.onClose()
				}}
				confirmButtonLabel="Wklej"
				confirmButtonColor="green"
				onRemove={() => {
					copyDisclosure.onClose()
					onClose(currentMenuDays, selectedMenuDays)
					setSelectedMenuDays(undefined)
				}}
			>
				Wklejenie jadłospisu spowoduje <strong>nadpisanie</strong> obecnego.
				<br />
				<br /> Czy na pewno chcesz kontynuować?
			</AlertDialog>
			<SaveCurrentMenu
				isOpen={saveDisclosure.isOpen}
				onClose={() => {
					refetch()
					saveDisclosure.onClose()
				}}
				currentMenu={currentMenuDays}
			/>
			<Table
				tableInstance={tableInstance}
				loading={isLoading}
				buttons={[
					{
						label: 'Zapisz aktualny jadłospis',
						type: 'add',
						action: () => saveDisclosure.onOpen(),
					},
				]}
			/>
		</ModalDialog>
	)
}

type SaveCurrentMenuProps = {
	isOpen: boolean
	onClose: () => void
	currentMenu: MenuDay[]
}
export const SaveCurrentMenu = ({
	isOpen,
	onClose,
	currentMenu,
}: SaveCurrentMenuProps) => {
	const formik = useRef<FormikProps<{ name: string }>>(null)
	const handleSubmit = async (values: { name: string }) => {
		await SavedMenusService.post(new SavedMenus(values.name, currentMenu))
		onClose()
	}

	return (
		<ModalDialog
			header="Zapisz aktualny jadłospis"
			isOpen={isOpen}
			onClose={onClose}
			onSave={() => formik.current.submitForm()}
		>
			<Formik
				innerRef={formik}
				initialValues={{ name: '' }}
				onSubmit={handleSubmit}
				validationSchema={yup.object({
					name: yup.string().required('Nazwa jadłospisu jest wymagana'),
				})}
			>
				<Form>
					<InputField
						label="Nazwa jadłospisu"
						placeholder="Nazwa jadłospisu"
						name="name"
					/>
				</Form>
			</Formik>
		</ModalDialog>
	)
}
