import { RouteObject } from 'react-router-dom'
import { AnnouncementList } from './AnnouncementList'

const announcementsRoutes: RouteObject[] = [
	{
		path: 'announcements',
		element: <AnnouncementList />,
		breadcrumb: 'Lista ogłoszeń',
	},
]

export default announcementsRoutes
