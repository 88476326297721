import { useDisclosure } from '@chakra-ui/react'
import {
	AlertDialog,
	DateFilter,
	formatDate,
	Link,
	Table,
	Tile,
} from '@kevea/react-components'
import { Patient } from 'models/patient'
import { TableTaskPlan, TaskPlan } from 'models/taskPlan'
import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Navigate, useOutletContext } from 'react-router-dom'
import {
	Cell,
	Column,
	useFilters,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table'
import { TaskPlanService } from 'services/taskPlan'
import { PatientContext } from '../Patient'
import { TaskPlanModal } from './TaskPlanModal'

export const TaskPlans = () => {
	const { patient, refetch, titleElement, ...rest } =
		useOutletContext<PatientContext>()
	const [isRemoving, setIsRemoving] = useState(false)

	const [selectedTaskPlan, setSelectedTaskPlan] = useState<
		TaskPlan | undefined
	>(undefined)
	const addDisclosure = useDisclosure()
	const removeDisclosure = useDisclosure()

	const fetchTaskPlans = async () => {
		if (patient) {
			return TaskPlanService.getAll(patient._id).then(res => res.data)
		}
	}

	const {
		data: notes,
		isLoading,
		refetch: refetchTaskPlans,
	} = useQuery('fetchTaskPlans', fetchTaskPlans)

	const data = useMemo(
		() => notes?.map(note => new TableTaskPlan(note)) ?? [],
		[notes],
	)

	const columns = useMemo<Column<TableTaskPlan>[]>(
		() => [
			{
				Header: 'Nazwa',
				accessor: 'title',
				Cell: ({ value, row }) => (
					<Link
						onClick={() => {
							setSelectedTaskPlan(row.original.original)
							addDisclosure.onOpen()
						}}
					>
						{value}
					</Link>
				),
			},
			{
				Header: 'Data rozpoczęcia',
				accessor: 'date',
			},
			{
				Header: 'Godzina',
				accessor: 'time',
			},
			{
				Header: 'Powtarzaj co',
				accessor: 'recuringEvery',
			},
			{
				Header: 'Data zakończenia',
				accessor: 'endDate',
			},
		],
		[],
	)

	const tableInstance = useTable(
		{ data, columns, initialState: { hiddenColumns: ['filterDate'] } },
		useFilters,
		useSortBy,
		usePagination,
		useRowSelect,
	)

	return patient ? (
		<Tile
			{...rest}
			header={`${Patient.getFullName(patient)} - Harmonogram zadań`}
		>
			{patient && (
				<TaskPlanModal
					isOpen={addDisclosure.isOpen}
					selectedTaskPlan={selectedTaskPlan}
					onClose={() => {
						setSelectedTaskPlan(undefined)
						addDisclosure.onClose()
						refetchTaskPlans()
					}}
					patient={patient}
				/>
			)}
			<AlertDialog
				header="Usuń harmonogram zadań"
				onClose={removeDisclosure.onClose}
				isRemoving={isRemoving}
				isOpen={removeDisclosure.isOpen}
				onRemove={() => {
					if (selectedTaskPlan?._id) {
						setIsRemoving(true)
						TaskPlanService.delete(selectedTaskPlan._id, patient._id).then(
							() => {
								setIsRemoving(false)
								setSelectedTaskPlan(undefined)
								removeDisclosure.onClose()
								refetchTaskPlans()
							},
						)
					}
				}}
			>
				Czy chcesz usunąć harmonogram zadań o nazwie{' '}
				<strong>
					{selectedTaskPlan?.type?.name ?? selectedTaskPlan?.title}
				</strong>
				?
			</AlertDialog>
			<Table
				tableInstance={tableInstance}
				loading={isLoading}
				buttons={[
					{
						type: 'add',
						action: () => {
							addDisclosure.onOpen()
						},
					},
				]}
				menuOptions={{
					singleSelection: [
						{
							label: 'Usuń',
							onClick: (notes: TableTaskPlan[]) => {
								setSelectedTaskPlan(notes[0]?.original)
								removeDisclosure.onOpen()
							},
						},
					],
				}}
			/>
		</Tile>
	) : (
		<Navigate to="/patients/new" />
	)
}
