import { useDisclosure } from '@chakra-ui/react'
import { AlertDialog, Link, Table, Tile } from '@kevea/react-components'
import { Device } from 'models/device'
import { SettingsContext } from 'pages/settings/Settings'
import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useOutletContext } from 'react-router-dom'
import {
	Cell,
	Column,
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table'
import { DeviceService } from 'services/device'
import { DeviceModal } from './DeviceModal'

export const DeviceList = () => {
	const { instance, refetch, ...rest } = useOutletContext<SettingsContext>()
	const removeDisclosure = useDisclosure()
	const [isRemoving, setIsRemoving] = useState(false)
	const addDisclosure = useDisclosure()
	const [selectedDevice, setSelectedDevice] = useState<Device | undefined>(
		undefined,
	)

	const {
		data: devices,
		isLoading,
		refetch: refetchDevices,
	} = useQuery('fetchDevices', Device.fetchDevices)

	const data = useMemo(
		() => devices?.map(device => new Device(device)) || [],
		[devices],
	)

	const columns = useMemo<Column<Device>[]>(
		() => [
			{
				Header: 'Nazwa',
				accessor: 'name',
			},
			{
				Header: 'Data wygaśnięcia',
				accessor: 'expiry',
			},
		],
		[],
	)

	const tableInstance = useTable(
		{ columns, data },
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
	)

	return (
		<Tile {...rest}>
			<AlertDialog
				header="Usuń urządzenie"
				onClose={removeDisclosure.onClose}
				isRemoving={isRemoving}
				isOpen={removeDisclosure.isOpen}
				onRemove={() => {
					if (selectedDevice?._id) {
						setIsRemoving(true)
						DeviceService.unregister(selectedDevice._id).then(() => {
							setIsRemoving(false)
							setSelectedDevice(undefined)
							removeDisclosure.onClose()
							refetchDevices()
						})
					}
				}}
			>
				Czy chcesz usunąć urządzenie <strong>{selectedDevice?.name}</strong>?
			</AlertDialog>
			<DeviceModal
				isOpen={addDisclosure.isOpen}
				onClose={() => {
					addDisclosure.onClose()
					refetchDevices()
				}}
			/>
			<Table
				tableInstance={tableInstance}
				loading={isLoading}
				buttons={[
					{
						label: 'Dodaj',
						type: 'add',
						action: () => {
							setSelectedDevice(undefined)
							addDisclosure.onOpen()
						},
					},
				]}
				menuOptions={{
					singleSelection: [
						{
							label: 'Usuń',
							onClick: devices => {
								setSelectedDevice(devices[0])
								removeDisclosure.onOpen()
							},
						},
					],
				}}
			/>
		</Tile>
	)
}
