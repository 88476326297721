import { Box, useDisclosure, Wrap, WrapItem } from '@chakra-ui/react'
import {
	AlertDialog,
	formatDate,
	Link,
	Table,
	Tile,
	TileGrid,
	useErrorToast,
} from '@kevea/react-components'
import { Deposit } from 'models/deposit'
import { Notes } from 'pages/notes/Notes'
import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useOutletContext } from 'react-router-dom'
import {
	Column,
	useTable,
	useGlobalFilter,
	useFilters,
	useSortBy,
	usePagination,
	useRowSelect,
} from 'react-table'
import { DepositService } from 'services/deposit'
import { PatientContext } from '../Patient'
import { DepositModal } from './DepositModal'

type Props = {}

export const Deposits = (props: Props) => {
	const { patient, breadcrumbs } = useOutletContext<PatientContext>()

	const [loading, setLoading] = useState(false)
	const [selectedDeposit, setSelectedDeposit] = useState<Deposit | undefined>(
		undefined,
	)
	const addDisclosure = useDisclosure()
	const removeDisclosure = useDisclosure()
	const error = useErrorToast()

	const print = () => {
		setLoading(true)
		DepositService.print(patient._id)
			.then(blob => {
				if (blob) window.open(URL.createObjectURL(blob), '_blank')
				setLoading(false)
			})
			.catch(e => {
				error({ description: 'Wystąpił błąd podczas generowania wydruku' })
				setLoading(false)
			})
	}

	const remove = () => {
		if (tableInstance.selectedFlatRows.length === 1) {
			setLoading(true)
			DepositService.delete(
				tableInstance.selectedFlatRows[0].original._id,
				patient._id,
			).then(() => {
				setLoading(false)
				removeDisclosure.onClose()
				refetchDeposits()
			})
		}
	}

	const {
		data: deposits,
		isLoading,
		refetch: refetchDeposits,
	} = useQuery('fetchDeposits', () =>
		patient ? Deposit.fetchDeposits(patient) : null,
	)

	const columns = useMemo<Column<Deposit>[]>(
		() => [
			{
				Header: 'Nazwa',
				accessor: 'name',
				Cell: ({ value, row }) => (
					<Link
						onClick={() => {
							setSelectedDeposit(row.original)
							addDisclosure.onOpen()
						}}
					>
						{value}
					</Link>
				),
			},
			{
				Header: 'Opis',
				accessor: 'description',
			},
			{
				Header: 'Ilość',
				accessor: 'amount',
			},
		],
		[],
	)

	const data = useMemo(() => deposits ?? [], [deposits])

	const tableInstance = useTable(
		{ columns: columns, data },
		useGlobalFilter,
		useFilters,
		useSortBy,
		usePagination,
		useRowSelect,
	)

	return (
		<TileGrid>
			<AlertDialog
				isRemoving={isLoading}
				isOpen={removeDisclosure.isOpen}
				onClose={removeDisclosure.onClose}
				header="Usuń depozyt"
				onRemove={remove}
			>
				Czy na pewno chcesz usunąć depozyt{' '}
				<strong>{tableInstance.selectedFlatRows?.[0]?.original?.name}</strong>?
			</AlertDialog>
			<DepositModal
				isOpen={addDisclosure.isOpen}
				onClose={deposit => {
					addDisclosure.onClose()
					if (deposit) {
						setLoading(true)
						if (selectedDeposit) {
							DepositService.put(
								{ _id: selectedDeposit._id, ...deposit },
								patient._id,
							).then(() => {
								setLoading(false)
								refetchDeposits()
							})
						} else {
							DepositService.post(deposit, patient._id).then(() => {
								setLoading(false)
								refetchDeposits()
							})
						}
					}
				}}
				deposit={selectedDeposit}
			/>
			<Tile breadcrumbs={breadcrumbs} header="Depozyty">
				<Table
					tableInstance={tableInstance}
					loading={isLoading || loading}
					buttons={[
						{
							label: 'Dodaj',
							type: 'add',
							action: () => {
								setSelectedDeposit(undefined)
								addDisclosure.onOpen()
							},
						},
						{
							action: print,
							type: 'print',
						},
					]}
					menuOptions={{
						noSelection: [],
						singleSelection: [
							{
								label: 'Usuń',
								onClick: removeDisclosure.onOpen,
							},
						],
						multipleSelection: [],
					}}
				/>
			</Tile>
		</TileGrid>
	)
}
