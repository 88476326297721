import { AxiosResponse } from 'axios'
import { WarehouseDocument } from 'models/warehouseDocument'
import { getBaseInstancePath, printConfig, Service } from 'services'
import http from './axios'

const path = '/warehouseDocuments'

export class WarehouseDocumentService extends Service<WarehouseDocument>({
	path,
}) {
	// static async changeStatus(
	// 	doc: WarehouseDocument,
	// 	status: WarehouseDocument['status'],
	// ): Promise<AxiosResponse<WarehouseDocument>> {
	// 	const newWarehouseDocument: WarehouseDocument = { ...doc }
	// 	doc.status = status
	// 	return http.put(
	// 		getBaseInstancePath(path) + `/${newWarehouseDocument._id}`,
	// 		doc,
	// 	)
	// }
	// static async print(id: string): Promise<Blob> {
	// 	return http
	// 		.get<Blob>(`${getBaseInstancePath(path)}/${id}/print`, printConfig)
	// 		.then(res => res.data)
	// }
}
