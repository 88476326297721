import { ContractTemplate } from 'models/contractTemplate'
import { ModalDialog, Label } from '@kevea/react-components'
import { useState } from 'react'
import { Select } from '@chakra-ui/react'

type Props = {
	isOpen: boolean
	insertText: (text: string) => void
	onClose: () => void
}

const TEMPLATE_OPTIONS: { value: string; label: string }[] = [
	{ label: 'Pacjent - Imię i nazwisko', value: 'patient.fullName' },
	{ label: 'Pacjent - Numer dowodu osobistego', value: 'patient.idNumber' },
	{
		label: 'Pacjent - Organ wydający dowód osobisty',
		value: 'patient.idIssuer',
	},
	{
		label: 'Pacjent - Data ważności dowodu osobistego',
		value: 'patient.idExpiryDate',
	},
	{
		label: 'Pacjent - Adres zamieszkania',
		value: 'patient.address',
	},
	{
		label: 'Pacjent - PESEL',
		value: 'patient.pesel',
	},
	{
		label: 'Pacjent - Data urodzenia',
		value: 'patient.dateOfBirth',
	},
	{
		label: 'Pacjent - Miejsce urodzenia',
		value: 'patient.birthPlace',
	},
	{
		label: 'Pacjent - Numer telefonu',
		value: 'patient.phoneNumber',
	},
	{ label: 'Umowa - Data podpisania', value: 'contractDateOfConclusion' },
	{ label: 'Aneks - Data podpisania', value: 'annexDateOfConclusion' },
	{ label: 'Aneks - Numer', value: 'annexNumber' },
	{ label: 'Umowa - Numer', value: 'contractNumber' },
	{ label: 'Własny numer', value: 'ownNumber' },
	{ label: 'Data od', value: 'validFrom' },
	{ label: 'Data do', value: 'validUntil' },
	{ label: 'Opłata miesięczna', value: 'monthlyCharge' },
	{ label: 'Opłata miesięczna słownie', value: 'monthlyChargeText' },
	{ label: 'Dzień płatności', value: 'paymentDate' },
	{ label: 'Osoba trzecia - Imię i nazwisko', value: 'thirdParty.fullName' },
	{
		label: 'Osoba trzecia - Dokument tożsamości',
		value: 'thirdParty.identification',
	},
	{
		label: 'Osoba trzecia - Adres zamieszkania',
		value: 'thirdParty.address',
	},
	{
		label: 'Osoba trzecia - PESEL',
		value: 'thirdParty.pesel',
	},
	{
		label: 'Osoba trzecia - Data urodzenia',
		value: 'thirdParty.dateOfBirth',
	},
	{
		label: 'Osoba trzecia - Telefon kontaktowy',
		value: 'thirdParty.phoneNumber',
	},
	{
		label: 'Osoba trzecia - Adres Email',
		value: 'thirdParty.emailAddress',
	},
	{
		label: 'Miejsce na podpisy',
		value: 'signatures',
	},
]

export const TemplateModal = (props: Props) => {
	const [value, setValue] = useState('')
	return (
		<>
			{props.isOpen && (
				<ModalDialog
					header="Wstaw wartość"
					onClose={() => props.onClose()}
					onSave={() => {
						props.onClose()
						props.insertText(value)
					}}
					isOpen={props.isOpen}
				>
					<Select
						placeholder="Wybierz..."
						onChange={e => setValue(e.target.value)}
					>
						{TEMPLATE_OPTIONS.map((option, idx) => (
							<option key={idx} value={`{{${option.value}}}`}>
								{option.label}
							</option>
						))}
					</Select>
				</ModalDialog>
			)}
		</>
	)
}
