import { IService } from 'models/service'

export const selectedServiceIdLocalStorageKey = 'selectedServiceId'
export const selectedServiceLocalStorageKey = 'selectedService'

export function getLSServiceId(): string | null {
	return localStorage.getItem(selectedServiceIdLocalStorageKey)
}
export function setLSServiceId(serviceId?: string): void {
	if (serviceId) {
		localStorage.setItem(selectedServiceIdLocalStorageKey, serviceId)
	} else {
		localStorage.removeItem(selectedServiceIdLocalStorageKey)
	}
}
export function getLSService(): IService | null {
	const ls = localStorage.getItem(selectedServiceLocalStorageKey)
	if (!ls) return null
	const parsed = JSON.parse(ls)
	if (
		(typeof parsed === 'object' || typeof parsed === 'function') &&
		parsed !== null
	) {
		const service = parsed as IService
		if (!service._id || !service.rhmConfig) return null
		return service
	}
	return null
}
export function setLSService(service?: IService): void {
	if (service) {
		localStorage.setItem(
			selectedServiceLocalStorageKey,
			JSON.stringify(service),
		)
	} else {
		localStorage.removeItem(selectedServiceLocalStorageKey)
	}
}
export const chooseService = (service: IService) => {
	setLSServiceId(service._id)
	setLSService(service)
	window.location.reload()
}
